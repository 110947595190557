import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_nav/about")({
  component: About,
});

function About() {
  return (
    <div className="min-h-[650px]">
      <div className="container mx-auto px-4 py-8">LMS2 is a learning management system created by Leon Young.</div>
    </div>
  );
}
