
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/_nav/privacy')({
  component: PrivacyPolicy,
})

function PrivacyPolicy() {
  return (
    <div className="font-sans">
      <header className=" py-4">
        <div className="container mx-auto px-4">
          <h1 className="text-3xl font-bold">Privacy Policy</h1>
        </div>
      </header>

      <div className="container mx-auto px-4 py-8">
        <section className="p-6 rounded shadow-md">
          <h2 className="text-2xl font-bold mb-4">Effective Date: January 1, 2024</h2>

          <p className="mb-4">
            At <strong>LMS2</strong> (referred to as "we," "our," or "us"), we are committed to protecting the privacy and security of our users. This Privacy Policy outlines how we collect, use, and share information about you when you use our platform.
          </p>

          <hr className="my-4"/>

          <h3 className="text-xl font-semibold mb-2">1. Information We Collect</h3>
          <ul className="list-disc list-inside mb-4">
            <li>Your name and email address collected through sign up or Google OAuth.</li>
            <li>Course participation data, including progress and responses.</li>
            <li>Any files or content you upload to the platform, such as documents or images.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">2. How We Use Your Information</h3>
          <ul className="list-disc list-inside mb-4">
            <li>To authenticate your identity and grant access to the LMS.</li>
            <li>To facilitate course management and track your learning progress.</li>
            <li>To improve our services through analytics and feedback.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">3. Data Sharing</h3>
          <p className="mb-4">
            We do not sell or rent your personal data. Your information is shared only with:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Google, for authentication purposes through OAuth.</li>
            {/* <li>Third-party service providers that assist in hosting, analytics, or communication services.</li> */}
          </ul>

          <h3 className="text-xl font-semibold mb-2">4. Data Retention</h3>
          <p className="mb-4">
            We retain your data for as long as necessary to provide our services. If you delete your account, we will securely delete your data within 30 days.
          </p>

          <h3 className="text-xl font-semibold mb-2">5. Your Rights</h3>
          <ul className="list-disc list-inside mb-4">
            <li>Access and update your personal information.</li>
            <li>Request the deletion of your data.</li>
            <li>Withdraw consent for data processing at any time.</li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">6. Security</h3>
          <p className="mb-4">
            We implement robust security measures to protect your data from unauthorized access, loss, or misuse. However, no system is completely secure, and we cannot guarantee absolute security.
          </p>

          <h3 className="text-xl font-semibold mb-2">7. Changes to This Policy</h3>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically.
          </p>

          <h3 className="text-xl font-semibold mb-2">8. Contact Us</h3>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <address className="italic">
            Email: leon.young@oracle.com<br/>
          </address>
        </section>
      </div>

      <footer className="py-4">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 LMS2. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}
