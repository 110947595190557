import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
} from "@/components/ui/dropdown-menu";
import { MoveButtons } from "@/lms/core/MoveButtons";
import {
  MenuButton,
  FullDialogButton,
  IconMenuItem,
  CopyCutButtons,
} from "@/components/format/Buttons";

import { DialogForm, useDialog } from "@/components/format/Dialog";

import {
  UnitAddForm,
  UnitDeleteForm,
  UnitEditForm,
  UnitTranslateForm,
} from "./UnitAdminForms";
import { CoursesResponse, UnitsResponse } from "@/lib/pb/types";
import { useLanguage } from "@/lib/lang";
import {
  Pencil,
  Languages,
  Trash2,
  Plus,
  Ellipsis,
  Eye,
  EyeOff,
  SquareArrowUp,
  SquareArrowDown,
} from "lucide-react";
import { LessonAddForm } from "@/lms/lesson/LessonAdminForms";
import { useEditUnitMutation } from "@/lib/pb/lms";
import { useClipboard, useCourse } from "@/lib/store";
import { LessonPasteButton } from "../lesson/LessonPasteButton";
import { Rank } from "../core/Rank";
import { UnitPasteButton } from "./UnitPasteButton";

export default function UnitAdminMenu({
  item,
  items,
}: {
  item: UnitsResponse;
  items: UnitsResponse[];
}) {
  const language = useLanguage();
  const editDialog = useDialog<HTMLDivElement>();
  const deleteDialog = useDialog<HTMLDivElement>();
  const translateDialog = useDialog<HTMLDivElement>();
  const type = "Unit";
  const { id, courseId, active, rank, children: lessons } = item;
  const { mutateAsync: edit, isPending } = useEditUnitMutation(courseId, id);
  const clipboard = useClipboard();

  return (
    <DropdownMenu>
      <MenuButton id={id} size={5} active={active} />

      <DropdownMenuContent
        side="right"
        align="start"
        className="border-2 border-primary/75"
      >
        <div className="flex gap-1">
          <UnitPasteButton
            icon={SquareArrowUp}
            courseId={courseId}
            rank={Rank.above(id, items)}
          />
          <UnitPasteButton
            icon={SquareArrowDown}
            courseId={courseId}
            rank={Rank.below(id, items)}
          />
          {lessons && (
            <LessonPasteButton
              icon={SquareArrowUp}
              courseId={courseId}
              unitId={id}
              rank={Rank.top(null, lessons)}
            />
          )}
          {lessons && (
            <LessonPasteButton
              icon={SquareArrowDown}
              courseId={courseId}
              unitId={id}
              rank={Rank.bottom(null, lessons)}
            />
          )}
        </div>
        <MoveButtons edit={edit} item={item} items={items} />
        <LessonAddForm unit={item} />
        <IconMenuItem
          triggerProps={editDialog.triggerProps}
          label={`Edit ${type}`}
          icon={Pencil}
        />
        <IconMenuItem
          triggerProps={translateDialog.triggerProps}
          label={`Translate ${type}`}
          icon={Languages}
          disabled={!language || language == "en"}
        />
        <CopyCutButtons item={item} type={type} />
        <IconMenuItem
          triggerProps={deleteDialog.triggerProps}
          label={`Delete ${type}`}
          icon={Trash2}
        />
        <IconMenuItem
          label={active ? "Make Inactive" : "Make Active"}
          icon={active ? EyeOff : Eye}
          onClick={async () => {
            if (isPending) return;
            await edit({ id, data: { active: !active } });
          }}
        />
        <DropdownMenuItem className="text-muted-foreground font-xs">
          id: {id} ({rank})
        </DropdownMenuItem>
      </DropdownMenuContent>

      <DialogForm
        item={item}
        dialogProps={editDialog.dialogProps}
        fullScreen={true}
        
        contents={UnitEditForm}
      />
      <DialogForm
        item={item}
        dialogProps={translateDialog.dialogProps}
        fullScreen={true}
        contents={UnitTranslateForm}
      />
      <DialogForm
        item={item}
        dialogProps={deleteDialog.dialogProps}
        className="sm:max-w-md"
        contents={UnitDeleteForm}
      />
    </DropdownMenu>
  );
}

export function CreateUnitButton() {
  const course = useCourse();
  return (
    <FullDialogButton
      icon={Plus}
      label="Create a new unit"
      item={course}
      contents={UnitAddForm}
      className="shadow-md"
    />
  );
}
