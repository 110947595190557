import { zodValidator } from "@/components/form";
import { useCopyCourseMutation } from "@/lib/pb/lms";
import { CoursesResponse } from "@/lib/pb/types";
import { ContentsProps } from "@/components/format/Dialog";
import { ConfirmCopyForm } from "@/components/form/Form";

export function CourseCopyForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  if (!item) return null;
  const { mutateAsync: copy, isPending } = useCopyCourseMutation();

  const options = {
    validatorAdapter: zodValidator(),
    defaultValues: { name: "Copy of " + (item.name ?? "") },
    onSubmit: async ({ value }: { value: Record<string, any> }) => {
      if (isPending) return;
      await copy({ id: item.id, data: value }).catch((e: any) =>
        console.log(e),
      );

      onComplete();
    },
  };

  return (
    <ConfirmCopyForm
      formOptions={options}
      onComplete={onComplete}
      type="Course"
    />
  );
}
