import { createFileRoute, Outlet, Navigate, redirect } from "@tanstack/react-router";
import pb from "@/lib/pb";

// const Component = () => {
//   const { user } = Route.useRouteContext();

//   // console.log("context", context)

//   // const user = useUser();
//   if (user) {
//     return <Outlet />;
//   }

// };

export const Route = createFileRoute("/_auth")({
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!pb.authStore.isValid) {
      throw redirect({
        to: "/start",
      });
    }

    // Otherwise, return the user in context
    return {
      user: pb.authStore.model,
    };
  },
  // beforeLoad: async ({ context }) => {
  //   const queryClient = context.queryClient;

  //   try {
  //     const data = await queryClient.fetchQuery(refreshAuthQuery);
  //     console.log("_auth route refresh", data)
  //     return {user: data.record};
  //   } catch (e) {
  //     return { user: null };
  //   }
  // },
 // component: Component,
});
