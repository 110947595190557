import {
  useEditUserMutation,
  useDeleteUserMutation,
  useUser,
} from "@/lib/pb/lms";
import { ContentsProps, Header } from "@/components/format/Dialog";
import { ConfirmButtons, Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { UsersResponse } from "@/lib/pb/types";
import {
  NameField,
  LastNameField,
  SwitchField,
  GenericInputField,
} from "@/components/form/CustomFields";
import { Label } from "@/components/ui/label";
import { RoleField } from "../core/LmsFields";
import { ConfirmDeleteForm } from "@/components/form/Form";
import { DialogButton } from "@/components/format/Buttons";
import { Key } from "lucide-react";
import { ROLES } from "@/lib/roles";

export function UserEditForm({
  item,
  onComplete,
}: ContentsProps<UsersResponse>) {
  if (!item) return;

  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isInstructor = user?.role >= ROLES.instructor;
  const canEdit = isInstructor && user?.role > item.role;

  const { mutateAsync: edit, isPending } = useEditUserMutation(item.id);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item.name,
      lastName: item.lastName,
      color: item.color,
      locked: item.locked,
      role: item.role,
    },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      await edit({ id: item.id, data: value }).catch((e) => console.log(e));
      onComplete();
    },
  });

  if (!canEdit) return;

  return (
    <div className="grid gap-6">
      <Header
        title="Edit User (Admin)"
        description="Make changes to the user here. Click save when you're done."
      />

      <Form form={form}>
        <div className="grid items-center gap-2 ">
          <Label>Email</Label>
          <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
            {item.email}
          </Label>
        </div>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-2 gap-4">
            <NameField form={form} className="grid items-center gap-2" />
            <LastNameField form={form} className="grid items-center gap-2" />
          </div>
          <div className="flex gap-4 items-center">
            <GenericInputField
              form={form}
              name="color"
              label="Color"
              type="color"
              className="grid items-center gap-2 w-16"
            />
            {isAdmin && (
              <RoleField form={form} className="grid items-center gap-2" />
            )}

            <SwitchField
              form={form}
              className="grid gap-2 items-center"
              name="locked"
              label="Locked"
            />

            {!item.verified && (
              <DialogButton
                icon={Key}
                variant="outline"
                item={item}
                label={"Change Password"}
                contents={ChangePasswordForm}
                className="ms-auto"
                preventDefault={true}
                onComplete={onComplete}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            form={form}
            onComplete={onComplete}
            label="Save changes"
          />
        </div>
      </Form>
    </div>
  );
}

function ChangePasswordForm({
  item,
  onComplete,
}: ContentsProps<UsersResponse>) {
  const user = useUser();
  const isAdmin = user?.role == ROLES.administrator;

  const { mutateAsync: edit, isPending } = useEditUserMutation(item.id);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      oldPassword: "",
      password: "",
      passwordConfirm: "",
    },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      const data = !isAdmin
        ? value
        : { password: value.password, passwordConfirm: value.passwordConfirm };
      console.log(data);
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      onComplete();
    },
  });
  return (
    <div className="grid gap-6">
      <Header
        title="Change User Password"
        description="Make changes to the user here. Click save when you're done."
      />

      <Form form={form} className="grid gap-4">
        <div className="grid gap-4 grid-cols-2">
          <div className="grid items-center gap-2 ">
            <Label>Name</Label>
            <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
              {item.name}
            </Label>
          </div>
          <div className="grid items-center gap-2 ">
            <Label>Email</Label>
            <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
              {item.email}
            </Label>
          </div>
        </div>
        <div className="grid gap-4 py-4">
          <div className="grid gap-4 w-80">
            {!isAdmin && (
              <GenericInputField
                form={form}
                name="password"
                label="Old Password"
                type="oldPassword"
                className="grid items-center gap-2 w-full"
              />
            )}
            <GenericInputField
              form={form}
              name="password"
              label="New Password"
              type="password"
              className="grid items-center gap-2 w-full"
            />
            <GenericInputField
              form={form}
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              className="grid items-center gap-2 w-full"
            />
          </div>
        </div>
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            form={form}
            onComplete={onComplete}
            label="Save changes"
          />
        </div>
      </Form>
    </div>
  );
}

export function UserDeleteForm({
  item,
  onComplete,
}: ContentsProps<UsersResponse>) {
  if (!item) return <div>No class found.</div>;

  const { mutateAsync: deleteClass, isPending } = useDeleteUserMutation();

  const options = {
    validatorAdapter: zodValidator(),
    defaultValues: { email: "" },
    onSubmit: async ({ value }) => {
      if (isPending) return;
      if (value.email !== item.email) return; //test field value
      await deleteClass({ id: item.id }).catch((e) => console.log(e));
      onComplete();
    },
  };

  return (
    <ConfirmDeleteForm
      formOptions={options}
      info={`TODO: add enrollment info`}
      match={item.email}
      onComplete={onComplete}
      type="User"
      fieldName="email"
    />
  );
}

// <div className="text-center">
//   This class has {item.totalEnrollments as number} enrolled and{" "}
//   {item.totalTeams as number} team(s).
// </div>
