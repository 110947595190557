import { AnnotationHandler } from "codehike/code";
import { Link } from "@tanstack/react-router";

export const link: AnnotationHandler = {
  name: "link",
  Inline: ({ annotation, children }) => {
    const { query } = annotation;

    return (
      <Link
        to={query}
        target={"_blank"}
        className="underline"
      >
        {children}
      </Link>
    );
  },
};
