import { queryOptions } from "@tanstack/react-query";
import { useAddMutation, useDeleteMutation } from "./mutations";
import { CommentsRecord, CommentsResponse } from "./types";
import { getFullList, getFullMap, getGroupList } from "@/lib/pb";
import { DEFAULT_STALETIME } from "@/lib/pb/lms";

export const useAddCommentMutation = (receiverId: string, elementId: string) =>
  useAddMutation<CommentsRecord, CommentsResponse>("comments", [
    commentsMapPartialQuery(elementId).queryKey,
    commentsListQuery(receiverId, elementId).queryKey, //full comments
  ]);

export const useDeleteCommentMutation = (receiverId: string, elementId: string) =>
  useDeleteMutation("comments", [
    commentsMapPartialQuery(elementId).queryKey,
    commentsListQuery(receiverId, elementId).queryKey, //full comments
  ]);

export const commentsListQuery = (userId: string, elementId: string) =>
  queryOptions({
    queryKey: ["comments", "list", {userId, elementId}],
    queryFn: () =>
      getFullList<CommentsResponse>("comments", {
        filter: `receiverId="${userId}"&&elementId="${elementId}"`,
        sort: "updated",
      }),
    staleTime: DEFAULT_STALETIME,
    refetchInterval: 1000 * 15,
    // refetchOnWindowFocus: false,
    // refetchOnReconnect: false,
    // refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch comments" },
  });

export const commentsMapPartialQuery = (elementId: string) =>
  queryOptions({
    queryKey: ["comments", "element", "partial", elementId],
    queryFn: () =>
      getGroupList<CommentsResponse>(
        "comments",
        {
          filter: `elementId="${elementId}"`,
         // expand: "classId",
          fields: "receiverId, updated",
          sort: "updated",
        },
        "receiverId",
      ),
    staleTime: Infinity,//DEFAULT_STALETIME,
    refetchOnWindowFocus: false, 
    refetchOnReconnect: false,
    refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch enrollments object" },
  });


export const commentSectionPartialQuery = (
  sectionId: string,
  classId?: string | undefined,
) => {
  //YIKES NO ENROLLMENT ID!!! so no class Id 
  const filter = `elementId.sectionId="${sectionId}"${classId ? `&& classId="${classId}"` : ""}`;
  return queryOptions({
    queryKey: ["comments", "section", "partial", { sectionId }],
    queryFn: () =>
      getGroupList<CommentsResponse>(
        "comments",
        {
          filter,
          fields: "elementId, receiverId",
        },
        "elementId",
      ),
    staleTime: DEFAULT_STALETIME,
    refetchInterval: 1000 * 15,
    meta: { errorMessage: "Failed to fetch submission list (partial)" },
    // notifyOnChangeProps: []
  });
};