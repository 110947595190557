import { FormFieldsProps } from "@/components/form/Form";
import { SwitchField } from "@/components/form/CustomFields";
import {
  CommonAdminFields,
  MarkdownField,
  PlacementSelectField,
  RequiredTextField,
  OptionalTextField,
} from "@/lms/core/LmsFields";
import { SelectField, SelectItem } from "@/components/form/Select";

const Id = "link";
const Name = "Link Button";

const Defaults = {
  url: "",
  description: "",
  label: "",
  type: Id,
  variant: "default",
  isInternal: false,
  placement: "right",
};

const switchClasses = "flex flex-col border-s-2 ps-2 gap-1";

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;

  return (
    <div className="grid gap-4 py-4">
      <div className="flex flex-wrap gap-4">
        <RequiredTextField
          name="url"
          label="URL"
          form={form}
          className="flex flex-col gap-2 w-[400px] "
        />
        <RequiredTextField
          name="label"
          label="Label"
          form={form}
          className="flex flex-col gap-2 w-[400px] "
        />
      </div>
      {!locked && (
        <div className="flex flex-wrap gap-4">
          <OptionalTextField
            name="className"
            label="className"
            form={form}
            className="flex flex-col gap-2 w-[400px] "
          />
          <SwitchField
            form={form}
            className={"grid gap-2"}
            name="isInternal"
            label="Internal Link"
          />
          <SelectField
            form={form}
            name="variant"
            label="Variant"
            className={switchClasses}
          >
            <SelectItem value={"default"}>default</SelectItem>
            <SelectItem value={"secondary"}>secondary</SelectItem>
            <SelectItem value={"accent"}>accent</SelectItem>
            <SelectItem value={"success"}>success</SelectItem>
            <SelectItem value={"warning"}>warning</SelectItem>
            <SelectItem value={"destructive"}>destructive</SelectItem>
            <SelectItem value={"info"}>info</SelectItem>
            <SelectItem value={"outline"}>outline</SelectItem>
            <SelectItem value={"ghost"}>ghost</SelectItem>
          </SelectField>
          <PlacementSelectField className={switchClasses} form={form} />
        </div>
      )}
      <MarkdownField
        form={form}
        name="description"
        size="sm"
        label="Description"
        disabled={isOriginal}
        vertical={!!locked}
      />

      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export default { Id, Name, Defaults, FormFields };
