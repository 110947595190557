import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ReactNode } from "react";

export interface CardFormatProps {
  className?: string;
  title?: ReactNode;
  description?: ReactNode;
  children?: ReactNode;
  headerClass?: string;
  titleClass?: string;
  action?: ReactNode;
}
// <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
//   <CardTitle className="text-sm font-medium">Subscriptions</CardTitle>
//   <Users className="h-4 w-4 text-muted-foreground" />
// </CardHeader>
function Header({
  title,
  description,
  titleClass = "text-xl",
  headerClass = "text-lg",
  action,
}: CardFormatProps) {
  if (!title && !description) return null;
  return (
    <CardHeader className={headerClass}>
      <div className="flex flex-row items-center justify-between space-y-0">
        {title ? <CardTitle className={titleClass}>{title}</CardTitle> : null}
        {action ? action : null}
      </div>
      {description ? <CardDescription>{description}</CardDescription> : null}
    </CardHeader>
  );
}

export function CardFormat({
  children,
  className,

  ...props
}: CardFormatProps) {
  return (
    <Card className={className}>
      <Header {...props} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}
