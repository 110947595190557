import NavBarLeft from "@/components/nav/NavBarLeft";
import NavBarRight from "@/components/nav/NavBarRight";
import NavSheet from "@/components/nav/NavSheet";
import NavBox from "@/components/nav/NavBox";

export default function NavBar() {
  return (
    <>
      <NavBox className=" h-12 lg:h-16 items-center gap-4 border-b bg-background px-4 md:px-10 lg:px-14 xl:px-16">
        <div className="mx-auto flex size-full max-w-container flex-row items-center gap-4 ">
          <NavSheet />
          <NavBarLeft />
          <NavBarRight />
        </div>
      </NavBox>
      
    </>
  );
}
///sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6
//hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6
