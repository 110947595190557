import CodeHike from "@/components/codehike/CodeHike";
//import { CardFormat } from "@/components/format/CardFormat";
import CourseAdminMenu from "@/lms/course/CourseAdminMenu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";
import { Link } from "@tanstack/react-router";
import { CoursesResponse } from "@/lib/pb/types";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";

export function AdminCourseCard({ course }: { course: CoursesResponse }) {
  return (
    <>
      <div className="flex gap-4 w-full">
        <Card className="p-4 w-auto lg:min-w-64">
          <CourseAdminMenu course={course.original || course} />
        </Card>
        <Link className="flex w-full" to={`/course/${course.id}`}>
          <CourseDetails course={course} />
        </Link>
      </div>
    </>
  );
}

export function CourseDetails({
  course,
  showDetails = false,
}: {
  course: CoursesResponse;
  showDetails?: boolean;
}) {
  const { name, description, imageUrl, details } = course;
  //crossOrigin="anonymous"
  return (
    <Card className="bg-background text-foreground py-6 w-full shadow-md">
      <div className="container mx-auto items ">
        <div className="grid lg:grid-cols-2 ">
          <div className="flex flex-col justify-center gap-4">
            <h1 className="text-2xl md:text-3xl xl:text-4xl font-bold mb-4">
              {name}
            </h1>
            <CourseDescription
              description={showDetails && details ? details : description}
            />
          </div>
          <div className="hidden lg:flex flex-1 h-full w-full justify-center">
            {!imageUrl ? null : (
              <img
                src={imageUrl}
                alt="Course Image"
                className="w-auto h-full rounded-lg object-cover max-h-72 bg-white"
              />
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}

export function CourseDetails2({ course }: { course: CoursesResponse }) {
  const { name, description, imageUrl, details } = course;
  //crossOrigin="anonymous"
  return (
    <Card className="bg-background text-foreground p-6 w-full shadow-md grid gap-4">
      <div className="flex gap-4">
        <div className="hidden lg:flex h-full">
          {!imageUrl ? null : (
            <img
              src={imageUrl}
              alt="Course Image"
              className=" h-full rounded-lg object-cover max-h-16 bg-white"
            />
          )}
        </div>
        <div className="flex tracking-tight items-center text-2xl md:text-3xl xl:text-4xl font-bold">
          {name}
        </div>
      </div>
       <CodeHike className="me-auto ms-10" code={details} />
    </Card>
  );
}

export function CourseDescription({ description }: { description: string }) {
  return (
    <ScrollArea className="pe-4">
      <CodeHike className="h-52" code={description} />
    </ScrollArea>
  );
}

// export function CourseDetails2({ course }: { course: any }) {
//   const hasImage = course?.imageUrl;
//   return (
//     <>
//       <Link to={`/${course.id}`}>
//         <Card className="h-full flex">
//           <CardContent className="my-auto">
//             {!hasImage ? null : (
//               <Avatar className="w-32 h-32 md:w-full md:h-full rounded-xl mx-auto">
//                 <AvatarImage src={course.imageUrl} />
//               </Avatar>
//             )}
//           </CardContent>
//         </Card>
//       </Link>
//       <Card className="hidden md:grid md:col-span-2 xl:col-span-3">
//         <CardHeader>
//           <CardTitle className="text-3xl"> {course.name}</CardTitle>
//         </CardHeader>
//         <CardContent>
//           <CourseDescription description={course.description} />
//         </CardContent>
//       </Card>
//     </>
//   );
// }
