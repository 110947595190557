import packageFiles from "./static";
//<script src="https://phaser.portl.page/lib/DisplayError.js" crossorigin="anonymous"></script>
//   <script src="DisplayError.js" crossorigin="anonymous"></script>

//  Un-minified
//https://cdnjs.cloudflare.com/ajax/libs/phaser/3.85.2/phaser.js
//https://cdn.jsdelivr.net/npm/phaser@v3.85.2/dist/phaser.js
//<script src="https://phaser.portl.page/lib/phaser.js" crossorigin="anonymous"></script>

export const indexHtml = `<!DOCTYPE html>
<html lang="en">
  <head>
    <script src="https://phaser.portl.page/lib/DisplayError2.js"></script>
    <script crossorigin src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.7.0/p5.js"></script>
    <script crossorigin src="https://cdnjs.cloudflare.com/ajax/libs/p5.js/1.7.0/addons/p5.sound.min.js"></script>
    <link rel="stylesheet" type="text/css" href="styles.css">
    <meta charset="utf-8" />

  </head>
  <body>
    <main>
    </main>

    <script src="sketch.js"></script>
  </body>
</html>`;

export const sketchJs = `function setup() {
  createCanvas(400, 400);
}

function draw() {
  background(204);

  ellipse (200, 200, 100, 100); // draw a circle in the center of the canvas
} `;

const stylesCss = `html, body {
  margin: 0;
  padding: 0;
  background-color: lightgray;
}

canvas {
  display: block;
}`;

export default {
  activeFile: "sketch.js",
  visibleFiles: ["sketch.js"],
  files: {
    "sketch.js": {
      code: sketchJs,
    },
    "index.html": {
      code: indexHtml,
    },
    "styles.css": {
      code: stylesCss,
    },
    ...packageFiles,
  },
};
