import { WebContainer } from "@webcontainer/api";
import { atom, useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { toast } from "sonner";
import {safeBoot} from "./utils/on-demand-boot";

export const webContainerAtom = atom<WebContainer | null>(null);
export const serverPortAtom = atom<number>(3000);

type WebContainerState = "booting" | "ready" | "none";

export function useServerPort() {
  const [port, setPort] = useAtom(serverPortAtom);

  return () => {
    setPort(port + 1);
    return port + 1;
  };
}

const crossOriginIsolatedErrorMessage = `Failed to execute 'postMessage' on 'Worker': SharedArrayBuffer transfer requires self.crossOriginIsolated.`;

export function useWebContainerManager() {
  const [webContainer, setWebContainer] = useAtom(webContainerAtom);
  const webContainerStatus = useRef<WebContainerState>("none");

  useEffect(() => {
    if (webContainerStatus.current === "none") {
      webContainerStatus.current = "booting";
      //toast.info("Loading web container");
      console.log("🟢 WEBCONTAINER ACTION: Booting");
      safeBoot({
        coep: "credentialless",
        forwardPreviewErrors: true,
      })
        .then((webContainer) => {
          setWebContainer(webContainer);
          webContainerStatus.current = "ready";
          webContainer.on("error", (e) => {
            console.error("WEBCONTAINER: ERROR ERROR", e);
          });
          // webContainer.on("preview-message", (msg) => {
          //   console.error("WEBCONTAINER: preview-message", msg);
          // });
        })

        .catch((error) => {
          console.log("WEBCONTAINER ACTION: Error on boot");
          if (!(error instanceof Error)) return;
          if (error.message === crossOriginIsolatedErrorMessage) {
            error.message += `\n\nSee https://webcontainers.io/guides/quickstart#cross-origin-isolation for more information.
              \nTo fix this error, please set the following headers in your server:\nCross-Origin-Embedder-Policy: require-corp\nCross-Origin-Opener-Policy: same-origin`;
            throw error;
          }
          throw error;
        });
    }

    return () => {
      //toast.info("Stopping web container");
      if (webContainerStatus.current === "ready") {
        console.log("🛑 WEBCONTAINER ACTION: Teardown");
        if (webContainer) {
          webContainer.teardown();
          setWebContainer(null);
        }
        webContainerStatus.current = "none";
      }
    };
  }, [webContainer]);
}
