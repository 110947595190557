import CodeHike from "@/components/codehike/CodeHike";
import { ElementsResponse } from "@/lib/pb/types";


export default function Markdown ({element}: {element: ElementsResponse}) {
  if (!element) return null;
  return (
    <div className="w-full">
      <CodeHike item={element} code={element.data.code} />
    </div>
  )
}

