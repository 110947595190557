import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useQuery, enrollmentsMapQuery } from "@/lib/pb/lms";
import { useUser } from "@/lib/store";
import { ROLES } from "@/lib/roles";
export const Route = createFileRoute("/_auth/")({
  // loader: (opts) =>
  //   opts.context.queryClient.ensureQueryData(enrollmentsListQuery()),
  component: AuthenticatedBase,
});

export function AuthenticatedBase() {
  const user = useUser();
  const {
    data: enrollments,
    isPending,
    error,
  } = useQuery(enrollmentsMapQuery(user?.id)); //used for redirect

  if (error) return "An error has occurred: " + error.message;

  if (isPending) return <div>Checking user enrollment... {user?.id}</div>;

  if (!user) {
    return <div>No Authenticated User Found.</div>;
  }

  if (user.role === ROLES.administrator) {
    return <Navigate to="/courses" />;
  }

  if (enrollments == null || enrollments.size === 0) {
    return <Navigate to="/enroll" />;
  }

  const firstEnrolledCourse = enrollments.values().next().value

  if (!firstEnrolledCourse) {
    return <Navigate to="/enroll" />;
  }
  return <Navigate to={`/course/${firstEnrolledCourse.courseId}`} />;
}
