import { LessonsResponse } from "@/lib/pb/types";
import { cn } from "@/lib/utils";
import { SectionList } from "@/lms/section/SectionList";
import { Collapsible, CollapsibleContent } from "@/components/ui/collapsible";
import { ComponentType, useEffect, useState } from "react";
import {
  Check,
  ChevronDown,
  CircleCheck,
  CircleCheckBig,
  SquareCheck,
  SquareCheckBig,
  Trophy,
} from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useParams } from "@tanstack/react-router";

import { Link } from "@/components/nav/Link";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";


export function LessonList({
  children,
  lessons,
  detail,
  subdetail,
  progress,
}: {
  lessons?: Array<LessonsResponse> | undefined;
  children?: React.ReactNode;
  detail: ComponentType<{ item: any }>;
  subdetail: ComponentType<{ item: any }>;
  progress?: any;
}): React.ReactElement {
  if (!lessons) return <div>Loading Lessons</div>;

  return (
    <ScrollArea className="flex-1">
  
      <div className="flex flex-col gap-2">
        {children}

        {lessons.length < 1 && <div>No Lessons</div>}
        {lessons?.map((lesson) => {
          const lp = progress?.children[lesson.id];
          return (
            <div key={lesson.id}>
              <LessonNode
                item={lesson}
                detail={detail}
                subdetail={subdetail}
                progress={lp}
              />
            </div>
          );
        })}
      </div>
    </ScrollArea>
  );
}

function LessonNode({
  item,
  detail: Detail,
  subdetail,
  progress,
}: {
  item: LessonsResponse;
  detail: ComponentType<{ item: any }>;
  subdetail: ComponentType<{ item: any }>;
  progress?: any;
}): React.ReactElement {
  // const { defaultOpenLevel, prefetch } = useContext(Context);
  const { lessonId } = useParams({
    strict: false,
  });
  const { id, active, url, children: sections } = item;
  const selected = lessonId == id;
  const remaining = progress ? progress.length - progress.completed : null;

  const [open, setOpen] = useState(selected);

  useEffect(() => {
    if (selected) {
      setOpen(true);
    }
  }, [selected]);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className={cn("flex gap-1", active ? "" : "opacity-50")}>
        <Link
          className={cn(
            "relative min-w-8 flex flex-1 items-center gap-2 rounded-md px-1.5 text-sm font-medium outline-none ring-ring transition-all border-2 border-transparent hover:border-primary hover:text-accent-foreground focus-visible:ring-2 ",
            selected ? "bg-primary/25 text-secondary-foreground" : "",
          )}
          to={`${url}#section-top`}
          onClick={() => {
            if (selected || !open) setOpen(!open);
          }}
        >
          <LessonTitle lesson={item} selected={selected} />
          {remaining != null && (
            <div
              className={cn(
                "absolute right-0 top-1 h-4 text-foreground text-xs",
              )}
            >
              {remaining == 0 ? (
                <SquareCheckBig className="text-success" />
              ) : (
                <div className="bg-warning rounded-sm px-1 ">{remaining}</div>
              )}
            </div>
          )}
        </Link>

        {Detail && (
          <div className="my-auto">
            <Detail item={item.original || item} />
          </div>
        )}
      </div>
      <CollapsibleContent>
        <SectionList
          sections={sections}
          detail={subdetail}
          selected={selected}
          progress={progress ? progress : undefined}
        />
      </CollapsibleContent>
    </Collapsible>
  );
}

function LessonTitle({
  lesson,
  selected,
}: {
  lesson: LessonsResponse;
  selected: boolean;
}) {
  const { index, name } = lesson;
  return (
    <div className="flex flex-1 tracking-tight gap-3 me-2">
      <div className="font-bold text-foreground">{index}</div>
      <div className="font-semibold break-normal">
        <BasicMarkdown
          className="line-clamp-2 tracking-tight"
          code={(selected ? "##### " : "###### ") + name}
        />
      </div>
    </div>
  );
}

{
  /* <div className="ms-auto me-2.5">
  <ChevronDown
    data-icon
    className={cn("transition-transform", !open && "rotate-90")}
  />
</div> */
}

// const shouldExtend =
//   active || childActive || (item.defaultOpen ?? defaultOpenLevel >= level);

// {item.sectionsInactive?.length > 0 && (
//   <div className="opacity-50">
//     <SectionList sections={item.sectionsInactive} />
//   </div>
// )}
// useOnChange(shouldExtend, (v) => {
//   if (v) setOpen(v);
// });

// const onClick: React.MouseEventHandler = useCallback(
//   (e) => {
//     if (
//       // clicking on icon
//       (e.target as HTMLElement).hasAttribute("data-icon") ||
//       active
//     ) {
//       setOpen((prev) => !prev);
//       e.preventDefault();
//     }
//   },
//   [active],
// );
// <NodeList
//   className="ms-2 flex flex-col border-s py-2 ps-2"
//   items={item.children}
//   level={level}
// />
