import { useEffect, useMemo, useState } from "react";
import { Table } from "@/components/table";
import { useQuery } from "@tanstack/react-query";
import { classesAdminListQuery } from "@/lib/pb/lms";
import { ColumnDef, RowSelectionState } from "@tanstack/react-table";
import {
  ClassesAdminViewResponse,
  CoursesResponse,
} from "@/lib/pb/types";
import { format } from "date-fns";
import { ClassAdminCard } from "@/lms/classes/ClassAdminCard";


export const CLASSES_COLUMNS: ColumnDef<ClassesAdminViewResponse>[] = [
  {
    accessorFn: (row) => `${row.name}/${row.info}`,
    id: "className",
    header: () => <span>Class</span>,
    cell: (info) => {
      const split = (info.getValue() as string).split("/");
      //const { name, instructor } = info.row;
      return (
        <div className="grid gap-1">
          <span className="font-bold">{split[0]} </span>
          <span className="hidden text-sm text-muted-foreground md:inline">
            {split[1]}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "instructor",
    header: () => "Instructor",
    // meta: { filterVariant: "text", filterName: "Instructor" },
  },
  {
    accessorKey: "startDate",
    header: () => "Start Date",
    //   meta: { filterVariant: "text", filterName: "Start Date" },
    cell: (info) => {
      const value = info.getValue() as string | number | Date;
      return value ? format(value, "PPP") : "";
    },
    //   sortingFn: "datetime",
  },
  {
    accessorKey: "endDate",
    header: () => "End Date",
    //   meta: { filterVariant: "text", filterName: "End Date" },
    cell: (info) => {
      const value = info.getValue() as string | number | Date;
      return value ? format(value, "PPP") : "";
    },
    //   sortingFn: "datetime",
  },
  {
    accessorKey: "totalEnrollments",
    header: () => "Total Enrolled",
    // meta: { filterVariant: "text", filterName: "Instructor" },
  },
  {
    accessorKey: "totalTeams",
    header: () => "Total Teams",
    // meta: { filterVariant: "text", filterName: "Instructor" },
  },
  // {
  //   id: "actions",
  //   cell: ({ row }) => <ClassRowActions row={row} />,
  // },
];

type ClassesTableProps = {
  course: CoursesResponse;
  active?: boolean;
};

export function ClassesTable({ course, active }: ClassesTableProps) {
  const { data: classes, ...classQuery } = useQuery(
    classesAdminListQuery(course.id),
  );

  const columns = useMemo(() => CLASSES_COLUMNS, []);
  const rowSelectionState = useState<RowSelectionState>({});
  const [rowSelection, setRowSelection] = rowSelectionState;
  const [selectedClassId, setSelectedClassId] = useState<string>();

  useEffect(() => {
    if (!rowSelection) return;
    const keys = Object.keys(rowSelection);
    if (keys?.length != 0) {
      setSelectedClassId(keys[0]);
    }
  }, [rowSelection]);

  useEffect(() => {
    setRowSelection({});
    setSelectedClassId(undefined);
  }, [course.id, active]);

  const items =
    typeof active == "boolean"
      ? classes?.filter((item) => item.active == active)
      : classes;

  //Check if selected class has been removed/deleted
  useEffect(() => {
    if (selectedClassId && items) {
      const classItem = items.find((item) => item.id == selectedClassId);
      if (!classItem) {
        setRowSelection({});
        setSelectedClassId(undefined);
      }
    }
  }, [classes]);

  if (classQuery.error)
    return "An error has occurred: " + classQuery.error.message;

  return (
    <div className={"w-full m-auto grid gap-16"}>
      <Table
        data={items}
        columns={columns}
        sortEnabled={true}
        isPending={classQuery.isPending}
        rowSelectionState={rowSelectionState}
      />
      {selectedClassId ? (
        <div className="flex border rounded-md shadow-md p-6 bg-accent/50">
          <ClassAdminCard classId={selectedClassId} />
        </div>
      ) : (
        <div className="h-48"></div>
      )}
    </div>
  );
}

