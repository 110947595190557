import { createFileRoute, Navigate } from "@tanstack/react-router";
import SignupForm from "@/components/auth/SignupForm";
import {CardFormat} from "@/components/format/CardFormat";
import { useUser } from "@/lib/store";
import {useStaticTranslations} from "@/lib/lang";

export const Route = createFileRoute("/_nav/signup")({
  component: Signup,
});

function Signup() {

  const user = useUser();
  const st = useStaticTranslations();
  if (user) {
    return <Navigate to="/" />;
  }

  //const title = form.useStore((state) => state.values.title)
  return (
    <div className="max-w-md mx-auto mt-4">
      <CardFormat
        className="mx-auto max-w-sm shadow-xl"
        title={st("Sign up")}
        description="Enter your information to create an account"
      >
        <SignupForm />
      </CardFormat>
    </div>
  );
}
