import { zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import {
  useEditElementMutation,
  useAddElementMutation,
  useDeleteElementMutation,
  useElementTranslation,
  useQuery,
  elementDetailQuery,
} from "@/lib/pb/lms";
import { ElementsResponse } from "@/lib/pb/types";

import {
  BaseForm,
  TranslateForm,
  ConfirmDeleteForm,
} from "@/components/form/Form";

import { ContentsProps } from "@/components/format/Dialog";
import { useLanguage, getLanguageName } from "@/lib/lang";

import { Rank } from "@/lms/core/Rank";
import { setClipboard, useClipboard } from "@/lib/store";
import Types from "@/lms/elements/Types";
import { memo, useState, useMemo } from "react";
import { getOne } from "@/lib/pb";
import { TranslationReset } from "../translate/TranslationReset";

const BaseDefaults = {
  active: true,
  access: 200,
  isSubmitType: false,
  submissionRequired: false,
};

export const ElementAddForm = memo(NonMemoziedElementAddForm);
function NonMemoziedElementAddForm({
  item,
  onComplete,
}: {
  item: any;
  onComplete: () => void;
}) {
  const { section, type } = item ?? {};
  const { id, courseId, children: elements } = section ?? {};
  const Type = Types[type as keyof typeof Types];
  const { mutateAsync: add, isPending } = useAddElementMutation(courseId, id);
  const [isValid, setIsValid] = useState(true);
  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { ...BaseDefaults, ...Type.Defaults }, //code templates?
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending || !isValid) return;
      const {
        submitButton, //ignored in add
        access, // Base default
        active, // Base default
        submissionRequired, // Base default,
        isSubmitType, // Base default
        translations, // paste json
        type,
        updated,
        created,
        ...elementData // element specific data -> data json field
      } = value;

      const data = {
        courseId: courseId,
        sectionId: id,
        rank: Rank.top(null, elements), //make this configureable
        access,
        active,
        submissionRequired,
        isSubmitType,
        translations,
        type: type ? type : Type.Id,
        data: elementData,
      };
      await add({ data }).catch((e) => console.error(e));
      onComplete();
    },
  });

  return (
    <BaseForm title={`Add ${Type.Name}`} form={form}>
      <Type.FormFields form={form} setIsValid={setIsValid} />
    </BaseForm>
  );
}

export const ElementEditForm = memo(NonMemoziedElementEditForm);
function NonMemoziedElementEditForm({
  item,
  onComplete,
}: ContentsProps<ElementsResponse>) {
  const {
    id, //non-editable
    courseId, //non-editable here
    sectionId, //non-editable here
    rank, //non-editable here
    type, //non-editable
    //isSubmitType, //Code Playgrounds can change their submit type
    parent, // remove injected value
    compiled, // remove injected value
    data, //***element specific data
    updated,
    created,
    ...rest // ***active, access, submissionRequired, isSubmitType
  } = item ?? {};

  const Type = Types[type as keyof typeof Types];
  // Memoize the Type component to prevent unnecessary recreations
  const TypeComponent = useMemo(() => Type.FormFields, [type]);

  //get full item with translation
  const { data: fullItem, tQuery } = useQuery(
    elementDetailQuery(item?.id, true),
  );
  const languages = Object.keys(fullItem?.translations ?? {});

  const { mutateAsync: edit, isPending } = useEditElementMutation(
    courseId,
    sectionId,
    id,
  );

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      ...(data as Object),
      ...rest, // active, access, submissionRequired, isSubmitType,
      submitButton: "submit",
    },

    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      const {
        submitButton, // remove
        access,
        active,
        submissionRequired,
        isSubmitType,
        ...elementData //element specific JSON data
      } = value;
      const data = {
        access,
        active,
        submissionRequired,
        isSubmitType,
        data: elementData,
      };
      await edit({ id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  });

  if (!item) return;

  return (
    <BaseForm
      title={`Edit ${Type.Name}`}
      form={form}
      save={type != "code-playground"}
      extras={
        <TranslationReset
          languages={languages}
          reset={async () => await edit({ id, data: { translations: null } })}
        />
      }
    >
      <TypeComponent form={form} />
    </BaseForm>
  );
}

export function ElementTranslateForm({
  item,
  onComplete,
}: ContentsProps<ElementsResponse>) {
  //fetch element translaton
  const { data, isPending, error } = useElementTranslation(item?.id);

  if (!item || isPending || error) return;

  return (
    <InnerTranslateForm
      item={item}
      onComplete={onComplete}
      translation={data ? data : item}
    />
  );
}

function InnerTranslateForm({
  item,
  translation,
  onComplete,
}: ContentsProps<ElementsResponse> & { translation: any }) {
  const { id, courseId, sectionId, type } = item; //dont pull .data

  const { mutateAsync: edit, isPending } = useEditElementMutation(
    courseId,
    sectionId,
    id,
  );

  const formOptions = {
    validatorAdapter: zodValidator(),
    defaultValues: {
      ...(translation.data as Object), //uses just .data (clean)
      // files,
      // hint,
      // instruction,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      //fetch entire translation JSON because you cant partial update
      const record = await getOne("elements", item.id, {
        fields: "translations",
      });
      const translations = record?.translations ? record.translations : {};
      const { submitButton, ...rest } = value;
      translations[languageCode] = { data: rest };
      await edit({ id, data: { translations } }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  };
  const form = useForm(formOptions);
  const languageCode = useLanguage();
  const languageName = getLanguageName(languageCode);
  const Type = Types[type as keyof typeof Types];

  return (
    <TranslateForm
      form={form}
      fields={Type.FormFields}
      item={item.data}
      language={`${languageName} (${languageCode})`}
      type={Type.Name}
    />
  );
}

export function ElementDeleteForm({
  item,
  onComplete,
}: ContentsProps<ElementsResponse>) {
  const Type = Types[item.type as keyof typeof Types];
  const clipboard = useClipboard();
  const { mutateAsync: remove, isPending } = useDeleteElementMutation(
    item.courseId,
    item.sectionId,
  );

  const options = {
    onSubmit: async () => {
      if (isPending) return;
      await remove({ id: item.id }).catch((e) => console.log(e));
      //clear clipboard if the delete item is in the clipboard
      if (item.id == clipboard?.item?.id) {
        setClipboard(null);
      }
      onComplete();
    },
  };

  return (
    <ConfirmDeleteForm
      formOptions={options}
      onComplete={onComplete}
      type={Type.Name}
    />
  );
}
