import {
  sectionDetailQuery,
  useAddSectionMutation,
  useDeleteSectionMutation,
  useEditSectionMutation,
  useQuery,
  useSectionTranslation,
} from "@/lib/pb/lms";
import { Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import {
  ElementsResponse,
  LessonsResponse,
  SectionsRecord,
  SectionsResponse,
} from "@/lib/pb/types";
import {
  CommonAdminFields,
  NameField,
  RequiredTextField,
} from "@/lms/core/LmsFields";

import { ContentsProps } from "@/components/format/Dialog";
import { setClipboard, useClipboard } from "@/lib/store";

import {
  BaseForm,
  TranslateForm,
  ConfirmDeleteForm,
  FormFieldsProps,
} from "@/components/form/Form";

import { getLanguageName, useLanguage } from "@/lib/lang";

const SECTION_DEFAULTS = {
  name: "",
  active: true,
  access: 200,
  lessonId: "",
  rank: "",
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  return (
    <div className="grid gap-4 py-4 px-2">
      <div className="flex flex-wrap gap-4 w-full">
        <NameField form={form} className="grid gap-2 w-80" />
      </div>
      {!locked && <CommonAdminFields form={form} />}
    </div>
  );
}

export function SectionEditForm({
  item,
  onComplete,
}: ContentsProps<SectionsResponse>) {
  if (!item) return;

  const { mutateAsync: edit, isPending } = useEditSectionMutation(
    item.courseId,
    item.lessonId,
    item.id,
  );

  //get full item with translation
  const { data: fullItem, tQuery } = useQuery(
    sectionDetailQuery(item?.id, true),
  );
  const languages = Object.keys(fullItem?.translations ?? {});

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item?.name,
      active: item?.active,
      access: item?.access,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      const { submitButton, ...rest } = value;
      const data = rest as SectionsRecord;
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  });
  return (
    <BaseForm
      title="Edit Section"
      form={form}
      save={true}
      extras={
        <TranslationReset
          languages={languages}
          reset={async () =>
            await edit({ id: item.id, data: { translations: null } })
          }
        />
      }
    >
      <FormFields form={form} />
    </BaseForm>
  );
}

import { Rank } from "@/lms/core/Rank";
import { arrayToMap, getOne } from "@/lib/pb";
import { useEffect, useState } from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import Types from "../elements/Types";
import { formatData } from "../elements/PasteJsonType";
import { createElement } from "@/lib/pb/copy";
import { TranslationReset } from "../translate/TranslationReset";

export function SectionAddForm({ lesson }: { lesson: LessonsResponse }) {
  const { courseId, id: lessonId, children: sections } = lesson;
  const { mutateAsync: add, isPending } = useAddSectionMutation(
    courseId,
    lessonId,
  );

  const form = useForm<SectionsRecord, any>({
    validatorAdapter: zodValidator(),
    defaultValues: { ...SECTION_DEFAULTS },
    onSubmit: async ({ value, formApi }) => {
      if (isPending) return;
      value.courseId = courseId;
      value.lessonId = lessonId;
      value.rank = Rank.bottom(null, sections);
      await add({ data: value }).catch((e) => console.error(e));

      formApi.reset();
    },
  });

  return (
    <Form form={form}>
      <div className="flex gap-4 my-auto">
        <RequiredTextField
          name="name"
          placeholder="Create a new section"
          form={form}
          className="grid gap-2 w-36 md:w-48 "
          disabled={isPending}
        />
      </div>
    </Form>
  );
}
export function SectionDeleteForm({
  item,
  onComplete,
}: ContentsProps<SectionsResponse>) {
  const { id, courseId, lessonId, name, children: elements } = item ?? {};

  const { mutateAsync: deleteItem, isPending } = useDeleteSectionMutation(
    courseId,
    lessonId,
  );
  const clipboard = useClipboard();

  async function remove() {
    if (isPending) return;
    await deleteItem({ id }).catch((e) => console.log(e));
    if (id == clipboard?.item?.id) {
      setClipboard(null); //clear if item is in clipboard
    }
    onComplete();
  }
  if (!item) return;

  const isEmpty = !elements || elements.length < 1;

  const formProps = isEmpty
    ? { formOptions: { onSubmit: remove } }
    : {
        formOptions: {
          validatorAdapter: zodValidator(),
          defaultValues: { name: "" },
          onSubmit: async ({ value }: { value: SectionsRecord }) => {
            if (value.name !== name) return;
            await remove();
          },
        },
        match: name,
        fieldName: "name",
      };

  return (
    <ConfirmDeleteForm {...formProps} onComplete={onComplete} type="Section" />
  );
}
//Handle Fetch of Translation object
export function SectionTranslateForm({
  item,
  onComplete,
}: ContentsProps<SectionsResponse>) {
  const { data, isPending, error } = useSectionTranslation(item?.id);

  if (!item || isPending || error) return;

  return (
    <InnerTranslateForm
      item={item}
      onComplete={onComplete}
      translation={data ? data : item}
    />
  );
}

function InnerTranslateForm({
  item,
  onComplete,
  translation,
}: ContentsProps<SectionsResponse> & { translation: any }) {
  const { id, courseId, lessonId } = item;
  const { mutateAsync: edit, isPending } = useEditSectionMutation(
    courseId,
    lessonId,
    id,
  );

  const formOptions = {
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: translation.name,
      description: translation.description,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      //fetch entire translation JSON because you cant partial update
      const record = await getOne("sections", item.id, {
        fields: "translations",
      });
      const translations = record?.translations ? record.translations : {};
      const { submitButton, ...rest } = value;
      translations[languageCode] = rest;
      await edit({ id, data: { translations } }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  };
  const form = useForm(formOptions);
  const languageCode = useLanguage();
  const languageName = getLanguageName(languageCode);

  return (
    <TranslateForm
      form={form}
      fields={FormFields}
      item={item}
      language={`${languageName} (${languageCode})`}
      type="Section"
    />
  );
}

// const SECTION_DEFAULTS = {
//   name: "",
//   active: true,
//   access: 200,
//   lessonId: "",
//   rank: "",
// };

export function SectionPasteJsonForm({
  item, //lesson
  onComplete,
}: ContentsProps<LessonsResponse>) {
  const { courseId, id: lessonId, children: sections } = item;
  const { mutateAsync: add, isPending } = useAddSectionMutation(
    courseId,
    lessonId,
  );

  const [content, setContent] = useState("");
  const [object, setObject] = useState(null);
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    navigator.clipboard.readText().then((clipText) => {
      setContent(clipText);
      try {
        const obj = JSON.parse(clipText);
        if (obj && obj.name) {
          setObject(obj);
          setIsValid(true);
        }
      } catch (e) {
        setIsValid(false);
        console.log(e);
      }
    });
  }, []);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: object?.name,
      active: !object?.hidden,
      access: 200,
      courseId: courseId,
      lessonId: lessonId,
      translations: object?.translations,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending || !isValid) return;
      if (!object) return;
      const { submitButton, ...rest } = value;
      const data = rest as SectionsRecord;

      data.rank = Rank.bottom(null, sections);
      const res = await add({ data }).catch((e) => console.log(e));
      if (!res) return;
      const elements = arrayToMap(object.elements, "id");
      const newElements: ElementsResponse[] = [];
      for (const id of object.order) {
        const el = await addJsonElement(
          elements.get(id),
          res.id,
          courseId,
          Rank.bottom(null, newElements),
        );
        if (el) newElements.push(el);
      }
      if (submitButton == "save") return;
      onComplete();
    },
  });

  return (
    <BaseForm title="Paste JSON Section" form={form} save={false}>
      {!isValid && object?.name ? (
        <div className="text-center text-destructive">JSON is not valid.</div>
      ) : (
        <div className="text-center">{object?.name}</div>
      )}
      <ScrollArea>
        <div className="border rounded-xl p-4 max-w-7xl text-muted-foreground text-sm max-h-96">
          {content}
        </div>
      </ScrollArea>
    </BaseForm>
  );
}

async function addJsonElement(
  element: any,
  sectionId: string,
  courseId: string,
  rank: string,
) {
  const { id, data, type, hidden, submissionRequired, translations } = element;
  if (!type || !data) return;
  if (typeof type != "string" || typeof data != "object") return;
  const newData = { data: {} };

  function setField(field: string, value: any) {
    newData[field] = value;
  }

  const Type = Types[type];
  if (
    (Type ||
      type == "code-hike" ||
      type == "markdown-2columns" ||
      type == "markdown-accordion" ||
      type == "external-link" ||
      type.endsWith("-playground")) &&
    hidden !== undefined
  ) {
    const { isSubmitType, submissionRequired: ignore, ...rest } = data;
    setField("type", type);
    setField("access", 200);
    setField("active", hidden ? false : true);
    setField("submissionRequired", submissionRequired);
    setField("isSubmitType", isSubmitType ? true : false);

    let formattedData = formatData(type, rest);

    Object.keys(formattedData).forEach(function (key) {
      if (key == "type") {
        setField(key, formattedData[key]);
      } else {
        newData.data[key] = formattedData[key];
      }
    });

    if (translations) {
      //add {data: }
      Object.keys(translations).forEach(function (key) {
        // need to format presentations
        let { isSubmitType, submissionRequired, ...translationData } =
          translations[key];
        translationData = formatData(type, translationData);
        translations[key] = { data: translationData };
      });
      setField("translations", translations);
    }
    const e = { ...newData, sectionId, courseId, rank }; //new element
    return await createElement(e);
  }
}
