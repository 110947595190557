import { FormFieldsProps } from "@/components/form/Form";

import { CommonAdminFields, MarkdownField } from "@/lms/core/LmsFields";

const Id = "markdown";
const Name = "Markdown";

const Defaults = {
  code: "",
  type: Id,
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;

  return (
    <div className="grid gap-4 py-4">
      <MarkdownField
        form={form}
        name="code"
        label="Markdown"
        disabled={isOriginal}
        vertical={!!locked}
      />

      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export default { Id, Name, Defaults, FormFields };
