import { createFileRoute } from '@tanstack/react-router'
import { useQuery } from '@tanstack/react-query'
import { courseDetailQuery } from '@/lib/pb/lms'
import { CourseDetails } from '@/lms/course/CourseCard'
import { File, ListFilter } from 'lucide-react'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'
import {
  Table,
  TableCell,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
} from '@/components/ui/table'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '@/components/ui/tabs'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
  DropdownMenuLabel,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { UnitsList } from '@/lms/unit/UnitsList'

//import { ClassesCard } from "@/lms/classes/ClassesCard";
import { CoursesResponse } from '@/lib/pb/types'

export const Route = createFileRoute('/_auth/course/$courseId_/old')({
  loader: ({ context: { queryClient }, params: { courseId } }) => {
    const courses = queryClient.getQueryData([
      'courses',
    ]) as Array<CoursesResponse>

    const course = courses?.find((item: any) => item.id === courseId)
    if (course) {
      return queryClient.setQueryData(['courses', courseId], course)
    }
  },
  // errorComponent: PostErrorComponent,
  component: CourseWrapper,
})

export class CourseNotFoundError extends Error {}

function CourseWrapper() {
  return <CourseComponent />
}

//xl:grid-cols-3 md:bg-green-300 lg:bg-blue-500 xl:bg-red-500
function CourseComponent() {
  const courseId = Route.useParams().courseId

  const {
    isPending,
    error,
    data: course,
  } = useQuery(courseDetailQuery(courseId))

  if (error) {
    return <div>An error has occurred: {error.message}</div>
  }

  return (
    <main className="grid flex-1 items-start gap-2  sm:py-0 md:gap-4 xl:grid-cols-3 xl:gap-8 2xl:px-12 ">
      <div>{course && <UnitsList course={course} />}</div>

      <div className="grid auto-rows-max items-start gap-2 md:gap-4 xl:gap-8 lg:col-span-2">
        {isPending ? <div>Loading...</div> : <CourseDetails course={course} />}

        <TabsDemo />
      </div>
      <div>class info</div>
    </main>
  )
}
//  <ClassesCard courseId={courseId} />
//{ course }: { course: any }

function TabsDemo() {
  return (
    <Tabs defaultValue="week">
      <div className="flex items-center">
        <TabsList>
          <TabsTrigger value="week">Week</TabsTrigger>
          <TabsTrigger value="month">Month</TabsTrigger>
          <TabsTrigger value="year">Year</TabsTrigger>
        </TabsList>
        <div className="ml-auto flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" size="sm" className="h-7 gap-1 text-sm">
                <ListFilter className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only">Filter</span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Filter by</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuCheckboxItem checked>
                Fulfilled
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Declined</DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem>Refunded</DropdownMenuCheckboxItem>
            </DropdownMenuContent>
          </DropdownMenu>
          <Button size="sm" variant="outline" className="h-7 gap-1 text-sm">
            <File className="h-3.5 w-3.5" />
            <span className="sr-only sm:not-sr-only">Export</span>
          </Button>
        </div>
      </div>
      <TabsContent value="week">
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="px-7">
            <CardTitle>Units</CardTitle>
            <CardDescription>Recent orders from your store.</CardDescription>
          </CardHeader>
          <CardContent>
            <TableDemo />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  )
}

function TableDemo() {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Customer</TableHead>
          <TableHead className="hidden sm:table-cell">Type</TableHead>
          <TableHead className="hidden sm:table-cell">Status</TableHead>
          <TableHead className="hidden md:table-cell">Date</TableHead>
          <TableHead className="text-right">Amount</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow className="bg-accent">
          <TableCell>
            <div className="font-medium">Liam Johnson</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              liam@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Sale</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-23</TableCell>
          <TableCell className="text-right">$250.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className="font-medium">Olivia Smith</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              olivia@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Refund</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="outline">
              Declined
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-24</TableCell>
          <TableCell className="text-right">$150.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className="font-medium">Noah Williams</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              noah@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Subscription</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-25</TableCell>
          <TableCell className="text-right">$350.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className="font-medium">Emma Brown</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              emma@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Sale</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-26</TableCell>
          <TableCell className="text-right">$450.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className="font-medium">Liam Johnson</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              liam@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Sale</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-23</TableCell>
          <TableCell className="text-right">$250.00</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className="font-medium">Liam Johnson</div>
            <div className="hidden text-sm text-muted-foreground md:inline">
              liam@example.com
            </div>
          </TableCell>
          <TableCell className="hidden sm:table-cell">Sale</TableCell>
          <TableCell className="hidden sm:table-cell">
            <Badge className="text-xs" variant="secondary">
              Fulfilled
            </Badge>
          </TableCell>
          <TableCell className="hidden md:table-cell">2023-06-23</TableCell>
          <TableCell className="text-right">$250.00</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}
