import { z } from "zod";

export const emailSchema = z.string().email("Invalid email address");
export const passwordSchema = z
  .string()
  .min(8, { message: "Password must be at least 8 characters long" })
  .max(32, { message: "Password must be no more than 32 characters long" });
//.regex(/[A-Z]/, { message: "Password must include at least one uppercase letter" })
//.regex(/[a-z]/, { message: "Password must include at least one lowercase letter" })
// .regex(/[0-9]/, { message: "Password must include at least one number" });
//.regex(/[\W_]/, { message: "Password must include at least one special character" });

export const nameSchema = z
  .string()
  .min(1, "Field is required") // Ensure the name is not empty
  .max(100, "Field must not be longer than 100 characters") // Set a maximum length
  .regex(/^[\p{L}\p{N} '-]+$/u, {
    message: "Invalid name format",
  }); // Allow Unicode letters, apostrophes, hyphens, and spaces

export const lastNameSchema = z
  .string()
  .max(100, "Field must not be longer than 100 characters") // Set a maximum length
  .regex(/^[\p{L}\p{N} '-]*$/u, {
    message: "Invalid name format",
  })
  .optional(); // Allow Unicode letters, apostrophes, hyphens, and spaces

export const requiredSchema = z
  .string()
  .min(1, { message: "This field is required." });
