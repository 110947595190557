import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PaginationTable, fuzzySort, fuzzyFilter } from "@/components/table";
import { ElementSubmissionsStats } from "@/lms/element/ElementSubmissionsStats";
import { enrollmentsListQuery } from "@/lib/pb/lms";

import { ColumnDef } from "@tanstack/react-table";
import {
  ElementsResponse,
  EnrollmentsResponse,
  SubmissionsResponse,
} from "@/lib/pb/types";
import { format, formatRelative } from "date-fns";
import { RoleNames } from "@/lib/roles";
import { useClassFilter } from "@/lib/store";
import { submissionElementQuery } from "@/lib/pb/submissions";
import Types from "@/lms/elements/Types";
import { TableComments } from "../comments/Comments";
import { commentsMapPartialQuery } from "@/lib/pb/comment";
import { Avatar } from "@/components/format/Avatar";

import { SortingFn } from "@tanstack/react-table";

export const mySortFunction: SortingFn<any> = (rowA, rowB, columnId) => {
  const a = rowA.original.submission?.data.response;
  const b = rowB.original.submission?.data.response;

  // Handle cases where 'response' might be undefined or null
  if (a == null && b == null) return 0;
  if (a == null) return -1;
  if (b == null) return 1;

  // If 'response' is a string, use localeCompare for proper sorting
  if (typeof a === "string" && typeof b === "string") {
    return a.localeCompare(b);
  }

  // If 'response' is a number, compare numerically
  if (typeof a === "number" && typeof b === "number") {
    return a - b;
  }

  // Add more type checks if needed, or default to zero
  return 0;
};
//Each of these is an enrollment
export const USERS_COLUMNS: ColumnDef<EnrollmentsResponse>[] = [
  {
    accessorFn: (row) => `${row.expand.userId.name}`,
    id: "className",
    header: () => <span>Name</span>,
    cell: (info) => {
      const name = info.getValue() as string;
      return (
        <div className="flex gap-2 items-center">
          <Avatar user={info.row.original.expand.userId} />
          {name}
        </div>
      );
    },
    meta: { filterVariant: "text", filterName: "Name" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  // {
  //   accessorKey: "expand.userId.email",
  //   header: () => "Email",
  //   meta: { filterVariant: "text", filterName: "Email" },
  //   filterFn: fuzzyFilter, //or just define with the function
  //   sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  // },
  {
    accessorKey: "expand.userId.role",
    header: () => "Role",
    cell: (info) => {
      const value = info.getValue() as keyof typeof RoleNames;
      return value + " " + RoleNames[value];
    },
    meta: { filterVariant: "multiselect", filterName: "Role" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorFn: (row) => `${row.element.type}`,
    id: "submission",
    header: () => "Submission",
    cell: (info) => {
      //const type = info.row.getValue("type") as string;
      const type = info.getValue() as string;
      const Type = Types[type];
      // console.log(info.row);
      const submission = info.row.original.submission;
      if (type == "feedback") {
        return <div className="text-muted-foreground">See Comments</div>;
      }
      if (!submission)
        return <div className="text-muted-foreground">No Submission</div>;
      const element = info.row.original.element;
      return <Type.Submission submission={submission} element={element} />;
    },
    sortingFn: mySortFunction,
  },
  {
    accessorFn: (row) => `${row.submission ? row.submission.attempts : 0}`,
    id: "attempts",
    header: () => "Attempts",
  },

  {
    accessorFn: (row) => `${row.comments ? row.comments.length : 0}`,
    id: "comments",
    header: () => "Comments",
    cell: (info) => {
      const enrollment = info.row.original;
      const element = enrollment.element;
      const count = info.getValue() as number;
      if (!element.data.enableComments && element.type != "feedback")
        return <div className="text-muted-foreground">disabled</div>;
      return (
        <TableComments
          userId={enrollment.userId}
          classId={enrollment.classId}
          item={element}
          count={count}
        />
      );
    },
  },
  {
    accessorKey: "lastUpdated",
    header: () => "Updated",
    cell: (info) => {
      const value = info.getValue() as string;
      return value ? formatRelative(value, new Date()) : "";
    },
    sortingFn: "datetime",
  },
];

export function ElementSubmissionsTable({
  element,
}: {
  element: ElementsResponse;
}) {
  const { courseId } = element;
  const [data, setData] = useState<any>();
  const cls = useClassFilter();

  const filter = cls ? `classId="${cls.id}"` : `courseId="${courseId}"`;
  const {
    data: enrollments,
    error,
    isPending,
  } = useQuery(enrollmentsListQuery(filter));

  const {
    data: submissions,
    refetch: refetchSubmissions,
    ...sQuery
  } = useQuery(submissionElementQuery(element.id));

  const {
    data: comments,
    refetch: refetchComments,
    ...cQuery
  } = useQuery(commentsMapPartialQuery(element.id));

  const refetch = () => {
    refetchSubmissions();
    refetchComments();
  };

  const columns = useMemo(() => USERS_COLUMNS, [enrollments, submissions]);

  useEffect(() => {
    if (!sQuery.isPending) refetchSubmissions();
    if (!cQuery.isPending) refetchComments();
  }, []);

  useEffect(() => {
    if (enrollments && submissions && comments) {
      let previous = null;
      enrollments.forEach((enrollment) => {
        enrollment.element = element;

        const userComments = comments.get(enrollment.userId);
        if (userComments && userComments.length > 0) {
          enrollment.lastUpdated =
            userComments[userComments.length - 1].updated;
        }
        enrollment.comments = userComments;
        const submission = submissions.get(enrollment.id);
        //inject element to show in response column
        if (submission) {
          submission.userId = enrollment.userId;
          submission.classId = enrollment.classId;
          submission.name = enrollment.expand?.userId?.name;
          submission.lastName = enrollment.expand?.userId?.lastName;
          submission.email = enrollment.expand?.userId?.email;
          submission.commentsLength = userComments ? userComments.length : 0;
          if (previous) {
            previous.next = submission;
          }
          submission.previous = previous;
          previous = submission;
          enrollment.submission = submission;
          if (
            !enrollment.lastUpdated ||
            new Date(submission.updated) > new Date(enrollment.lastUpdated)
          ) {
            enrollment.lastUpdated = submission.updated;
          }
        }
      });
      setData([...enrollments]);
    }
  }, [submissions, enrollments, comments]);

  if (error) return "An error has occurred: " + error.message;
  if (sQuery.error) return "An error has occurred: " + sQuery.error.message;
  if (isPending || sQuery.isPending) return "Loading...";

  return (
    <div className="p-2 w-full m-auto max-w-9xl grid gap-4">
      <ElementSubmissionsStats items={enrollments} element={element} />

      <PaginationTable
        data={data}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
        refetch={refetch}

      />
    </div>
  );
}
