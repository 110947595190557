/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as NavImport } from './routes/_nav'
import { Route as AuthImport } from './routes/_auth'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as NavStartImport } from './routes/_nav/start'
import { Route as NavSignupImport } from './routes/_nav/signup'
import { Route as NavPrivacyImport } from './routes/_nav/privacy'
import { Route as NavLoginImport } from './routes/_nav/login'
import { Route as NavDashboardImport } from './routes/_nav/dashboard'
import { Route as NavAboutImport } from './routes/_nav/about'
import { Route as AuthProfileImport } from './routes/_auth/profile'
import { Route as AuthEnrollImport } from './routes/_auth/enroll'
import { Route as AuthSiteImport } from './routes/_auth/_site'
import { Route as AuthCourseCourseIdImport } from './routes/_auth/course/$courseId'
import { Route as AuthSiteUsersImport } from './routes/_auth/_site.users'
import { Route as AuthSiteCoursesImport } from './routes/_auth/_site.courses'
import { Route as AuthCourseCourseIdIndexImport } from './routes/_auth/course/$courseId.index'
import { Route as AuthCourseCourseIdOldImport } from './routes/_auth/course/$courseId_.old'
import { Route as AuthCourseCourseIdAdminImport } from './routes/_auth/course/$courseId_.admin'
import { Route as AuthCourseCourseIdUnitIdImport } from './routes/_auth/course/$courseId/$unitId'
import { Route as AuthCourseCourseIdUnitIdIndexImport } from './routes/_auth/course/$courseId/$unitId/index'
import { Route as AuthCourseCourseIdUnitIdLessonIdImport } from './routes/_auth/course/$courseId/$unitId/$lessonId'
import { Route as AuthCourseCourseIdUnitIdLessonIdIndexImport } from './routes/_auth/course/$courseId/$unitId/$lessonId/index'
import { Route as AuthCourseCourseIdUnitIdLessonIdSectionIdImport } from './routes/_auth/course/$courseId/$unitId/$lessonId/$sectionId'

// Create/Update Routes

const NavRoute = NavImport.update({
  id: '/_nav',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRoute,
} as any)

const NavStartRoute = NavStartImport.update({
  id: '/start',
  path: '/start',
  getParentRoute: () => NavRoute,
} as any)

const NavSignupRoute = NavSignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => NavRoute,
} as any)

const NavPrivacyRoute = NavPrivacyImport.update({
  id: '/privacy',
  path: '/privacy',
  getParentRoute: () => NavRoute,
} as any)

const NavLoginRoute = NavLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => NavRoute,
} as any)

const NavDashboardRoute = NavDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => NavRoute,
} as any)

const NavAboutRoute = NavAboutImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => NavRoute,
} as any)

const AuthProfileRoute = AuthProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthRoute,
} as any)

const AuthEnrollRoute = AuthEnrollImport.update({
  id: '/enroll',
  path: '/enroll',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSiteRoute = AuthSiteImport.update({
  id: '/_site',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCourseCourseIdRoute = AuthCourseCourseIdImport.update({
  id: '/course/$courseId',
  path: '/course/$courseId',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSiteUsersRoute = AuthSiteUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AuthSiteRoute,
} as any)

const AuthSiteCoursesRoute = AuthSiteCoursesImport.update({
  id: '/courses',
  path: '/courses',
  getParentRoute: () => AuthSiteRoute,
} as any)

const AuthCourseCourseIdIndexRoute = AuthCourseCourseIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthCourseCourseIdRoute,
} as any)

const AuthCourseCourseIdOldRoute = AuthCourseCourseIdOldImport.update({
  id: '/course/$courseId_/old',
  path: '/course/$courseId/old',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCourseCourseIdAdminRoute = AuthCourseCourseIdAdminImport.update({
  id: '/course/$courseId_/admin',
  path: '/course/$courseId/admin',
  getParentRoute: () => AuthRoute,
} as any)

const AuthCourseCourseIdUnitIdRoute = AuthCourseCourseIdUnitIdImport.update({
  id: '/$unitId',
  path: '/$unitId',
  getParentRoute: () => AuthCourseCourseIdRoute,
} as any)

const AuthCourseCourseIdUnitIdIndexRoute =
  AuthCourseCourseIdUnitIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthCourseCourseIdUnitIdRoute,
  } as any)

const AuthCourseCourseIdUnitIdLessonIdRoute =
  AuthCourseCourseIdUnitIdLessonIdImport.update({
    id: '/$lessonId',
    path: '/$lessonId',
    getParentRoute: () => AuthCourseCourseIdUnitIdRoute,
  } as any)

const AuthCourseCourseIdUnitIdLessonIdIndexRoute =
  AuthCourseCourseIdUnitIdLessonIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AuthCourseCourseIdUnitIdLessonIdRoute,
  } as any)

const AuthCourseCourseIdUnitIdLessonIdSectionIdRoute =
  AuthCourseCourseIdUnitIdLessonIdSectionIdImport.update({
    id: '/$sectionId',
    path: '/$sectionId',
    getParentRoute: () => AuthCourseCourseIdUnitIdLessonIdRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_nav': {
      id: '/_nav'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof NavImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_site': {
      id: '/_auth/_site'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthSiteImport
      parentRoute: typeof AuthImport
    }
    '/_auth/enroll': {
      id: '/_auth/enroll'
      path: '/enroll'
      fullPath: '/enroll'
      preLoaderRoute: typeof AuthEnrollImport
      parentRoute: typeof AuthImport
    }
    '/_auth/profile': {
      id: '/_auth/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthProfileImport
      parentRoute: typeof AuthImport
    }
    '/_nav/about': {
      id: '/_nav/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof NavAboutImport
      parentRoute: typeof NavImport
    }
    '/_nav/dashboard': {
      id: '/_nav/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof NavDashboardImport
      parentRoute: typeof NavImport
    }
    '/_nav/login': {
      id: '/_nav/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof NavLoginImport
      parentRoute: typeof NavImport
    }
    '/_nav/privacy': {
      id: '/_nav/privacy'
      path: '/privacy'
      fullPath: '/privacy'
      preLoaderRoute: typeof NavPrivacyImport
      parentRoute: typeof NavImport
    }
    '/_nav/signup': {
      id: '/_nav/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof NavSignupImport
      parentRoute: typeof NavImport
    }
    '/_nav/start': {
      id: '/_nav/start'
      path: '/start'
      fullPath: '/start'
      preLoaderRoute: typeof NavStartImport
      parentRoute: typeof NavImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_site/courses': {
      id: '/_auth/_site/courses'
      path: '/courses'
      fullPath: '/courses'
      preLoaderRoute: typeof AuthSiteCoursesImport
      parentRoute: typeof AuthSiteImport
    }
    '/_auth/_site/users': {
      id: '/_auth/_site/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthSiteUsersImport
      parentRoute: typeof AuthSiteImport
    }
    '/_auth/course/$courseId': {
      id: '/_auth/course/$courseId'
      path: '/course/$courseId'
      fullPath: '/course/$courseId'
      preLoaderRoute: typeof AuthCourseCourseIdImport
      parentRoute: typeof AuthImport
    }
    '/_auth/course/$courseId/$unitId': {
      id: '/_auth/course/$courseId/$unitId'
      path: '/$unitId'
      fullPath: '/course/$courseId/$unitId'
      preLoaderRoute: typeof AuthCourseCourseIdUnitIdImport
      parentRoute: typeof AuthCourseCourseIdImport
    }
    '/_auth/course/$courseId_/admin': {
      id: '/_auth/course/$courseId_/admin'
      path: '/course/$courseId/admin'
      fullPath: '/course/$courseId/admin'
      preLoaderRoute: typeof AuthCourseCourseIdAdminImport
      parentRoute: typeof AuthImport
    }
    '/_auth/course/$courseId_/old': {
      id: '/_auth/course/$courseId_/old'
      path: '/course/$courseId/old'
      fullPath: '/course/$courseId/old'
      preLoaderRoute: typeof AuthCourseCourseIdOldImport
      parentRoute: typeof AuthImport
    }
    '/_auth/course/$courseId/': {
      id: '/_auth/course/$courseId/'
      path: '/'
      fullPath: '/course/$courseId/'
      preLoaderRoute: typeof AuthCourseCourseIdIndexImport
      parentRoute: typeof AuthCourseCourseIdImport
    }
    '/_auth/course/$courseId/$unitId/$lessonId': {
      id: '/_auth/course/$courseId/$unitId/$lessonId'
      path: '/$lessonId'
      fullPath: '/course/$courseId/$unitId/$lessonId'
      preLoaderRoute: typeof AuthCourseCourseIdUnitIdLessonIdImport
      parentRoute: typeof AuthCourseCourseIdUnitIdImport
    }
    '/_auth/course/$courseId/$unitId/': {
      id: '/_auth/course/$courseId/$unitId/'
      path: '/'
      fullPath: '/course/$courseId/$unitId/'
      preLoaderRoute: typeof AuthCourseCourseIdUnitIdIndexImport
      parentRoute: typeof AuthCourseCourseIdUnitIdImport
    }
    '/_auth/course/$courseId/$unitId/$lessonId/$sectionId': {
      id: '/_auth/course/$courseId/$unitId/$lessonId/$sectionId'
      path: '/$sectionId'
      fullPath: '/course/$courseId/$unitId/$lessonId/$sectionId'
      preLoaderRoute: typeof AuthCourseCourseIdUnitIdLessonIdSectionIdImport
      parentRoute: typeof AuthCourseCourseIdUnitIdLessonIdImport
    }
    '/_auth/course/$courseId/$unitId/$lessonId/': {
      id: '/_auth/course/$courseId/$unitId/$lessonId/'
      path: '/'
      fullPath: '/course/$courseId/$unitId/$lessonId/'
      preLoaderRoute: typeof AuthCourseCourseIdUnitIdLessonIdIndexImport
      parentRoute: typeof AuthCourseCourseIdUnitIdLessonIdImport
    }
  }
}

// Create and export the route tree

interface AuthSiteRouteChildren {
  AuthSiteCoursesRoute: typeof AuthSiteCoursesRoute
  AuthSiteUsersRoute: typeof AuthSiteUsersRoute
}

const AuthSiteRouteChildren: AuthSiteRouteChildren = {
  AuthSiteCoursesRoute: AuthSiteCoursesRoute,
  AuthSiteUsersRoute: AuthSiteUsersRoute,
}

const AuthSiteRouteWithChildren = AuthSiteRoute._addFileChildren(
  AuthSiteRouteChildren,
)

interface AuthCourseCourseIdUnitIdLessonIdRouteChildren {
  AuthCourseCourseIdUnitIdLessonIdSectionIdRoute: typeof AuthCourseCourseIdUnitIdLessonIdSectionIdRoute
  AuthCourseCourseIdUnitIdLessonIdIndexRoute: typeof AuthCourseCourseIdUnitIdLessonIdIndexRoute
}

const AuthCourseCourseIdUnitIdLessonIdRouteChildren: AuthCourseCourseIdUnitIdLessonIdRouteChildren =
  {
    AuthCourseCourseIdUnitIdLessonIdSectionIdRoute:
      AuthCourseCourseIdUnitIdLessonIdSectionIdRoute,
    AuthCourseCourseIdUnitIdLessonIdIndexRoute:
      AuthCourseCourseIdUnitIdLessonIdIndexRoute,
  }

const AuthCourseCourseIdUnitIdLessonIdRouteWithChildren =
  AuthCourseCourseIdUnitIdLessonIdRoute._addFileChildren(
    AuthCourseCourseIdUnitIdLessonIdRouteChildren,
  )

interface AuthCourseCourseIdUnitIdRouteChildren {
  AuthCourseCourseIdUnitIdLessonIdRoute: typeof AuthCourseCourseIdUnitIdLessonIdRouteWithChildren
  AuthCourseCourseIdUnitIdIndexRoute: typeof AuthCourseCourseIdUnitIdIndexRoute
}

const AuthCourseCourseIdUnitIdRouteChildren: AuthCourseCourseIdUnitIdRouteChildren =
  {
    AuthCourseCourseIdUnitIdLessonIdRoute:
      AuthCourseCourseIdUnitIdLessonIdRouteWithChildren,
    AuthCourseCourseIdUnitIdIndexRoute: AuthCourseCourseIdUnitIdIndexRoute,
  }

const AuthCourseCourseIdUnitIdRouteWithChildren =
  AuthCourseCourseIdUnitIdRoute._addFileChildren(
    AuthCourseCourseIdUnitIdRouteChildren,
  )

interface AuthCourseCourseIdRouteChildren {
  AuthCourseCourseIdUnitIdRoute: typeof AuthCourseCourseIdUnitIdRouteWithChildren
  AuthCourseCourseIdIndexRoute: typeof AuthCourseCourseIdIndexRoute
}

const AuthCourseCourseIdRouteChildren: AuthCourseCourseIdRouteChildren = {
  AuthCourseCourseIdUnitIdRoute: AuthCourseCourseIdUnitIdRouteWithChildren,
  AuthCourseCourseIdIndexRoute: AuthCourseCourseIdIndexRoute,
}

const AuthCourseCourseIdRouteWithChildren =
  AuthCourseCourseIdRoute._addFileChildren(AuthCourseCourseIdRouteChildren)

interface AuthRouteChildren {
  AuthSiteRoute: typeof AuthSiteRouteWithChildren
  AuthEnrollRoute: typeof AuthEnrollRoute
  AuthProfileRoute: typeof AuthProfileRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthCourseCourseIdRoute: typeof AuthCourseCourseIdRouteWithChildren
  AuthCourseCourseIdAdminRoute: typeof AuthCourseCourseIdAdminRoute
  AuthCourseCourseIdOldRoute: typeof AuthCourseCourseIdOldRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthSiteRoute: AuthSiteRouteWithChildren,
  AuthEnrollRoute: AuthEnrollRoute,
  AuthProfileRoute: AuthProfileRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthCourseCourseIdRoute: AuthCourseCourseIdRouteWithChildren,
  AuthCourseCourseIdAdminRoute: AuthCourseCourseIdAdminRoute,
  AuthCourseCourseIdOldRoute: AuthCourseCourseIdOldRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface NavRouteChildren {
  NavAboutRoute: typeof NavAboutRoute
  NavDashboardRoute: typeof NavDashboardRoute
  NavLoginRoute: typeof NavLoginRoute
  NavPrivacyRoute: typeof NavPrivacyRoute
  NavSignupRoute: typeof NavSignupRoute
  NavStartRoute: typeof NavStartRoute
}

const NavRouteChildren: NavRouteChildren = {
  NavAboutRoute: NavAboutRoute,
  NavDashboardRoute: NavDashboardRoute,
  NavLoginRoute: NavLoginRoute,
  NavPrivacyRoute: NavPrivacyRoute,
  NavSignupRoute: NavSignupRoute,
  NavStartRoute: NavStartRoute,
}

const NavRouteWithChildren = NavRoute._addFileChildren(NavRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthSiteRouteWithChildren
  '/enroll': typeof AuthEnrollRoute
  '/profile': typeof AuthProfileRoute
  '/about': typeof NavAboutRoute
  '/dashboard': typeof NavDashboardRoute
  '/login': typeof NavLoginRoute
  '/privacy': typeof NavPrivacyRoute
  '/signup': typeof NavSignupRoute
  '/start': typeof NavStartRoute
  '/': typeof AuthIndexRoute
  '/courses': typeof AuthSiteCoursesRoute
  '/users': typeof AuthSiteUsersRoute
  '/course/$courseId': typeof AuthCourseCourseIdRouteWithChildren
  '/course/$courseId/$unitId': typeof AuthCourseCourseIdUnitIdRouteWithChildren
  '/course/$courseId/admin': typeof AuthCourseCourseIdAdminRoute
  '/course/$courseId/old': typeof AuthCourseCourseIdOldRoute
  '/course/$courseId/': typeof AuthCourseCourseIdIndexRoute
  '/course/$courseId/$unitId/$lessonId': typeof AuthCourseCourseIdUnitIdLessonIdRouteWithChildren
  '/course/$courseId/$unitId/': typeof AuthCourseCourseIdUnitIdIndexRoute
  '/course/$courseId/$unitId/$lessonId/$sectionId': typeof AuthCourseCourseIdUnitIdLessonIdSectionIdRoute
  '/course/$courseId/$unitId/$lessonId/': typeof AuthCourseCourseIdUnitIdLessonIdIndexRoute
}

export interface FileRoutesByTo {
  '': typeof AuthSiteRouteWithChildren
  '/enroll': typeof AuthEnrollRoute
  '/profile': typeof AuthProfileRoute
  '/about': typeof NavAboutRoute
  '/dashboard': typeof NavDashboardRoute
  '/login': typeof NavLoginRoute
  '/privacy': typeof NavPrivacyRoute
  '/signup': typeof NavSignupRoute
  '/start': typeof NavStartRoute
  '/': typeof AuthIndexRoute
  '/courses': typeof AuthSiteCoursesRoute
  '/users': typeof AuthSiteUsersRoute
  '/course/$courseId/admin': typeof AuthCourseCourseIdAdminRoute
  '/course/$courseId/old': typeof AuthCourseCourseIdOldRoute
  '/course/$courseId': typeof AuthCourseCourseIdIndexRoute
  '/course/$courseId/$unitId': typeof AuthCourseCourseIdUnitIdIndexRoute
  '/course/$courseId/$unitId/$lessonId/$sectionId': typeof AuthCourseCourseIdUnitIdLessonIdSectionIdRoute
  '/course/$courseId/$unitId/$lessonId': typeof AuthCourseCourseIdUnitIdLessonIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/_nav': typeof NavRouteWithChildren
  '/_auth/_site': typeof AuthSiteRouteWithChildren
  '/_auth/enroll': typeof AuthEnrollRoute
  '/_auth/profile': typeof AuthProfileRoute
  '/_nav/about': typeof NavAboutRoute
  '/_nav/dashboard': typeof NavDashboardRoute
  '/_nav/login': typeof NavLoginRoute
  '/_nav/privacy': typeof NavPrivacyRoute
  '/_nav/signup': typeof NavSignupRoute
  '/_nav/start': typeof NavStartRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/_site/courses': typeof AuthSiteCoursesRoute
  '/_auth/_site/users': typeof AuthSiteUsersRoute
  '/_auth/course/$courseId': typeof AuthCourseCourseIdRouteWithChildren
  '/_auth/course/$courseId/$unitId': typeof AuthCourseCourseIdUnitIdRouteWithChildren
  '/_auth/course/$courseId_/admin': typeof AuthCourseCourseIdAdminRoute
  '/_auth/course/$courseId_/old': typeof AuthCourseCourseIdOldRoute
  '/_auth/course/$courseId/': typeof AuthCourseCourseIdIndexRoute
  '/_auth/course/$courseId/$unitId/$lessonId': typeof AuthCourseCourseIdUnitIdLessonIdRouteWithChildren
  '/_auth/course/$courseId/$unitId/': typeof AuthCourseCourseIdUnitIdIndexRoute
  '/_auth/course/$courseId/$unitId/$lessonId/$sectionId': typeof AuthCourseCourseIdUnitIdLessonIdSectionIdRoute
  '/_auth/course/$courseId/$unitId/$lessonId/': typeof AuthCourseCourseIdUnitIdLessonIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/enroll'
    | '/profile'
    | '/about'
    | '/dashboard'
    | '/login'
    | '/privacy'
    | '/signup'
    | '/start'
    | '/'
    | '/courses'
    | '/users'
    | '/course/$courseId'
    | '/course/$courseId/$unitId'
    | '/course/$courseId/admin'
    | '/course/$courseId/old'
    | '/course/$courseId/'
    | '/course/$courseId/$unitId/$lessonId'
    | '/course/$courseId/$unitId/'
    | '/course/$courseId/$unitId/$lessonId/$sectionId'
    | '/course/$courseId/$unitId/$lessonId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/enroll'
    | '/profile'
    | '/about'
    | '/dashboard'
    | '/login'
    | '/privacy'
    | '/signup'
    | '/start'
    | '/'
    | '/courses'
    | '/users'
    | '/course/$courseId/admin'
    | '/course/$courseId/old'
    | '/course/$courseId'
    | '/course/$courseId/$unitId'
    | '/course/$courseId/$unitId/$lessonId/$sectionId'
    | '/course/$courseId/$unitId/$lessonId'
  id:
    | '__root__'
    | '/_auth'
    | '/_nav'
    | '/_auth/_site'
    | '/_auth/enroll'
    | '/_auth/profile'
    | '/_nav/about'
    | '/_nav/dashboard'
    | '/_nav/login'
    | '/_nav/privacy'
    | '/_nav/signup'
    | '/_nav/start'
    | '/_auth/'
    | '/_auth/_site/courses'
    | '/_auth/_site/users'
    | '/_auth/course/$courseId'
    | '/_auth/course/$courseId/$unitId'
    | '/_auth/course/$courseId_/admin'
    | '/_auth/course/$courseId_/old'
    | '/_auth/course/$courseId/'
    | '/_auth/course/$courseId/$unitId/$lessonId'
    | '/_auth/course/$courseId/$unitId/'
    | '/_auth/course/$courseId/$unitId/$lessonId/$sectionId'
    | '/_auth/course/$courseId/$unitId/$lessonId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  NavRoute: typeof NavRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  NavRoute: NavRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/_nav"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_site",
        "/_auth/enroll",
        "/_auth/profile",
        "/_auth/",
        "/_auth/course/$courseId",
        "/_auth/course/$courseId_/admin",
        "/_auth/course/$courseId_/old"
      ]
    },
    "/_nav": {
      "filePath": "_nav.tsx",
      "children": [
        "/_nav/about",
        "/_nav/dashboard",
        "/_nav/login",
        "/_nav/privacy",
        "/_nav/signup",
        "/_nav/start"
      ]
    },
    "/_auth/_site": {
      "filePath": "_auth/_site.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_site/courses",
        "/_auth/_site/users"
      ]
    },
    "/_auth/enroll": {
      "filePath": "_auth/enroll.tsx",
      "parent": "/_auth"
    },
    "/_auth/profile": {
      "filePath": "_auth/profile.tsx",
      "parent": "/_auth"
    },
    "/_nav/about": {
      "filePath": "_nav/about.tsx",
      "parent": "/_nav"
    },
    "/_nav/dashboard": {
      "filePath": "_nav/dashboard.tsx",
      "parent": "/_nav"
    },
    "/_nav/login": {
      "filePath": "_nav/login.tsx",
      "parent": "/_nav"
    },
    "/_nav/privacy": {
      "filePath": "_nav/privacy.tsx",
      "parent": "/_nav"
    },
    "/_nav/signup": {
      "filePath": "_nav/signup.tsx",
      "parent": "/_nav"
    },
    "/_nav/start": {
      "filePath": "_nav/start.tsx",
      "parent": "/_nav"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/_site/courses": {
      "filePath": "_auth/_site.courses.tsx",
      "parent": "/_auth/_site"
    },
    "/_auth/_site/users": {
      "filePath": "_auth/_site.users.tsx",
      "parent": "/_auth/_site"
    },
    "/_auth/course/$courseId": {
      "filePath": "_auth/course/$courseId.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/course/$courseId/$unitId",
        "/_auth/course/$courseId/"
      ]
    },
    "/_auth/course/$courseId/$unitId": {
      "filePath": "_auth/course/$courseId/$unitId.tsx",
      "parent": "/_auth/course/$courseId",
      "children": [
        "/_auth/course/$courseId/$unitId/$lessonId",
        "/_auth/course/$courseId/$unitId/"
      ]
    },
    "/_auth/course/$courseId_/admin": {
      "filePath": "_auth/course/$courseId_.admin.tsx",
      "parent": "/_auth"
    },
    "/_auth/course/$courseId_/old": {
      "filePath": "_auth/course/$courseId_.old.tsx",
      "parent": "/_auth"
    },
    "/_auth/course/$courseId/": {
      "filePath": "_auth/course/$courseId.index.tsx",
      "parent": "/_auth/course/$courseId"
    },
    "/_auth/course/$courseId/$unitId/$lessonId": {
      "filePath": "_auth/course/$courseId/$unitId/$lessonId.tsx",
      "parent": "/_auth/course/$courseId/$unitId",
      "children": [
        "/_auth/course/$courseId/$unitId/$lessonId/$sectionId",
        "/_auth/course/$courseId/$unitId/$lessonId/"
      ]
    },
    "/_auth/course/$courseId/$unitId/": {
      "filePath": "_auth/course/$courseId/$unitId/index.tsx",
      "parent": "/_auth/course/$courseId/$unitId"
    },
    "/_auth/course/$courseId/$unitId/$lessonId/$sectionId": {
      "filePath": "_auth/course/$courseId/$unitId/$lessonId/$sectionId.tsx",
      "parent": "/_auth/course/$courseId/$unitId/$lessonId"
    },
    "/_auth/course/$courseId/$unitId/$lessonId/": {
      "filePath": "_auth/course/$courseId/$unitId/$lessonId/index.tsx",
      "parent": "/_auth/course/$courseId/$unitId/$lessonId"
    }
  }
}
ROUTE_MANIFEST_END */
