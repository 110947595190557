import React from 'react';
import {
  File,
  FileText,
  FileImage,
  FileAudio,
  FileVideo,
  FileArchive,
  FileCode,
  FileX,
  LucideIcon,
} from 'lucide-react';

const getIconByMimeType = (mimeType: string): LucideIcon => {
  if (mimeType.startsWith('image/')) return FileImage;
  if (mimeType.startsWith('audio/')) return FileAudio;
  if (mimeType.startsWith('video/')) return FileVideo;
  if (mimeType.startsWith('text/')) return FileText;
  if (mimeType === 'application/pdf') return FileText;
  if (mimeType.includes('zip') || mimeType.includes('archive')) return FileArchive;
  if (mimeType.startsWith('application/') && mimeType.includes('word')) return FileX;
  if (mimeType.startsWith('application/') && mimeType.includes('code')) return FileCode;

  // Default icon for unknown file types
  return File;
};

interface FileIconProps {
  mimeType: string;
  className?: string;
}

const FileMimeIcon: React.FC<FileIconProps> = ({ mimeType, className }) => {
  const Icon = getIconByMimeType(mimeType);
  return <Icon className={className} />;
};

export default FileMimeIcon;