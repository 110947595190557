import { Label } from "@/components/ui/label";
import {
  NameField,
  LastNameField,
  GenericInputField,
} from "@/components/form/CustomFields";
import { ConfirmButtons, Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { useUser } from "@/lib/store";
import { useEditUserMutation } from "@/lib/pb/auth";
import { AnyFunction } from "@/lib/types";
import { Header } from "@/components/format/Dialog";

export function EditProfileForm({ onComplete }: { onComplete: AnyFunction }) {
  const user = useUser();
  const editUser = useEditUserMutation();
  const defaultUser = {
    name: user?.name,
    lastName: user?.lastName,
    color: user?.color,
  };
  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { ...defaultUser },
    onSubmit: async ({ value }) => {
      if (editUser.isPending) return;
      await editUser.mutateAsync(value).catch((e) => console.log(e));
      onComplete();
    },
  });
  return (
    <div className="grid gap-4">
      <Header
        title="Edit Profile"
        description="Make changes to your profile here. Click save when you're done."
      />
      <Form form={form} className="grid gap-4">
        <div className="grid items-center gap-2 ">
          <Label>Email</Label>
          <Label className="ps-3 py-3 border rounded-md text-muted-foreground">
            {user?.email}
          </Label>
        </div>

        <div className="grid gap-4 py-4">
          <NameField form={form} className="grid items-center gap-2" />
          <LastNameField form={form} className="grid items-center gap-2" />

          <GenericInputField
            form={form}
            name="color"
            label="Color"
            type="color"
            className="grid items-center gap-2 w-16"
          />
        </div>
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            onComplete={onComplete}
            form={form}
            label="Save changes"
            disabled={editUser.isPending}
          />
        </div>
      </Form>
    </div>
  );
}
