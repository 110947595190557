import { Link as TanstackLink, LinkProps as TanstackLinkProps } from "@tanstack/react-router";
import { MouseEventHandler, ReactNode } from "react";
import { cn } from "@/lib/utils";

import {
  NavigationMenuItem,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";

export const links = [
//  { to: "/about", label: "About" },
  { to: "/enroll", label: "Enroll" },
  // { to: "/dashboard", label: "Dash" },
];
//items-center
const STYLE = {
  base: "flex w-full flex-row gap-2 rounded-md px-2 py-1.5 transition-colors duration-100 [&_svg]:size-4 ",
  active: "bg-primary/10 font-medium text-foreground",
  inactive: "hover:bg-accent/50 hover:text-accent-foreground/80 hover:transition-none text-muted-foreground",
};

//active based on pathname
export const NavLink = ({ to, children, className, ...props }: LinkProps) => {
  return (
    <NavigationMenuItem>
      <TanstackLink
        to={to}
        className={cn(navigationMenuTriggerStyle(), className)}
        activeProps={{ className: STYLE.active }}
        inactiveProps={{ className: STYLE.inactive }}
        {...props}
      >
        {children}
      </TanstackLink>
    </NavigationMenuItem>
  );
};

///active based on some selector key (not pathname)
export function MenuLink({
  active = false,
  children,
  className,
  onClick,
  ...props
}: {
  active?: boolean;
  children: ReactNode;
  className?: string;
  onClick?: MouseEventHandler<HTMLElement>;
}) {
  const activeStyle = active ? STYLE.active : STYLE.inactive;
  return (
    <a
      className={cn(STYLE.base, activeStyle, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </a>
  );
}

interface LinkProps extends TanstackLinkProps{
  children: ReactNode;
  className?: string;
  external?: boolean;
};

export function Link({
  children,
  className,
  ...props
}: LinkProps) {
  return (
    <TanstackLink
      className={cn(STYLE.base, className)}
      activeProps={{ className: STYLE.active }}
      inactiveProps={{ className: STYLE.inactive }}
      {...props}
    >
      {children}
    </TanstackLink>
  );
}

/*
import Original from 'next/link';
import { forwardRef, type AnchorHTMLAttributes } from 'react';

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  //If the href is an external URL automatically determined by default
  external?: boolean;
};

/**
// Wraps `next/link` and safe to use in mdx documents

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href = '#',
      external = !(
        href.startsWith('/') ||
        href.startsWith('#') ||
        href.startsWith('.')
      ),
      ...props
    },
    ref,
  ) => {
    if (external) {
      return (
        <a
          ref={ref}
          href={href}
          rel="noreferrer noopener"
          target="_blank"
          {...props}
        >
          {props.children}
        </a>
      );
    }

    return <Original ref={ref} href={href} {...props} />;
  },
);

Link.displayName = 'Link';

export { Link as default };

*/
