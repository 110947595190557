import { z } from "zod";
import {
  Selection,
  Selectable,
  SelectionProvider,
} from "codehike/utils/selection";
import { Block, CodeBlock, parseProps } from "codehike/blocks";
import  {Code} from "./CHCode";

const Schema = Block.extend({
  steps: z.array(Block.extend({ code: CodeBlock })),
});

export default function Spotlight(props: unknown) {
  const { steps } = parseProps(props, Schema);

  return (
    <SelectionProvider className="flex wide-content">
      <div className="flex-1 mt-4 ml-2 prose prose-invert prose-h2:mt-4 max-w-xl">
        {steps.map((step, i) => (
          <Selectable
            key={i}
            index={i}
            selectOn={["click"]}
            className="border data-[selected=true]:border-primary px-5 py-2 mb-4 rounded bg-secondary cursor-pointer hover:bg-primary/25 transition-colors duration-200 ease-in-out"
          >
            <h2 className="text-xl">{step.title}</h2>
            <div>{step.children}</div>
          </Selectable>
        ))}
      </div>
      <div className="w-1/2 max-w-xl ">
        <div className="top-16 sticky overflow-auto h-full p-4">
          <Selection
            from={steps.map((step) => (
              <Code codeblock={step.code} />
            ))}
          />
        </div>
      </div>
    </SelectionProvider>
  );
}