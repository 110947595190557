import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Globe } from "lucide-react";
import {
  availableLanguages,
  LanguageKey,
  setLanguage,
  useLanguage,
} from "@/lib/store";

export default function TranslationDropdown() {
  const lang = useLanguage();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" size="sm">
          <Globe className="text-primary h-4 w-4 me-0" />
          {lang}
          <span className="sr-only">Change Language Setting</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start">
        {Object.keys(availableLanguages).map((code) => (
          <LanguageItem
            key={code}
            code={code}
            language={availableLanguages[code as LanguageKey]}
          />
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function LanguageItem({ code, language }: { code: string; language: string }) {
  return (
    <DropdownMenuItem onClick={() => setLanguage(code)}>
      {code}: {language}
    </DropdownMenuItem>
  );
}
