import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useUnit } from "@/lib/store";

export const Route = createFileRoute("/_auth/course/$courseId/$unitId/")({
  component: UnitComponent,
});

function UnitComponent() {
  const { courseId, unitId } = Route.useParams();

  const unit = useUnit();

  if (!unit || unit.id !== unitId) return null;

  const lessons = unit.children;

  if (!lessons || lessons.length < 1) return null;

  const { id, children: sections } = lessons[0];

  if (sections && sections.length > 0) {
    //console.log("skippppppp")
    return (
      <Navigate to={`/course/${courseId}/${unitId}/${id}/${sections[0].id}`} />
    );
  }

  return <Navigate to={`/course/${courseId}/${unitId}/${id}`} />;
}
