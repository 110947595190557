import { PasteButton } from "@/components/format/Buttons";
import { getOne } from "@/lib/pb";
import { useAddElementMutation, useEditElementMutation } from "@/lib/pb/lms";
import { ElementsResponse } from "@/lib/pb/types";
import { setClipboard, useClipboard } from "@/lib/store";
import { toast } from "sonner";

export function ElementPasteButton({
  icon,
  courseId,
  sectionId,
  rank,
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  courseId: string;
  sectionId: string;
  rank: string;
}) {
  const clipboard = useClipboard();

  const { item: clipped, type, isCut } = clipboard ? clipboard : {};

  const { mutateAsync: edit, isPending: editPending } = useEditElementMutation(
    clipped?.courseId,
    clipped?.sectionId,
    clipped?.id,
    sectionId,
  );

  const { mutateAsync: add, isPending: addPending } = useAddElementMutation(courseId, sectionId);

  if (!clipboard) return null;
  if (!clipped) return null;
  if (type != "Element") return null;

  //pasting element from clipboard
  async function paste() {
    if (!clipped) return;
    if (editPending || addPending) return;
    const changes = { courseId, sectionId, rank };

    if (isCut) {
      if (clipped.courseId != courseId)
        return toast.error("You can only copy items between courses.");
      await edit({ id: clipped.id, data: changes }).catch((e) => {
        throw new Error("Unable to remove element");
      });
      setClipboard(null);
    } else {
      const full = await getOne<ElementsResponse>("elements", clipped.id); //full data
      if (!full) throw new Error("Unable to fetch element");

      const { id: oldId, ...rest } = full; //new element, remove oldId
      const data = { ...rest, ...changes };

      await add({ data }).catch((e) => {
        throw new Error("Unable to add element");
      });
    }
  }

  return (
    <PasteButton label="Paste" icon={icon} isCut={isCut} onClick={paste} />
  );
}
