import { cn } from "@/lib/utils";
import { Link } from "@tanstack/react-router";
import { cva } from "class-variance-authority";
import { StepForward, StepBack, ArrowUpToLine } from "lucide-react";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";

export interface FooterProps {
  section: {
    lessonId: string;
    previous?: { name: string; url: string; index: string; lessonId: string };
    next?: { name: string; url: string; index: string; lessonId: string };
  };
}

const itemVariants = cva(
  "flex w-full flex-col gap-2 rounded-lg border bg-card p-4 text-sm transition-colors hover:bg-accent/80 hover:text-accent-foreground",
);

const itemLabel = cva("inline-flex items-center gap-0.5 text-muted-foreground");

export function SectionFooter({ section }: FooterProps): React.ReactElement {
  const { previous, next } = section;

  const differentLesson =
    section.next && section.lessonId !== section.next.lessonId;
  const nextLesson = next?.parent; // parent of section is lesson

  return (
    <div className="flex flex-col w-full max-w-4xl mx-auto gap-6">
      <div
        id="section-bottom"
        className="flex w-full justify-center pt-12 text-muted-foreground"
      >
        <Link to={section.url + "#section-top"} className="flex gap-2">
          <ArrowUpToLine className="h-4 w-4" />
          <div className="text-sm">Back to top</div>
        </Link>
      </div>

      <div className="grid grid-cols-2 gap-4 pb-6">
        {previous ? (
          <Link
            to={previous.url + "#section-top"}
            className={cn(itemVariants(), "w-full")}
          >
            <div className={cn(itemLabel())}>
              <StepBack className="-ms-2 size-4 shrink-0 rtl:rotate-180 me-2" />
              Previous
            </div>
            <div className="flex gap-4 -ms-2 items-center">
              <div>{previous.index}</div>
              <BasicMarkdown className="line-clamp-1" code={previous.name} />
            </div>
          </Link>
        ) : null}
        {next ? (
          <Link
            to={next.url + "#section-top"}
            className={cn(itemVariants({ className: "col-start-2" }))}
          >
            <div className={cn(itemLabel({ className: "flex-row-reverse" }))}>
              <StepForward className="-me-2 size-4 shrink-0 rtl:rotate-180 ms-2" />
              Next
            </div>
            {differentLesson && nextLesson ? (
              <div className="-me-2 ms-auto flex flex-row-reverse gap-4 items-center text-lg font-semibold ">
                <BasicMarkdown
                  className="line-clamp-1"
                  code={nextLesson.name}
                />
                <div>{nextLesson.index}</div>
              </div>
            ) : (
              <div className="-me-2 flex flex-row-reverse gap-4 items-center ">
                <BasicMarkdown className=" line-clamp-1 " code={next.name} />
                <div>{next.index}</div>
              </div>
            )}
          </Link>
        ) : null}
      </div>
    </div>
  );
}
//
