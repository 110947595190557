import {
  lessonDetailQuery,
  useAddLessonMutation,
  useDeleteLessonMutation,
  useEditLessonMutation,
  useLessonTranslation,
  useQuery,
} from "@/lib/pb/lms";
import { Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { LessonsRecord, LessonsResponse, UnitsResponse } from "@/lib/pb/types";
import {
  CommonAdminFields,
  NameField,
  RequiredTextField,
} from "@/lms/core/LmsFields";
import { ContentsProps } from "@/components/format/Dialog";

import {
  BaseForm,
  TranslateForm,
  ConfirmDeleteForm,
  FormFieldsProps,
} from "@/components/form/Form";

import { getLanguageName, useLanguage } from "@/lib/lang";
import { setClipboard, useClipboard } from "@/lib/store";

const LESSON_DEFAULTS = {
  name: "",
  active: true,
  access: 200,
  unitId: "",
  rank: "",
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  return (
    <div className="grid gap-4 py-4 px-2">
      <div className="flex flex-wrap gap-4 w-full">
        <NameField form={form} className="grid gap-2 w-80 " />
      </div>
      {!locked && <CommonAdminFields form={form} />}
    </div>
  );
}

export function LessonEditForm({
  item,
  onComplete,
}: ContentsProps<LessonsResponse>) {
  if (!item) return;
  const { mutateAsync: edit, isPending } = useEditLessonMutation(
    item.courseId,
    item.unitId,
    item.id,
  );

  //get full item with translation
  const { data: fullItem, tQuery } = useQuery(
    lessonDetailQuery(item?.id, true),
  );
  const languages = Object.keys(fullItem?.translations ?? {});

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item?.name,
      active: item?.active,
      access: item?.access,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      if (!item) return console.error("UnitLessonForm: item is undefined");
      const { submitButton, ...rest } = value;
      const data = rest as LessonsRecord;
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  });
  return (
    <BaseForm
      title="Edit Lesson"
      form={form}
      save={true}
      extras={
        <TranslationReset
          languages={languages}
          reset={async () =>
            await edit({ id: item.id, data: { translations: null } })
          }
        />
      }
    >
      <FormFields form={form} />
    </BaseForm>
  );
}

import { Rank } from "@/lms/core/Rank";
import { getOne } from "@/lib/pb";
import { TranslationReset } from "../translate/TranslationReset";

export function LessonAddForm({ unit }: { unit: UnitsResponse }) {
  const { courseId, id: unitId, children: lessons } = unit;
  const { mutateAsync: add, isPending } = useAddLessonMutation(
    courseId,
    unitId,
  );

  const form = useForm<LessonsRecord, any>({
    validatorAdapter: zodValidator(),
    defaultValues: { ...LESSON_DEFAULTS },
    onSubmit: async ({ value, formApi }) => {
      if (isPending) false;
      value.unitId = unitId;
      value.courseId = courseId;
      value.rank = Rank.bottom(null, lessons);
      await add({ data: value }).catch((e) => console.error(e));
      formApi.reset();
    },
  });

  return (
    <Form form={form}>
      <div className="flex gap-4 my-auto">
        <RequiredTextField
          name="name"
          placeholder="Create a new lesson"
          form={form}
          className="grid gap-2 w-36 md:w-48 "
          disabled={isPending}
        />
      </div>
    </Form>
  );
}

export function LessonDeleteForm({
  item,
  onComplete,
}: ContentsProps<LessonsResponse>) {
  const { id, courseId, unitId, name, children: sections } = item ?? {};

  const { mutateAsync: deleteItem, isPending } = useDeleteLessonMutation(
    courseId,
    unitId,
  );

  const clipboard = useClipboard();
  async function remove() {
    if (isPending) return;
    await deleteItem({ id }).catch((e) => console.log(e));
    if (id == clipboard?.item?.id) {
      setClipboard(null);
    }
    onComplete();
  }
  if (!item) return;

  const formProps =
    !sections || sections.length < 1
      ? { formOptions: { onSubmit: remove } }
      : {
          formOptions: {
            validatorAdapter: zodValidator(),
            defaultValues: { name: "" },
            onSubmit: async ({ value }: { value: LessonsRecord }) => {
              if (value.name !== name) return;
              await remove();
            },
          },
          match: name,
          fieldName: "name",
        };

  return (
    <ConfirmDeleteForm {...formProps} onComplete={onComplete} type="Lesson" />
  );
}

//Handle Fetch of Translation object
export function LessonTranslateForm({
  item,
  onComplete,
}: ContentsProps<LessonsResponse>) {
  const { data, isPending, error } = useLessonTranslation(item?.id);
  if (!item || isPending || error) return;

  return (
    <InnerTranslateForm
      item={item}
      onComplete={onComplete}
      translation={data ? data : item}
    />
  );
}

function InnerTranslateForm({
  item,
  onComplete,
  translation,
}: ContentsProps<LessonsResponse> & { translation: any }) {
  const { id, courseId, unitId } = item;
  const { mutateAsync: edit, isPending } = useEditLessonMutation(
    courseId,
    unitId,
    id,
  );

  const formOptions = {
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: translation.name,
      description: translation.description,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      //fetch entire translation JSON because you cant partial update
      const record = await getOne("lessons", item.id, {
        fields: "translations",
      });
      const translations = record?.translations ? record.translations : {};
      const { submitButton, ...rest } = value;
      translations[languageCode] = rest;
      await edit({ id, data: { translations } }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  };
  const form = useForm(formOptions);
  const languageCode = useLanguage();
  const languageName = getLanguageName(languageCode);

  return (
    <TranslateForm
      form={form}
      fields={FormFields}
      item={item}
      language={`${languageName} (${languageCode})`}
      type="Lesson"
    />
  );
}
