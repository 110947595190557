import CodeHike from "@/components/codehike/CodeHike";
import { ClassesResponse } from "@/lib/pb/types";
import { format } from "date-fns";
import { Label } from "@/components/ui/label";

function formatDate(date: string) {
  return date ? format(date, "PPP") : "TBD";
}

export function ClassDetails({
  item,
  className,
}: {
  item: ClassesResponse;
  className?: string;
}) {
  return (
    <div className={className}>
      <div className="flex flex-cols space-x-2 gap-2">
        <div className="grid justify-center">
          <ItemLabel label="Info:" />
          <ItemLabel label="Instructor:" />
          <ItemLabel label="Dates:" />
          {/* {item.details && <ItemLabel label="Details:" />} */}
        </div>
        <div className="grid justify-center">
          <div className="h-8 my-auto">{item.info}</div>
          <div className="h-8 my-auto">{item.instructor}</div>
          <div className="h-8 my-auto">
            {formatDate(item.startDate)} to {formatDate(item.endDate)}
          </div>
          {/* {item.details && <div className="h-8"></div>} */}
        </div>
      </div>
      {item.details && <CodeHike code={item.details} className="flex me-auto border-2"/>}
    </div>
  );
}

function ItemLabel({ label }: { label: string }) {
  return (
    <div className="flex justify-end my-auto h-8 tracking-tight text-muted-foreground">
      {label}
    </div>
  );
}
