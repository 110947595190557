import {Link} from "@tanstack/react-router"
import {
  type HTMLAttributes,
  type ReactNode,
  useEffect,
  useState,
} from 'react';
import { cn } from '@/lib/utils';

export interface NavBoxProps {
  /**
   * Use transparent background
   *
   * @defaultValue none
   */
  transparentMode?: 'always' | 'top' | 'none';
}

export interface TitleProps {
  title?: ReactNode;

  /**
   * Redirect url of title
   * @defaultValue '/'
   */
  url?: string;
}

export default function NavBox({
  transparentMode = 'none',
  ...props
}: NavBoxProps & HTMLAttributes<HTMLElement>): React.ReactElement {
  const [transparent, setTransparent] = useState(transparentMode !== 'none');

  useEffect(() => {
    if (transparentMode !== 'top') return;

    const listener = (): void => {
      setTransparent(window.scrollY < 10);
    };

    listener();
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  }, [transparentMode]);

  return (
    <header
      {...props}
      className={cn(
        'sticky top-0 z-50 border-b transition-colors',
        transparent
          ? 'border-transparent'
          : 'border-foreground/10 bg-background/60 backdrop-blur-md',
        props.className,
      )}
    />
  );
}

import { Sprout } from "lucide-react";

export function Title({ title='LMS2', url = '/' }: TitleProps): React.ReactElement {
  return (
    <Link
      to={url}
      className="inline-flex items-center gap-2.5 font-semibold [&_svg]:size-5"
    >
      <Sprout style={{height: "1.5rem", width: "1.5rem"}} />
      <div className="text-lg">{title}</div>
    </Link>
  );
}

// import { Link } from "@tanstack/react-router";
// import { Shapes } from "lucide-react";

// export default function NavBrand() {
//   return (
//     <Link
//       to="/"
//       className="flex items-center gap-2 text-lg font-semibold md:text-base"
//     >
//       <Shapes className="h-6 w-6" />
//       <span className="">LMSv2</span>
//     </Link>
//   );
// }
// <Link
//   href="#"
//   className="flex items-center gap-2 text-lg font-semibold"
// >
//   <Package2 className="h-6 w-6" />
//   <span className="sr-only">Acme Inc</span>
// </Link>