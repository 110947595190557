import { loader } from "@monaco-editor/react";
import * as monaco from "monaco-editor";

import editorWorker from "monaco-editor/esm/vs/editor/editor.worker?worker";
import jsonWorker from "monaco-editor/esm/vs/language/json/json.worker?worker";
import cssWorker from "monaco-editor/esm/vs/language/css/css.worker?worker";
import htmlWorker from "monaco-editor/esm/vs/language/html/html.worker?worker";
import tsWorker from "monaco-editor/esm/vs/language/typescript/ts.worker?worker";

import { shikiToMonaco } from "@shikijs/monaco";
import { createHighlighter } from "shiki";
// check later https://github.com/vitejs/vite/discussions/1791
self.MonacoEnvironment = {
  getWorker(_, label) {
    let worker = null;
    if (label === "json") {
      worker = new jsonWorker();
    } else if (label === "css" || label === "scss" || label === "less") {
      worker = new cssWorker();
    } else if (
      label === "html" ||
      label === "handlebars" ||
      label === "razor"
    ) {
      worker = new htmlWorker();
    } else if (label === "typescript" || label === "javascript") {
      worker = new tsWorker();
    } else {
      worker = new editorWorker();
    }
    worker.onerror = (e) => {
      console.error("Error in worker: " + label, e);
    };
    return worker;
  },
};

createHighlighter({
  themes: ["dark-plus", "light-plus"],
  langs: ["javascript", "typescript", "vue"],
}).then((highlighter) => {
  loader.config({ monaco });

//https://github.com/sveltejs/language-tools/issues/1190 svelte-monaco-editor
  loader.init().then((monacoInstance) => {
    monacoInstance.languages.register({ id: "vue" });
    monacoInstance.languages.register({ id: "typescript" });
    monacoInstance.languages.register({ id: "javascript" });
    monacoInstance.languages.register({ id: "svelte" });
    shikiToMonaco(highlighter, monacoInstance);
  });
});