import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { classesListQuery, enrollmentsMapQuery } from "@/lib/pb/lms";
import { useState, useMemo } from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { useUser } from "@/lib/store";
import { ClassesResponse } from "@/lib/pb/types";
import { ROLES } from "@/lib/roles";
import {
  EnrollButton,
  EnrollTeamButton,
} from "@/lms/classes/ClassEnrollButton";

export function ClassesEnrollList({ courseId }: { courseId: string }) {
  const user = useUser();
  const { data: classes, ...classesQuery } = useQuery(
    classesListQuery(courseId),
  );
  const { data: enrollments, ...enrollmentsQuery } = useQuery(
    enrollmentsMapQuery(user?.id),
  );

  const enrollingState = useState(false); //disable all enroll buttons

  const foundEnrollment = enrollments?.get(courseId)
  
  const isLocked = foundEnrollment && user?.role <= ROLES.student;

  if (!user) return "An error has occurred: user required to enroll in a class";
  if (classesQuery.error)
    return "An error has occurred: " + classesQuery.error.message;
  if (enrollmentsQuery.error)
    return "An error has occurred: " + enrollmentsQuery.error.message;
  if (enrollmentsQuery.isPending) return <div>Finding Enrollment</div>;

  return (
    <div className="flex flex-col gap-2 min-w-96 w-full text-sm">
      <div className="text-xl font-bold mb-3">Open Classes</div>

      {classesQuery.isPending ? (
        <PendingClassItem />
      ) : (
        classes?.map((classItem) => {
          const isEnrolledClass = foundEnrollment?.classId === classItem.id;
          if ((isLocked && !isEnrolledClass) || !classItem.active) {
            return; //Students do not get to switch enrollment
          }
          return (
            <ClassEnrollItem key={classItem.id} item={classItem}>
              {isEnrolledClass ? (
                <Link to={`/course/${courseId}`}>
                  <Button variant="secondary">Go to Enrolled Class</Button>
                </Link>
              ) : classItem.requireTeamSelection ? (
                <EnrollTeamButton
                  enrollingState={enrollingState}
                  classItem={classItem}
                  enrollmentItem={foundEnrollment}
                />
              ) : (
                <EnrollButton
                  enrollingState={enrollingState}
                  classItem={classItem}
                  enrollmentItem={foundEnrollment}
                />
              )}
            </ClassEnrollItem>
          );
        })
      )}
    </div>
  );
}

type ClassEnrollItemProps = {
  item: ClassesResponse;
  children: React.ReactNode;
};

const ClassEnrollItem = ({ item, children }: ClassEnrollItemProps) => (
  <div
    className={`flex items-center justify-between rounded-md bg-secondary/30 border p-4 shadow-lg ${item.active ? "" : "opacity-50"}`}
  >
    <div>
      <div className="text-lg font-bold">
        {item.name}
        {item.requireTeamSelection && "*"}
      </div>
      <div className="text-muted-foreground">{item.info}</div>
    </div>
    {children}
  </div>
);

const PendingClassItem = () => (
  <>
    <Skeleton className="w-full h-16" />
    <Skeleton className="w-full h-16" />
    <Skeleton className="w-full h-16" />
  </>
);
