import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Header } from "@/components/format/Dialog";
import { Button } from "@/components/ui/button";
import { MessageSquare, X } from "lucide-react";
import { CommentsResponse, ElementsResponse } from "@/lib/pb/types";
import { CommentAddForm } from "./CommentAddForm";
import { commentsListQuery } from "@/lib/pb/comment";
import { useQuery } from "@tanstack/react-query";
import { formatRelative, formatDistance } from "date-fns";
//import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useEffect, useRef } from "react";
import { cn } from "@/lib/utils";
import { useUser } from "@/lib/store";
import { TooltipButton } from "@/components/format/Buttons";
import { useDeleteCommentMutation } from "@/lib/pb/comment";
import { useEnrollment } from "@/lib/store";

export function Comments({
  userId,
  classId,
  item,
}: {
  userId: string;
  classId: string;
  item: ElementsResponse;
}) {
  const { data: comments, isPending } = useQuery(
    commentsListQuery(userId, item.id),
  );

  const numberOfComments = comments?.length ?? 0;

  return (
    <Dialog modal={false}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            "relative h-9 px-4 rounded-full text-muted-foreground  hover:bg-info/80 hover:text-info-foreground flex gap-2 border border-info items-center",
          )}
        >
          <MessageSquare className="h-4 w-4" />
          <div>Comments</div>
          <Indicator count={numberOfComments} />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col border-2 border-info/75 w-full max-w-4xl h-[700px]">
        {comments && (
          <CommentContent comments={comments} item={item} receiverId={userId} classId={classId}/>
        )}
      </DialogContent>
    </Dialog>
  );
}

function CommentContent({
  comments,
  item,
  receiverId,
  classId,
  isPending,
}: {
  comments: CommentsResponse[];
  item: ElementsResponse;
  receiverId?: string;
  classId?: string;
  isPending?: boolean;
}) {
  const bottomRef = useRef<HTMLDivElement | null>(null); // Ref for ScrollArea
  // Scroll to the bottom when messages change
  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [comments]);

  return (
    <>
      <Header title={<Title />} />
      <div className="flex flex-col gap-2 h-full">
        <ScrollArea className="mb-auto h-[400px] border-2 rounded-md p-2">
          {comments &&
            comments.map((comment) => (
              <CommentItem key={comment.id} comment={comment} />
            ))}
          <div ref={bottomRef}></div>
        </ScrollArea>
        <CommentAddForm item={item} receiverId={receiverId} classId={classId}/>
      </div>
    </>
  );
}

///ADMIN ONLY
export function TableComments({
  userId,
  classId,
  item,
  count,
}: {
  userId: string;
  classId: string;
  item: ElementsResponse;
  count: number;
}) {
  return (
    <Dialog modal={false}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            "relative h-10 px-4 rounded-md border-2 hover:border-foreground flex gap-2",
          )}
        >
          <MessageSquare className="h-4 w-4" />
          <Indicator count={count} />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col border-2 border-info/75 w-full max-w-4xl h-[700px]">
        <TableCommentContent userId={userId} item={item} classId={classId}/>
      </DialogContent>
    </Dialog>
  );
}

function Indicator({ count }: { count: number }) {
  if (!count || count == 0) return null;
  return (
    <div className="absolute top-0 right-0 text-xs font-bold px-1 h-4 bg-red-500 text-primary-foreground rounded-full ">
      {count}
    </div>
  );
}

function TableCommentContent({
  userId,
  classId,
  item,
}: {
  userId: string;
  classId: string;
  item: ElementsResponse;
}) {
  const { data: comments, isPending } = useQuery(
    commentsListQuery(userId, item.id),
  );

  return (
    <>
      {comments && (
        <CommentContent comments={comments} item={item} receiverId={userId} classId={classId}/>
      )}
    </>
  );
}

import { Avatar } from "@/components/format/Avatar";
import { userDetailQuery } from "@/lib/pb/lms";

function CommentItem({ comment }: { comment: CommentsResponse }) {
  const user = useUser();
  const { data, isPending } = useQuery(userDetailQuery(comment.senderId));
  const isSender = comment.senderId == user.id;
  const sender = isSender ? user : data;

  if (isPending) return null;
  return (
    <div key={comment.id} className="flex space-x-4 w-full mb-3 ">
      <div className="w-full">
        <div className="flex gap-2 w-full items-center pe-1">
          <Avatar user={sender} />
          <h3 className="font-semibold me-auto">{sender.name}</h3>
          <div className="text-sm text-muted-foreground">
            {formatRelative(comment.updated, new Date())}
          </div>
          {isSender ? (
            <DeleteCommentButton comment={comment} />
          ) : (
            <div className="w-6 h-4" />
          )}
        </div>
        <BasicMarkdown className="ps-10" code={comment.content} />
      </div>
    </div>
  );
}

function DeleteCommentButton({ comment }: { comment: CommentsResponse }) {
  const { mutateAsync: deleteComment, isPending: isDeleting } =
    useDeleteCommentMutation(comment.receiverId, comment.elementId);

  return (
    <TooltipButton
      variant="ghost"
      icon={X}
      info="Delete Comment"
      className="p-1 h-6"
      onClick={async () => {
        if (isDeleting) return;
        await deleteComment({ id: comment.id }).catch((e) => console.log(e));
      }}
    />
  );
}

function Title() {
  return (
    <div className="flex gap-2 text-info">
      <MessageSquare className="h-5 w-5" />
      <div>Comments</div>
    </div>
  );
}
