import { createFileRoute } from "@tanstack/react-router";
import { EnrollCoursesList } from "@/lms/course/CoursesList";
export const Route = createFileRoute("/_auth/enroll")({
  component: EnrollComponent,
});
import NavBar from "@/components/nav/NavBar";
import { Footer } from "@/components/nav/Footer";
import { useUser } from "@/lib/store";

function EnrollComponent() {
  const user = useUser();
  if(!user || !user.id) return <div>No user found.</div>
  return (
    <>
      <NavBar />
      <div className="min-h-[650px]">
        <div className="grid gap-4 px-4 md:px-6 xl:px-16 ">
          <EnrollCoursesList />
        </div>
      </div>
      <Footer />
    </>
  );
}
