import {
  CommentsResponse,
  ElementsResponse,
  SubmissionsResponse,
} from "@/lib/pb/types";
import { cn } from "@/lib/utils";
import ElementAdminMenu from "../element/ElementAdminMenu";
import Elements from "@/lms/elements/Elements";
import { ElementSubmissionAdminNav } from "../element/ElementSubmissionAdminNav";
import React, { useEffect, useRef } from "react";
import { submissionSectionPartialQuery } from "@/lib/pb/submissions";
import { commentSectionPartialQuery } from "@/lib/pb/comment";
import { useQuery } from "@tanstack/react-query";
import { useClassFilter, useUser } from "@/lib/store";

export default function SectionContents({
  sectionId,
  items,
}: {
  sectionId: string;
  items: ElementsResponse[];

}) {

  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isCoach = user?.role >= ROLES.coach;

  // if (items.length < 1) {
  //   return <div className="flex w-full justify-center">No elements found</div>;
  // }

  //const sectionProgress = useSectionProgress();

  return (
    <div className="grid gap-6 w-full ">
      {isCoach && <AdminSubmissionFetcher sectionId={sectionId} />}
      {items?.map((element) => (
        <div
          id={"element-" + element.id}
          key={element.id}
          className={cn(
            `relative flex gap-4 border-2 border-transparent border-dashed py-1.5`,
            isAdmin ? "hover:border-primary/25 hover:rounded-md" : "",
            element.active ? "" : "opacity-50",
          )}
        >
          <Element element={element}>
           
            {isCoach && element.isSubmitType && (
              <ElementSubmissionAdminNav key={element.id} element={element} />
            )}
          </Element>
          {isAdmin && (
            <ElementAdminMenu
              item={element.original || element}
              items={items}
              size={5}
              className="absolute top-0 right-0"
            />
          )}
        </div>
      ))}
    </div>
  );
}

function Element({
  element,
  ...props
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  const Component = Elements[element.type as keyof typeof Elements];
  if (!Component)
    return (
      <div className="w-full text-center">
        Unknown Element Type: {element.type}
      </div>
    );
  return <Component element={element} {...props} />;
}

import { atom, useAtom } from "jotai";
import { ROLES } from "@/lib/roles";

//move the fetcher out!!! to prevent refetching on every render

interface SubmissionsQuery {
  data: Map<string, SubmissionsResponse[]> | undefined;
  isPending: boolean;
  refetch: () => void;
}
interface CommentsQuery {
  data: Map<string, CommentsResponse[]> | undefined;
  isPending: boolean;
  refetch: () => void;
}
export const submissionsQueryAtom = atom<SubmissionsQuery | null>(null);
export const commentsQueryAtom = atom<CommentsQuery | null>(null);

function AdminSubmissionFetcher({ sectionId }: { sectionId: string }) {
  const cls = useClassFilter();

  const [, setSubmissionsQuery] = useAtom(submissionsQueryAtom);
  const [, setCommentssQuery] = useAtom(commentsQueryAtom);

  const { data, isPending, refetch } = useQuery(
    submissionSectionPartialQuery(sectionId, cls?.id),
  );
  const {
    data: commentsMap,
    isPending: isCommentsPending,
    refetch: refetchComments,
  } = useQuery(commentSectionPartialQuery(sectionId, cls?.id));

  useEffect(() => {
    if (!isPending) refetch();
  }, [cls]);

  useEffect(() => {
    setSubmissionsQuery({ data, isPending, refetch });
  }, [data, isPending, refetch]);

  useEffect(() => {
    if (commentsMap) {
      setCommentssQuery({
        data: commentsMap,
        isPending: isCommentsPending,
        refetch: refetchComments,
      });
    }
  }, [commentsMap, isCommentsPending]);

  return <div></div>;
}
