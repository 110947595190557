import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Menu } from "lucide-react";
import { Title } from "./NavBox";
import {Link, links} from "./Link";

export default function NavSheet() {
  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="icon" className="shrink-0 md:hidden">
          <Menu className="h-5 w-5" />
          <span className="sr-only">Toggle navigation menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left">
        <nav className="grid text-lg font-medium gap-2">
          <SheetClose asChild={true}>
            <Title />
          </SheetClose>
          {links.map((link) => (
            <SheetClose asChild={true} key={link.to}>
              <Link to={link.to} >{link.label}</Link>
            </SheetClose>
          ))}

        </nav>
      </SheetContent>
    </Sheet>
  );
}
