import { useAtom } from "jotai";
import { useEffect, useState, useRef, useCallback } from "react";
import { LoadingFrame } from "./LoadingFrame";
import { useActiveCode, useSandpack } from "@codesandbox/sandpack-react";
import { webContainerAtom } from "@/lms/playground/WebContainer";
import { WebContainerProcess } from "@webcontainer/api";

import { ScrollArea } from "@/components/ui/scroll-area";
import XTerm, { Terminal } from "@/lms/playground/XTerm";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";

export default function WebContainerPreview({
  baseFiles,
  setPreviewLoading,
  mountPoint,
  showTerminal = false,
  previewId = 0,
}: {
  baseFiles: Record<string, any>;
  setPreviewLoading: (loading: boolean) => void;
  mountPoint: string;
  showTerminal?: boolean;
  previewId?: number;
}) {
  const [terminal, setTerminal] = useState<Terminal | null>(null);
  //const { instance: terminal, ref: xTermRef } = useXTerm();
  const [webContainer] = useAtom(webContainerAtom);

  // use useRef to prevent stale closures
  const serverProcessRef = useRef<WebContainerProcess | null>(null);
  const installProcessRef = useRef<WebContainerProcess | null>(null);
  const [serverStatus, setServerStatus] = useState("idle");
  const [mountStatus, setMountStatus] = useState("idle");
  const [installStatus, setInstallStatus] = useState("idle");
  const [url, setUrl] = useState("");
  const { sandpack } = useSandpack();
  const { files } = sandpack;

  async function remountFiles(files: any) {
    if (serverStatus == "ready") {
      console.log("🔴 remount files");
      webContainer?.mount(toFileTree(files), { mountPoint }).catch((e) => {
        console.error("WebContainerError: remounting files: ", e);
      });
    }
  }
  //catch user changes during sever boot//cause flash?
  useEffect(() => {
    if (serverStatus == "ready") {
      remountFiles(files);
    }
  }, [serverStatus]);

  useEffect(() => {
    if (baseFiles) remountFiles(baseFiles.files);
  }, [baseFiles]); //submission and reset

  async function setup() {
    setPreviewLoading(true);
    console.log("======setting up server =====");

    if (!webContainer) {
      terminal?.write("WebContainer booting...");
      setPreviewLoading(false);
      return;
    }
    terminal?.write(`WebContainer detected @ ${webContainer.workdir} \r\n`);

    //==== start MOUNT process ====
    if (mountStatus == "idle") {
      setMountStatus("mounting");
      terminal?.write("Mounting file system...\r\n");
      const rootFiles = await webContainer.fs.readdir(".");
      // make the directory for mount point if it doesn't exist
      if (rootFiles.includes(mountPoint) == false) {
        await webContainer.fs.mkdir(mountPoint).catch((e) => {
          console.error("WebContainerError: mkdir: ", e);
          setMountStatus("error");
          setPreviewLoading(false);
          return;
        });
      }
      await webContainer.mount(toFileTree(files), { mountPoint }).catch((e) => {
        console.error("WebContainerError: mounting files: ", e);
        setMountStatus("error");
        setPreviewLoading(false);
        return;
      });
      setMountStatus("success");
      terminal?.write("Files mounted at ./" + mountPoint + "\r\n");
    }

    //==== start INSTALL process ====
    setInstallStatus("installing");
    const installprocess = await webContainer
      .spawn("pnpm", ["--prefix", mountPoint, "install"])
      .catch(async (e) => {
        console.log("Install Error", e);
        return null;
      });
    if (!installprocess) {
      //catch install error
      setPreviewLoading(false);
      setInstallStatus("error");
      return;
    }
    installProcessRef.current = installprocess;
    installprocess.output.pipeTo(
      new WritableStream({
        write(data) {
          terminal?.write(data);
        },
      }),
    );
    const exitCode = await installprocess.exit;
    if (exitCode !== 0) {
      setInstallStatus("error");
      console.error("WebContainerError: install exit code: ", exitCode);
      setPreviewLoading(false);
      return;
    }
    setInstallStatus("success");

    //==== start SERVER process ====
    const port = stringToPort(mountPoint);
    setServerStatus("starting");
    if (serverProcessRef.current) {
      //kill old server
      serverProcessRef.current.kill();
    }
    terminal?.write(`Starting server on port ${port}.\r\n${url}\r\n`);
    const spawnOptions = {
      cwd: mountPoint,
      env: { PORT: port },
    };
    const serverprocess = await webContainer.spawn(
      "pnpm",
      ["run", "dev-port"],
      spawnOptions,
    );
    serverProcessRef.current = serverprocess;

    serverprocess.output.pipeTo(
      new WritableStream({
        write(data) {
          terminal?.write(data);
        },
      }),
    );
    // Wait for `server-ready` event
    webContainer.on("server-ready", (_port, url) => {
      if (port !== _port) return;
      console.log("server-ready... ", _port, url);
      setUrl(url);
      setServerStatus(`ready`);
      terminal?.write(`Server running on port ${port}.\r\n${url}\r\n`);
      setPreviewLoading(false);
    });
  }

  useEffect(() => {
    if (!serverProcessRef.current) return;
    serverProcessRef.current.exit.then((exitCode) => {
      if (exitCode !== 0) {
        setServerStatus("error");
        console.error("WebContainerError: server exit code: ", exitCode);
        setPreviewLoading(false);
      }
    });
  }, [serverProcessRef]);

  const cleanup = async () => {
    console.log("🧹 WebContainerPreview: cleanup ");
    if (installProcessRef.current) {
      //console.log("Delete files =======");
      try {
        await webContainer?.fs.rm(mountPoint, { recursive: true });
      } catch (e) {
        console.error(e);
      }
      setMountStatus("idle");
      //console.log("Kill install process =======");
      installProcessRef.current.kill();
      installProcessRef.current = null;
      setInstallStatus("idle");
    }
    if (serverProcessRef.current) {
      //console.log("Kill server process =======");
      serverProcessRef.current.kill();
      serverProcessRef.current = null;
      setServerStatus("idle");
    }
  };

  useEffect(() => {
    if (!webContainer) return; //TODO restart webcontainer on error
    if (showTerminal && !terminal) return; //wait for terminal to be mounted
    if (mountStatus != "idle") return;
    setup().catch((e) => {
      console.error("WebContainerError: setup", e);
      cleanup();
    });
  }, [webContainer, terminal]); //wait of webcontainer to boot

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return (
    <ResizablePanelGroup direction="vertical">
      <ResizablePanel defaultSize={90}>
        <div className="flex h-full items-center justify-center">
          {url ? (
            <PreviewFrame
              previewId={previewId}
              url={url}
              mountPoint={mountPoint}
            />
          ) : (
            <LoadingFrame
              mountStatus={mountStatus}
              installStatus={installStatus}
              serverStatus={serverStatus}
            />
          )}
        </div>
      </ResizablePanel>
      {showTerminal && (
        <>
          <ResizableHandle withHandle />
          <ResizablePanel
            defaultSize={10}
            className="flex flex-grow w-full h-full"
          >
            <div className="flex flex-grow w-full">
              <ScrollArea className="flex flex-col overflow-y-auto w-full">
                <XTerm className="p-2 bg-black" setTerminal={setTerminal} />
              </ScrollArea>
            </div>
          </ResizablePanel>
        </>
      )}
    </ResizablePanelGroup>
  );
}

function PreviewFrame({
  url,
  mountPoint,
  previewId = 0,
}: {
  url: string;
  mountPoint: string;
  previewId: number;
}) {
  const [webContainer] = useAtom(webContainerAtom);
  const { code } = useActiveCode();
  const { sandpack } = useSandpack();
  const { activeFile } = sandpack;
  const iframeRef = useRef(null);
  const prevFileRef = useRef(activeFile);
  // what if files have changes!!!! not just active file!
  // await webContainer.mount(toFileTree(files), { mountPoint }).catch((e) => {
  //   console.error("WebContainerError: mounting files: ", e);
  // if we do that dont we need to do pnpm install?? if the package.json is different

  //useEffect(() => {console.log("🏇 PrevewFrame remounted")},[])
  useEffect(() => {
    //Dont reload page when active file changes
    if (activeFile !== prevFileRef.current) {
      prevFileRef.current = activeFile;
      return;
    }
    const timeout = setTimeout(() => {
      webContainer?.fs.writeFile(`${mountPoint}/${activeFile}`, code);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [code, activeFile]);

  return (
    <iframe
      key={previewId}
      className="w-full h-full justify-center items-center"
      ref={iframeRef}
      src={url}
      allow="cross-origin-isolated"
    />
  );
}

export function toFileTree(files) {
  // console.log("starting files");
  // console.log(files);
  const root = {};

  for (const filePath in files) {
    //console.log("processing: ", filePath);
    const file = files[filePath];
    const code = file.code ? file.code.trim() : file;
    if (typeof code != "string" || code === "") continue;

    const segments = filePath.split("/").filter((segment) => segment);

    let currentTree = root;

    for (let i = 0; i < segments.length; ++i) {
      const name = segments[i];

      if (i === segments.length - 1) {
        currentTree[name] = {
          file: {
            contents: code,
          },
        };
      } else {
        let folder = currentTree[name];

        assertDirectoryNode(folder);

        if (!folder) {
          folder = {
            directory: {},
          };

          currentTree[name] = folder;
        }

        currentTree = folder.directory;
      }
    }
  }
  return root;
}

function assertDirectoryNode(node) {
  if (node && !("directory" in node)) {
    throw new Error("Expected directory node");
  }
}

function stringToPort(s: string): number {
  // Define the range for dynamic/private ports
  const MIN_PORT = 1024;
  const MAX_PORT = 65535;
  const PORT_RANGE = MAX_PORT - MIN_PORT + 1;

  // Simple hash function to convert string to a 32-bit unsigned integer
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    const ch = s.charCodeAt(i);
    hash = (hash << 5) - hash + ch;
    hash |= 0; // Convert to 32-bit integer
  }
  hash = hash >>> 0; // Ensure non-negative

  // Map the hash into the valid port range
  const port = MIN_PORT + (hash % PORT_RANGE);
  return port;
}
