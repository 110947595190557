import { Store } from "@tanstack/store";
import { useStore } from "@tanstack/react-store";
import { AuthModel } from "pocketbase";
import {
  ClassesResponse,
  CoursesResponse,
  EnrollmentsResponse,
  LessonsResponse,
  SectionsResponse,
  SubmissionsResponse,
  UnitsResponse,
} from "./pb/types";
//================================
export const userState = new Store<{ user: AuthModel }>({
  user: null,
});
export const useUser = (): AuthModel =>
  useStore(userState, (state) => state.user);

export const setUser = (user: AuthModel) =>
  userState.setState((state) => ({ ...state, user: user }));

//================================
export const enrollmentState = new Store<{
  enrollment: EnrollmentsResponse | null;
}>({
  enrollment: null,
});
export const useEnrollment = (): EnrollmentsResponse | null =>
  useStore(enrollmentState, (state) => state.enrollment);

export const setEnrollment = (enrollment: EnrollmentsResponse | null) =>
  enrollmentState.setState((state) => ({ ...state, enrollment: enrollment }));

//================================

export const availableLanguages = {
  en: "English",
  es: "Español",
  pt: "Português",
} as const;
// Extract the keys of availableLanguages into a type
export type LanguageKey = keyof typeof availableLanguages;

export const languageState = new Store<{ language: LanguageKey }>({
  language: "en",
});
export const useLanguage = (): LanguageKey =>
  useStore(languageState, (state) => state.language);

export const setLanguage = (code: LanguageKey) =>
  languageState.setState((state) => ({ ...state, language: code }));

//================================

export const darkModeState = new Store<{ isDark: boolean }>({
  isDark: window.matchMedia("(prefers-color-scheme: dark)").matches,
});
export const useDarkMode = (): boolean =>
  useStore(darkModeState, (state) => state.isDark);

export const setDarkMode = (value: boolean) =>
  darkModeState.setState((state) => ({ ...state, isDark: value }));

//================================ ENROLLED CLASS (NOT FILTER)
export const classState = new Store<{ class: ClassesResponse | null }>({
  class: null,
});
export const useClass = (): ClassesResponse | null =>
  useStore(classState, (state) => state.class);

export const setClass = (value: ClassesResponse | null) =>
  classState.setState(() => ({ class: value ? { ...value } : null }));

//================================
export const courseState = new Store<{ course: CoursesResponse | null }>({
  course: null,
});
export const useCourse = (): CoursesResponse | null =>
  useStore(courseState, (state) => state.course);

export const setCourse = (value: CoursesResponse | null) =>
  courseState.setState(() => ({ course: value ? { ...value } : null }));

//================================

export const unitState = new Store<{ unit: UnitsResponse | null }>({
  unit: null,
});
export const useUnit = (): UnitsResponse | null =>
  useStore(unitState, (state) => state.unit);

export const setUnit = (value: UnitsResponse | null) =>
  unitState.setState(() => ({ unit: value ? { ...value } : null }));

//================================

export const lessonState = new Store<{ lesson: LessonsResponse | null }>({
  lesson: null,
});
export const useLesson = (): LessonsResponse | null =>
  useStore(lessonState, (state) => state.lesson);

export const setLesson = (value: LessonsResponse | null) =>
  lessonState.setState(() => ({ lesson: value ? { ...value } : null }));

//================================

export const sectionState = new Store<{ section: SectionsResponse | null }>({
  section: null,
});
export const useSection = (): SectionsResponse | null =>
  useStore(sectionState, (state) => state.section);

export const setSection = (value: SectionsResponse | null) =>
  sectionState.setState(() => ({ section: value ? { ...value } : null }));

//================================

export const searchResultsState = new Store<{ searchResults: any[] | null }>(
  {
    searchResults: null,
  },
);
export const useSearchResults = (): any[] | null =>
  useStore(searchResultsState, (state) => state.searchResults);

export const setSearchResults = (value: any[] | null) =>
  searchResultsState.setState(() => ({
    searchResults: value ? [...value] : null,
  }));

//================================

export const progressTreeState = new Store<{
  progressTree: Record<string, any> | null;
}>({
  progressTree: null,
});
export const useProgressTree = (): Record<string, any> | null =>
  useStore(progressTreeState, (state) => state.progressTree);

export const setProgressTree = (value: Record<string, any> | null) =>
  progressTreeState.setState(() => ({
    progressTree: value ? { ...value } : null,
  }));

type SectionProgress = {
  index: string;
  sectionId: string;
  complete: boolean;
  id: string;
  completedSet: Set<string>;
  length: number;
};

export const sectionProgressState = new Store<{
  sectionProgress: SectionProgress | null;
}>({
  sectionProgress: null,
});
export const useSectionProgress = (): SectionProgress | null =>
  useStore(sectionProgressState, (state) => state.sectionProgress);

export const setSectionProgress = (value: SectionProgress | null) =>
  sectionProgressState.setState(() => ({
    sectionProgress: value ? { ...value } : null,
  }));

export const updateSectionProgress = (
  elementId: string,
  isComplete: boolean,
  onChange: Function,
) => {
  sectionProgressState.setState((prevState) => {
    if (!prevState.sectionProgress) {
      console.error("store: updateSectionProgress: sectionProgress is null");
      return prevState;
    }

    const hasElement = prevState.sectionProgress.completedSet.has(elementId);
    if (isComplete && hasElement) return prevState;
    if (!isComplete && !hasElement) return prevState; // important!

    // Create a new Set to ensure immutability
    const newCompletedSet = new Set(prevState.sectionProgress.completedSet);
    if (!isComplete && hasElement) {
      newCompletedSet.delete(elementId);
    } else {
      newCompletedSet.add(elementId);
    }

    const sectionComplete =
      newCompletedSet.size === prevState.sectionProgress.length;

    if (prevState.sectionProgress.complete !== sectionComplete) {
      onChange(sectionComplete);
    }

    return {
      sectionProgress: {
        ...prevState.sectionProgress,
        completedSet: newCompletedSet,
        complete: sectionComplete,
      },
    };
  });
};
//================================

export const classFilterState = new Store<{
  classFilter: ClassesResponse | null;
}>({
  classFilter: null,
});
export const useClassFilter = (): ClassesResponse | null =>
  useStore(classFilterState, (state) => state.classFilter);

export const setClassFilter = (value: ClassesResponse | null) =>
  classFilterState.setState(() => ({
    classFilter: value ? { ...value } : null,
  }));

//================================

// export const sectionSubmissionsState = new Store<{
//   sectionSubmissions: Map<string, SubmissionsResponse[]> | null;
// }>({
//   sectionSubmissions: null,
// });
// export const useSectionSubmissions = (): Map<
//   string,
//   SubmissionsResponse[]
// > | null =>
//   useStore(sectionSubmissionsState, (state) => state.sectionSubmissions);

// export const setSectionSubmissions = (
//   value: Map<string, SubmissionsResponse[]> | null,
// ) =>
//   sectionSubmissionsState.setState(() => {
//     console.log("inside ", value)
//     return {
//       sectionSubmissions: value ? new Map(value) : null,
//     };
//   });

//================================
// export const headingsState = new Store<{ headings: any[] }>({
//   headings: [],
// });
// export const useHeadings = () =>
//   useStore(headingsState, (state) => state.headings);

// export const setHeadings = (value: any[]) =>
//   headingsState.setState((state) => ({headings: [...value]}));

type ClipboardType = {
  type: string;
  isCut: boolean;
  border?: string;
  item: {
    id: string;
    courseId: string;
    unitId?: string;
    lessonId?: string;
    sectionId?: string;
  };
};
export const clipboardState = new Store<ClipboardType | null>(null);

export const useClipboard = (): ClipboardType | null =>
  useStore(clipboardState, (state) => state);

export const setClipboard = (newState: ClipboardType | null) =>
  clipboardState.setState((state) =>
    newState
      ? {
          ...newState,
          border: newState.isCut ? "border-destructive" : "border-warning",
        }
      : null,
  );

type StringMap = {
  [key: string]: string;
};

//What if you want to search the entire course? YOu would need to index the entire course
// you would need to put course id in every lesson not just unitId
//If you fetch all lessons you would need to filter by unit to sort them
// create the tree structure
export const LessonIndexStore = new Store<StringMap>({});

// export const useCurrent = ():ClipboardType => useStore(clipboardState, (state) => state);

// export const setCurrent = (id: string, type: string) =>
//   clipboardState.setState((state) => ({ ...state, id, type }));
