import CodeHike from "@/components/codehike/CodeHike";
import { ElementsResponse } from "@/lib/pb/types";
import { Playground, SandpackFiles } from "@/lms/playground/Playground";
import { RotateCw, Keyboard } from "lucide-react";
import { useEnrollment } from "@/lib/store";
import { Form } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { ElementSubmissionNav } from "@/lms/element/ElementSubmissonNav";
import { useSubmit, useSubmissionDetail } from "@/lib/pb/submissions";
import { cn } from "@/lib/utils";
import { Widths } from "@/lib/sizes";
import { GenericInputField } from "@/components/form/CustomFields";
import { memo, useCallback, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { ElementTitle } from "@/lms/element/ElementTitle";

export default function CodePlayground({
  element,
  children, // <ElementSubmissionAdminNav element={element} />
}: {
  element: ElementsResponse;
  children?: React.ReactNode;
}) {
  const data = element.original ? element.original.data : element.data;

  //get translation from element.data but keep original
  if(element.original) {
    data.files = data.files;
    data.hint = data.hint;
    data.instruction = data.instruction;
  }

  // const { instruction, ...playgroundProps } = element.data;
  //const instruction = element?.data?.instruction;
  return (
    <div className="grid w-full gap-2">
      <div className="w-full max-w-3xl flex flex-col gap-2 mx-auto ">
        {element.isSubmitType && (
          <ElementTitle icon={Keyboard} title={"Code Playground"}>
            {children}
          </ElementTitle>
        )}
        {data.instruction && <CodeHike item={element} code={data.instruction} />}
      </div>
      <div
        className={cn(
          "mx-auto w-full bg-accent/40 rounded-md border border-primary/50 hover:border-primary",
          Widths[data.width as keyof typeof Widths],
        )}
      >
        {element.isSubmitType ? (
          <CodePlaygroundForm element={element} />
        ) : (
          <BasicCodePlayground element={element} />
        )}
      </div>
    </div>
  );
}

function BasicCodePlayground({ element }: { element: ElementsResponse }) {
  const { files, instruction, ...playgroundProps } = element.data as Record<
    string,
    any
  >;
  return (
    <MemoizedPlayground
      {...playgroundProps}
      baseFiles={files}
      mountPoint={element.id}
    />
  );
}

//const MemoizedCodePlaygroundForm = memo(CodePlaygroundForm);
const MemoizedPlayground = memo(Playground); //because of dirty state

function CodePlaygroundForm({ element }: { element: ElementsResponse }) {
  const data = element.data;
  const { files, instruction, ...playgroundProps } = element.data as Record<
    string,
    any
  >; //default data

  const [dirty, setDirty] = useState(false); //this triggers rerender!
  const [baseFiles, setBaseFiles] = useState(files);

  const enrollment = useEnrollment(); //stale time infinity

  const { submit, isPending: submitPending } = useSubmit(
    element.id,
    element.sectionId,
    enrollment?.id,
  );

  //CHECK SINGLE SUBMISSION (User) refech could cause loss of work
  const { data: submission, isPending: isFetchingSubmission } =
    useSubmissionDetail(
      element.id,
      element.sectionId,
      enrollment?.id,
      element.submissionRequired,
    );

  useEffect(() => {
    if (submission && submission.data && submission.data.files) {
      // console.log("Resetting to submission file data............", data.files);
      setBaseFiles({ ...submission.data.files });
      setDirty(false);
    }
  }, [submission]); //sets base file to user submission.

  //FILES CHANGE (Admin Edit)
  useEffect(() => {
    if (submission || !data) return; //don't replace submission with admin edit
    //console.log("base files changed", data.files);
    setBaseFiles({ ...data.files });
    setDirty(false);
  }, [data]);

  const formOptions = {
    defaultValues: { files }, //when use basefile i get an error
    //defaultValues: { response: submission?.data?.response },
    //TODO: Create a defaultSubmit function
    onSubmit: async ({ value, formApi }: { value: any; formApi: any }) => {
      const data = {
        elementId: element.id,
        enrollmentId: enrollment?.id,
        attempts: submission ? submission.attempts + 1 : 1,
        data: value,
        complete: true,
      };

      if (!submit) throw new Error("no submit function");
      const result = await submit(data, submission?.id);
      if (result) formApi.reset();
    },
  };

  const form = useForm(formOptions);
  //const formFiles = form.useStore((state) => state.values.files);

  const updateParentForm = useCallback(
    (newFiles: SandpackFiles) => {
      const { files, activeFile, visibleFiles } = newFiles as SandpackFiles;

      const formFiles = form.getFieldValue("files");
      form.setFieldValue("files", {
        ...formFiles,
        files: files ?? formFiles.files,
        activeFile: activeFile ?? formFiles.activeFile,
        visibleFiles: visibleFiles ?? formFiles.visibleFiles,
      });

      let foundDirtyFile = false;
      //what happens if user closes visible files..
      const original = submission?.data?.files
        ? submission.data.files
        : data.files;
      if (files) {
        if (haveSameKeys(files, original.files)) {
          visibleFiles?.map((name) => {
            if (isFileDirty(files[name], original?.files[name])) {
              //console.log("DIRTY: ", files[name], original?.files[name]);
              foundDirtyFile = true;
            }
          });
        } else {
          // dont have the same file names then dirty
          foundDirtyFile = true;
        }
        setDirty(foundDirtyFile);
      }
    },
    [submission, data],
  );

  const btnStyle =
    "h-9 flex rounded-full text-muted-foreground hover:text-foreground text-xs w-20 text-wrap";
  return (
    <div className="relative">
      <MemoizedPlayground
        {...playgroundProps}
        key={playgroundProps.splitPercent}
        baseFiles={baseFiles}
        updateParentForm={updateParentForm}
        //keeping mount point the same
        mountPoint={element.id}
      />

      <Form key={element.id} form={form}>
        <fieldset>
          <GenericInputField
            form={form}
            className="hidden"
            name="files"
            type="hidden"
          />
          <ElementSubmissionNav
            item={element}
            form={form}
            disabled={submitPending || !dirty}
            submission={submission}
            hint={data.hint}
            className="px-4 py-3 border-t-2 "
          >
            <Button
              variant="ghost"
              className={btnStyle}
              onClick={(e) => {
                e.preventDefault();
                setBaseFiles({ ...files });
                setDirty(false);
              }}
            >
              <RotateCw className="h-4 w-4 pe-1" />
              Reset
            </Button>

            {submission && (
              <Button
                variant="ghost"
                className={btnStyle}
                onClick={(e) => {
                  e.preventDefault();
                  setBaseFiles({ ...submission.data?.files });
                  setDirty(false);
                }}
              >
                Restore to Saved
              </Button>
            )}
          </ElementSubmissionNav>
        </fieldset>
      </Form>
      {dirty && (
        <div className="flex ms-4 px-2 absolute bottom-[3.5rem] z-10 left-1 text-primary text-xs bg-background border-primary border rounded-xl">
          You have unsaved changes. Submit to save.
        </div>
      )}
    </div>
  );
}

function isFileDirty(f1: any, f2: any) {
  if (!f1 || !f2) return false;
  // console.log(f1, f2);
  const f1Code = typeof f1 == "string" ? f1 : f1.code;
  const f2Code = typeof f2 == "string" ? f2 : f2.code;
  return f1Code.trim() != f2Code.trim();
}

function haveSameKeys(obj1, obj2) {
  //console.log("haveSameKeys", obj1, obj2);
  const keys1 = Object.keys(obj1).sort();
  const keys2 = Object.keys(obj2).sort();

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) {
      return false;
    }
  }

  return true;
}
