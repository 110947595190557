import { Database } from "lucide-react";
import { formatBytes } from "@/lib/utils";
import { Card, CardContent } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import {cn} from "@/lib/utils";

export function StorageCard({
  usage,
  maxUsage,
  className,
}: {
  usage: number;
  maxUsage: number;
  className: string;
}) {
  const percentUsage = (100 * usage) / (maxUsage * 1024 * 1024);
  return (
    <Card className={cn("rounded-md text-xs shadow-sm", className)}>
      <CardContent className="flex items-start gap-2.5 p-2.5">
        <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-md bg-accent text-accent-foreground">
          <Database className="h-4 w-4 text-muted-foreground" />
        </div>
        <div className="grid flex-1 gap-1">
          <p className="font-medium text-lg">
            Storage Usage 
          </p>
          <p className="text-muted-foreground">
            {formatBytes(usage)} / {maxUsage} MB used ({Math.round(percentUsage)}%)
          </p>
          <Progress value={percentUsage} className="mt-1" />
        </div>
      </CardContent>
    </Card>
  );
}
