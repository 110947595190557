import { zodValidator } from "@/components/form";
import { ConfirmDeleteForm } from "@/components/form/Form";
import { useDeleteClassMutation } from "@/lib/pb/lms";
import { ClassesAdminViewResponse } from "@/lib/pb/types";
import { ContentsProps } from "@/components/format/Dialog";

export function ClassDeleteForm({
  item,
  onComplete,
}: ContentsProps<ClassesAdminViewResponse>) {
  if (!item) return <div>No class found.</div>;

  const { mutateAsync: deleteClass, isPending } = useDeleteClassMutation(
    item.courseId,
    item.id,
  );

  const options = {
    validatorAdapter: zodValidator(),
    defaultValues: { name: "" },
    onSubmit: async ({ value }: { value: { name: string } }) => {
      if (isPending) return;
      if (value.name !== item.name) return; //test field value
      await deleteClass({ id: item.id }).catch((e) => console.log(e));
      onComplete();
    },
  };

  return (
    <ConfirmDeleteForm
      formOptions={options}
      info={`This class has ${item.totalEnrollments as number} enrolled and ${item.totalTeams as number} team(s).`}
      match={item.name}
      onComplete={onComplete}
      type="Class"
      fieldName="name"
    />
  );
}
