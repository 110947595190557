import {
  Table as TanstackTable,
  Header,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  useReactTable,
  OnChangeFn,
  ColumnFiltersState,
} from "@tanstack/react-table";
import {
  Table as ShadTable,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Skeleton } from "@/components/ui/skeleton";
import { ArrowUp, ArrowDown } from "lucide-react";
import { useState } from "react";
import { BaseSystemFields } from "@/lib/pb/types";
import { useEffect } from "react";

export interface TableProps<T> {
  data?: Array<T>;
  columns: ColumnDef<T>[];
  sortEnabled?: boolean;
  isPending?: boolean;
  rowSelectionState?: [RowSelectionState, OnChangeFn<RowSelectionState>];
  refetch?: () => void;
  initialPageSize?: number;
  columnFilters?:  ColumnFiltersState;
}

export function Table<T extends BaseSystemFields<unknown>>({
  data = [],
  columns,
  sortEnabled = false,
  isPending = false,
  rowSelectionState,
}: TableProps<T>) {
  const [rowSelection, setRowSelection] = rowSelectionState
    ? rowSelectionState
    : [];
  const table = useReactTable({
    columns,
    data,
    //debugTable: true,
    getRowId: (row) => row.id,
    enableMultiRowSelection: false,
    onRowSelectionChange: setRowSelection,
    state: {
      rowSelection: rowSelection, //pass the row selection state
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: !sortEnabled ? undefined : getSortedRowModel(),
  });

  //Select first class if data is availabe
  useEffect(() => {
    if (!data || !rowSelectionState) return;
    if (Object.keys(rowSelection).length <= 0 && data.length > 0) {
      // console.log("set row", data[0].id);
      setRowSelection({ [data[0].id]: true });
    }
  }, [data]);

  return (
    <BaseTable
      table={table}
      isPending={isPending}
      sortEnabled={sortEnabled}
      isSelectable={!!rowSelectionState}
    />
  );
}

export function BaseTable({
  sortEnabled = false,
  isPending = false,
  table,
  isSelectable = false,
}: {
  sortEnabled?: boolean;
  isPending?: boolean;
  table: TanstackTable<any>;
  isSelectable?: boolean;
}) {
  return (
    <ShadTable className="object-contain">
      <TableHeader>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              return (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {sortEnabled ? (
                    <SortableHeader header={header} />
                  ) : (
                    <>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </>
                  )}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
      <TableBody>
        {isPending ? (
          <TableRow>
            {table.getLeafHeaders().map((_, j) => (
              <TableCell key={j}>
                <Skeleton className="h-10" />
              </TableCell>
            ))}
          </TableRow>
        ) : table.getRowCount() < 1 ? (
          <TableRow>
            <TableCell colSpan={table.getLeafHeaders().length}>
              <div className="text-center">No rows found</div>
            </TableCell>
          </TableRow>
        ) : (
          table.getRowModel().rows.map((row) => {
            return (
              <TableRow
                key={row.id}
                className={
                  isSelectable && row.getIsSelected()
                    ? "bg-primary/25 hover:bg-primary/50"
                    : isSelectable
                      ? "cursor-pointer"
                      : ""
                }
                onClick={isSelectable ? row.getToggleSelectedHandler() : null}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })
        )}
      </TableBody>
    </ShadTable>
  );
}

function SortableHeader({ header }: { header: Header<any, any> }) {
  return (
    <div
      className={
        header.column.getCanSort() ? "cursor-pointer select-none flex" : ""
      }
      onClick={header.column.getToggleSortingHandler()}
    >
      <div>
        {flexRender(header.column.columnDef.header, header.getContext())}
      </div>
      {{
        asc: <ArrowUp className="ps-2" />,
        desc: <ArrowDown className="ps-2" />,
      }[header.column.getIsSorted() as string] ?? null}
    </div>
  );
}
