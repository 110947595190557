import { Input as ShadInput } from "@/components/ui/input";
import { Textarea as ShadTextarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import type { FieldApi } from "@tanstack/react-form";

interface InputProps {
  field?: FieldApi<any, any, any, any>;
  placeholder?: string;
  type?: string;
  className?: string;
  required?: boolean;
  defaultValue?: any;
  disabled?: boolean;
  rows?: string;
  onChange?: (e: any) => void;
}

export function Input({ field, className, onChange,...props }: InputProps) {
  return (
    <ShadInput
      id={field?.name}
      name={field?.name}
      value={field?.state.value}
      onBlur={field?.handleBlur}
      className={cn(
        props.type == "file" ? "file:cursor-pointer file:h-10 p-0 cursor-pointer hover:file:bg-primary/80 file:bg-primary file:rounded-md file:me-4 file:px-4" : "border-4",
        className,
      )}
      onChange={(e) => {
        field?.handleChange(e.target.value);
        onChange?.(e);
      }}
      onKeyDown={(e) => e.stopPropagation()}
      {...props}
    />
  );
}
// onKeyDown={(e) => e.stopPropagation()} https://github.com/radix-ui/primitives/discussions/787

export function Textarea({ field, className,...props }: InputProps) {
  return (
    <ShadTextarea
      id={field?.name}
      name={field?.name}
      value={field?.state.value}
      onBlur={field?.handleBlur}
      className={cn(
        "bg-background border-muted-foreground resize-none",
        className,
      )}
      rows={props.rows ?? "1"}
      onChange={(e) => {
        field?.handleChange(e.target.value);
      }}
      onKeyDown={(e) => e.stopPropagation()}
      {...props}
    />
  );
}
