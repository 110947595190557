import React, { useState, useEffect } from "react";
//import { Button } from "react-bootstrap";
import { Play, Square, Clock, Clock12 } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
//import Input from "../../ui/Input";

export const CountdownTimer = () => {
  const [minutes, setMinutes] = useState<number>(1); // Default to 1 minute
  const [time, setTime] = useState(60);
  const [timerOn, setTimerOn] = useState(false);

  useEffect(() => {
    let interval = null;

    if (timerOn) {
      if (time > 0) {
        interval = setInterval(() => {
          setTime((prevTime) => prevTime - 1);
        }, 1000);
      } else {
        setTimerOn(false);
        setTime(0);
      }
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerOn, time]);

  const startTimer = () => {
    // setTime(minutes * 60);
    if (time > 0) setTimerOn(true);
  };
  const stopTimer = () => {
    //setTime(minutes * 60);
    setTimerOn(false);
  };

  return (
    <div
      className={cn(
        "border-2 rounded-lg w-24",
        timerOn ? "bg-warning" : "bg-background",
      )}
    >
      <div className="flex items-center">
        <Button
          size="sm"
          className="px-1"
          variant={timerOn ? "warning" : "secondary"}
          onClick={timerOn ? stopTimer : startTimer}
        >
          {timerOn ? <Clock12 className="animate-spin" /> : <Clock />}
        </Button>
        {!timerOn ? (
          <div className="w-full text-center">
            <div className="font-bold text-md h-4">
              {Math.floor(time / 60)}:
              {time % 60 < 10 ? `0${time % 60}` : time % 60}
            </div>
            <Button
              variant="secondary"
              className="h-4 w-4 px-1 py-0 border-2 text-xs"
              onClick={() => setTime(time + 300)}
            >
              5
            </Button>
            <Button
              variant="secondary"
              className="h-4 w-4 px-1 py-0 border-2"
              onClick={() => setTime(time + 30)}
            >
              +
            </Button>
            <Button
              variant="secondary"
              className="h-4 w-4 px-1 py-0 border-2"
              onClick={() => {
                if (time >= 30) setTime(time - 30);
                else setTime(0);
              }}
            >
              -
            </Button>
            <Button
              variant="secondary"
              className="h-4 w-4 px-1 py-0 border-2 text-xs"
              onClick={() => {
                setTime(0);
              }}
            >
              0
            </Button>
          </div>
        ) : (
          <div className="flex w-full font-bold text-2xl justify-center">
            {Math.floor(time / 60)}:
            {time % 60 < 10 ? `0${time % 60}` : time % 60}
          </div>
        )}
      </div>
    </div>
  );
};
