import { createFileRoute, Outlet } from "@tanstack/react-router";

import NavBar from "@/components/nav/NavBar";
import { Footer } from "@/components/nav/Footer";

export const Route = createFileRoute("/_nav")({
  component: NavPage,
});

function NavPage() {
  return (
    <div className="flex w-full flex-col">
      <NavBar />
      <div className="p-4 lg:mx-8">
        <Outlet />
      </div>
      <Footer/>
    </div>
  );
}
