import { Outlet, createFileRoute, Link } from "@tanstack/react-router";
import { SidebarSearch } from "@/components/sidebar/nav-search";
import { BreadcrumbNav } from "@/components/nav/BreadcrumbNav";
import { Footer } from "@/components/nav/Footer";
//import { useMemo } from "react";

import {
  useUser,
  useUnit,
  setUnit,
  setLesson,
  setSection,
  useCourse,
} from "@/lib/store";
import { ROLES } from "@/lib/roles";

import UnitAdminMenu from "@/lms/unit/UnitAdminMenu";
import LessonAdminMenu from "@/lms/lesson/LessonAdminMenu";
import SectionAdminMenu from "@/lms/section/SectionAdminMenu";
import { Clipboard } from "@/lms/core/Clipboard";

import { AppSidebar } from "@/components/sidebar/app-sidebar";
import { SidebarLayout, SidebarTrigger } from "@/components/ui/sidebar";
import { useEffect } from "react";
import { CourseAdminDropdownMenu } from "@/lms/course/CourseAdminMenu";

export const Route = createFileRoute("/_auth/course/$courseId/$unitId")({
  component: UnitPage,
});

export function UnitPage() {
  const { unitId } = Route.useParams();
  const course = useCourse();
  const unit = useUnit();
  const units = course?.children ?? []; //array

  useEffect(() => {
    let newUnit = null;
    if (course?.units) {
      //object
      newUnit = course.units[unitId] ?? null;
      // if (newUnit) {
      //   if (unit && unit.id !== newUnit.id) {
      //     console.log("reset section and lesson");
      //    // setSection(null);
      //    // setLesson(null);
      //   }
      // }
    }
    //console.log("setting unit ", newUnit?.name);
    setUnit(newUnit);
  }, [unitId, course]);

  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isInstructor = user?.role >= ROLES.instructor;
  //max width 840px
  //
  if (!unit)
    return <div className="w-full pt-16 text-center">No unit: {unitId}</div>;

  return (
    <div className="">
      <SidebarLayout defaultOpen={true}>
        {units && (
          <AppSidebar
            units={units}
            details={{
              unit: isAdmin ? UnitAdminMenu : null,
              section: isAdmin ? SectionAdminMenu : null,
              lesson: isAdmin ? LessonAdminMenu : null,
            }}
          />
        )}
        <div className="flex flex-col w-full">
          <div className="fixed xl:ms-1 h-[100dvh]">
            <SidebarTrigger />
          </div>

          <div className="flex flex-col gap-6 w-full">
            <div className="flex w-full gap-4">
              <div
                id="section-top"
                className="flex w-full max-w-7xl pt-0 lg:pt-4 justify-center  mx-auto"
              >
                <div className="w-full max-w-3xl flex my-0 h-8 gap-16 items-center ">
                  {/* <BreadcrumbNav /> */}
                  <SidebarSearch results={searchResults} />
                  {isInstructor && <CourseAdminDropdownMenu item={course}/>}
                  <Clipboard />
                </div>
              </div>
              <div className=" flex w-60 shrink-0 max-2xl:hidden xl:w-72 "></div>
            </div>

            <Outlet />
            <div className="flex mx-auto">
              <Footer />
            </div>
          </div>
        </div>
      </SidebarLayout>
    </div>
  );
}

//adding here because $courseId_ doesnt have the same context
// beforeLoad: async ({
//   context: { queryClient },
//   params: { courseId, unitId },
// }) => {
//   // !!! components that read these values from useRouteContext dont get updated
//   try {
//     const results = await Promise.all([
//       queryClient.ensureQueryData(courseDetailQuery(courseId)),
//       queryClient.ensureQueryData(unitDetailQuery(unitId)),
//     ]);
//     console.log(results);
//     return { course: results[0], unit: results[1] };
//   } catch (e) {
//     console.error("Error with unit route: ", e);
//     return { course: null, unit: null };
//   }
// },

// {false && units && (
//   <Sidebar
//     unit={unit}
//     units={units}
//     details={{
//       unit: isAdmin ? UnitAdminMenu : null,
//       section: isAdmin ? SectionAdminMenu : null,
//       lesson: isAdmin ? LessonAdminMenu : null,
//     }}
//   />
// )}

const searchResults = [
  {
    title: "Routing Fundamentals",
    teaser:
      "The skeleton of every application is routing. This page will introduce you to the fundamental concepts of routing for the web and how to handle routing in Next.js.",
    url: "#",
  },
  {
    title: "Layouts and Templates",
    teaser:
      "The special files layout.js and template.js allow you to create UI that is shared between routes. This page will guide you through how and when to use these special files.",
    url: "#",
  },
  {
    title: "Data Fetching, Caching, and Revalidating",
    teaser:
      "Data fetching is a core part of any application. This page goes through how you can fetch, cache, and revalidate data in React and Next.js.",
    url: "#",
  },
  {
    title: "Server and Client Composition Patterns",
    teaser:
      "When building React applications, you will need to consider what parts of your application should be rendered on the server or the client. ",
    url: "#",
  },
  {
    title: "Server Actions and Mutations",
    teaser:
      "Server Actions are asynchronous functions that are executed on the server. They can be used in Server and Client Components to handle form submissions and data mutations in Next.js applications.",
    url: "#",
  },
];
