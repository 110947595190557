import { UnitsResponse } from "@/lib/pb/types";
import { Link } from "@/components/nav/Link";
import { EyeOff } from "lucide-react";
import { Progress } from "../format/Progress";

export function UnitItem({
  unit,
  progress,
}: {
  unit: UnitsResponse;
  progress: any;
}) {
  return (
    <Link
      to={`/course/${unit.courseId}/${unit.id}/`}
      className={unit.active ? "h-auto" : "h-auto opacity-50"}
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-sm bg-primary text-primary-foreground">
        {unit.index}
      </div>
      <div className="ps-2 grid flex-1 leading-tight">
        <div className="flex gap-2 items-center line-clamp-1 font-semibold text-lg tracking-tight justify-between">
          {unit.name}

          {progress && (
            <div className="w-24">
              <Progress
                variant="bg-success"
                value={
                  progress.length == 0
                    ? 0
                    : (progress.completed / progress.length) * 100
                }
              />
            </div>
          )}
        </div>
        <div className="text-xs text-muted-foreground">
          <div className="line-clamp-3 ">{unit.subtitle}</div>
        </div>
      </div>
    </Link>
  );
}
