import Pocketbase, {
  RecordFullListOptions,
  RecordListOptions,
  RecordModel,
} from "pocketbase";
import { setUser } from "@/lib/store";
import { AnyRecord } from "@/lib/types";
//https://github.com/oven-sh/bun/issues/6338
//https://github.com/oven-sh/bun/issues/9877
import { TypedPocketBase, UserFilesResponse } from "./types";
import { filterFns } from "@tanstack/react-table";
const pb = new Pocketbase(import.meta.env.VITE_PB_URL) as TypedPocketBase;

pb.authStore.onChange(
  (token, model) => {
    model
      ? console.log("authStore change: ", model.email)
      : console.log("authStore clear");
    setUser(model);
    console.log("authStore change: ", model);
  },
  true, //true sets state when page reloads.
);

//cancelKey need for mulitple adds PasteButton.jsx
export async function pbCreate<T = RecordModel>(
  collection: string,
  set: AnyRecord,
): Promise<T> {
  // const params = cancelKey ? { '$cancelKey': cancelKey } : null
  return await pb.collection(collection).create<T>(set);
}

export async function pbDelete(
  collection: string,
  id: string,
): Promise<boolean> {
  return await pb.collection(collection).delete(id);
}

export async function pbUpdate(
  collection: string,
  id: string,
  set: AnyRecord,
): Promise<any> {
  return await pb.collection(collection).update(id, set);
}

const pbHeaders = {
  "Access-Control-Allow-Origin": import.meta.env.VITE_PB_URL,
  "Access-Control-Allow-Methods": "GET, POST, PUT",
  "Access-Control-Allow-Headers": "Content-Type",
};

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export async function getOne<T = RecordModel>(
  collection: string,
  id: string | null | undefined,
  params: RecordFullListOptions = {},
) {
  console.log("GET getOne " + id, params);
  if ( !id) return null;

  // await delay(1000);
  params.headers = pbHeaders;
  return pb.collection(collection).getOne<T>(id, params);
}

 // *getFirstListItem For consistency with `getOne`, this method will throw a 404
 // * ClientResponseError if no item was found. (Not using getFirstListItem)
export async function getFirst<T = RecordModel>(
  collection: string,
  filter: string,
  params: RecordFullListOptions = {},
) {
  console.log("GET getFirst " + collection, filter);
  params.headers = pbHeaders;
  params.filter = filter;
  params.skipTotal = true;
  params.requestKey = filter;
  //await delay(1000);
  const result = await pb.collection(collection).getList<T>(1, 1, params); //params);
  if (!result?.items?.length) {
    return null;
  }
  return result.items[0];
}

export async function getTranslation(
  collection: string,
  id: string | undefined,
  params: RecordFullListOptions = {},
  language: string,
) {
  if (!id) return null;
  const result = await getOne(collection, id, params);
  const entry = result?.translations?.[language];
  return entry ? { id, ...entry } : null;
}



export async function getFullList<T = RecordModel>(
  collection: string,
  params: RecordFullListOptions = {},
) {
  console.log("GET getFullList " + collection, params);
  params.batch = 4000;
  params.headers = pbHeaders;

  //await delay(1000);
  return pb.collection(collection).getFullList<T>(params); //params);
}

export async function getFullObject<T = RecordModel>(
  collection: string,
  params: RecordFullListOptions = {},
  indexBy: string = "id",
) {
  const list = await getFullList<T>(collection, params);
  return arrayToObject<T>(list, indexBy); //params);
}

export async function getFullMap<T = RecordModel>(
  collection: string,
  params: RecordFullListOptions = {},
  indexBy: string = "id",
) {
  const list = await getFullList<T>(collection, params);
  return arrayToMap<T>(list, indexBy); //params);
}

export async function getGroupList<T = RecordModel>(
  collection: string,
  params: RecordFullListOptions = {},
  indexBy: string = "id",
) {
  const list = await getFullList<T>(collection, params);
  return groupBy<T>(list, indexBy); //params);
}

function groupBy<T>(items: T[], key: string): Map<string, T[]> {
  const map = new Map<string, T[]>();

  for (const item of items) {
    const keyValue = (item as any)[key];
    if (!map.has(keyValue)) {
      map.set(keyValue, []);
    }
    map.get(keyValue)!.push(item);
  }

  return map;
}

export function arrayToMap<T extends Record<string, any>>(
  arr: T[],
  indexBy: string,
): Map<string, T> {
  return arr.reduce((map, item) => {
    map.set(item[indexBy], item);
    return map;
  }, new Map<string, T>());
}

//checking empty Object is slow because need to extract keys... =/
function arrayToObject<T extends Record<string, any>>(
  arr: T[],
  indexBy: string,
): Record<string, T> {
  return arr.reduce(
    (obj, item) => {
      obj[item[indexBy]] = item;
      return obj;
    },
    {} as Record<string, T>,
  );
}
//const itemMap = new Map(array.map(item => [item.id, item]));

export interface TranslationItem {
  id: string;
  translations: { [key: string]: { [key: string]: string } } | undefined;
}

// export async function getTranslationList<T extends TranslationItem>(
//   collection: string,
//   params: RecordFullListOptions = {},
//   language: string,
// ) {
//     const array = await getFullList<T>(collection, params);
//     return  array.map((item) =>
//         item.translations && item.translations[language]
//           ? [item.id, { id: item.id, ...item.translations[language] }]
//           : [item.id, { id: item.id }]
//       )

//   }

interface TranslateType {
  id: string;
  translations: { [key: string]: { [key: string]: string } } | undefined;
}

// TODO: This would be better as an Object instead of MAP since there isnt a lot of changes to the map
export async function getTranslationMap<T = RecordModel>(
  collection: string,
  params: RecordFullListOptions = {},
  language: string,
) {
  const array = await getFullList<TranslateType>(collection, params);
  return new Map(
    array.map((item) =>
      item.translations && item.translations[language]
        ? [item.id, { id: item.id, ...item.translations[language] } as T]
        : [item.id, { id: item.id } as T],
    ),
  );
}

export function createMap<T>(
  children: Array<T>,
  parents: Array<any>,
  parentId: keyof T,
) {
  let map = new Map<string, Array<T>>(parents.map((p) => [p.id, []]));
  children.forEach((child) => map.get(child[parentId] as string)?.push(child));
  return map;
}

// export async function getList(
//   collection: string,
//   params: RecordListOptions = {},
// ) {
//   console.log("getListFn " + collection, params);
//   params.headers = pbHeaders;

//   //await delay(3000);
//   const res = await pb.collection(collection).getList(1, 1000, params); //params);
//   return res.items;
// }

// const paramFields = {
//   course: "id, name, description, details, bannerUrl, color, order",
//   units: "id, name, description, hidden, order, excludedClasses",
//   lessons: "id, name, hidden, order",
//   sections: "id, name, hidden, order",
//   elements: "id, hidden, submissionRequired, type",
// };
export function getUrl(record: UserFilesResponse, options?:Record<string, any>) {
  return pb.files.getUrl(record, record.file, options);
}

export default pb;
