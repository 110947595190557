import { CourseDetails } from "@/lms/course/CourseCard";
import { coursesListQuery, useCoursesTranslation } from "@/lib/pb/lms";
import { useQuery } from "@tanstack/react-query";
import { ClassesEnrollList } from "@/lms/classes/ClassesEnrollList";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { translateItems } from "@/lib/lang";

function PendingCourses() {
  return (
    <div className="grid gap-8">
      <Skeleton className="w-full h-72 shadow-xl rounded-xl" />
    </div>
  );
}

export function EnrollCoursesList() {
  const { data: courses, ...coursesQuery } = useQuery(coursesListQuery());
  const { data: translations, ...translationsQuery } = useCoursesTranslation();
  const translatedCourses = translateItems(courses, translations);

  if (coursesQuery.error && !coursesQuery.isPending) {
    return <div>Error fetching courses for CoursesList</div>;
  }
  if (translationsQuery.error && !translationsQuery.isPending) {
    return <div>Error fetching translations for CoursesList</div>;
  }

  return (
    <div className="grid gap-4 w-full max-w-7xl mx-auto ">
      <div className="flex justify-between p-4 rounded-t-xl">
        <div className="text-3xl font-bold tracking-tight">
          Enroll in a Class
        </div>
      </div>
      <div className="grid gap-8">
        {coursesQuery.isPending ? (
          <PendingCourses />
        ) : (
          translatedCourses?.map((course) => (
            <div key={course.id} className="grid gap-8">
              <Separator />
              <div
                className="flex flex-wrap md:flex-nowrap gap-8 justify-center"
                key={course.id}
              >
                <div className="basis-3/5">
                  <CourseDetails course={course} />
                </div>
                <div className="basis-2/5">
                  <ClassesEnrollList courseId={course.id} />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

//use on Start page when there is no user
export function CoursesList() {
  const { data: courses, ...coursesQuery } = useQuery(coursesListQuery());
  const { data: translations, ...translationsQuery } = useCoursesTranslation();
  const translatedCourses = translateItems(courses, translations);

  if (coursesQuery.error && !coursesQuery.isPending) {
    return <div>Error fetching courses for CoursesList</div>;
  }
  if (translationsQuery.error && !translationsQuery.isPending) {
    return <div>Error fetching translations for CoursesList</div>;
  }

  return (
    <div className="grid gap-8 w-full max-w-7xl mx-auto ">
      {coursesQuery.isPending ? (
        <PendingCourses />
      ) : (
        translatedCourses?.map((course) => (
          <CourseDetails key={course.id} course={course} />
        ))
      )}
    </div>
  );
}
