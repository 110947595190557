import { useLanguage, LanguageKey, availableLanguages } from "@/lib/store";
export { useLanguage } from "@/lib/store";

export function getLanguageName(key: LanguageKey) {
  return availableLanguages[key];
}

interface TranslatableItem{
  id: string;
  [key: string]: any;
  data?: any;
  original?: { [key: string]: any };
}

export function findTranslationField<T extends TranslatableItem>(
  item: T,
  field: string,
  translations: Map<string, T> | undefined,
) {
  if (!translations) return item[field];
  const entry = translations.get(item.id);
  if (!entry) return item[field];
  return entry[field];
}

export function findTranslation<T extends TranslatableItem>(
  item: T,
  translations: Map<string, T> | undefined,
) {
  if (!translations) return item;
  const entry = translations.get(item.id);
  return entry ? translateItem(item, entry) : item;
}

// export function translateField<T extends TranslatableItem>(
//   item: T,
//   field: string,
//   translation: T,
// ) {
//   if (!translation) return item[field];
//   return translation[field] ? translation[field] : item[field];
// }

export function translateItem<T extends TranslatableItem>(
  item: T,
  translation: T,
) {
  if (!item) return null;
  if (!translation) return {...item, original: item};
  let ret =  Object.assign({ ...item }, translation) as T;
  if(item.data && translation.data) //for elements: preserve nontranslated data
    ret.data = Object.assign({...item.data}, translation.data);
  ret.original = item;
  return ret;
}

export function translateItems<T extends TranslatableItem>(
  items: Array<T> | undefined | null,
  translations: Map<string, T> | undefined | null
) {
  if (!items) return [];
  if (!translations) return items;
  return items.map((item) => findTranslation<T>(item, translations) as T);
} 

//Set language - lookup browser language, default 'en'
export const browserLanguage = navigator.language.split("-")[0];

export const defaultLanguage: LanguageKey =
  browserLanguage in availableLanguages
    ? (browserLanguage as LanguageKey)
    : "en";

export const StaticTranslations = {
  "en": { es: "es", pt: "pt" },
  "Home": { es: "Inicio", pt: "Início" },
  "Get Started": { es: "Empezar", pt: "Iniciar" },
  "Logout": { es: "Cerrar sesión", pt: "Encerrar sessão" },
  "Login": { es: "Iniciar sesión", pt: " Iniciar sessão" },
  "Sign up": { es: "Crear Cuenta", pt: "Criar Conta" },
  "Name": { es: "Nombre", pt: "Nome" },
  "Email": { es: "Correo Electrónico", pt: "E-mail" },
  "Password": { es: "Contraseña", pt: "Senha" },
};

type STKeys = keyof typeof StaticTranslations;

export function useStaticTranslations() {
  const language = useLanguage();
  const translate = (key: STKeys) => {
    if (language === "en") return key;
    const translations = StaticTranslations[key];
    if (!translations) return key;
    return translations[language] ? translations[language] : key;
  };
  return translate;
}
// export const languageCodes = {
//   en: 'English',
//   zh: '中文',
//   hi: 'हिन्दी',
//   es: 'Español',
//   ar: 'العربية',
//   bn: 'বাংলা',
//   pt: 'Português',
//   ru: 'Русский',
//   ja: '日本語',
//   pa: 'ਪੰਜਾਬੀ',
//   de: 'Deutsch',
//   jv: 'Basa Jawa',
//   ko: '한국어',
//   fr: 'Français',
//   te: 'తెలుగు',
//   mr: 'मराठी',
//   tr: 'Türkçe',
//   ta: 'தமிழ்',
//   vi: 'Tiếng Việt',
//   ur: 'اردو',
//   it: 'Italiano',
//   th: 'ไทย',
//   gu: 'ગુજરાતી',
//   fa: 'فارسی',
//   pl: 'Polski',
//   uk: 'Українська',
//   ml: 'മലയാളം',
//   ha: 'Hausa',
//   or: 'ଓଡ଼ିଆ',
//   my: 'ဗမာ'
// }
