import { SwitchField } from "@/components/form/CustomFields";
import { FormFieldsProps } from "@/components/form/Form";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";
import { cn } from "@/lib/utils";

import {
  CommonAdminFields,
  MarkdownField,
  SubmissionRequiredSwitchField,
  OptionalTextField,
  EnableCommentsSwitchField,
} from "@/lms/core/LmsFields";
import { formatAnswer, getAnswers } from "./FreeResponse";
import CodeHike from "@/components/codehike/CodeHike";

const Id = "free-response";
const Name = "Free Response";

const Defaults = {
  question: "",
  hint: "",
  answer: "",
  rows: "1",
  type: Id,
  isSubmitType: true,
  submissionRequired: true,
  caseSensitive: false,
  ignoreWhiteSpaces: false,
  enableComments: true,
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  return (
    <div className="grid gap-4 py-4 px-1">
      <MarkdownField
        form={form}
        name="question"
        label="Question"
        disabled={isOriginal}
        vertical={!!locked}
        size="sm"
      />
      <div className="flex gap-4">
        <OptionalTextField
          form={form}
          className="grid gap-2"
          name="answer"
          label="Answer"
          disabled={isOriginal}
          placeholder="Leave blank if no correct answer"
          description="If multiple correct answers exist, separate each answer with double pipes ||."
        />
        <SwitchField
          form={form}
          className="grid border-s-2 ps-2"
          name="ignoreWhiteSpaces"
          label="Ignore White Spaces"
          disabled={isOriginal}
        />
        <SwitchField
          form={form}
          className="grid border-s-2 ps-2"
          name="caseSensitive"
          label="Case Sensitive"
          disabled={isOriginal}
        />
        <OptionalTextField
          form={form}
          className="grid border-s-2 ps-2 gap-2 w-16"
          name="rows"
          label="Rows"
          disabled={isOriginal}
        />
      </div>
      <MarkdownField
        form={form}
        name="hint"
        label="Hint"
        disabled={isOriginal}
        vertical={!!locked}
        size="sm"
      />
      {!locked && (
        <CommonAdminFields form={form}>
          <SubmissionRequiredSwitchField form={form} />
          <EnableCommentsSwitchField form={form} />
        </CommonAdminFields>
      )}
    </div>
  );
}

export function Submission({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  const response = submission.data?.response;
  const { answer, caseSensitive, ignoreWhiteSpaces } = element.data;
  const answers = getAnswers(answer, caseSensitive, ignoreWhiteSpaces);
  const correct = answers?.includes(
    formatAnswer(response, caseSensitive, ignoreWhiteSpaces),
  );
  return (
    <BasicMarkdown
      className={cn(
        "border rounded-sm px-2 min-w-32",
        answers
          ? correct
            ? "border-success"
            : "border-destructive"
          : "border-muted-foreground",
      )}
      code={response}
    />
  );
}
export function SubmissionInfo({  items, element }) {
  return (
    <div className="flex flex-col gap-4 border-2 h-full rounded-xl py-2 px-4">
      <div className="text-md font-bold">Question</div>
      <CodeHike item={element} code={element.data.question} />
    </div>
  );
}

export default { Id, Name, Defaults, FormFields, Submission, SubmissionInfo };
