import { Rank } from "@/lms/core/Rank";
import { ChevronsUp, ChevronUp, ChevronDown, ChevronsDown } from "lucide-react";
import { IconButton } from "@/components/format/Buttons";
import { useState } from "react";
//only changes rank within the same item
export function MoveButtons({ edit, item, items }) {
  const [isMoving, setIsMoving] = useState(false);

  async function move(rankFn) {
    const rank = rankFn(item.id, items);
    if (!rank) return;
    setIsMoving(true);
    await edit({ id: item.id, data: { rank } });
    setIsMoving(false);
  }

  return (
    <div className="flex">
      <div className="grid grid-cols-4 ">
        <MoveButton
          icon={ChevronsUp}
          onClick={() => move(Rank.top)}
          disabled={isMoving}
        />
        <MoveButton
          icon={ChevronUp}
          onClick={() => move(Rank.up)}
          disabled={isMoving}
        />
        <MoveButton
          icon={ChevronDown}
          onClick={() => move(Rank.down)}
          disabled={isMoving}
        />
        <MoveButton
          icon={ChevronsDown}
          onClick={() => move(Rank.bottom)}
          disabled={isMoving}
        />
      </div>
    </div>
  );
}

function MoveButton({ icon, onClick, disabled }) {
  return (
    <IconButton
      icon={icon}
      onClick={onClick}
      className="h-8 border-0"
      disabled={disabled}
    />
  );
}
