import { showError, showSuccess } from "@/lib/toast";
import { AnyRecord } from "@/lib/types";
import { RecordIdString } from "@/lib/pb/types";
import pb from "@/lib/pb";
import {
  useMutation,
  useQueryClient,
  QueryKey,
  MutationFunction,
  matchQuery,
} from "@tanstack/react-query";


const COLLECTIONS = {
  classes: { singular: "class", parentKey: "courseId" },
  courses: { singular: "course" },
  teams: { singular: "team" },
  units: { singular: "unit" },
  lessons: { singular: "lesson" },
  sections: { singular: "section" },
  elements: { singular: "element" },
  enrollments: { singular: "enrollment" },
  users: { singular: "user" },
  submissions: { singular: "submission" },
  comments: { singular: "comment" },
  user_files: { singular: "user file" },
  section_progress: { singular: "section progress" },
};
type COLLECTION_KEYS = keyof typeof COLLECTIONS;

type UpdateCacheOptions = {
  queryKey?: QueryKey;
  updater?: Function;
};
type MutationOptions<TResult, TVariables> = {
  mutationFn: MutationFunction<TResult, TVariables>;
  invalidates?: Array<QueryKey>;
  action?: string;
  message?: string;
  updateCache?: UpdateCacheOptions;
};

export function useBaseMutation<TVariables, TResult>({
  mutationFn,
  invalidates,
  updateCache,
  action,
  message,
}: MutationOptions<TResult, TVariables>) {
  const queryClient = useQueryClient();

  return useMutation<TResult, Error, TVariables>({
    mutationFn: mutationFn,
    onError: (e) => {
      showError(e, `${action} failed`);
    },
    onSuccess: (mutationResult: TResult, crudProps: TVariables) => {
      showSuccess(`${action} successful`, message);
      if (invalidates) {
        //console.log(invalidates);
        return queryClient.invalidateQueries({
          predicate: (query) =>
            invalidates.some((queryKey) => matchQuery({ queryKey }, query)) ??
            true,
        });
      }
      if (updateCache) {
        const { queryKey, updater } = updateCache;
        if (queryKey && updater) {
          queryClient.setQueryData(queryKey, (oldData: any[]) =>
            updater({ oldData, mutationResult, crudProps }),
          );
        }
      }
    },
  });
}
export function useCopyMutation<TRecord extends BodyParams, TResponse>(
  collection: COLLECTION_KEYS,
  invalidates: Array<QueryKey>,
) {
  return useBaseMutation({
    mutationFn: async ({ id, data }: { id: RecordIdString; data: TRecord }) => {
      const res = await pb.collection(collection).create<TResponse>(data);
      return res;
    },
    invalidates,
    action: `Copied ${COLLECTIONS[collection].singular}`,
  });
}

export function useAddMutation<TRecord extends BodyParams, TResponse>(
  collection: COLLECTION_KEYS,
  invalidates: Array<QueryKey>,
) {
  return useBaseMutation({
    mutationFn: async ({ data }: { data: TRecord }) =>
      await pb.collection(collection).create<TResponse>(data),
    invalidates,
    action: `Add new ${COLLECTIONS[collection].singular}`,
  });
}

// export function useUpsertMutation<TRecord extends BodyParams, TResponse>(
//   collection: COLLECTION_KEYS,
//   invalidates: Array<QueryKey>,
// ) {
//   return useBaseMutation({
//     mutationFn: async ({ data }: { data: TRecord }) =>
//       await pb.collection(collection).create<TResponse>(data),
//     invalidates,
//     action: `Add new ${COLLECTIONS[collection].singular}`,
//   });
// }

export function useAddCacheMutation<TRecord extends BodyParams, TResponse>(
  collection: COLLECTION_KEYS,
  queryKey: QueryKey,
) {
  return useBaseMutation({
    mutationFn: async ({ data }: { data: TRecord }) =>
      await pb.collection(collection).create<TResponse>(data),
    updateCache: { queryKey, updater: addUpdater },
    action: `Add new ${COLLECTIONS[collection].singular}`,
  });
}

export function useEditMutation<TRecord extends BodyParams, TResponse>(
  collection: COLLECTION_KEYS,
  invalidates: Array<QueryKey>,
) {
  return useBaseMutation({
    mutationFn: async ({ id, data }: { id: RecordIdString; data: TRecord }) =>
      await pb.collection(collection).update<TResponse>(id, data),
    invalidates,
    action: `Edit ${COLLECTIONS[collection].singular}`,
  });
}
export function useDeleteMutation(
  collection: COLLECTION_KEYS,
  invalidates: Array<QueryKey>,
) {
  return useBaseMutation({
    mutationFn: async ({ id }: { id: RecordIdString }) =>
      await pb.collection(collection).delete(id),
    invalidates,
    action: `Delete ${COLLECTIONS[collection].singular}`,
  });
}

export function useEditCacheMutation<TRecord extends BodyParams, TResponse>(
  collection: COLLECTION_KEYS,
  queryKey: QueryKey,
) {
  return useBaseMutation({
    mutationFn: async ({ id, data }: { id: RecordIdString; data: TRecord }) =>
      await pb.collection(collection).update<TResponse>(id, data),
    updateCache: { queryKey, updater: editUpdater },
    action: `Edit ${COLLECTIONS[collection].singular}`,
  });
}

export function useDeleteCacheMutation(
  collection: COLLECTION_KEYS,
  queryKey: QueryKey,
) {
  return useBaseMutation({
    mutationFn: async ({ id }: { id: RecordIdString }) =>
      await pb.collection(collection).delete(id),
    updateCache: { queryKey, updater: deleteUpdater },
    action: `Delete ${COLLECTIONS[collection].singular}`,
  });
}



type BodyParams = { [key: string]: any } | FormData | undefined;

type CRUDProps = {
  id: RecordIdString;
  data: BodyParams;
};

type UpdateProps = {
  oldData: [];
  mutationResult: AnyRecord | boolean;
  crudProps: CRUDProps;
};

export const addUpdater = (props: UpdateProps) => {
  const { oldData, mutationResult } = props;
  return [...oldData, mutationResult];
};

export const editUpdater = (props: UpdateProps) => {
  const { oldData, crudProps } = props;
  //todo should also check for individual item in cache or invalidate
  return oldData.map((item: AnyRecord) => {
    if (item.id === crudProps.id) {
      return { ...item, ...crudProps.data };
    }
    return item;
  });
};

//Delete returns true on success
export const deleteUpdater = (props: UpdateProps) => {
  const { oldData, mutationResult, crudProps } = props;
  //todo should also check for individual item w/id in cache or invalidate
  return !mutationResult
    ? oldData
    : oldData.filter((item: AnyRecord) => item.id !== crudProps.id);
};
