import { PasteButton } from "@/components/format/Buttons";
import { useCopySectionMutation, useEditSectionMutation } from "@/lib/pb/lms";
import { setClipboard, useClipboard } from "@/lib/store";
import { toast } from "sonner";

export function SectionPasteButton({
  icon,
  courseId,
  lessonId,
  rank,
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  courseId: string;
  lessonId: string;
  rank: string;
}) {
  const clipboard = useClipboard();

  const { item: clipped, type, isCut } = clipboard ? clipboard : {};

  const { mutateAsync: edit, isPending: editPending } = useEditSectionMutation(
    clipped?.courseId,
    clipped?.lessonId,
    clipped?.id,
    lessonId,
  ); //this does not edit the elements course!!!!!!!!!!!

  const { mutateAsync: copy, isPending: copyPending } = useCopySectionMutation(
    courseId,
    lessonId,
  );

  if (!clipboard) return null;
  if (!clipped) return null;
  if (type != "Section") return null;

  //pasting element from clipboard
  async function paste() {
    if (editPending || copyPending) return;
    if (!clipped) return;

    const changes = { courseId, lessonId, rank };

    if (isCut) {
      if (clipped.courseId != courseId)
        return toast.error("You can only copy items between courses.");
      await edit({ id: clipped.id, data: changes }).catch((e) => {
        throw new Error("Unable to move section");
      });
      setClipboard(null);
    } else {
      await copy({ id: clipped.id, changes }).catch((e) => {
        throw new Error("Unable to copy section");
      });
    }
  }

  return (
    <PasteButton label="Paste" icon={icon} isCut={isCut} onClick={paste} />
  );
}
