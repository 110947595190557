import { Separator } from "@/components/ui/separator";
import { useUser } from "@/lib/store";
import { Navigate, createFileRoute } from "@tanstack/react-router";
import {
  useQuery,
  coursesListQuery,
  useCoursesTranslation,
} from "@/lib/pb/lms";
import { CoursesResponse } from "@/lib/pb/types";
import { cn } from "@/lib/utils";
import { useEffect, useState } from "react";
import { MenuLink } from "@/components/nav/Link";
import { AdminCourseCard } from "@/lms/course/CourseCard";
import { ClassesManager } from "@/lms/classes/ClassesManager";
import { ROLES } from "@/lib/roles";
import { FullDialogButton } from "@/components/format/Buttons";
import { Library, LibraryBig, Plus } from "lucide-react";
import { CourseAddForm } from "@/lms/course/CourseAdminForms";
import { translateItems } from "@/lib/lang";

export const Route = createFileRoute("/_auth/_site/courses")({
  component: CoursesPage,
});

export function CoursesPage() {
  const user = useUser();

  if (!user) return <div>No Authenticated User Found.</div>;

  if (user.role === ROLES.administrator) {
    return <CourseAdminPage />;
  }

  return <Navigate to="/" />;
}

export default function CourseAdminPage() {
  const { data: courses, ...coursesQuery } = useQuery(coursesListQuery());
  const { data: translations, ...translationsQuery } = useCoursesTranslation();
  const translatedCourses = translateItems(courses, translations);

  const selectedCourseState = useState<CoursesResponse>();
  const [course, setCourse] = selectedCourseState;

  useEffect(() => {
    // set the selected course to first or previously selected course
    if (translatedCourses) {
      setCourse(
        !course
          ? translatedCourses[0]
          : translatedCourses.find((c) => c.id === course.id),
      );
    }
  }, [courses, translations]);

  if (coursesQuery.error)
    return <div>An error has occurred: {coursesQuery.error.message}</div>;
  if (translationsQuery.error)
    return <div>An error has occurred: {translationsQuery.error.message}</div>;

  if (coursesQuery.isPending) return <div>Loading Courses...</div>;

  return (
    <div className="w-full space-y-6 lg:px-10 mx-auto">
      <div className="flex flex-col space-x-10 lg:flex-row lg:space-x-12 lg:space-y-0">
        <aside className="lg:-mx-4 lg:w-1/5 p-4 ">
          {translatedCourses && (
            <SidebarNav
              courses={translatedCourses}
              selectedCourseState={selectedCourseState}
            />
          )}
        </aside>
        <div className="grid lg:max-w-7xl w-full gap-16">
          {course ? <AdminCourseCard course={course} /> : null}
          {course ? <ClassesManager course={course} /> : null}
        </div>
      </div>
    </div>
  );
}

interface SidebarNavProps {
  className?: string;
  courses: CoursesResponse[];
  selectedCourseState: [CoursesResponse | undefined, Function];
}

export function SidebarNav({
  className,
  courses,
  selectedCourseState,
  ...props
}: SidebarNavProps) {
  const [selectedCourse, setSelectedCourse] = selectedCourseState;
  const navStyle =
    "flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1 flex-wrap ";
  return (
    <nav className={cn("flex lg:grid gap-4", className)} {...props}>
      <div className="flex gap-4 flex-wrap">
        <h2 className="text-2xl font-bold tracking-tight flex gap-2 items-center">
          <Library />
          Courses
        </h2>
        <FullDialogButton
          icon={Plus}
          label="New course"
          contents={CourseAddForm}
          className="shadow-md"
        />
      </div>
      <Separator className="hidden lg:block my-4" />
      <div className={navStyle}>
        <div className="hidden font-bold my-auto lg:flex">Active Courses</div>
        {courses
          .filter((item) => item.active)
          .map((item) => (
            <MenuLink
              key={item.id}
              onClick={() => setSelectedCourse(item)}
              active={selectedCourse?.id == item.id}
              className="ps-4"
            >
              {item.name}
            </MenuLink>
          ))}
      </div>
      <div className={cn(navStyle, "hidden lg:flex")}>
        <div className="font-bold my-auto">Inactive Courses</div>
        {courses
          .filter((item) => !item.active)
          .map((item) => (
            <MenuLink
              key={item.id}
              onClick={() => setSelectedCourse(item)}
              active={selectedCourse?.id == item.id}
              className="ps-4"
            >
              {item.name}
            </MenuLink>
          ))}
      </div>
    </nav>
  );
}
