import { Column } from "@tanstack/react-table";
import { Input } from "@/components/ui/input";
import { useEffect, useState } from "react";
const MIN = 0;
const MAX = 1;

import { SelectFilter, MultiSelectFilter } from "./MultiSelectFilter";

export function Filter({ column }: { column: Column<any, unknown> }) {
  const { filterVariant, filterName } = column.columnDef.meta ?? {};
  const columnFilterValue = column.getFilterValue();

  return filterVariant === "range" ? (
    <div>
      <div className="flex space-x-2">
        <RangeInput column={column} isMax={MIN} />
        <RangeInput column={column} isMax={MAX} />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === "select" ? (
    <SelectFilter column={column} title={filterName}/>
  ) : filterVariant === "multiselect" ? (
    <MultiSelectFilter column={column} title={filterName} />
  ) : (
    <DebouncedInput
      className="w-64 border shadow rounded"
      onChange={(value) => column.setFilterValue(value)}
      placeholder={`Search by ${filterName?.toLocaleLowerCase()}...`}
      type="text"
      value={(columnFilterValue ?? "") as string}
    />
  );
}
function RangeInput({
  column,
  isMax,
}: {
  column: Column<any, unknown>;
  isMax: 0 | 1;
}) {
  const columnFilterValue = column.getFilterValue();
  return (
    <DebouncedInput
      type="number"
      min={Number(column.getFacetedMinMaxValues()?.[MIN] ?? "")}
      max={Number(column.getFacetedMinMaxValues()?.[MAX] ?? "")}
      value={(columnFilterValue as [number, number])?.[isMax] ?? ""}
      onChange={(value) =>
        column.setFilterValue((old: [number, number]) =>
          isMax ? [old?.[MIN], value] : [value, old?.[MAX]],
        )
      }
      placeholder={`${isMax ? "Max" : "Min"} ${
        column.getFacetedMinMaxValues()?.[isMax] !== undefined
          ? `(${column.getFacetedMinMaxValues()?.[isMax]})`
          : ""
      }`}
      className="w-24 border shadow rounded"
    />
  );
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <Input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

// export function Filter({ column }: { column: Column<any, unknown> }) {
//   const columnFilterValue = column.getFilterValue();
//   const { filterVariant, filterName } = column.columnDef.meta ?? {};

//   return filterVariant === "range" ? (
//     <div>
//       <div className="flex space-x-2">
//         {/* See faceted column filters example for min max values functionality */}
//         <DebouncedInput
//           type="number"
//           value={(columnFilterValue as [number, number])?.[0] ?? ""}
//           onChange={(value) =>
//             column.setFilterValue((old: [number, number]) => [value, old?.[1]])
//           }
//           placeholder={`Min`}
//           className="w-24 border shadow rounded"
//         />
//         <DebouncedInput
//           type="number"
//           value={(columnFilterValue as [number, number])?.[1] ?? ""}
//           onChange={(value) =>
//             column.setFilterValue((old: [number, number]) => [old?.[0], value])
//           }
//           placeholder={`Max`}
//           className="w-24 border shadow rounded"
//         />
//       </div>
//       <div className="h-1" />
//     </div>
//   ) : filterVariant === "select" ? (
//     <select
//       onChange={(e) => column.setFilterValue(e.target.value)}
//       value={columnFilterValue?.toString()}
//     >
//       {/* See faceted column filters example for dynamic select options */}
//       <option value="">All</option>
//       <option value="complicated">complicated</option>
//       <option value="relationship">relationship</option>
//       <option value="single">single</option>
//     </select>
//   ) : (
// <DebouncedInput
//   className="w-36 border shadow rounded"
//   onChange={(value) => column.setFilterValue(value)}
//   placeholder={`Search by ${filterName?.toLocaleLowerCase()}...`}
//   type="text"
//   value={(columnFilterValue ?? "") as string}
// />
//     // See faceted column filters example for datalist search suggestions
//   );
// }

// <>
//   {/* Autocomplete suggestions from faceted values feature */}
//   <datalist id={column.id + "list"}>
//     {sortedUniqueValues.map((value: any) => (
//       <option value={value} key={value} />
//     ))}
//   </datalist>
//   <DebouncedInput
//     type="text"
//     value={(columnFilterValue ?? "") as string}
//     onChange={(value) => column.setFilterValue(value)}
//     placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
//     className="w-36 border shadow rounded"
//     list={column.id + "list"}
//   />
// </>
