import { CircleCheck, CircleX } from "lucide-react";
import { ReactNode } from "react";

export function CorrectIndicator({
  children,
  correct,
}: {
  children?: ReactNode;
  correct: boolean;
}) {
  return (
    <div className=" ps-2 text-sm my-auto max-w-xs">
      {correct ? (
        <div className="flex gap-2 text-success items-center font-bold">
          <CircleCheck className="w-4 h-4" />
          Correct
        </div>
      ) : (
        <div className="flex gap-2 text-red-600 items-center font-bold">
          <CircleX className="w-4 h-4" />
          <div>Incorrect, try again.</div>
        </div>
      )}
      {children}
    </div>
  );
}