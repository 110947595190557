import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Moon, Sun } from "lucide-react";
import { useEffect } from "react";
import {useDarkMode, setDarkMode} from "@/lib/store";

export default function DarkModeSwitch() {
  const isDark = useDarkMode();
  // const [isDark, setIsDark] = useState(
  //   window.matchMedia("(prefers-color-scheme: dark)").matches,
  // );

  useEffect(() => {
    const root = document.documentElement;

    if (isDark) {
      root.classList.add("dark");
      root.setAttribute("data-theme", "dark");
    } else {
      root.classList.remove("dark");
      root.setAttribute("data-theme", "light");
    }
  }, [isDark]);


  return (
    <div className="flex items-center">
      <Switch
        className="scale-75"
        id="dark-mode"
        checked={isDark}
        onCheckedChange={() => setDarkMode(!isDark)}
      />
      <Label htmlFor="dark-mode">
        {isDark ? <Moon className="h-4" /> : <Sun className="h-4" />}
      </Label>
    </div>
  );
}
