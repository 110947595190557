import ReactMarkdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw'
import remarkBreaks from "remark-breaks";
import { cn } from "@/lib/utils";

type MarkdownProps = {
  code: string | null | undefined;
  className?: string;
};

export function BasicMarkdown({ code, className }: MarkdownProps) {
  if(!code) return null;
  return (
    <ReactMarkdown
      className={cn("markdown-body ", className)}
      remarkPlugins={[[remarkGfm], [remarkBreaks]]}
      rehypePlugins={[
        [rehypeRaw],
        [rehypeExternalLinks, { target: "_blank" }],
      ]}
    >
      {code}
    </ReactMarkdown>
  );
}

