import CodeHike from "@/components/codehike/CodeHike";
import { FormFieldsProps } from "@/components/form/Form";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";

import {
  CommonAdminFields,
  EnableCommentsSwitchField,
  MarkdownField,
  SubmissionRequiredSwitchField,
} from "@/lms/core/LmsFields";
import { ImageViewer } from "./Image";
import FileMimeIcon from "../files/FileMimeIcon";
import { getUrl } from "@/lib/pb";
import { formatBytes } from "@/lib/utils";

const Id = "file-submission";
const Name = "File Submission";

const Defaults = {
  question: "",
  type: Id,
  isSubmitType: true,
  submissionRequired: true,
  enableComments: true,
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;

  return (
    <div className="grid gap-4 py-4">
      <MarkdownField
        form={form}
        name="question"
        label="Question/Prompt"
        disabled={isOriginal}
        vertical={!!locked}
      />

      {!locked && (
        <CommonAdminFields form={form}>
          <SubmissionRequiredSwitchField form={form} />
          <EnableCommentsSwitchField form={form} />
        </CommonAdminFields>
      )}
    </div>
  );
}

export function Submission({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  // const response = submission.data?.response;
  // const { answer, caseSensitive, ignoreWhiteSpaces } = element.data;
  if (!submission) return;
  const { type, name, size } = submission.data;
  const isImage = type.startsWith("image");
  console.log(submission)
  return (
    <div className="flex items-center gap-2">
      <div className="max-h-[50px] max-w-[50px]">
        {isImage ? (
          <ImageViewer
            className="bg-white border-2"
            url={getUrl(submission)}
            useThumbnail={true}
          />
        ) : (
          <a href={getUrl(submission)} download={name}>
            <FileMimeIcon
              mimeType={type}
              className="h-[40px] w-[40px] mx-auto"
            />
          </a>
        )}
      </div>
      <div className="flex flex-col text-xs ">
        <div className="line-clamp-1 font-bold">{name}</div>
        <div>{type}</div>
        <div>{formatBytes(size)}</div>
        
      </div>
    </div>
  );
}

export function SubmissionInfo({ items, element }) {
  return (
    <div className="flex flex-col gap-4 border-2 h-full rounded-xl py-2 px-4">
      <div className="text-md font-bold">Question</div>
      <CodeHike item={element} code={element.data.question} />
    </div>
  );
}

export default { Id, Name, Defaults, FormFields, Submission, SubmissionInfo };
