import { createFileRoute, Outlet } from "@tanstack/react-router";
import { enrollmentsMapQuery, useQuery } from "@/lib/pb/lms";
import { useEffect } from "react";
import {
  setCourse,
  useUser,
  useEnrollment,
  setEnrollment,
  setClass,
  setProgressTree,
  setSearchResults,
} from "@/lib/store";
import { useCourseTree } from "@/lib/pb/course-tree";
import { ROLES } from "@/lib/roles";
import { sectionProgressListQuery } from "@/lib/pb/section-progress";
import { ElementsResponse } from "@/lib/pb/types";

export const Route = createFileRoute("/_auth/course/$courseId")({
  component: CourseLayout,
});

interface ProgressNode {
  index: string;
  children: Record<string, any>;
  completed: number;
  length: number;
}

function CourseLayout() {
  const { courseId } = Route.useParams();

  const { course, isPending, searchResults } = useCourseTree(courseId);

  //get singular enrollment
  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;

  //enrollments object now has expand.classId
  const { data: enrollments, ...eQuery } = useQuery(
    enrollmentsMapQuery(user?.id), //used for
  );

  const enrollment = useEnrollment();

  const { data: sectionProgress, isPending: isSectionProgressPending } =
    useQuery(sectionProgressListQuery(enrollment?.id));

  useEffect(() => {
    setCourse(course ? course : null);
    setSearchResults(searchResults);
  }, [course]); //course-tree

  // maybe expand classId in enrollment to speed this up
  useEffect(() => {
    if (!enrollments) {
      setEnrollment(null);
      setClass(null);
      return;
    }
    const enrollment = enrollments.get(courseId);
    if (enrollment) {
      setEnrollment(enrollment);
      setClass(enrollment.expand?.classId);
    } else {
      setEnrollment(null);
      setClass(null);
    }
  }, [enrollments, courseId]);

  useEffect(() => {
    //Build Progress Tree TODO move this out
    if (!sectionProgress || isSectionProgressPending || !course) return;
    const progress: Record<string, ProgressNode> = {};
    const units = course.children;
    units.forEach((unit: any) => {
      const lessons = unit.children;
      const unitProgress: ProgressNode = {
        index: unit.index,
        children: {},
        completed: 0,
        length: 0,
      };
      lessons.forEach((lesson: any) => {
        const sections = lesson.children;
        const lessonProgress: ProgressNode = {
          index: lesson.index,
          children: {},
          completed: 0,
          length: sections.length,
        };
        unitProgress.length += sections.length;
        //chccskd complete here leon
        sections.forEach((section: any) => {
          //store section in store
          const elements = section.children;
          const sp = sectionProgress.get(section.id);
          const complete = sp ? sp.complete : false;
          const completedSet = new Set(
            elements
              ?.filter(
                (el: ElementsResponse) =>
                  complete || !(el.isSubmitType && el.submissionRequired),
              )
              .map((el: ElementsResponse) => el.id),
          );
          const progress = {
            index: section.index,
            sectionId: section.id,
            complete,
            id: sp?.id,
            completedSet,
            length: elements.length,
          };
          lessonProgress.children[section.id] = progress;

          if (progress.complete) {
            lessonProgress.completed++;
            unitProgress.completed++;
          }
        });
        unitProgress.children[lesson.id] = lessonProgress;
      });
      progress[unit.id] = unitProgress;
    });
    setProgressTree(progress);
  }, [sectionProgress, course]);

  if (!isAdmin) {
    if (eQuery.isPending) {
      //if not admin wait to find enrollment
      return (
        <div className="w-full text-center p-16">Checking enrollment...</div>
      );
    }
    if (!enrollment) {
      return <div className="w-full text-center p-16">Not enrolled.</div>;
    }
  }

  if (isPending)
    return <div className="w-full text-center p-16">Loading Course...</div>;

  if (!course)
    return (
      <div className="w-full text-center p-16">No Course: {courseId} </div>
    );
  //return <div>{translatedCourse.name}</div>
  //console.log("what??", course)
  return <Outlet />;
}
