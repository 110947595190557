import { useForm, type FormApi, type FormOptions } from "@tanstack/react-form";
import { cn } from "@/lib/utils";
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

import { SaveSubmitButtons, ConfirmButtons } from "@/components/form";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Header } from "@/components/format/Dialog";
import { MatchTextField, RequiredTextField } from "@/lms/core/LmsFields";
import { Languages } from "lucide-react";

//Tanstack Form Component
export default function Form({
  form,
  className,
  children,
}: {
  form: FormApi<any, any>;
  className?: string;
  setSubmitter?: React.Dispatch<React.SetStateAction<null>> | undefined;
  children: React.ReactNode;
}) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        void form.handleSubmit();
      }}
      className={cn("flex flex-col", className)}
    >
      {children}
    </form>
  );
}

export type BaseFormProps = {
  title: string | React.ReactNode;
  form: FormApi<any, any>;
  save?: boolean;
  children?: React.ReactNode;
  extras?: React.ReactNode;
};

export function BaseForm({
  title,
  form,
  save,
  children,
  extras,
}: BaseFormProps) {
  return (
    <Form form={form}>
      <DialogHeader className="flex flex-row max-w-[100vw] gap-4 pb-1 border-b-2">
        <div className="flex flex-col gap-2">
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            Make changes below and then save your changes.
          </DialogDescription>
        </div>
        <div className="flex flex-col sm:flex-row sm:justify-end sm:space-x-2 me-4">
          <SaveSubmitButtons form={form} save={save} />
        </div>
        {extras}
      </DialogHeader>
      <ScrollArea className="max-h-[91vh] pe-4">{children}</ScrollArea>
    </Form>
  );
}



export type FormFieldsProps = {
  isTranslate?: boolean;
  isOriginal?: boolean;
  form: FormApi<any, any>;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
};

type OriginalFormProps<TResponse> = {
  item: TResponse;
  formFields: React.FunctionComponent<FormFieldsProps>;
};

export function OriginalForm<TResponse>({
  item,
  formFields,
}: OriginalFormProps<TResponse>) {
  const form = useForm({ defaultValues: { ...item } });
  const Fields = formFields;

  return (
    <>
      <DialogHeader className="flex-row max-w-[100vw] gap-4">
        <div>
          <DialogTitle>Original Languange</DialogTitle>
          <DialogDescription>
            The original English translation can not be edited here.
          </DialogDescription>
        </div>
      </DialogHeader>
      <ScrollArea className="max-h-[91vh] pe-4 border-e">
        <fieldset disabled>
          <Fields form={form} isOriginal={true} />
        </fieldset>
      </ScrollArea>
    </>
  );
}

export function TranslateForm({
  form,
  fields: FormFields,
  type,
  language,
  item,
}: {
  form: FormApi<any, any>;
  fields: React.FunctionComponent<FormFieldsProps>;
  type: string;
  language: string;
  item: any;
}) {
  return (
    <div className="grid md:grid-cols-2 w-full">
      <BaseForm
        title={
          <div className="flex items-center">
            <Languages className="w-6 h-6 mr-2" />
            Translate {type} to{" "}
            <div className="bg-success p-1 rounded-md ml-2">{language}</div>
          </div>
        }
        form={form}
        save={true}
      >
        <FormFields form={form} isTranslate={true} />
      </BaseForm>
      <div className="px-4 border-s border-foreground flex flex-col">
        <div className="h-2"></div>
        <OriginalForm formFields={FormFields} item={item} />
      </div>
    </div>
  );
}

type CopyFormProps = {
  formOptions: FormOptions<any, any>;
  onComplete: () => void;
  type: string;
};
export function ConfirmCopyForm({
  formOptions,
  onComplete,
  type,
}: CopyFormProps) {
  const form = useForm(formOptions);
  return (
    <div className="grid gap-4">
      <Header
        title={`Confirm Copy ${type}`}
        description={`Are you sure you want to duplicate this ${type.toLowerCase()}? This will create a new copy with the same content.`}
      />

      <Form form={form}>
        <div className="grid gap-4">
          <RequiredTextField
            form={form}
            name="name"
            label={`New ${type.toLowerCase()} name`}
            className="grid gap-2 w-96"
          />
          <ConfirmButtons
            form={form}
            onComplete={onComplete}
            label={`Copy ${type}`}
          />
        </div>
      </Form>
    </div>
  );
}

type DeleteFormProps = {
  formOptions: FormOptions<any, any>;
  info?: string;
  match?: string;
  onComplete: () => void;
  type: string;
  fieldName?: string;
};
export function ConfirmDeleteForm({
  formOptions,
  info,
  match,
  onComplete,
  type,
  fieldName,
}: DeleteFormProps) {
  const form = useForm(formOptions);

  if (!match) {
    return (
      <div>
        <Header
          title={`Confirm Delete ${type}`}
          description="This action is permanent and cannot be undone. Are you sure you want
          to proceed?"
        />

        <div className="p-6">
          <ConfirmButtons
            variant="destructive"
            onComplete={onComplete}
            onClick={formOptions.onSubmit}
            label={`Delete ${type}`}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="grid gap-4">
      <Header
        title={`Confirm Delete ${type}`}
        description={`This action is permanent and cannot be undone. To confirm, please type the ${fieldName} of the ${type.toLowerCase()} below.`}
      />
      <Form form={form}>
        <div className="grid gap-4">
          <div className="text-center">{info}</div>
          <div className="text-center text-sm text-destructive">{match}</div>
          <MatchTextField
            form={form}
            info={match}
            name={fieldName}
            label={`Enter ${type.toLowerCase()} ${fieldName}`}
            className="w-96 grid gap-2"
          />

          <ConfirmButtons
            variant="destructive"
            form={form}
            onComplete={onComplete}
            label={`Delete ${type}`}
          />
        </div>
      </Form>
    </div>
  );
}
