import SectionContents from "@/lms/section/SectionContents";
import { ArrowDownToLine, Bookmark } from "lucide-react";
import { SectionFooter } from "@/lms/section/SectionFooter";
import SectionAdminMenu from "@/lms/section/SectionAdminMenu";
import {
  useQuery,
  elementsListQuery,
  useElementsTranslation,
} from "@/lib/pb/lms";
import { ElementsResponse, SectionsResponse } from "@/lib/pb/types";
//import { FreeResponseAddForm } from "@/lms/element/FreeResponse";
import { translateItems } from "@/lib/lang";
import { compileElements } from "@/components/codehike/CodeHike";
import { useEffect, useMemo, useState } from "react";
import { useUser } from "@/lib/store";
import { Link } from "@tanstack/react-router";
import { TableOfContents } from "@/lms/section/TableOfContents";
import { ROLES } from "@/lib/roles";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";
import { ElementTypesDialog } from "../element/ElementTypesDialog";
//import { WebContainerCard } from "@/lms/playground/WebContainerCard";
import { useWebContainerManager } from "@/lms/playground/WebContainer";
import { NavigateButtons } from "../core/NavigateButtons";
import { CountdownTimer } from "@/lms/core/CountdownTimer";

export function Section({ section }: { section: SectionsResponse }) {
  const { id } = section;
  useWebContainerManager();
  const {
    data: elements,
    error,
    refetch,
    ...eQuery
  } = useQuery(elementsListQuery(id));
  const { data: translations, ...tQuery } = useElementsTranslation(id);

  const [compiledElements, setCompiledElements] = useState<
    ElementsResponse[] | null
  >(null);

  const memoizedElements = useMemo(
    () => (compiledElements ? [...compiledElements] : []),
    [compiledElements],
  );

  const compile = async (items: ElementsResponse[]) => {
    const res = await compileElements(items);
    setCompiledElements([...res]);
  };

  //refetch elements when course tree data changes
  //  useEffect(() => {
  //    console.log("🔴 section useeffect getting called");
  //    // if (!eQuery.isPending) refetch();
  //    // if (!tQuery.isPending) tQuery.refetch();
  // }, [section]);

  useEffect(() => {
    //fetch elements here (since elements are not in useStore state manager)
    if (elements) {
      //need to inject before translate
      elements.map((element) => {
        element.parent = section; //inject section
      });
      const translatedElements = translateItems(elements, translations);
      // console.log(
      //   "compile elements for header Add pending check here",
      //   section,
      // );
      compile(translatedElements);
    } else {
      setCompiledElements(null);
    }
  }, [elements, translations]);

  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isInstructor = user?.role >= ROLES.instructor;
  if (!section) {
    return (
      <div className="flex w-full justify-center">Section unavailable.</div>
    );
  }

  if (error) {
    console.error(error);
  }

  return (
    <div className="flex w-full gap-4 pb-64">
      <div className="mx-auto flex w-full max-w-7xl flex-1 flex-col gap-10 px-4 md:px-4 md:pt-2 lg:px-6">
        <SectionTitle section={section} isAdmin={isAdmin} />

        {memoizedElements && (
          <SectionContents
            sectionId={section.id}
            items={memoizedElements}
      
          />
        )}

        <SectionFooter section={section} />
        <div className="h-[200px]"></div>
      </div>

      <div className="sticky top-4 flex h-[90vh] w-60 shrink-0 flex-col gap-4 pr-3 max-2xl:hidden xl:w-72 rtl:pl-3">
        {memoizedElements && (
          <TableOfContents section={section} items={memoizedElements} />
        )}
      </div>
      <div className="fixed top-0.5 xl:top-2 right-1">
        <div className="flex gap-2 text-xl font-bold  px-1 rounded-md justify-center items-center text-primary">
          <Bookmark className="h-4 w-4" /> <div>{section?.index}</div>
        </div>
        
      </div>
      <div className="flex flex-col fixed bottom-0.5 xl:bottom-2 right-1 items-end gap-2">
        {isInstructor && <CountdownTimer />}
        <NavigateButtons />
      </div>
    </div>
  );
}

function SectionTitle({
  section,
  isAdmin,
}: {
  section: SectionsResponse;
  isAdmin: boolean;
}) {
  return (
    <div className="w-full flex ">
      <div className="w-full max-w-3xl flex gap-6 my-auto ms-auto">
        <div className="text-3xl font-semibold mt-0.5">{section?.index}</div>
        <div className="ms-4 scale-110">
          <BasicMarkdown
            className="tracking-tight leading-tight"
            code={"# " + section?.name}
          />
        </div>
        {isAdmin && section && (
          <SectionAdminMenu item={section.original || section} size={6} />
        )}
      </div>

      <div className="flex flex-col ms-auto my-auto">
        {isAdmin && section && <ElementTypesDialog section={section} />}
      </div>
    </div>
  );
}

function JumpToBottom({ section }: { section: SectionsResponse }) {
  return (
    <Link
      to={section.url + "#section-bottom"}
      className="flex gap-2 text-sm text-muted-foreground p-4"
    >
      <ArrowDownToLine className="h-4 w-4" />
      <div className="text-sm">Jump to bottom</div>
    </Link>
  );
}
