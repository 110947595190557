import {
  Table as TanstackTable,
  FilterFn,
  SortingFn,
  ColumnDef,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  useReactTable,
  RowData,
  sortingFns,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { BaseTable, TableProps } from "./Table";
import { Filter } from "./Filter";
import { useState } from "react";
import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";
import { Button } from "@/components/ui/button";
import { RotateCw } from "lucide-react";

declare module "@tanstack/react-table" {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: "text" | "range" | "select" | "multiselect";
    filterName?: string;
  }
  //add fuzzy filter to the filterFns
  // interface FilterFns {
  //   fuzzy: FilterFn<unknown>
  // }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}
export function FilterTable<T>({
  data = [],
  columns,
  sortEnabled = false,
  isPending = false,
  refetch,
}: TableProps<T>) {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      columnFilters,
    },
    enableMultiRowSelection: false,
    onColumnFiltersChange: setColumnFilters,
    //debugTable: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: !sortEnabled ? undefined : getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    getFacetedRowModel: getFacetedRowModel(), // client-side faceting
    getFacetedUniqueValues: getFacetedUniqueValues(), // generate unique values for select filter/autocomplete
    getFacetedMinMaxValues: getFacetedMinMaxValues(), // generate min/max values for range filter
  });

  return (
    <div className="grid gap-2">
      {!isPending && <FilterNav table={table} refetch={refetch} />}
      <BaseTable
        table={table}
        sortEnabled={sortEnabled}
        isPending={isPending}
      />
    </div>
  );
}
// <div>{meta.filterName}:</div>
export function FilterNav({
  table,
  refetch,
}: {
  table: TanstackTable<any>;
  refetch?: () => void;
}) {
  const isFiltered = table.getState().columnFilters.length > 0;
  return (
    <div className="flex gap-4 items-center">
      {refetch && (
        <Button variant="ghost" className="p-2 h-7 gap-2" onClick={refetch}>
          <RotateCw className="h-4" /> Refresh
        </Button>
      )}
      {table.getLeafHeaders().map((header) => {
        const meta = header.column.columnDef.meta;
        return meta?.filterName && header.column.getCanFilter() ? (
          <div key={header.id} className="grid gap-2">
            <Filter column={header.column} />
          </div>
        ) : null;
      })}
      {isFiltered && (
        <div className="grid gap-2 my-auto">
          <Button
            variant="default"
            size="sm"
            onClick={() => table.resetColumnFilters()}
            className="h-8 w-16"
          >
            Reset
          </Button>
        </div>
      )}
    </div>
  );
}
// <Cross2Icon className="ml-2 h-4 w-4" />
// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Define a custom fuzzy sort function that will sort by rank if the row has ranking information
export const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!,
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};
