import { GenericInputField } from "@/components/form/CustomFields";
import { useState } from "react";
import FileMimeIcon from "./FileMimeIcon";
import { ImageViewer } from "@/lms/elements/Image";
import { formatBytes } from "@/lib/utils";

interface FileInputProps {
  usage?: number;
  maxUsage?: number;
  selectedFile: File | null;
  setSelectedFile: (file: File | null) => void;
  info: string;
  setInfo: (message: string) => void;
  form: any; // Replace with the appropriate form type if available
  name: string;
}

export function FileInput({
  form,
  name,
  selectedFile,
  setSelectedFile,
  usage,
  maxUsage,
}: FileInputProps) {
  const maxUsageMB = maxUsage ? maxUsage * 1024 * 1024 : undefined;
  const [info, setInfo] = useState(
    usage && maxUsageMB && usage > maxUsageMB
      ? `You have exceed your storage limit of ${maxUsage} MB.`
      : "",
  );

  const handleSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5242880) {
        setSelectedFile(null);
        setInfo("The maximum allowed file size is 5MB");
        return;
      }
      if (maxUsageMB && usage + file.size > maxUsageMB) {
        setSelectedFile(null);
        setInfo(`This will exceeded your storage limit of ${maxUsage} MB.`);
        return;
      }
      setInfo("");
      setSelectedFile(file);
    }
  };

  return (
    <>
      {!selectedFile && (
        <GenericInputField
          form={form}
          className={
            "w-96 bg-accent border-2 rounded border-primary/80 hover:border-primary"
          }
          name={name}
          type="file"
          description={<div className="text-red-700">{info}</div>}
          onChange={handleSelect}
          disabled={usage && maxUsageMB ? usage >= maxUsageMB : false}
        />
      )}
      {selectedFile && (
        <div className="flex flex-col justify-center mx-auto gap-2">
          {selectedFile.type.startsWith("image") ? (
            <ImageViewer
              url={URL.createObjectURL(selectedFile)}
              className="object-contain max-h-[200px] max-w-[400px] bg-white border-2"
            />
          ) : (
            <FileMimeIcon
              mimeType={selectedFile.type}
              className="w-10 h-10 mx-auto"
            />
          )}
          <div className="text-sm flex gap-4 items-center justify-between border border-foreground rounded px-2">
            <FileMimeIcon mimeType={selectedFile.type} className="h-4 w-4" />
            <div>{selectedFile.name}</div>
            <div>{formatBytes(selectedFile.size)}</div>
          </div>
        </div>
      )}
    </>
  );
}
