import { GenericInputField, SwitchField } from "@/components/form/CustomFields";
import { FormFieldsProps } from "@/components/form/Form";
import { Playground, SandpackFiles } from "@/lms/playground/Playground";
import { SelectField, SelectItem } from "@/components/form/Select";
import {
  CommonAdminFields,
  EnableCommentsSwitchField,
  HeightSelectField,
  MarkdownField,
  OptionalTextField,
  SubmissionRequiredSwitchField,
  WidthSelectField,
} from "@/lms/core/LmsFields";
import physics_golf from "@/lms/playground/templates/phaser_golf_1";
import { memo, useEffect, useRef, useState } from "react";
import { TemplateDropdown } from "../playground/TemplateDropdown";
import { cn, generateRandomString } from "@/lib/utils";
import { SandboxEnvironment } from "@codesandbox/sandpack-react";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";

const Id = "code-playground";
const Name = "Code Playground";

const Defaults = {
  type: "code-playground",
  isSubmitType: true,
  submissionRequired: true,
  files: physics_golf,
  instruction: "",
  hint: "",
  showFileExplorer: false,
  showEditor: true,
  showPreview: true,
  showTerminal: false,
  showOpenInCodeSandbox: false,
  splitPercent: 50,
  readOnly: false,
  width: "xxl",
  height: "md",
  environment: "static",
  direction: "horizontal",
  enableComments: true,
  highlightText: "",
  highlightRegex: "",
  highlightLines: "",
};

const switchClasses = "flex flex-col border-s-2 ps-2 gap-1";

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  
  const [baseFiles, setBaseFiles] = useState(form.getFieldValue("files"));

  //form.useStore (setState), form.getFieldValue (doesnt update state)
  const showEditor = form.useStore((state: any) => state.values.showEditor);
  useEffect(() => {
    if (showEditor == false) form.setFieldValue("showFileExplorer", false);
  }, [showEditor]);

  const showPreview = form.useStore((state: any) => state.values.showPreview);
  useEffect(() => {
    if (showPreview == false) form.setFieldValue("showTerminal", false);
  }, [showPreview]);

  // const highlightText = form.useStore(
  //   (state: any) => state.values.highlightText,
  // );

  //const highlightTextRef = useRef(form.getFieldValue("highlightText"));

  // useEffect(() => {
  //   if (!highlightTextRef) return;
  //   highlightTextRef.current = form.getFieldValue("highlightText");
  // }, [highlightText]);

  const showOpenInCodeSandbox = form.useStore(
    (state: any) => state.values.showOpenInCodeSandbox,
  );

  // function handleChange(e) {
  //   //console.log(e.target.value)
  //   highlightTextRef.current = e.target.value;
  // }

  const environment = form.getFieldValue("environment");

  // 1. baseFiles(Defaults)
  // 2.form field (FORM) files updateParentForm called from MonacoEditor
  // 3. webContainer (WC) files fs.writeFile, PreviewFrame when active Files changes
  // 4.sandPack(SP) files updateFile
  //
  //gets the Defaults files
  
  const [mountPoint, setMountPoint] = useState(generateRandomString()); // useMemo(()=>generateRandomString(), [])

  function updateParentForm(newFiles: any) {
    const { files, activeFile, visibleFiles } = newFiles;
    //console.log("✋ updateParentForm", files);
    const formFiles = form.getFieldValue("files");
    form.setFieldValue("files", {
      ...formFiles,
      files: files ?? formFiles.files,
      activeFile: activeFile ?? formFiles.activeFile,
      visibleFiles: visibleFiles ?? formFiles.visibleFiles,
    });
  }

  function updatePlaygroundFiles(
    files: SandpackFiles,
    environment: SandboxEnvironment = "static",
  ) {
    //console.log("🛝 updatePlaygroundFiles", files);
    setBaseFiles(files);
    form.setFieldValue("files", files);
    form.setFieldValue("environment", environment);
    setMountPoint(generateRandomString()); //forces playground REMOUNT
  }

  return (
    <div className="flex flex-col w-full gap-1.5 py-4">
      <div className="border border-primary rounded-md">
        <Playground
          key={mountPoint} //remounts entire playground
          baseFiles={baseFiles}
          updateParentForm={updateParentForm}
          canEditFiles={true}
          showFileExplorer={true}
          mountPoint={mountPoint}
          disabled={isOriginal}
          showTerminal={true}
          showOpenInCodeSandbox={false} //form in form error
          environment={environment}
          highlightText={form.getFieldValue("highlightText")}
          highlightRegex={form.getFieldValue("highlightRegex")}
          highlightLines={form.getFieldValue("highlightLines")}
        />
      </div>
      <GenericInputField
        form={form}
        className="hidden"
        name="files"
        type="hidden"
      />
      {!locked && (
        <div className="flex gap-4 flex-wrap w-full sm:max-w-xl md:max-w-3xl lg:max-w-5xl xl:max-w-full">
          <div className={cn(switchClasses, "flex items-center ")}>
            <TemplateDropdown updatePlaygroundFiles={updatePlaygroundFiles} />
          </div>
          <SwitchField
            form={form}
            className={switchClasses}
            name="isSubmitType"
            label="Enable Submit"
          />

          <SwitchField
            form={form}
            className={switchClasses}
            name="showFileExplorer"
            label="Show File Explorer"
            disabled={!showEditor}
          />
          <SwitchField
            form={form}
            className={switchClasses}
            name="showEditor"
            label="Show Editor"
          />
          <SwitchField
            form={form}
            className={switchClasses}
            name="showPreview"
            label="Show Preview"
          />
          <SwitchField
            form={form}
            className={switchClasses}
            name="showTerminal"
            label="Show Terminal"
            disabled={!showPreview}
          />

          <SwitchField
            form={form}
            className={switchClasses}
            name="readOnly"
            label="Read Only"
          />

          <SelectField
            form={form}
            name="direction"
            label="Direction"
            className={switchClasses}
          >
            <SelectItem value={"horizontal"}>horizontal</SelectItem>
            <SelectItem value={"vertical"}>vertical</SelectItem>
          </SelectField>

          <WidthSelectField
            form={form}
            label="Width"
            className={switchClasses}
          />

          <HeightSelectField
            form={form}
            label="Height"
            className={switchClasses}
          />

          <GenericInputField
            form={form}
            className={switchClasses + " w-20"}
            label="Split %"
            name="splitPercent"
            type="number"
          />
          <SwitchField
            form={form}
            className={switchClasses}
            name="showOpenInCodeSandbox"
            label="Show Open in Sandbox"
          />
          <SelectField
            form={form}
            name="environment"
            label="CSB Environment 2"
            className={switchClasses}
            disabled={!showOpenInCodeSandbox}
          >
            {SandboxEnvironments.map((env) => (
              <SelectItem key={env} value={env}>
                {env}
              </SelectItem>
            ))}
          </SelectField>

          <OptionalTextField
            form={form}
            className={switchClasses}
            name="highlightText"
            label="Highlight Text"
            placeholder="i.e. const"
          />
          <OptionalTextField
            form={form}
            className={switchClasses}
            name="highlightRegex"
            label="Highlight Regex"
            placeholder="i.e. [a-z]"
          />
          <OptionalTextField
            form={form}
            className={switchClasses}
            name="highlightLines"
            label="Highlight Line Numbers"
            placeholder="i.e. 1,3,5-10"
          />
        </div>
      )}
      <MarkdownField
        form={form}
        label="Instruction"
        name="instruction"
        disabled={isOriginal}
        vertical={!!locked}
        size="sm"
      />
      <MarkdownField
        form={form}
        name="hint"
        label="Hint"
        disabled={isOriginal}
        vertical={!!locked}
        size="sm"
      />
      {!locked && (
        <CommonAdminFields form={form}>
          <SubmissionRequiredSwitchField form={form} />
          <EnableCommentsSwitchField form={form} />
        </CommonAdminFields>
      )}
    </div>
  );
}

const SandboxEnvironments = [
  "angular-cli",
  "create-react-app",
  "create-react-app-typescript",
  "svelte",
  "parcel",
  "vue-cli",
  "static",
  "solid",
  "node",
];

export function Submission({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  const originalFiles = element.data?.files;
  const modifiedFiles = submission.data?.files;

  if (!originalFiles) return <div>No element files</div>;
  if (!modifiedFiles) return <div>No submission files</div>;

  const activeFile = originalFiles.activeFile;
  const original = originalFiles.files[activeFile]?.code;
  const modified = modifiedFiles.files[activeFile]?.code;
  const [expanded, setExpanded] = useState(false);
  return (
    <div className="flex">
      <ScrollArea
        className={cn(
          "w-full md:max-w-md xl:max-w-4xl border-2 rounded border-muted-foreground hover:border-foreground cursor-pointer",
          expanded ? "h-64" : "h-10",
        )}
        onClick={(e) => {
          e.preventDefault();
          setExpanded(!expanded);
        }}
      >
        <CodeDiff
          original={original}
          modified={modified}
          fileName={activeFile}
        />
      </ScrollArea>
      <div className="ms-1">
        <TooltipDialogButton
          info="Open Code Playground"
          variant="secondary"
          size="sm"
          className="px-2 h-8"
          icon={SquareArrowOutUpRight}
        >
          <SubmissionPlayground submission={submission} element={element} />
        </TooltipDialogButton>
      </div>
    </div>
  );
}
import { diffLines } from "diff";
import { SquareArrowOutUpRight } from "lucide-react";

function CodeDiff({
  original,
  modified,
  fileName,
}: {
  original: string;
  modified: string;
  fileName?: string;
}) {
  //TODO: add check for null inputs here
  if (original == null || modified == null) {
    return <pre className="m-0">No changes</pre>;
  }

  const result = diffLines(original, modified);

  let count = 0;
  return (
    <div className="relative flex flex-col text-xs w-full py-1">
      <div className="absolute top-0 right-0 px-2 bg-primary rounded-md text-primary-foreground me-2">
        {fileName}
      </div>
      {result.map((part, index) => {
        if (part.removed == true) return;
        count += part.count;
        if (!part.added) return;
        return (
          <div key={index} className="flex">
            <pre className="w-8 text-end pe-1">{count}</pre>
            <pre
              key={index}
              className={cn(
                "ps-1 border-s-2 border-muted-foreground",
                part.added
                  ? "text-foreground"
                  : part.removed
                    ? "bg-red-100 text-red-800"
                    : "text-gray-600",
              )}
            >
              {part.value}
            </pre>
          </div>
        );
      })}
    </div>
  );
}

import { DiffEditor } from "@monaco-editor/react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TooltipDialogButton } from "@/components/format/Buttons";
import { SubmissionPlayground } from "../submissions/SubmissionPlayground";
import CodeHike from "@/components/codehike/CodeHike";

export function SubmissionDiffEditor({
  original,
  modified,
}: {
  original: string;
  modified: string;
}) {
  return (
    <DiffEditor
      className="w-full max-w-xl h-16 text-foreground bg-background"
      language="text/plain"
      theme={null}
      original={original}
      modified={modified}
      options={{
        wordWrap: "on",
        wrappingStrategy: "advanced",
        lineNumbers: "on", // Disable line numbers
        glyphMargin: false, // Disable glyph margin
        folding: false, // Disable code folding feature
        fontSize: "10px",
        //    automaticLayout: true,
        scrollBeyondLastLine: false,
        scrollbar: {
          verticalScrollbarSize: 0,
        },
        codeLens: false,
        lineDecorationsWidth: 1,
        lineNumbersMinChars: 0,
        renderMarginRevertIcon: false,
        renderControlCharacters: false,
        renderFinalNewline: "off",
        renderGutterMenu: false,
        renderIndicators: false,
        renderLineHighlight: "none", // "all" | "line" | "none" | "gutter";
        // renderLineHighlightOnlyWhenFocus?: boolean;
        automaticLayout: true,
        renderOverviewRuler: false,
        renderSideBySide: true,
        //renderSideBySideInlineBreakpoint?: number;
        renderValidationDecorations: "off", //: "off" | "on" | "editable";
        renderWhitespace: "none", //: "all" | "none" | "boundary" | "selection" | "trailing";
        readOnly: true,
        minimap: { enabled: false }, // Disable minimap
      }}
    />
  );
}

export function SubmissionInfo({ items, element }) {
  return (
    <div className="grid border-2 h-full rounded-xl p-2">
      <CodeHike item={element} code={element.data.instruction} />
    </div>
  );
}

export default { Id, Name, Defaults, FormFields, Submission, SubmissionInfo };
