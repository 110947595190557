import { Card, CardContent } from "@/components/ui/card";
import { TeamsTable } from "@/lms/team/TeamsTable";
import { Users } from "lucide-react";
import { TeamsRecord, TeamsResponse } from "@/lib/pb/types";
import { useAddTeamMutation } from "@/lib/pb/lms";
import { RequiredTextField } from "@/lms/core/LmsFields";
import { Form, zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";

type TeamAdminProps = {
  courseId: string;
  classId: string;
};

export function TeamAdminCard(props: TeamAdminProps) {
  return (
    <Card className="overflow-hidden ">
      <CardContent className="p-0 text-sm">
        <div className="grid gap-4 mx-6 mt-6">
          <div className="flex gap-4 flex-wrap">
            <div className="flex font-bold text-2xl gap-2">
              <Users className="h-6 w-6 mt-1" />
              <div className="">Teams</div>
            </div>
            <TeamAddForm {...props} />
          </div>
          <TeamsTable {...props} />
        </div>
      </CardContent>
    </Card>
  );
}

export function TeamAddForm({ courseId, classId }:TeamAdminProps) {
  const { mutateAsync: add, isPending } = useAddTeamMutation(courseId, classId);

  const form = useForm<TeamsRecord, any>({
    validatorAdapter: zodValidator(),
    defaultValues: { name: "" },
    onSubmit: async ({value, formApi}) => {
      if(isPending) return
      value.classId = classId;
      await add({ data: value }).catch((e) => console.error(e));
      formApi.reset();
    },
  });
  return (
    <Form form={form}>
      <div className="flex gap-4 my-auto">
        <RequiredTextField
          name="name"
          placeholder="Create a new team"
          form={form}
          className="grid gap-2 w-64"
          disabled={isPending}
        />
      </div>
    </Form>
  );
}
