import { createFileRoute, Navigate } from "@tanstack/react-router";
import LoginForm from "@/components/auth/LoginForm";
import {CardFormat} from "@/components/format/CardFormat";
import { useUser } from "@/lib/store";
import {useStaticTranslations} from "@/lib/lang";

export const Route = createFileRoute("/_nav/login")({
  component: Login,
});

export function Login() {
  const user = useUser();
  const st = useStaticTranslations();
  if (user) {
    return <Navigate to="/" />;
  }
  return (
    <div className="max-w-md mx-auto mt-4">
      <CardFormat
        className="mx-auto max-w-sm shadow-xl"
        title={st("Login")}
        description="Enter your email below to login to your account"
      >
        <LoginForm />
      </CardFormat>
    </div>
  );
}
