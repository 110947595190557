import { Button } from "@/components/ui/button";
import { useForm } from "@tanstack/react-form";
import { ConfirmButtons, Form, zodValidator } from "@/components/form";
import { useQuery } from "@tanstack/react-query";
import {
  teamsListQuery,
  useAddEnrollmentMutation,
  useEditEnrollmentMutation,
} from "@/lib/pb/lms";
import { useNavigate } from "@tanstack/react-router";
import { useUser } from "@/lib/store";
import { SelectField, SelectItem } from "@/components/form/Select";
import { ClassesResponse, EnrollmentsResponse } from "@/lib/pb/types";
import { DialogButton } from "@/components/format/Buttons";
import { ContentsProps, Header } from "@/components/format/Dialog";

type EnrollButtonProps = {
  enrollingState: [boolean, Function]; //disable all enrolling buttons
  classItem: ClassesResponse;
  enrollmentItem?: EnrollmentsResponse;
};

export function EnrollButton({
  enrollingState,
  classItem,
  enrollmentItem,
}: EnrollButtonProps) {
  const navigate = useNavigate();
  const user = useUser();
  const { mutateAsync: enroll, isPending: addPending } =
    useAddEnrollmentMutation(user?.id);
  const { mutateAsync: change, isPending: changePending } =
    useEditEnrollmentMutation(user?.id);

  const [isEnrolling, setIsEnrolling] = enrollingState;
  const { id: classId, courseId } = classItem;

  if (!user) return;

  const handleEnroll = async () => {
    if (addPending || changePending) return;
    setIsEnrolling(true);
    const data = { classId, courseId, userId: user.id, teamId: "" };
   
    const res = enrollmentItem
      ? await change({ id: enrollmentItem.id, data }).catch((e) =>
          console.error(e),
        )
      : await enroll({ data }).catch((e) => console.error(e));
    setIsEnrolling(false);
    if (res && !enrollmentItem) navigate({ to: `/course/${courseId}` });
  };

  return (
    <Button
      onClick={() => handleEnroll()}
      disabled={isEnrolling || !classItem.active}
    >
      {enrollmentItem ? "Switch Enrollment" : "Enroll"}
    </Button>
  );
}

export function EnrollTeamButton({
  enrollingState,
  classItem,
  enrollmentItem,
}: EnrollButtonProps) {
  return (
    <DialogButton
      label={enrollmentItem ? "Switch Enrollment*" : "Enroll*"}
      contents={EnrollTeamForm}
      variant="default"
      item={{ enrollmentItem: enrollmentItem, classItem }}
      actionState={enrollingState}
    />
  );
}

type EnrollTeamItems = {
  enrollmentItem?: EnrollmentsResponse;
  classItem: ClassesResponse;
};

function EnrollTeamForm({
  item,
  onComplete,
  actionState,
}: ContentsProps<EnrollTeamItems>) {
  const user = useUser();
  if (!item || !actionState || !user) {
    console.error("Error in EnrollTeamForm");
    return <div>Error in EnrollTeamForm: required data is missing.</div>;
  }

  const navigate = useNavigate();
  const { mutateAsync: enroll, isPending: addPending } =
    useAddEnrollmentMutation(user?.id);
  const { mutateAsync: change, isPending: changePending } =
    useEditEnrollmentMutation(user?.id);

  const [isEnrolling, setIsEnrolling] = actionState;
  const { enrollmentItem, classItem } = item;
  const { id: classId, courseId } = classItem;
  const { isPending, error, data: teams } = useQuery(teamsListQuery(classId));

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { teamId: "" },
    onSubmit: async ({ value }) => {
      if (addPending || changePending) return;
      setIsEnrolling(true);
      const data = { classId, courseId, userId: user.id, teamId: value.teamId };
      const res = enrollmentItem
        ? await change({ id: enrollmentItem.id, data }).catch((e) =>
            console.error(e),
          )
        : await enroll({ data }).catch((e) => console.error(e));
      setIsEnrolling(false);
      if (res && !enrollmentItem) navigate({ to: `/course/${courseId}` });
      onComplete();
    },
  });
  const teamId = form.useStore((state) => state.values.teamId);

  if (!user) return;

  if (error && !isPending) return <div>Error loading teams</div>;

  if (isPending) return <div>Loading...</div>;

  return (
    <div className="grid gap-2">
      <Header
        title="Select a team to enroll"
        description="This class requires a team to be enrolled. Ask the instructor if you do not have a team."
      />
      <Form form={form}>
        <div className="grid gap-4 py-4">
          {teams?.length > 0 && (
            <SelectField
              form={form}
              name="teamId"
              label="Team"
              placeholder="Select a team"
              className="grid gap-2"
            >
              {teams.map((team) => (
                <SelectItem key={team.id} value={team.id}>
                  {team.name}
                </SelectItem>
              ))}
            </SelectField>
          )}
          {teams?.length < 1 && (
            <div className="text-center text-destructive">No teams found.</div>
          )}
        </div>
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <ConfirmButtons
            onComplete={onComplete}
            form={form}
            label="Enroll"
            disabled={!teamId || isEnrolling}
          />
        </div>
      </Form>
    </div>
  );
}
