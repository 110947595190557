import { ElementSubmitButton } from "@/components/form/SubmitButtons";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";
import { useEnrollment } from "@/lib/store";
import { FormApi } from "@tanstack/react-form";
import { formatRelative, formatDistance } from "date-fns";
import { HintTrigger } from "./Hint";
import { cn } from "@/lib/utils";
import { Comments } from "@/lms/comments/Comments";

export function ElementSubmissionNav({
  item,
  submission,
  form,
  disabled = false,
  hint,
  className,
  children,
}: {
  item: ElementsResponse;
  submission: SubmissionsResponse | null | undefined;
  form: FormApi<any, any>;
  disabled?: boolean;
  hint?: string;
  className?: string;
  children?: React.ReactNode;
}) {
  const enrollment = useEnrollment();
  const enableComments = item?.data?.enableComments;
  return (
    <div className={cn("flex gap-4 items-center justify-between", className)}>
      {!enrollment ? (
        <div className="text-xs text-muted-foreground">
          Must enroll in class to submit.
        </div>
      ) : (
        <div className="flex gap-2">
          <ElementSubmitButton
            form={form}
            resubmit={!!submission}
            disabled={disabled || !enrollment}
          />
          {children}
        </div>
      )}
      <div className="flex gap-2">
        {hint && (
          <div className="flex text-sm  ">
            <HintTrigger hint={hint} />
          </div>
        )}
        {enrollment && enableComments && (
          <Comments userId={enrollment.userId} item={item} classId={enrollment.classId} />
        )}
      </div>

      <div className="flex flex-col justify-center opacity-75">
        <div className="text-xs text-muted-foreground ms-auto flex gap-2">
          {submission
            ? `${submission.attempts} submission(s) ${item.submissionRequired ? "*" : ""}`
            : item.submissionRequired && (
                <div className="text-destructive">* submission required</div>
              )}
        </div>
        <div className="text-xs text-muted-foreground ms-auto text-end">
          {submission
            ? "Updated " + formatRelative(submission.updated, new Date())
            : "Unsubmitted"}
        </div>
      </div>
    </div>
  );
}
