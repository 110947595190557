import { Link } from "./Link";

export function Footer() {
  return (
    <div className=" h-12 lg:h-16 items-center gap-4 border-b bg-background px-4 md:px-10 lg:px-14 xl:px-16">
      <div className="mx-auto flex size-full max-w-container flex-row items-center gap-4 ">
        <div className="flex gap-4 ms-auto text-sm ">
          <Link to={"/About"} className="flex w-32  justify-center">
            About
          </Link>
          <Link to={"/privacy"} className="flex  justify-center">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
}
