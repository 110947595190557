import CodeHike from "@/components/codehike/CodeHike";
import { ClassDeleteForm } from "@/lms/classes/ClassDeleteForm";
import { DialogButton, FullDialogButton } from "@/components/format/Buttons";
import { Pencil, Trash2, Clock } from "lucide-react";
import { Label } from "@/components/ui/label";
import { ClassEditForm } from "@/lms/classes/ClassAdminForm";
import { format } from "date-fns";
import { ClassesAdminViewResponse, RecordIdString } from "@/lib/pb/types";
import { classAdminDetailQuery, useQuery } from "@/lib/pb/lms";
import { Skeleton } from "@/components/ui/skeleton";
import { Separator } from "@/components/ui/separator";
import { TeamAdminCard } from "@/lms/team/TeamAdminCard";
import { ClassDetails } from "@/lms/classes/ClassDetails";
function formatDate(date: string) {
  return date ? format(date, "PPP") : "TBD";
}

function ItemLabel({ label }: { label: string }) {
  return <Label className="h-10 pt-0.5 flex justify-end">{label}</Label>;
}

export function ClassAdminCard({ classId }: { classId: RecordIdString }) {
  const { data, isPending, error } = useQuery(classAdminDetailQuery(classId));

  if (isPending) return <Skeleton className="w-full h-48 " />;
  if (error) return <div>An error has occurred: {error.message}</div>;
  return (
    <div className="grid gap-4 w-full">
      <div className="flex">
        <Clock className="w-8 h-8 me-4 my-auto" />
        <div className="font-bold text-xl pe-8 my-auto">{data?.name}</div>
        <ClassAdminButtons item={data} />
      </div>
      <div className="grid md:grid-cols-2 w-full gap-10">
        <div className="w-full flex flex-col ">
          <Separator />
          <ClassDetails item={data} className="w-full pt-4" />
        </div>
        <TeamAdminCard courseId={data.courseId} classId={classId} />
      </div>
    </div>
  );
}

function ClassAdminButtons({ item }: { item: ClassesAdminViewResponse }) {
  return (
    <>
      <FullDialogButton
        icon={Pencil}
        label="Edit"
        item={item}
        contents={ClassEditForm}
        variant="ghost"
      />

      <DialogButton
        label="Delete"
        variant="ghost"
        icon={Trash2}
        contents={ClassDeleteForm}
        item={item}
        disabled={item.active}
      />
    </>
  );
}
