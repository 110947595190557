//For Admin Components
import MarkdownType from "@/lms/elements/MarkdownType";
import FreeResponseType from "@/lms/elements/FreeResponseType";
import MultipleChoiceType from "@/lms/elements/MultipleChoiceType";
import CodePlaygroundType from "@/lms/elements/CodePlaygroundType";
import ImageType from "@/lms/elements/ImageType";
import YouTubeType from "@/lms/elements/YouTubeType";
import PasteJsonType from "@/lms/elements/PasteJsonType";
import FileSubmissionType from "@/lms/elements/FileSubmissionType";
import LinkButtonType from "@/lms/elements/LinkButtonType";
import FeedbackType from "@/lms/elements/FeedbackType";
export default {
  markdown: MarkdownType,
  "free-response": FreeResponseType,
  "multiple-choice": MultipleChoiceType,
  "code-playground": CodePlaygroundType,
  image: ImageType,
  youtube: YouTubeType,
  "paste-json": PasteJsonType,
  "file-submission": FileSubmissionType,
  "link": LinkButtonType,
  "feedback": FeedbackType,
};
