// Import Monaco configuration
import "../../monacoConfig";
import {
  UnstyledOpenInCodeSandboxButton,
  SandpackProvider,
  SandpackFileExplorer,
  SandboxEnvironment,
  SandpackThemeProvider,
  // SandpackPreview,
  // SandpackLayout,
  // useSandpack,
} from "@codesandbox/sandpack-react";
import { nightOwl, aquaBlue } from "@codesandbox/sandpack-themes";
import { RotateCw } from "lucide-react";

import { useDarkMode } from "@/lib/store";
import WebContainerPreview from "./WebContainerPreview";
import { MonacoEditor } from "./MonacoEditor";
import { SandpackFileExplorer as FullFileExplorer } from "sandpack-file-explorer";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { cn } from "@/lib/utils";
import { useEffect, useMemo, useState } from "react";
import { Heights, Widths } from "@/lib/sizes";
import { Button } from "@/components/ui/button";

export interface SandpackFiles {
  activeFile: string;
  visibleFiles: string[];
  files: Record<string, any>;
}

export interface PlaygroundProps {
  baseFiles: Record<string, any>;
  updateParentForm?: (
    files: SandpackFiles,
    environment: SandboxEnvironment,
  ) => void | undefined;
  canEditFiles?: boolean;
  showFileExplorer?: boolean;
  showFileTabs?: boolean;
  showEditor?: boolean;
  showPreview?: boolean;
  showTerminal?: boolean;
  showOpenInCodeSandbox?: boolean;
  width?: keyof typeof Widths;
  height?: keyof typeof Heights;
  splitPercent?: number;
  readOnly?: boolean;
  mountPoint: string;
  disabled?: boolean;
  environment?: SandboxEnvironment;
  direction?: "horizontal" | "vertical";
  highlightText?: string;
  highlightRegex?: string;
  highlightLines?: string;
}
//{"package.json":"{}"}
export function Playground(props: PlaygroundProps) {
  const baseFiles = props.baseFiles;
  const [defaultFiles, setDefaultFiles] = useState(baseFiles.files);

  useEffect(() => {
   // console.log("base file of playground changed 🔴");
    setDefaultFiles(baseFiles.files); //for resetting!
  }, [baseFiles]);

  const { activeFile, visibleFiles } = props.baseFiles;
  // experiemntal bundler cant do static
  // bundlerURL: "https://bundler.up.railway.app",
  //bundlerURL:"https://preview.sandpack-static-server.codesandbox.io",
  //bundlerURL: "https://sandpack-bundler.codesandbox.io"//https://github.com/codesandbox/sandpack/blob/8716a577694885b53e0696a275ecbf0b87097fc1/sandpack-react/src/types.ts#L120
  // autorun: true,
  //autoReload: true,
  return (
    <SandpackProvider
      customSetup={{
        environment: props.environment ? props.environment : "static",
      }}
      options={{
        autorun: true,
        autoReload: true,
        activeFile,
        visibleFiles,
        initMode: "immediate",
      }}
      files={defaultFiles}
    >
      <PlaygroundPanels {...props} splitPercent={props.splitPercent} />
    </SandpackProvider>
  );
}

//extracted so we can use use Sandpack hooks
function PlaygroundPanels({
  baseFiles, // populated by
  updateParentForm, //needed by outside form to extract data
  mountPoint,
  canEditFiles = false,
  showFileExplorer = false,
  showFileTabs = true, //cant think of when we dont want to show tabs
  showEditor = true,
  showPreview = true,
  showTerminal = false,
  showOpenInCodeSandbox = true,
  splitPercent = 50,
  height = "md",
  width = "full",
  disabled = false, // when translating
  readOnly = false,
  //environment = "static",
  direction = "horizontal",
  highlightText = "",
  highlightRegex = "",
  highlightLines = "",
}: PlaygroundProps) {
  const isDark = useDarkMode();
  //const { updateFile, setActiveFile, resetAllFiles } = sandpack;
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewId, setPreviewId] = useState(1);
  const editorDisabled = disabled;

  if (!showPreview && !showEditor) return;

  const style = useMemo(() => ({ height: Heights[height] }), [height]);
  return (
    <SandpackThemeProvider theme={isDark ? nightOwl : aquaBlue}>
      <div className="relative bg-background rounded-md" style={style}>
        <ResizablePanelGroup
          direction={direction}
          className={cn("rounded-md md:min-w-[450px] relative", Widths[width])}
        >
          {showEditor && (
            <ResizablePanel defaultSize={splitPercent * 1.0}>
              <div
                key={mountPoint /* reloads the playground */}
                className={cn(
                  "flex flex-row-reverse h-full",
                  editorDisabled ? "opacity-50" : "opacity-100",
                )}
              >
                <MonacoEditor
                  baseFiles={baseFiles}
                  showFileTabs={showFileTabs}
                  closableTabs={showFileExplorer}
                  readOnly={editorDisabled || readOnly}
                  updateParentForm={updateParentForm}
                  highlightText={highlightText}
                  highlightRegex={highlightRegex}
                  highlightLines={highlightLines}
                />

                {showFileExplorer && (
                  <div className="flex border-e-2 w-[200px]">
                    {canEditFiles ? (
                      <FullFileExplorer />
                    ) : (
                      <SandpackFileExplorer autoHiddenFiles />
                    )}
                  </div>
                )}
              </div>
            </ResizablePanel>
          )}
          {showEditor && showPreview && <ResizableHandle withHandle />}
          {showPreview && (
            <ResizablePanel defaultSize={100.0 - splitPercent}>
              <div
                className={cn(
                  "flex h-full justify-center w-full",
                  direction == "horizontal" ? "border-s-2" : "border-t-4",
                )}
              >
                {/* <StaticPreview /> */}
                {/* <SandpackLayout>
                <SandpackPreview
                  showOpenInCodeSandbox={showOpenInCodeSandbox}
                />
              </SandpackLayout> */}
                <WebContainerPreview
                  baseFiles={baseFiles}
                  setPreviewLoading={setPreviewLoading}
                  mountPoint={mountPoint}
                  showTerminal={showTerminal}
                  previewId={previewId}
                />
              </div>
            </ResizablePanel>
          )}

          <div className="flex gap-2 absolute bottom-1 right-1 text-muted-foreground opacity-50">
            {showOpenInCodeSandbox && <OpenInCodeSandboxButton />}
            {showPreview && (
              <Button
                variant="secondary"
                className="rounded-full h-7 px-2"
                onClick={(e) => {
                  e.preventDefault();
                  setPreviewId(previewId + 1);
                }}
              >
                <RotateCw />
              </Button>
            )}
          </div>
        </ResizablePanelGroup>
      </div>
    </SandpackThemeProvider>
  );
}

function OpenInCodeSandboxButton() {
  return (
    <UnstyledOpenInCodeSandboxButton className="rounded-full border-2 border-muted-foreground h-6 px-2 text-xs bg-secondary text-secondary-foreground font-medium">
      Open in CodeSandbox
    </UnstyledOpenInCodeSandboxButton>
  );
}
