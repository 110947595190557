import { Field, Input, Textarea } from "@/components/form";
import {
  emailSchema,
  passwordSchema,
  nameSchema,
  lastNameSchema,
  requiredSchema,
} from "@/lib/validation";

import { FormApi } from "@tanstack/react-form";
import { z } from "zod";

export interface FieldProps {
  form: FormApi<any, any>;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: string;
}

export interface InputFieldProps extends FieldProps {
  name: string;
  label?: React.ReactNode;
  type?: string;
  schema?: z.ZodSchema<any, any>;
  description?: React.ReactNode;
}

export const GenericInputField: React.FC<InputFieldProps> = ({
  form,
  className,
  name,
  label,
  type,
  schema,
  ...props
}) => {
  return (
    <div >
      <Field
        form={form}
        className={className}
        name={name}
        label={label ? label : null}
        validator={schema}
        disabled={props.disabled}
        children={(field) => <Input type={type} field={field} {...props} />}
      />
      {props.description && (
        <div className="text-sm text-muted-foreground ps-4">
          {props.description}
        </div>
      )}
    </div>
  );
};
export const NameField = (props: FieldProps) => (
  <GenericInputField
    name="name"
    label="First Name"
    schema={nameSchema}
    placeholder="Your Name"
    type="text"
    autoComplete="given-name"
    {...props}
  />
);

export const LastNameField = (props: FieldProps) => (
  <GenericInputField
    name="lastName"
    label="Last Name (optional)"
    schema={lastNameSchema}
    type="text"
    autoComplete="family-name"
    {...props}
  />
);

export const EmailField = (props: FieldProps) => (
  <GenericInputField
    name="email"
    label="Email"
    schema={emailSchema}
    type="email"
    autoComplete="email"
    {...props}
  />
);

export const PasswordField = (props: FieldProps) => (
  <GenericInputField
    name="password"
    label="Password"
    schema={requiredSchema}
    type="password"
    autoComplete="current-password"
    {...props}
  />
);

export const NewPasswordField = (props: FieldProps) => (
  <GenericInputField
    name="password"
    label="Password"
    schema={passwordSchema}
    type="password"
    autoComplete="new-password"
    {...props}
  />
);

export interface SwitchFieldProps {
  form: FormApi<any, any>;
  className?: string;
  name: string;
  label: JSX.Element | string;
  disabled?: boolean;
  required?: boolean;
}

export const GenericTextareaField: React.FC<InputFieldProps> = ({
  form,
  className,
  name,
  label,
  schema,
  ...props
}) => {
  return (
    <div>
      <Field
        form={form}
        className={className}
        name={name}
        label={label ? label : null}
        validator={schema}
        children={(field) => <Textarea field={field} {...props} />}
      />
      {props.description && (
        <div className="text-sm text-muted-foreground p-1">
          {props.description}
        </div>
      )}
    </div>
  );
};

import { Switch } from "@/components/ui/switch";
import React from "react";
export const SwitchField: React.FC<SwitchFieldProps> = ({
  form,
  className,
  name,
  label,
  ...props
}) => {
  return (
    <Field
      form={form}
      className={className}
      name={name}
      label={label}
      validator={null}
      disabled={props.disabled}
      children={(field) => (
        <Switch
          className="scale-75 my-auto "
          id={field?.name}
          name={field?.name}
          checked={field?.state.value}
          onBlur={field?.handleBlur}
          onCheckedChange={(e) => {
            //console.log(e);
            field?.handleChange(e);
          }}
          {...props}
        />
      )}
    />
  );
};

// export function PasswordField({
//   form,
//   className,
//   validate = true,
//   ...props
// }: FieldProps) {
//   return (
//     <Field
//       form={form}
//       className={className}
//       name="password"
//       label="Password"
//       validator={validate ? passwordSchema : null}
//       children={(field) => <Input type="password" field={field} {...props} />}
//     />
//   );
// }
