import { createFileRoute } from '@tanstack/react-router'
import { useQuery } from '@tanstack/react-query'
import { courseDetailQuery } from '@/lib/pb/lms'
import { CourseDetails } from '@/lms/course/CourseCard'
import { UnitsList } from '@/lms/unit/UnitsList'
import { CoursesResponse } from '@/lib/pb/types'

export const Route = createFileRoute('/_auth/course/$courseId_/admin')({
  loader: ({ context: { queryClient }, params: { courseId } }) => {
    const courses = queryClient.getQueryData([
      'courses',
    ]) as Array<CoursesResponse>

    const course = courses?.find((item: any) => item.id === courseId)
    if (course) {
      return queryClient.setQueryData(['courses', courseId], course)
    }
  },
  // errorComponent: PostErrorComponent,
  component: CourseComponent,
})

export class CourseNotFoundError extends Error {}

function CourseComponent() {
  const courseId = Route.useParams().courseId

  const {
    isPending,
    error,
    data: course,
  } = useQuery(courseDetailQuery(courseId))

  if (error) {
    return <div>An error has occurred: {error.message}</div>
  }

  return (
    <main className="grid flex-1 items-start gap-2  sm:py-0 md:gap-4 xl:grid-cols-3 xl:gap-8 2xl:px-12 ">
      <div>{course && <UnitsList course={course} />}</div>
      <div className="grid auto-rows-max items-start gap-2 md:gap-4 xl:gap-8 lg:col-span-2">
        {isPending ? <div>Loading...</div> : <CourseDetails course={course} />}
        <div>class info</div>
      </div>
    </main>
  )
}
