import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

import {
  MenuButton,
  IconMenuItem,
  CopyCutButtons,
} from "@/components/format/Buttons";

import { DialogForm, useDialog } from "@/components/format/Dialog";

import { ElementsResponse } from "@/lib/pb/types";
import { useLanguage } from "@/lib/lang";
import {
  Pencil,
  Languages,
  Trash2,
  Eye,
  EyeOff,
  SquareArrowUp,
  SquareArrowDown,
} from "lucide-react";
import {
  ElementDeleteForm,
  ElementEditForm,
  ElementTranslateForm,
} from "@/lms/element/ElementAdminForm";
import { useEditElementMutation } from "@/lib/pb/lms";
import { MoveButtons } from "@/lms/core/MoveButtons";

import { useClipboard } from "@/lib/store";
import { Rank } from "../core/Rank";
import { ElementPasteButton } from "./ElementPasteButton";
import { ElementTypesDialog } from "./ElementTypesDialog";

export default function ElementAdminMenu({
  item,
  items, //for sort order
  size = 4,
  className,
}: {
  item: ElementsResponse;
  items: ElementsResponse[];
  size?: number;
  className?: string;
}) {
  const language = useLanguage();
  const editDialog = useDialog<HTMLDivElement>();
  const deleteDialog = useDialog<HTMLDivElement>();
  const translateDialog = useDialog<HTMLDivElement>();
  const type = "Element";

  const { id, sectionId, courseId, rank, active, parent: section } = item;
  const { mutateAsync: edit, isPending } = useEditElementMutation(
    courseId,
    sectionId,
    id,
  );

  return (
    <DropdownMenu>
      <MenuButton id={id} size={size} active={active} className={className} />

      <DropdownMenuContent
        side="right"
        align="start"
        className="border-2 border-primary/75"
      >
        <div className="flex gap-1">
          <ElementPasteButton
            icon={SquareArrowUp}
            courseId={item.courseId}
            sectionId={item.sectionId}
            rank={Rank.above(item.id, items)}
          />
          <ElementPasteButton
            icon={SquareArrowDown}
            courseId={item.courseId}
            sectionId={item.sectionId}
            rank={Rank.below(item.id, items)}
          />
        </div>
        <MoveButtons edit={edit} item={item} items={items} />

        <IconMenuItem
          triggerProps={editDialog.triggerProps}
          label={`Edit ${type}`}
          icon={Pencil}
        />

        <IconMenuItem
          triggerProps={translateDialog.triggerProps}
          label={`Translate ${type}`}
          icon={Languages}
          disabled={!language || language == "en"}
        />

        <CopyCutButtons item={item} type={type} />

        <IconMenuItem
          triggerProps={deleteDialog.triggerProps}
          label={`Delete ${type}`}
          icon={Trash2}
        />
        <IconMenuItem
          label={active ? "Make Inactive" : "Make Active"}
          icon={active ? EyeOff : Eye}
          onClick={async () => {
            if (isPending) return;
            await edit({ id, data: { active: !active } });
          }}
        />

        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(id)}
          className="text-muted-foreground font-xs"
        >
          id: {id} ({rank})
        </DropdownMenuItem>

        {section && <ElementTypesDialog section={section} />}
      </DropdownMenuContent>

      <DialogForm
        item={item}
        dialogProps={editDialog.dialogProps}
        className="sm:max-w-full"
        contents={ElementEditForm}
      />
      <DialogForm
        item={item}
        dialogProps={translateDialog.dialogProps}
        className="sm:max-w-full"
        contents={ElementTranslateForm}
      />
      <DialogForm
        item={item}
        dialogProps={deleteDialog.dialogProps}
        className="sm:max-w-md"
        contents={ElementDeleteForm}
      />
    </DropdownMenu>
  );
}
