import packageFiles from "./static";
//<script src="https://phaser.portl.page/lib/DisplayError.js" crossorigin="anonymous"></script>
//   <script src="DisplayError.js" crossorigin="anonymous"></script>

//  Un-minified
//https://cdnjs.cloudflare.com/ajax/libs/phaser/3.85.2/phaser.js
//https://cdn.jsdelivr.net/npm/phaser@v3.85.2/dist/phaser.js
//<script src="https://phaser.portl.page/lib/phaser.js" crossorigin="anonymous"></script>

export const indexHtml = `<html>
    <head>
      <meta charset="UTF-8">
      <title>Game Title</title>
      <script src="https://phaser.portl.page/lib/DisplayError2.js" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/phaser@v3.86.0/dist/phaser.js" crossorigin="anonymous"></script>
      <script src="https://phaser.portl.page/lib/helper.js" crossorigin="anonymous"></script>
      <script src="https://phaser.portl.page/lib/addons.js" crossorigin="anonymous"></script>
      <script src="https://phaser.portl.page/lib/GridLayout.js" crossorigin="anonymous"></script>
      <script src="https://phaser.portl.page/lib/SimpleScene.js" crossorigin="anonymous"></script>
      <script src="https://phaser.portl.page/lib/pathsegs.js" crossorigin="anonymous"></script>
      <script src="Scene1.js"></script>
      <script src="Scene2.js"></script>
      
    </head>

    <body>
      <div id="phaser-div"></div>
      <script src="main.js"></script>
    </body>
  </html>`;

export const mainJs = `// Game configuration object
let config = {
  autoFocus: false,
  roundedPixels: false,
  type: Phaser.AUTO,
  width: 1000,
  height: 800,
  backgroundColor: 0x000000,
  scene: [Scene1],
  parent: "phaser-div",
  dom: {
    createContainer: true,
  },
  fontFamily: "Arial",
  physics: {
    default: "matter",
    matter: {
      debug: false,
    }
  },
  scale: {
    mode: Phaser.Scale.FIT,
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
};

//global data object
let data = {
  hits: 0,
  //lives: 3,
  //health: 100,
};

//Create a Phaser Game using the config
const game = new Phaser.Game(config);
  `;

const sceneJs = `class Scene1 extends SimpleScene {
    constructor() {
      super("Scene1");
    }

    preload() {
    
    }

    create() {
      this.drawGrid();
    }

    update() {

    }
  }
  `;

export default {
  activeFile: "Scene1.js",
  visibleFiles: ["Scene1.js", "main.js"],
  files: {
    "Scene1.js": {
      code: sceneJs,
    },
    "main.js": {
      code: mainJs,
    },

    "index.html": {
      code: indexHtml,
    },
    ...packageFiles,
  },
};
