import { Header } from "@/components/format/Dialog";
import { Inbox } from "lucide-react";
import { ElementSubmissionsTable } from "@/lms/element/ElementSubmissionsTable";
import { ElementsResponse } from "@/lib/pb/types";
import { ClassFilter } from "../classes/ClassFilter";
import Types from "../elements/Types";
import { ScrollArea } from "@/components/ui/scroll-area";

export function ElementSubmissionsDialog({ item }: { item: ElementsResponse }) {
  const Type = Types[item.type];
  return (
    <div className="flex flex-col gap-2">
      <Header
        title={
          <div className="flex text-primary items-center gap-2">
            <Inbox className="h-6 w-6 me-2 text-4xl" /> {Type.Name}
            <ClassFilter className="text-sm ms-10" />
          </div>
        }
      />
      <ScrollArea className="h-[90dvh]">
        <div className="flex flex-col ">
          <ElementSubmissionsTable element={item} />
        </div>
      </ScrollArea>
    </div>
  );
}
