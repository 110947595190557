import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useQuery, classesListQuery } from "@/lib/pb/lms";

import { useCourse, useClassFilter, setClassFilter } from "@/lib/store";
import { cn } from "@/lib/utils";
import { Clock, ChevronsUpDown } from "lucide-react";

export function ClassFilter({className} : {className?:string}) {
  const course = useCourse();
  const { data: classes, ...classesQuery } = useQuery(
    classesListQuery(course?.id),
  );

  const classFilter = useClassFilter();
  const allClassLabel = `All (${classes?.length ? classes.length : 0})`
  const label = !classFilter ? allClassLabel: classFilter.name;

  return (
    <div className={cn("flex gap-2 items-center", className)}>
      <div className="flex gap-1 text-muted-foreground ">
        <Clock size="14" />
        <div>Class: </div>
      </div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="h-8 w-60 text-muted-foreground">
            <Label label={label} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => setClassFilter(null)}>
            <div className="font-bold">{allClassLabel}</div>
          </DropdownMenuItem>
          {classes ? (
            classes.map((classItem) => (
              <DropdownMenuItem
                key={classItem.id}
                onClick={() => setClassFilter(classItem)}
              >
                {classItem.name}
              </DropdownMenuItem>
            ))
          ) : (
            <DropdownMenuItem>Loading...</DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

function Label({ label }: { label: string }) {
  return (
    <div className="flex items-center gap-2 w-full  ">
      <div className=" text-sm tracking-tight truncate">{label}</div>
      <ChevronsUpDown className="ms-auto h-4 w-4" />
    </div>
  );
}
