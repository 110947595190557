import * as React from "react";
import {
  Select as ShadSelect,
  SelectContent,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FieldApi, FormApi } from "@tanstack/react-form";
import Field from "@/components/form/Field";

export interface SelectProps {
  className?: string;
  placeholder?: string;
  children: React.ReactNode;
  field?: FieldApi<any, any, any, any>;
  type? : string;
}

export { SelectItem } from "@/components/ui/select";

export function Select({
  field,
  className,
  placeholder,
  children,
  type,
  ...props
}: SelectProps) {
  //console.log(props, field?.state.value)
  return (
    <ShadSelect
      id={field?.name}
      name={field?.name} 
      value={type == "number" ?  field?.state.value.toString() : field?.state.value}
      onBlur={field?.handleBlur}
      onValueChange={(e) => {
        // console.log(typeof e)
        // console.log(e)
        field?.handleChange(type == "number" ? parseInt(e) : e);
      }}
      {...props}
    >
      <SelectTrigger className={className}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent>{children}</SelectContent>
    </ShadSelect>
  );
}

export interface SelectFieldProps {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
  children: React.ReactNode;
  form: FormApi<any, any>;
  type? : string;
  disabled?: boolean;
}

export const SelectField = ({
  form,
  className,
  name,
  label,
  children,
  type,
  ...props
}: SelectFieldProps) => {
  return (
    <Field
      form={form}
      className={className}
      name={name}
      label={label}
      validator={null}
      disabled={props.disabled}
      children={(field) => (
        <Select field={field} {...props} type={type}>
          {children}
        </Select>
      )}
    />
  );
};
