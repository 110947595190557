import { useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { PaginationTable, fuzzySort, fuzzyFilter } from "@/components/table";
import { enrollmentsListQuery } from "@/lib/pb/lms";

import { ColumnDef } from "@tanstack/react-table";
import {
  CoursesResponse,
  EnrollmentsResponse,

} from "@/lib/pb/types";
import { format, formatRelative } from "date-fns";
import { RoleNames } from "@/lib/roles";
//import { useClassFilter } from "@/lib/store";
import { Avatar } from "@/components/format/Avatar";
import { DialogButton } from "@/components/format/Buttons";
import { ArrowLeftRight, Pencil, X } from "lucide-react";
import { UserEditForm } from "../user/UserAdminForms";
import { EnrollmentEditForm, EnrollmentDeleteForm } from "../user/EnrollmentAdminForms";


//Each of these is an enrollment
export const USERS_COLUMNS: ColumnDef<EnrollmentsResponse>[] = [
  {
    accessorFn: (row) => `${row.expand.userId.name}`,
    id: "className", //why?? TODO?? 
    header: () => <span>Name</span>,
    cell: (info) => {
      const name = info.getValue() as string;
      return (
        <DialogButton
          icon={Pencil}
          item={info.row.original.expand.userId}
          contents={UserEditForm}
          label={<div className="flex gap-2 items-center">
            <Avatar user={info.row.original.expand.userId} />
            {name}

          </div>}
          className="mx-auto"
          variant="ghost"
        />
        
      );
    },
    meta: { filterVariant: "text", filterName: "Name" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  {
    accessorKey: "expand.userId.email",
    header: () => "Email",
    meta: { filterVariant: "text", filterName: "Email" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  {
    accessorKey: "expand.userId.role",
    header: () => "Role",
    cell: (info) => {
      const value = info.getValue() as keyof typeof RoleNames;
      return value + " " + RoleNames[value];
    },
    meta: { filterVariant: "multiselect", filterName: "Role" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "expand.classId.name",
    header: () => "Class",
    cell: (info) => {
      const value = info.getValue() ;
      return value ;
    },
    meta: { filterVariant: "multiselect", filterName: "Class" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "expand.teamId.name",
    header: () => "Team",
    cell: (info) => {
      const value = info.getValue() ;
      return value ;
    },
    meta: { filterVariant: "multiselect", filterName: "Team" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "created",
    header: () => "Created",
    cell: (info) => {
      const value = info.getValue() as string;
      return value ? formatRelative(value, new Date()) : "";
    },
    sortingFn: "datetime",
  },
  {
    id: "action",
    header: () => "Modify Enrollment",
    cell: ({ row }) => (
      <div className="flex gap-4">
        <DialogButton
          icon={ArrowLeftRight}
          item={row.original}
          contents={EnrollmentEditForm}
          className=""
          variant="ghost"
        />
        <DialogButton
          icon={X}
          item={row.original}
          contents={EnrollmentDeleteForm}
          className=""
          variant="ghost"
        />
      </div>
    ),
  },
];

export function CourseEnrollmentsTable({
  course,
}: {
  course: CoursesResponse;
}) {
  const { id: courseId } = course;
  const [data, setData] = useState<any>();
//  const cls = useClassFilter();

 // const filter = cls ? `classId="${cls.id}"` : `courseId="${courseId}"`;
  const filter = `courseId="${courseId}"`;
  const {
    data: enrollments,
    error,
    isPending,
  } = useQuery(enrollmentsListQuery(filter));


  useEffect(()=>{
    //process enrollments
    if(!enrollments)return
    enrollments.forEach(e=> {
      if(!e.teamId) {
        e.expand.teamId = {
          id: "",
          name: "unassigned",
        }
      }
    })
    setData([...enrollments])
  }, [enrollments])

  if (error) return "An error has occurred: " + error.message;
  const columns = useMemo(() => USERS_COLUMNS, []);

  return (
    <div className="p-2 w-full m-auto max-w-9xl grid gap-4">
      <PaginationTable
        data={data}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
      />
    </div>
  );
}
