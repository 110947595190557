import type { useForm, FieldApi } from "@tanstack/react-form";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { zodValidator } from "@tanstack/zod-form-adapter";

export default function Field({
  form,
  name,
  label,
  className,
  validator,
  children,
  disabled,
  ...props
}: {
  form: ReturnType<typeof useForm>;
  name: string;
  label?: React.ReactNode | string;
  className?: string;
  validator?: any;
  children: (field: FieldApi<any, any, any, any>) => React.ReactNode;
  disabled?: boolean;
}) {
  return (
    <form.Field
      name={name}
      validators={validator ? { onChange: validator } : undefined}
      children={(field: FieldApi<any, any, any, any>) => (
        <div className="grid gap-2">
          <div className={className}>
            {label ? (
              <Label
                className={cn(
                  "flex font-medium items-center",
                  disabled ? "opacity-50" : "",
                )}
                htmlFor={field.name}
              >
                {label}
              </Label>
            ) : null}
            {children(field)}
          </div>
          <div className="text-red-500 text-sm">
            {field.state.meta.isTouched && field.state.meta.errors.length ? (
              <em>{field.state.meta.errors.join(",")}</em>
            ) : null}
            {field.state.meta.isValidating ? "Validating..." : null}
          </div>
        </div>
      )}
      {...props}
    />
  );
}

//{labelJSX ? labelJSX : null}
// export default function SwitchField({
//   form,
//   name,
//   label,
//   labelJSX,
//   className,
//   validator,
//   children,
// }: {
//   form: ReturnType<typeof useForm>;
//   name: string;
//   label?: JSX.Element;
//   labelJSX?: JSX.Element;
//   className?: string;
//   validator?: any;
//   children: (field: FieldApi<any, any, any, any>) => React.ReactNode;
// }) {
//   return (
//     <form.Field
//       name={name}
//       validators={{
//         onChange: validator,
//       }}
//       children={(field: FieldApi<any, any, any, any>) => (
//         <div className={className}>
//           {label ? (
//             <Label className="font-medium" htmlFor={field.name}>
//               {label}
//             </Label>
//           ) : null}
//           {labelJSX ? labelJSX : null}
//           {children(field)}
//           {field.state.meta.touchedErrors ? (
//             <em className="text-xs text-red-500">
//               {field.state.meta.touchedErrors}
//             </em>
//           ) : null}
//         </div>
//       )}
//     />
//   );
// }

// <Switch
//   className="scale-75"
//   id="dark-mode"
//   checked={isDark}
//   onCheckedChange={toggle}
// />
// <Label htmlFor="dark-mode">
//   {isDark ? <Moon className="h-4" /> : <Sun className="h-4" />}
// </Label>
