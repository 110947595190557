import * as React from "react";
import { cn } from "@/lib/utils";
import { LoaderCircle } from "lucide-react";

const spinnerVariants = "rounded-full animate-spin";

interface SpinnerProps extends React.HTMLAttributes<SVGSVGElement> {
  className?: string;
}

const Spinner = React.forwardRef<SVGSVGElement, SpinnerProps>((props, ref) => {
  const { className, ...rest } = props;
  return (
    <LoaderCircle
      ref={ref}
      className={cn(className, spinnerVariants)}
      {...rest}
    />
  );
});
Spinner.displayName = "Spinner";

export { Spinner };
