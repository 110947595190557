export const Heights = {
  full: "100dvh",
  xxxl: "900px",
  xxl: "800px",
  xl: "700px",
  lg: "600px",
  md: "500px",
  sm: "400px",
  xs: "300px",
  xxs: "200px",
  xxxs: "100px",
};

export const Widths = {
  full: "w-full",
  xxxl: "w-full max-w-7xl",
  xxl: "w-full max-w-6xl",
  xl: "w-full max-w-5xl",
  lg: "w-full max-w-4xl",
  md: "w-full max-w-3xl",
  sm: "w-full max-w-2xl",
  xs: "w-full max-w-md",
  xxs: "w-full max-w-xs",
  xxxs: "w-full max-w-48",
};