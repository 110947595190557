import { toast } from "sonner";

import {
  useMutation,
  useQueryClient,
  QueryKey,
  MutationFunction,
} from "@tanstack/react-query";

export function useToastMutation<TVariables, TResult>(
  mutationFn: MutationFunction<TResult, TVariables>,
  key?: QueryKey,
  action?: string,
  message?: string,
) {
  const queryClient = useQueryClient();

  return useMutation<TResult, Error, TVariables>({
    mutationFn: mutationFn,
    onError: (e) => {
      showError(e, `${action} Failed`);
    },
    onSuccess: (mutationResult: TResult, variables: TVariables) => {
      showSuccess(`${action} Successful`, message);
      key && queryClient.setQueryData(key, mutationResult);
    },
  });
}

export function showError(error: any, title: string) {
  let message =
    error instanceof Error ? error.message : "An unknown error occurred";

  if (error.response?.data) {
    message = "";
    const data = error.response.data;
    Object.keys(data).forEach((key) => {
      message += `\n${key}: ${data[key].message}. `;
    });
  }

  toast.error(title, {
    description: message,
  });
}

export function showSuccess(title: string, description?: string) {
  toast.success(title, { description });
}


/*

(oldData) => {
  return oldData.map((user) => {
    if (user.id === variables.userId) {
      return { ...user, name: data.name };
    }
    return user;
  });

(existingExpenses) => ({
  ...existingExpenses,
  expenses: existingExpenses!.expenses.filter((e) => e.id !== id),
})

export function useEditMutation(collection) {
  const { data: cache } = useFetchAll(collection);
  const mutationFn = async ({id, set}) => {
    //console.log('editing ' + collection + ' with ' + id, set)
    const result = await editFn(collection, id, set);
    return collection === 'course' ? result : {...cache, [id]: result};
  }
  return useLmsMutation(mutationFn, [collection], `editing ${collection}`)
}


export function useLmsMutation(mutationFn, key, message) {
  const {language} = useLanguage();                                                     
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onError: err => handleError(err, `LMS Mutation Error: ${message}` ), 
    onSuccess: async (data, args) => {
      if(key) {
        if (key[1] == 'id' && data.id) key[1] = data.id //add element uses this
        if(data.newId) {
          const {newId, ...rest} = data;
          data = rest;
        }
        const collection = key[0];
       // const open = collection == 'course' || collection == 'units' || collection == 'classes';
        //add language.fetch key to query data
        if (paramFields[collection]) key = [...key, language.fetch] //translatable
        const queryKey = _addUserIdtoKey(key)
        const res = await queryClient.setQueryData(queryKey, data);
      }
      return args;
    }
  });
}
*/
