import { Navigate, createFileRoute } from "@tanstack/react-router";
import { useLesson } from "@/lib/store";

export const Route = createFileRoute(
  "/_auth/course/$courseId/$unitId/$lessonId/",
)({
  component: LessonComponent,
});

function LessonComponent() {
  const { courseId, unitId, lessonId } = Route.useParams();
  const lesson = useLesson();

  if (!lesson) return <div>No lesson: {lessonId}</div>;

  if (lesson.id !== lessonId) return <div>Lesson IDs do not match</div>; //wait for ids to sync

  const sections = lesson.children;

  if (!sections || sections.length < 1)
    return <div className="w-full text-center pt-16">No sections available.</div>;

  return (
    <Navigate
      to={`/course/${courseId}/${unitId}/${lessonId}/${sections[0].id}`}
    />
  );
}
