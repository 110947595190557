import { zodValidator } from "@/components/form";
import { useDeleteCourseMutation } from "@/lib/pb/lms";
import { CoursesResponse, CoursesRecord } from "@/lib/pb/types";
import { ContentsProps } from "@/components/format/Dialog";
import { ConfirmDeleteForm } from "@/components/form/Form";

export function CourseDeleteForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  if (!item) return;

  const { mutateAsync: deleteCourse, isPending } = useDeleteCourseMutation();

  const options = {
    validatorAdapter: zodValidator(),
    defaultValues: { name: "" },
    onSubmit: async ({ value }: { value: CoursesRecord }) => {
      if (isPending) return;
      if (value.name !== item.name) return;
      await deleteCourse({ id: item.id }).catch((e) => console.log(e));
      onComplete();
    },
  };

  return (
    <ConfirmDeleteForm
      formOptions={options}
      match={item.name}
      onComplete={onComplete}
      type="Course"
      fieldName="name"
    />
  );
}
