import phaserFiles from "./phaser";
const scene2 = `class Scene2 extends SimpleScene {
  constructor() {
    super("Scene2");
  }

  create() {
    // Display "You Win!" text
    this.win = this.add.text(640, 300, "You Win!\\nHits: " + data.hits, 0xffffff, 64);
    this.win.setOrigin(0.5);
    this.win.enableClick()
  }

  update() {
    if (this.win.wasReleased()) {
      this.scene.start("Scene1")
    }
  }
}`;

const scene1 = `class Scene1 extends SimpleScene {
  constructor() {
    super("Scene1");
  }
  preload() {
    let path = "https://phaser.portl.page/assets/";
    this.load.image("ball", path + "orb-blue.png");
    this.load.image("hole", path + "/orb-green.png");
    this.load.image("bg", path + "/mountains.png");
    this.load.image("box", path + "/block.png");
    this.load.matterSVG("moon", path + "/svg/moon.svg");
  }
  create() {
    this.matter.setBounds();
    this.matter.setGravity(1);
   // this.drawGrid();
    this.bg = this.add.sprite(640, 360, "bg");
    this.bg.setScale(1.4, 1.1)
    this.bg.depth = -1
    // Create the hole as a regular non-physics object
    this.hole = this.add.sprite(1012, 700, "hole");

    this.ball = this.add.sprite(100, 500, "ball");
    this.ball.enablePhysics();
    this.ball.setCircle();
    this.ball.setBounce(0.9);
    this.ball.setFriction(0.005);
    this.ball.enableTrace();

    this.groundL = this.createWall(500, 700, 1000, 150);
    this.groundR = this.createWall(1200, 700, 350, 150);
    this.hill = this.createWall(500, 640, 500, 500);
    this.hill.setAngle(45);
    this.moon1 = this.add.matterSVG(900, 350, "moon");
    this.moon1.setStatic(true);
    this.moon1.angle = -45

    for(let i = 0; i < 4; i++) {
      let b = this.add.sprite(1150, 605 - (i * 32), "box")
      b.enablePhysics();
      b.setScale(.4)
      b.setFriction(0.5);
      b.setMass(0.1);

    }

    this.cameras.main.zoom -= 0.07;
    this.line = this.add.line(0, 0, 0, 0, 0xffffff, 4);
    data.hits = 0
    this.info = this.add.text(50, 50, "Hits: 0", 0x000000, 40)
  }

  createWall(x, y, w, h) {
    let wall = this.add.rectangle(x, y, w, h, 0x00aa00);
    wall.enablePhysics();
    wall.setStatic(true);
    return wall;
  }

  update() {

    let pointer = this.getPointer();
    if (pointer.wasReleased()) {
      let distance = this.ball.distanceTo(pointer);
      let velocity = -0.15 * distance;
      let angle = this.ball.angleTo(pointer);
      if (velocity < -30) { 
        velocity = -30;
      }
      this.ball.clearTrace();
      this.ball.launch(velocity, angle);
      data.hits += 1;
      this.info.setText("Hits: " + data.hits)
    }

    this.line.clear();
    // Draw a line from the ball to the pointer if the pointer is down
    if (pointer.isDown) {
      this.line.setPoints(this.ball, pointer);
    }
    if (this.ball.intersects(this.hole)) {
      this.scene.start("Scene2");
    }
  }
}
`;

export default {
  ...phaserFiles,
  files: {
    ...phaserFiles.files,
    "Scene1.js": {
      code: scene1,
    },
    "Scene2.js": {
      code: scene2,
    },
  },
};
