import { Cloud, CloudUpload, Folder } from "lucide-react";
import { Header } from "@/components/format/Dialog";
import { useUser } from "@/lib/store";
import { FileUploader } from "@/lms/files/FileUploader";
import { StorageCard } from "@/lms/files/StorageCard";
import { userFilesListQuery } from "@/lib/pb/files";
import { useQuery } from "@tanstack/react-query";
import { UserFilesTable } from "../files/FilesTable";
import { Separator } from "@/components/ui/separator";
import { useState, useEffect } from "react";
import { TooltipDialogButton } from "@/components/format/Buttons";
import { ScrollArea } from "@/components/ui/scroll-area";

const MAX_USAGE = 20;

export function CloudFiles() {
  const user = useUser();
  const {
    data: files,
    isPending,
    error,
    refetch,
    ...eQuery
  } = useQuery(userFilesListQuery(user?.id));

  const [usage, setUsage] = useState(0);

  useEffect(() => {
    if (files) {
      let total = 0;
      files.forEach((file) => {
        total += file.size;
      });
      setUsage(total);
    }
  }, [files]);

  return (
    <TooltipDialogButton icon={Cloud} info="Cloud Files" iconSize="h-5 w-5">
      <div className="flex flex-col w-full h-screen gap-6">
        <Header title={<Title />} description={<Description />} />
        <ScrollArea className="">
          <div className="h-[83dvh] flex flex-col w-full gap-6">
            <div className="flex gap-6 ">
              <FileUploader usage={usage} maxUsage={MAX_USAGE} />
              <Separator orientation="vertical" />
              <StorageCard
                className="w-[300px] h-[100px]"
                usage={usage}
                maxUsage={MAX_USAGE}
              />
            </div>
            <Separator />
            <div>
              <div className="tracking-tight text-xl flex gap-4 items-center ms-4 mb-2">
                <Folder />
                <div>Your Uploaded Files</div>
              </div>

              <UserFilesTable
                files={files ? files : []}
                refetch={refetch}
                isPending={isPending}
              />
            </div>
          </div>
        </ScrollArea>
      </div>
    </TooltipDialogButton>
  );
}

function Title() {
  return (
    <div className="flex gap-4 items-center text-2xl">
      <CloudUpload className="h-6 w-6" /> Cloud Files
    </div>
  );
}

function Description() {
  return (
    <div className="max-w-4xl">
      Upload and access files you need for your playgrounds. File sizes are
      limited to 5MB. Files are subject to moderation and removal according to
      school policy. Use the Copy URL button to copy the URL to your file, and
      paste it into your code.
    </div>
  );
}
