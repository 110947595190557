export const RoleNames = {
  900: "administrator",
  800: "developer",
  700: "editor",
  600: "translator",
  500: "instructor",
  400: "teacher",
  350: "coach-multiclass",
  300: "coach",
  200: "student",
  150: "student-limited",
  100: "authenticated",
  0: "public",
}

// Function to reverse keys and values in an object
function reverseObject(obj: Record<number, string> ) {
  const reversed: Record<string, number> = {};
  for (const key in obj) {
    reversed[obj[key]] = parseInt(key);
  }
  return reversed;
}

// Create a reversed roles object once
export const ROLES = reverseObject(RoleNames);