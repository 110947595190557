import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useState } from "react";
import { ClassesTable } from "@/lms/classes/ClassesTable";
import { ClassAddForm } from "./ClassAdminForm";
import { FullDialogButton } from "@/components/format/Buttons";
import { Plus } from "lucide-react";
import { CoursesResponse } from "@/lib/pb/types";

export function ClassesManager({ course }: { course: CoursesResponse }) {
  const [isActive, setIsActive] = useState(true);
  return (
    <Tabs
      onValueChange={(value) => setIsActive(value == "active")}
      defaultValue="active"
      className="border rounded-xl shadow-xl py-4"
    >
      <div className="grid gap-4 mx-6 mt-2 pb-24">
        <div className="flex gap-6 flex-wrap">
          <div className="flex font-bold text-2xl my-auto px-4">Classes</div>
          <FullDialogButton
            icon={Plus}
            label="Create a new class"
            item={course}
            contents={ClassAddForm}
            className="shadow-md"
          />

          <TabsList className="border">
            <TabsTrigger className="w-48" value="active">
              Active
            </TabsTrigger>
            <TabsTrigger className="w-48" value="inactive">
              Inactive
            </TabsTrigger>
          </TabsList>
        </div>

        <ClassesTable course={course} active={isActive} />
      </div>
    </Tabs>
  );
}
// <CardHeader className="flex flex-row items-start bg-muted/50">
//   <div className="grid gap-0.5">
//     <CardTitle className="group flex items-center gap-2 text-2xl">
//       Classes
//       <Button
//         size="icon"
//         variant="outline"
//         className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
//       >
//         <Copy className="h-3 w-3" />
//         <span className="sr-only">Copy Order ID</span>
//       </Button>
//     </CardTitle>
//     <CardDescription>Choose an available class</CardDescription>
//   </div>
//   <div className="ml-auto flex items-center gap-1">
//     <ClassAddDialog courseId={courseId} />
//   </div>
// </CardHeader>
// Updated <time dateTime="2023-11-23">November 23, 2023</time>
