import {
  Trash2,
  Copy,
  Languages,
  Pencil,
  EyeOff,
  Eye,
  Users,
} from "lucide-react";
import { ROLES } from "@/lib/roles";
import { Badge } from "@/components/ui/badge";
import { CourseDeleteForm } from "@/lms/course/CourseDeleteForm";
import { CourseCopyForm } from "@/lms/course/CourseCopyForm";
import {
  FullDialogButton,
  DialogButton,
  IconButton,
  IconMenuItem,
  MenuButton,
} from "@/components/format/Buttons";
import {
  CourseEditForm,
  CourseTranslateForm,
} from "@/lms/course/CourseAdminForms";
import { Link } from "@/components/nav/Link";
import { useLanguage, getLanguageName } from "@/lib/lang";
import { useEditCourseMutation, useUser } from "@/lib/pb/lms";

export default function CourseAdminMenu({ course }: { course: any }) {
  const language = useLanguage();
  const { mutateAsync: edit, isPending } = useEditCourseMutation(course.id);

  return (
    <div className="grid">
      {course.active ? (
        <Badge className="mx-auto mb-3 text-md">Active</Badge>
      ) : (
        <Badge className="mx-auto mb-3 text-md" variant="secondary">
          Not Active
        </Badge>
      )}
      <FullDialogButton
        label="Edit Course"
        icon={Pencil}
        item={course}
        contents={CourseEditForm}
        variant="ghost"
        className="h-8 lg:justify-start"
      />
      <FullDialogButton
        label={`Translate ${language == "en" ? "Course" : "to " + getLanguageName(language)}`}
        icon={Languages}
        item={course}
        contents={CourseTranslateForm}
        variant="ghost"
        className="h-8 lg:justify-start"
        disabled={!language || language == "en"}
      />
      <FullDialogButton
        label="Manage Enrollments"
        icon={Users}
        item={course}
        contents={CourseEnrollmentsDialog}
        variant="ghost"
        className="h-8 lg:justify-start"
      />

      <DialogButton
        label="Copy Course"
        icon={Copy}
        item={course}
        contents={CourseCopyForm}
        variant="ghost"
        className="h-8 lg:justify-start"
      />

      <DialogButton
        label="Delete Course"
        icon={Trash2}
        item={course}
        contents={CourseDeleteForm}
        disabled={course.active}
        variant="ghost"
        className="h-8 lg:justify-start"
      />
      <IconButton
        label={course.active ? "Make Inactive" : "Make Active"}
        icon={course.active ? EyeOff : Eye}
        onClick={async () => {
          if (isPending) return;
          await edit({ id: course.id, data: { active: !course.active } });
        }}
        variant="ghost"
        className="h-8 lg:justify-start"
      />
      <div className="h-12"></div>
      <Link
        to={"/course/" + course.id}
        className="mt-auto mx-auto border items-center"
      >
        Go to course
      </Link>
    </div>
  );
}

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { DialogForm, useDialog } from "@/components/format/Dialog";
import { CoursesResponse } from "@/lib/pb/types";
import { CourseEnrollmentsDialog } from "./CourseEnrollmentsDialog";
import { Button } from "@/components/ui/button";

export function CourseAdminDropdownMenu({ item }: { item: CoursesResponse }) {
  const language = useLanguage();
  const user = useUser();
  const isAdmin = user?.role === ROLES.administrator;
  const isInstructor = user?.role >= ROLES.instructor;

  const editDialog = useDialog<HTMLDivElement>();
  //const deleteDialog = useDialog<HTMLDivElement>();
  const translateDialog = useDialog<HTMLDivElement>();
  const enrollmentsDialog = useDialog<HTMLDivElement>();
  const type = "Course";
  const { id, active } = item;
  //const { mutateAsync: edit, isPending } = useEditCourseMutation(item.id);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className={""}>
          Admin
          <span className="sr-only">Menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        align="start"
        className="border-2 border-primary/75"
      >
        {isAdmin && (
          <IconMenuItem
            triggerProps={editDialog.triggerProps}
            label={`Edit ${type}`}
            icon={Pencil}
          />
        )}
        {isAdmin && (
          <IconMenuItem
            triggerProps={translateDialog.triggerProps}
            label={`Translate ${language == "en" ? "Course" : "to " + getLanguageName(language)}`}
            icon={Languages}
            disabled={!language || language == "en"}
          />
        )}
        <IconMenuItem
          triggerProps={enrollmentsDialog.triggerProps}
          label={`Manage Enrollments`}
          icon={Users}
        />
        {/* <IconMenuItem
          triggerProps={deleteDialog.triggerProps}
          label={`Delete ${type}`}
          icon={Trash2}
          disabled={active}
        />
        <IconMenuItem
          label={`${active ? "Deactivate" : "Activate"} ${type}`}
          icon={active ? EyeOff : Eye}
          onClick={async () => {
            if (isPending) return;
            await edit({ id, data: { active: !active } });
          }}
        /> */}
        <DropdownMenuItem className="text-muted-foreground font-xs">
          id: {id}
        </DropdownMenuItem>
      </DropdownMenuContent>

      <DialogForm
        item={item}
        dialogProps={editDialog.dialogProps}
        fullScreen={true}
        contents={CourseEditForm}
      />
      <DialogForm
        item={item}
        dialogProps={translateDialog.dialogProps}
        fullScreen={true}
        contents={CourseTranslateForm}
      />
      <DialogForm
        item={item}
        dialogProps={enrollmentsDialog.dialogProps}
        fullScreen={true}
        contents={CourseEnrollmentsDialog}
      />
      {/* <DialogForm
        item={item}
        dialogProps={deleteDialog.dialogProps}
        className="sm:max-w-md"
        contents={CourseDeleteForm}
      /> */}
    </DropdownMenu>
  );
}

function MenuContent() {}

//{...editDialog.triggerProps}
// <DropdownMenuLabel>Admin Course Menu</DropdownMenuLabel>
// <DropdownMenuSeparator />
