import {
  useQuery,
  lessonsTreeQuery,
  unitsListQuery,
  useUnitsTranslation,
  useLessonsTranslation,
  useSectionsTranslation,
  sectionsTreeQuery,
  elementsTreeQuery,
  courseDetailQuery,
  useCourseTranslation,
} from "@/lib/pb/lms";
import { useMemo } from "react";
import { translateItem, translateItems } from "@/lib/lang";
import { createMap } from "@/lib/pb";
import { useCourse } from "@/lib/store";
import {
  ElementsResponse,
  LessonsResponse,
  SectionsResponse,
  UnitsResponse,
} from "@/lib/pb/types";

//course would only have or need children
type ExtrasType = {
  index?: string;
  url?: string | null;
  next?: any;
  previous?: any;
  parent?: any;
  children?: any[] | null;
  siblings?: any[] | null;
  compiled?: any[];
  original?: any;
};

type UnitsResponseX = (ExtrasType & UnitsResponse) | null;
type LessonsResponseX = (ExtrasType & LessonsResponse) | null;
type SectionsResponseX = (ExtrasType & SectionsResponse) | null;
type ElementsResponseX =
  | ({ parent: SectionsResponseX; siblings?: any[] | null } & ElementsResponse)
  | null;

export function useCourseTree(courseId: string) {
  // const { unitId, lessonId, sectionId } = useParams({ strict: false });
  // let currentUnit, currentLesson, currentSection = null;

  // COURSE
  const { data: cData, ...cQuery } = useQuery(courseDetailQuery(courseId));
  const { data: CT, ...CTQuery } = useCourseTranslation(courseId);
  //UNITS
  const { data: uData, ...uQuery } = useQuery(unitsListQuery(courseId));
  const { data: UT, ...UTQuery } = useUnitsTranslation(courseId);
  //LESSONS
  const { data: lData, ...lQuery } = useQuery(lessonsTreeQuery(courseId));
  const { data: LT, ...LTQuery } = useLessonsTranslation(courseId);
  //SECTIONS
  const { data: sData, ...sQuery } = useQuery(sectionsTreeQuery(courseId));
  const { data: ST, ...STQuery } = useSectionsTranslation(courseId);
  //ELEMENTS
  const { data: eData, ...eQuery } = useQuery(elementsTreeQuery(courseId));

  const isPending =
    cQuery.isPending ||
    uQuery.isPending ||
    lQuery.isPending ||
    sQuery.isPending ||
    eQuery.isPending;

  // const isTranslationPending = //blocks when english
  //   CTQuery.isPending ||
  //   UTQuery.isPending ||
  //   LTQuery.isPending ||
  //   STQuery.isPending;

  const error =
    cQuery.error ||
    CTQuery.error ||
    uQuery.error ||
    UTQuery.error ||
    lQuery.error ||
    LTQuery.error ||
    sQuery.error ||
    STQuery.error ||
    eQuery.error;
  if (!isPending) {
    if (cQuery.error) throw new Error("Failed to fetch course");
    if (CTQuery.error) throw new Error("Failed to fetch course translation");
    if (uQuery.error) throw new Error("Failed to fetch units");
    if (UTQuery.error) throw new Error("Failed to fetch unit translations");
    if (lQuery.error) throw new Error("Failed to fetch lessons");
    if (LTQuery.error) throw new Error("Failed to fetch lesson translations");
    if (sQuery.error) throw new Error("Failed to fetch sections");
    if (STQuery.error) throw new Error("Failed to fetch section translations");
    if (eQuery.error) throw new Error("Failed to fetch elements");
  }

  const derivedState = useMemo(() => {
    const searchResults: any[] = [];

    function addSearchResult(item) {
      searchResults.push(item)
    }
    if (isPending || error) return null;

    //console.log("=== recalculate course tree ===");

    const course = translateItem(cData, CT);
    const uTranslated = translateItems(uData, UT) as UnitsResponseX[];
    const lTranslated = translateItems(lData, LT);
    const sTranslated = translateItems(sData, ST);

    //create map instead of filtering
    const lessonMap = createMap(lTranslated, uTranslated, "unitId");
    const sectionMap = createMap(sTranslated, lTranslated, "lessonId");
    const elementMap = createMap(eData ?? [], sTranslated, "sectionId");
    course.url = `/course/${courseId}`;
    let index = 1;
    let sectionPrev: SectionsResponseX = null;
    let lessonPrev: LessonsResponseX = null;
    let unitPrev: UnitsResponseX = null;
    
    uTranslated.forEach((item) => {
      if (!item) return;
      item.parent = course;
      item.url = `${course.url}/${item.id}`;
      item.index = item.active ? (index++).toString() : "#";
      if (unitPrev) {
        unitPrev.next = item;
        item.previous = unitPrev;
      }
      //searchResults.push(`${item.index}, ${item.name}`);
      addSearchResult(item);
      unitPrev = item;
      const lessons = lessonMap.get(item.id) as LessonsResponseX[];
      let lessonIndex = 1;
      lessons?.forEach((lesson) => {
        if (!lesson) return;
        lesson.parent = item;
        lesson.url = `${item.url}/${lesson.id}`;
        lesson.index =
          item.index + "." + (lesson.active ? (lessonIndex++).toString() : "#");

        if (lessonPrev) {
          lessonPrev.next = lesson;
          lesson.previous = lessonPrev;
        }
        //searchResults.push({index:lesson.index, name:lesson.name);
        addSearchResult(lesson);
        lessonPrev = lesson;
        const sections = sectionMap.get(lesson.id) as SectionsResponseX[];
        let sectionIndex = 1;
        sections?.forEach((section) => {
          if (!section) return;
          section.parent = lesson;
          section.url = `${lesson.url}/${section.id}`;
          section.index =
            lesson.index +
            "." +
            (section.active ? (sectionIndex++).toString() : "#");

          if (sectionPrev) {
            sectionPrev.next = section;
            section.previous = sectionPrev;
          }
          //searchResults.push(`${section.index}, ${section.name}`);
          addSearchResult(section);
          sectionPrev = section;
          //ELEMENTS WILL NOT BE USED FOR PROGRESS TREE //DONT NEED TO DO THIS FOR REGULAR USERS
          // use section_progress istead
          const elements = elementMap.get(section.id) as ElementsResponseX[];
          elements?.forEach((element) => {
            if (!element) return;
            element.parent = section; //unused by full element fetch
            element.siblings = elements;
          });
          section.children = elements;
          section.siblings = sections;
        });
        lesson.children = sections;
        lesson.siblings = lessons;
        if (sections?.[0]) lesson.url = sections[0].url;
      });
      item.children = lessons;
      item.siblings = uTranslated;
      if (lessons?.[0]) item.url = lessons[0].url;
    });
    const units = [...uTranslated];
    course.children = units;
    course.units = arrayToObject(uTranslated);
    course.lessons = arrayToObject(lTranslated);
    course.sections = arrayToObject(sTranslated);
    //course.elements = arrayToObject(eData ?? []);

    return { units, course, searchResults };
  }, [CT, cData, uData, UT, lData, LT, sData, ST, eData]);

  // console.log(derivedState)

  return {
    // unit: currentUnit,
    // lesson: currentLesson,
    // section: currentSection,
    course: derivedState?.course,
    units: derivedState?.units,
    searchResults: derivedState?.searchResults,
    isPending,
    error,
  };
}

function arrayToObject(arr: Array<any>) {
  return arr.reduce((obj, item) => {
    obj[item.id] = item;
    return obj;
  }, {});
}

// COURSE TREE QUERY NOT USED??? Sorting can be done on backend
interface ActiveItem {
  active: boolean;
}

function seperateActive<T extends ActiveItem>(items: Array<T>) {
  return items.reduce<{ active: T[]; inactive: T[] }>(
    (acc, item) => {
      if (item.active) {
        acc.active.push(item as T);
      } else {
        acc.inactive.push(item as T);
      }
      return acc;
    },
    { active: [], inactive: [] },
  );
}

function sortInactive<T extends ActiveItem>(items: Array<T>) {
  const { active, inactive } = seperateActive(items);
  return active.concat(inactive);
}
