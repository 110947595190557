import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";

import { MoveButtons } from "@/lms/core/MoveButtons";
import {
  MenuButton,
  IconMenuItem,
  CopyCutButtons,
} from "@/components/format/Buttons";

import { DialogForm, useDialog } from "@/components/format/Dialog";

import {
  LessonEditForm,
  LessonDeleteForm,
  LessonTranslateForm,
  LessonPasteJsonForm,
} from "@/lms/lesson/LessonAdminForms";
import { LessonsResponse } from "@/lib/pb/types";
import { useLanguage } from "@/lib/lang";
import {
  Pencil,
  Languages,
  Trash2,
  Eye,
  EyeOff,
  SquareArrowUp,
  SquareArrowDown,
  ClipboardPaste,
} from "lucide-react";
import { SectionAddForm, SectionPasteJsonForm } from "../section/SectionAdminForm";
import { useEditLessonMutation } from "@/lib/pb/lms";
import { useClipboard, setClipboard } from "@/lib/store";
import { Rank } from "@/lms/core/Rank";
import { SectionPasteButton } from "../section/SectionPasteButton";
import { LessonPasteButton } from "./LessonPasteButton";

export default function LessonAdminMenu({ item }: { item: LessonsResponse }) {
  const language = useLanguage();
  const editDialog = useDialog<HTMLDivElement>();
  const deleteDialog = useDialog<HTMLDivElement>();
  const translateDialog = useDialog<HTMLDivElement>();
  const pasteJsonDialog = useDialog<HTMLDivElement>();
  const type = "Lesson";
  const {
    id,
    unitId,
    courseId,
    active,
    rank,
    children: sections,
    siblings: lessons,
  } = item;
  const { mutateAsync: edit, isPending } = useEditLessonMutation(
    courseId,
    unitId,
    id,
  );
  //console.log("LessonAdminMenu: item ", item)
  return (
    <DropdownMenu>
      <MenuButton id={id} size={4} active={active} />

      <DropdownMenuContent
        side="right"
        align="start"
        className="border-2 border-primary/75"
      >
        <div className="flex gap-1">
          {lessons && (
            <LessonPasteButton
              icon={SquareArrowUp}
              courseId={courseId}
              unitId={unitId}
              rank={Rank.above(id, lessons)}
            />
          )}
          {lessons && (
            <LessonPasteButton
              icon={SquareArrowDown}
              courseId={courseId}
              unitId={unitId}
              rank={Rank.below(id, lessons)}
            />
          )}
          {sections && (
            <SectionPasteButton
              icon={SquareArrowUp}
              courseId={courseId}
              lessonId={id}
              rank={Rank.top(null, sections)}
            />
          )}
          {sections && (
            <SectionPasteButton
              icon={SquareArrowDown}
              courseId={courseId}
              lessonId={id}
              rank={Rank.bottom(null, sections)}
            />
          )}
        </div>

        <MoveButtons edit={edit} item={item} items={lessons} />

        <SectionAddForm lesson={item} />

        <DropdownMenuSeparator />
        <IconMenuItem
          triggerProps={editDialog.triggerProps}
          label={`Edit ${type}`}
          icon={Pencil}
        />
        <IconMenuItem
          triggerProps={translateDialog.triggerProps}
          label={`Translate ${type}`}
          icon={Languages}
          disabled={!language || language == "en"}
        />
        <CopyCutButtons item={item} type={type} />
        <IconMenuItem
          triggerProps={deleteDialog.triggerProps}
          label={`Delete ${type}`}
          icon={Trash2}
        />
        <IconMenuItem
          label={active ? "Make Inactive" : "Make Active"}
          icon={active ? EyeOff : Eye}
          onClick={async () => {
            if (isPending) return;
            await edit({ id, data: { active: !active } });
          }}
        />
        <IconMenuItem
          triggerProps={pasteJsonDialog.triggerProps}
          label={`Paste JSON`}
          icon={ClipboardPaste}
        />
        <DropdownMenuItem className="text-muted-foreground font-xs">
          id: {id} ({rank})
        </DropdownMenuItem>
      </DropdownMenuContent>

      <DialogForm
        item={item}
        dialogProps={editDialog.dialogProps}
        className="sm:max-w-2xl"
        contents={LessonEditForm}
      />
      <DialogForm
        item={item}
        dialogProps={translateDialog.dialogProps}
        className="sm:max-w-full"
        contents={LessonTranslateForm}
      />
      <DialogForm
        item={item}
        dialogProps={deleteDialog.dialogProps}
        className="sm:max-w-md"
        contents={LessonDeleteForm}
      />
      <DialogForm
        item={item}
        dialogProps={pasteJsonDialog.dialogProps}
        className="sm:max-w-full"
        contents={SectionPasteJsonForm}
      />
    </DropdownMenu>
  );
}
