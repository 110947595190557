import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar";
import {
  Bold,
  Italic,
  Code,
  CodeSquare,
  TextQuote,
  ListPlus,
  List,
  ListOrdered,
  ListTodo,
  Link,
  Strikethrough,
  Table,
  Image,
  ChevronDown,
  Underline,
  Highlighter,
  Columns2,
  Columns3,
  Pilcrow,
  Mountain,
  Megaphone,
  Focus,
  Command,
  ListCollapse,
  RectangleEllipsis,
  MessageSquare,
} from "lucide-react";
import { AlertIcons } from "@/components/codehike/Alert";
import { EditorView } from "codemirror";

function MenubarButton({
  Icon,
  action,
  pre,
  post,
  text,
}: {
  Icon: any;
  action: Function;
  pre: string;
  post?: string;
  text?: string;
}) {
  return (
    <MenubarMenu>
      <MenubarTrigger
        asChild
        className="px-2 "
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          action(pre, post, text);
        }}
      >
        <div className="flex">
          {typeof Icon == "string" ? Icon : <Icon size={16} />}
        </div>
      </MenubarTrigger>
    </MenubarMenu>
  );
}

export function MDMenubar({ editorView }: { editorView: EditorView }) {
  //const [open, setOpen] = useState(false);
 // console.log("render menubar");

  const insert = (text: string) => {
    if (!editorView) return;
    const { from, to } = editorView.state.selection.main;
    editorView?.dispatch({
      changes: { from, to, insert: text },
    });
  };

  const insertStart = (text: string) => {
    if (!editorView) return;
    const { state } = editorView;
    const cursorPos = state.selection.main.head; // Get the cursor position
    const line = state.doc.lineAt(cursorPos); // Get the line at the cursor's position

    // Dispatch a transaction to append the text at the start of the line
    editorView.dispatch({
      changes: { from: line.from, insert: text },
    });
  };

  const toggle = (
    prependText: string,
    appendText: string = "",
    defaultText: string,
  ) => {
    if (!editorView) return;
    const { from, to } = editorView.state.selection.main;

    const selectedText =
      from === to ? defaultText : editorView.state.sliceDoc(from, to);

    if (!selectedText) return;

    let newText;
    // Check if selected text starts with prependText and ends with appendText
    if (
      selectedText.startsWith(prependText) &&
      selectedText.endsWith(appendText)
    ) {
      // Remove prependText and appendText
      newText = selectedText.slice(
        prependText.length,
        selectedText.length - appendText.length,
      );
    } else {
      // Add prependText and appendText
      newText = `${prependText}${selectedText}${appendText}`;
    }
    editorView.dispatch({
      changes: { from, to, insert: newText },
    });
  };

  const toggleLines = (prependText: string, appendText?: string) => {
    if (!editorView) return;

    const { state } = editorView;
    const { from, to } = state.selection.main;

    let changes = [];

    for (let pos = from; pos <= to; ) {
      let line = state.doc.lineAt(pos);
      // if (line.from >= from && line.to <= to) {
      const lineText = state.doc.sliceString(line.from, line.to);
      const alreadyPrepended = lineText.startsWith(prependText);

      if (alreadyPrepended) {
        // Remove the prependText if it already exists
        changes.push({
          from: line.from,
          to: line.from + prependText.length,
          insert: "",
        });
      } else {
        // Check appendText
        let newText = `${prependText}${lineText}`;
        if (appendText && !lineText.endsWith(appendText)) {
          newText += appendText;
        }
        // Add the prependText if it does not exist
        changes.push({
          from: line.from,
          to: line.to,
          insert: newText,
        });
      }

      pos = line.to + 1; // Move to the start of the next line
    }

    if (changes.length > 0) {
      editorView.dispatch({ changes });
    }
  };

  return (
    <Menubar className="bg-secondary/50 gap-0">
      <MenubarButton Icon={Bold} action={toggle} pre="**" post="**" />
      <MenubarButton Icon={Italic} action={toggle} pre="*" post="*" />
      <MenubarButton Icon={Underline} action={toggle} pre="<u>" post="</u>" />
      <MenubarButton
        Icon={Highlighter}
        action={toggle}
        pre="<mark>"
        post="</mark>"
      />
      <MenubarButton
        Icon={Strikethrough}
        action={toggle}
        pre="<s>"
        post="</s>"
      />
      <MenubarButton Icon={Command} action={toggle} pre="<kbd>" post="</kbd>" />
      <MenubarButton Icon={Code} action={toggle} pre="_js`" post="`_" />
      <MenubarButton
        Icon={CodeSquare}
        action={toggle}
        pre={"``` js main.js -ncaw\n"}
        post={"\n```"}
        text="console.log('Hello World')"
      />
      <MenubarButton Icon={"//"} action={toggleLines} pre="//" />
      <MenubarButton
        Icon={Link}
        action={insert}
        pre="[title](https://www.example.com)"
      />
      <MenubarButton
        Icon={Image}
        action={insert}
        pre="![alt text](http://www.example.com/image.jpg)"
      />
      <MenubarButton
        Icon={Table}
        action={insert}
        pre={`\n\n| Syntax      | Description | Test Text     |
| :---        |    :---:    |          ---: |
| Header      | Title       | Here's this   |
| Paragraph   | Text        | And more      |\n`}
      />
      <MenubarMenu>
        <MenubarTrigger className="px-2" asChild>
          <div>
            h1 <ChevronDown size={14} />
          </div>
        </MenubarTrigger>
        <MenubarContent>
          <MenubarItem onClick={(e) => toggleLines("# ")}>
            Heading 1 #
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("## ")}>
            Heading 2 ##
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("### ")}>
            Heading 3 ###
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("##### ")}>
            Heading 4 ####
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("###### ")}>
            Heading 5 #####
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="px-2" asChild>
          <div>
            <ListPlus size={16} /> <ChevronDown size={14} />
          </div>
        </MenubarTrigger>
        <MenubarContent>
          <MenubarItem onClick={(e) => toggleLines("> ", "  ")}>
            <MenuTitle Icon={TextQuote} title="Blockquote" />
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("- ")}>
            <MenuTitle Icon={List} title="Bulleted List" />
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("1. ")}>
            <MenuTitle Icon={ListOrdered} title="Ordered List" />
          </MenubarItem>
          <MenubarItem onClick={(e) => toggleLines("- [ ] ")}>
            <MenuTitle Icon={ListTodo} title="Task List" />
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="px-2" asChild>
          <div>
            <AlertIcons.important size={16} /> <ChevronDown size={14} />
          </div>
        </MenubarTrigger>
        <MenubarContent>
          <AlertMenuItem type="note" insert={insert} />
          <AlertMenuItem type="tip" insert={insert} />
          <AlertMenuItem type="important" insert={insert} />
          <AlertMenuItem type="warning" insert={insert} />
          <AlertMenuItem type="caution" insert={insert} />
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="px-2" asChild>
          <div>
            <Columns2 size={16} /> <ChevronDown size={14} />
          </div>
        </MenubarTrigger>
        <MenubarContent>
          <MenubarItem onClick={(e) => insert(twoColumn)}>
            <MenuTitle Icon={Columns2} title="2 Columns" />
          </MenubarItem>
          <MenubarItem onClick={(e) => insert(threeColumn)}>
            <MenuTitle Icon={Columns3} title="3 Columns" />
          </MenubarItem>
          <MenubarItem
            onClick={(e) =>
              insert(
                `\n<Accordion title='Accordion Title'>\nAccordion Content\n</Accordion>\n`,
              )
            }
          >
            <MenuTitle Icon={ListCollapse} title="Accordion" />
          </MenubarItem>
          <MenubarItem onClick={(e) => insert(lorem)}>
            <MenuTitle Icon={Pilcrow} title="Lorem ipsum" />
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
      <MenubarMenu>
        <MenubarTrigger className="px-2" asChild>
          <div>
            <Mountain size={16} /> <ChevronDown size={14} />
          </div>
        </MenubarTrigger>
        <MenubarContent>
          <MenubarItem
            onClick={(e) => insert("// !callout[/amet/] This is a callout")}
          >
            <MenuTitle Icon={Megaphone} title="Callout" />
          </MenubarItem>
          <MenubarItem onClick={(e) => insert("// !className line-through")}>
            <MenuTitle Icon={Columns3} title="Class Name" />
          </MenubarItem>
          <MenubarItem onClick={(e) => insert("// !focus(1:5)")}>
            <MenuTitle Icon={Focus} title="Focus" />
          </MenubarItem>
          <MenubarItem onClick={(e) => insert("// !mark(1:2) gold")}>
            <MenuTitle Icon={Highlighter} title="Mark" />
          </MenubarItem>
          <MenubarItem
            onClick={(e) => insert('// !fold[/className="(.*?)"/gm]')}
          >
            <MenuTitle Icon={RectangleEllipsis} title="Fold" />
          </MenubarItem>
          <MenubarItem
            onClick={(e) => insert("// !tooltip[/lorem/] description")}
          >
            <MenuTitle Icon={MessageSquare} title="Tooltip" />
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
}

const lorem = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`;

const twoColumn = `<div className="grid grid-cols-2 gap-4">
  <div>
    Column 1
  </div>
  <div>
    Column 2
  </div>
</div>`;

const threeColumn = `<div className="grid grid-cols-3 gap-4">
  <div className="sm:col-span-1 mx-auto">
    Column 1
  </div>
  <div className="sm:col-span-1 mx-auto">
    Column 2
  </div>
  <div className="sm:col-span-1 mx-auto">
    Column 3
  </div>
</div>`;

function MenuTitle({ Icon, title }: { Icon: any; title: string }) {
  return (
    <>
      <Icon size={16} />
      <div className="ps-2 pt-[2px]">
        <span className="capitalize">{title}</span>
      </div>
    </>
  );
}

function AlertMenuItem({ type, insert }: { type: string; insert: Function }) {
  const Icon = AlertIcons[type] || AlertIcons.note;
  return (
    <MenubarItem
      onClick={(e) =>
        insert(`\n<Alert type="${type}" title="">\nmessage\n</Alert>\n`)
      }
    >
      <MenuTitle Icon={Icon} title={type + " Alert"} />
    </MenubarItem>
  );
}
