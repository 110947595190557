import { useEffect, useRef, useState } from "react";
import { markdown, markdownLanguage } from "@codemirror/lang-markdown";
import { syntaxHighlighting, HighlightStyle } from "@codemirror/language";
import { vscodeDarkInit, vscodeLightInit } from "@uiw/codemirror-theme-vscode";
import { languages } from "@codemirror/language-data";
import { EditorView, minimalSetup, basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
//import { highlightActiveLine } from "@codemirror/view";
import { ScrollArea } from "@/components/ui/scroll-area";
import { AnyFunction } from "@/lib/types";
import CodeHike from "@/components/codehike/CodeHike";
import { tags } from "@lezer/highlight";
import { MDMenubar } from "@/components/form/MDMenubar";
import { useDarkMode } from "@/lib/store";
import { cn } from "@/lib/utils";

type MDEditorProp = {
  id?: string | undefined;
  name?: string | undefined;
  value?: string;
  onChange: AnyFunction;
  className?: string;
  disabled?: boolean;
  vertical?: boolean;
  size?: keyof typeof sizes;
};

const markdownHighlighting = HighlightStyle.define([
  {
    tag: tags.heading1,
    fontSize: "2em",
    fontWeight: "bold",
    // borderBottom: "1px solid gray",
  },
  {
    tag: tags.heading2,
    fontSize: "1.5em",
    fontWeight: "bold",
    //borderBottom: "1px solid gray",
  },
  {
    tag: tags.heading3,
    fontSize: "1.2em",
    fontWeight: "bold",
  },
]);
const themeOptions = { settings: { fontSize: "13px" } };

function Editor({ value, onChange, disabled, ...props }: MDEditorProp) {
  const editor = useRef<HTMLDivElement>(null);
  const [editorView, setEditorView] = useState<EditorView | null>(null);
  const isDark = useDarkMode();

  useEffect(() => {
    if (!editor.current) return;

    const startState = EditorState.create({
      doc: value,
      extensions: [
        basicSetup,
        //highlightActiveLine(),
        markdown({
          base: markdownLanguage,
          codeLanguages: languages,
          addKeymap: true,
          extensions: [],
        }),
        syntaxHighlighting(markdownHighlighting),
        isDark ? vscodeDarkInit(themeOptions) : vscodeLightInit(themeOptions),
        EditorView.lineWrapping,
        EditorView.updateListener.of((update) => {
          if (!disabled && update.docChanged) {
            onChange(update.state.doc.toString());
          }
        }),
        EditorState.readOnly.of(!!disabled),
      ],
    });

    const view = new EditorView({
      state: startState,
      parent: editor.current,
    });

    setEditorView(view);

    return () => {
      view.destroy();
    };
  }, [editor]);

  return (
    <div>
      {!disabled ? (
        <MDMenubar editorView={editorView} />
      ) : (
        <div className="h-10 bg-secondary/50 rounded-md"></div>
      )}

      <ScrollArea className="">
        <div ref={editor} {...props} />
      </ScrollArea>
    </div>
  );
}

//"grid md:grid-cols-[40%_58%] h-full gap-4 w-full"

const sizes = {
  xxl: ["lg:h-[705px]", "lg:h-[650px]"],
  xl: ["lg:h-[750px]", "lg:h-[750px]"],
  lg: ["lg:h-[555px]", "lg:h-[600px]"],
  md: ["lg:h-[405px]", "lg:h-[450px]"],
  sm: ["h-[255px]", "h-[300px]"],
  xs: ["h-[105px]", "h-[150px]"],
};

export function MDEditor({
  value,
  onChange,
  vertical = false,
  size = "lg",
  ...props
}: MDEditorProp) {
  return (
    <div className={cn("grid gap-4", vertical ? "" : "lg:grid-cols-[49%_50%]")}>
      <div className="border rounded-md bg-background ">
        <Editor
          className={cn(
            "h-[33vh] rounded-xl",
            vertical ? "" : sizes[size][0],
            props.disabled ? "opacity-50" : "",
          )}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>

      <ScrollArea
        className={cn(
          "my-auto border-4 border-info rounded-xl bg-background py-3 px-10 h-[33vh]",
          vertical ? "" : sizes[size][1],
        )}
      >
        {value && <CodeHike code={value} />}
      </ScrollArea>
    </div>
  );
}
