import {
  NameField,
  MarkdownField,
  ActiveSwitchField,
  UrlField,
  PublicAccessField,
} from "@/lms/core/LmsFields";
import { zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import { AnyFunction } from "@/lib/types";
import {
  useEditCourseMutation,
  useAddCourseMutation,
  useCourseTranslation,
  courseDetailQuery,
  useQuery,
} from "@/lib/pb/lms";
import { CoursesRecord, CoursesResponse } from "@/lib/pb/types";

import {
  BaseForm,
  TranslateForm,
  FormFieldsProps,
} from "@/components/form/Form";

import { ContentsProps } from "@/components/format/Dialog";
import { useLanguage, getLanguageName } from "@/lib/lang";
import { getOne } from "@/lib/pb";
import { TranslationReset } from "../translate/TranslationReset";

const COURSE_DEFAULTS = {
  name: "",
  description: "",
  imageUrl: "",
  details: "",
  resources:"",
  active: true,
  access: 0,
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;

  return (
    <div className="grid gap-4 py-4 px-1">
      <div className="flex flex-wrap gap-4 w-full">
        <NameField form={form} className="grid gap-2 " />
        <div className="basis-1/2">
          <UrlField
            name="imageUrl"
            label="Image URL"
            form={form}
            className="grid gap-2"
          />
        </div>
      </div>
      <MarkdownField
        form={form}
        name="description"
        label="Description"
        disabled={isOriginal}
        size="sm"
        vertical={locked}
      />
      <MarkdownField
        form={form}
        name="details"
        label="Details"
        disabled={isOriginal}
         size="lg"
        vertical={locked}
      />
      {!locked && (
        <div className="flex flex-wrap gap-4 xl:gap-6 w-full my-auto">
          <PublicAccessField
            label="Access (default: public)"
            form={form}
            className="grid gap-2 "
          />
          <ActiveSwitchField form={form} />
        </div>
      )}
    </div>
  );
}

export function CourseEditForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  if (!item) return;
  const { mutateAsync: edit, isPending } = useEditCourseMutation(item.id);

  //get full item with translation
  const { data: fullItem, tQuery } = useQuery(
    courseDetailQuery(item?.id, true),
  );
  const languages = Object.keys(fullItem?.translations ?? {});

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item?.name,
      description: item?.description,
      imageUrl: item?.imageUrl,
      active: item?.active,
      access: item?.access,
      details: item?.details,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      if (!item) return console.error("CourseEditForm: item is undefined");
      const { submitButton, ...rest } = value;
      const data = rest as CoursesRecord;
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  });
  return (
    <BaseForm
      title="Edit Course"
      form={form}
      save={true}
      extras={
        <TranslationReset
          languages={languages}
          reset={async () =>
            await edit({ id: item.id, data: { translations: null } })
          }
        />
      }
    >
      <FormFields form={form} />
    </BaseForm>
  );
}

export function CourseAddForm({ onComplete }: { onComplete: AnyFunction }) {
  const { mutateAsync: add, isPending } = useAddCourseMutation();

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { ...COURSE_DEFAULTS },
    onSubmit: async ({ value }: { value: CoursesRecord }) => {
      if (isPending) return;
      await add({ data: value }).catch((e) => console.error(e));
      onComplete();
    },
  });
  return (
    <BaseForm title="Add Course" form={form}>
      <FormFields form={form} />
    </BaseForm>
  );
}
//Handle Fetch of Translation object
export function CourseTranslateForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  const { data, isPending, error } = useCourseTranslation(item?.id);
  if (!item || isPending || error) return;

  return (
    <InnerTranslateForm
      item={item}
      onComplete={onComplete}
      translation={data ? data : item}
    />
  );
}

function InnerTranslateForm({
  item,
  onComplete,
  translation,
}: ContentsProps<CoursesResponse> & { translation: any }) {
  const { mutateAsync: edit, isPending } = useEditCourseMutation(item.id);

  const formOptions = {
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: translation.name,
      description: translation.description,
      imageUrl: translation.imageUrl,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      //fetch entire translation JSON because you cant partial update
      const record = await getOne("courses", item.id, {
        fields: "translations",
      });
      const translations = record?.translations ? record.translations : {};
      const { submitButton, ...rest } = value;
      translations[languageCode] = rest;
      await edit({ id: item.id, data: { translations } }).catch((e) =>
        console.log(e),
      );
      if (submitButton == "save") return;
      onComplete();
    },
  };
  const form = useForm(formOptions);
  const languageCode = useLanguage();
  const languageName = getLanguageName(languageCode);

  return (
    <TranslateForm
      form={form}
      fields={FormFields}
      item={item}
      language={`${languageName} (${languageCode})`}
      type="Course"
    />
  );
}
