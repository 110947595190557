import { ElementsResponse } from "@/lib/pb/types";
import { cn, Direction } from "@/lib/utils";
import CodeHike from "@/components/codehike/CodeHike";
import { useNavigate } from "@tanstack/react-router";
import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";

export default function LinkButton({ element }: { element: ElementsResponse }) {
  const data = element.original ? element.original.data : element.data;

  //can not be translated (use original)
  const variant = data?.variant;
  const className = data?.className;
  const isInternal = data?.isInternal;
  const placement = data?.placement || "right";

  const { url, description, label } = element.data;

  const navigate = useNavigate();

  if (!url) return;

  const open = () => {
    if (!isInternal) {
      window.open(url, "_blank");
    } else {
      navigate({ to: url });
    }
  };
  return (
    <div className="w-full">
      <div
        className={cn(
          " flex max-w-3xl mx-auto justify-center gap-6 ",
          Direction[placement as keyof typeof Direction],
        )}
      >
        <CodeHike item={element} code={description} />
        <Button
          variant={variant ? variant : "default"}
          className={cn(
            "items-center flex gap-2 font-bold text-lg mx-auto",
            className,
          )}
          onClick={open}
        >
          {label}
          {!isInternal && <ExternalLink className="w-4 h-4" />}
        </Button>
      </div>
    </div>
  );
}
