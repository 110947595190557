import {
  CoursesRecord,
  CoursesResponse,
  ElementsRecord,
  ElementsResponse,
  LessonsRecord,
  LessonsResponse,
  RecordIdString,
  SectionsRecord,
  SectionsResponse,
  UnitsRecord,
  UnitsResponse,
} from "@/lib/pb/types";
import pb, { getFullList, getOne, createMap } from "@/lib/pb";

type CopyItemProps = {
  id: RecordIdString;
  changes: ChangeProps;
};
type ChangeProps = {
  courseId: string;
  unitId?: string;
  lessonId?: string;
  rank: string;
};
export async function copyCourse({
  id,
  data,
}: {
  id: RecordIdString;
  data: CoursesRecord;
}) {
  const options = { filter: `courseId="${id}"` };

  const course = await getOne<CoursesResponse>("courses", id); //full data
  if (!course) throw new Error("Course not found");


  //switch to fetcn in parallel 

  
  // Get Units
  const units = await getFullList<UnitsResponse>("units", options);
  if (!units) throw new Error("Units not found");

  //Get Lessons
  const lessons = await getFullList<LessonsResponse>("lessons", options);
  if (!lessons) throw new Error("Lessons not found");
  const lessonsMap = createMap(lessons, units, "unitId");

  //Get Sections
  const sections = await getFullList<SectionsResponse>("sections", options);
  if (!sections) throw new Error("Sections not found");
  const sectionsMap = createMap(sections, lessons, "lessonId");

  //Get Elements
  const elements = await getFullList<ElementsResponse>("elements", options);
  if (!elements) throw new Error("Elements not found");
  const elementsMap = createMap(elements, sections, "sectionId");

  // Create Course
  const { id: oldId, ...record } = course; //pull out id
  const c = { ...record, ...data }; //new course
  const newCourse = await pb.collection("courses").create(c);
  if (!newCourse) throw new Error("Course not created: " + c.name);
  
  await copyUnits(newCourse.id, units, lessonsMap, sectionsMap, elementsMap);
  return newCourse;
}

// COPY UNIT =======================================
export async function copyUnit({ id, changes }: CopyItemProps) {
  //Get Unit
  const unit = await getOne<UnitsResponse>("units", id); //full data
  if (!unit) throw new Error("Unit not found");
  
  //Get Lessons
  const lOptions = { filter: `unitId="${id}"` };
  const lessons = await getFullList<LessonsResponse>("lessons", lOptions);
  if (!lessons) throw new Error("Lessons not found");

  //Get Sections
  const sOptions = {
    filter: lessons.map((l) => `lessonId="${l.id}"`).join(" || "),
  };
  const sections = await getFullList<SectionsResponse>("sections", sOptions);
  if (!sections) throw new Error("Sections not found");
  const sectionsMap = createMap(sections, lessons, "lessonId");
  
  //Get Elements
  const options = {
    filter: sections.map((s) => `sectionId="${s.id}"`).join(" || "),
  };
  const elements = await getFullList<ElementsResponse>("elements", options);
  if (!elements) throw new Error("Elements not found");
  const elementsMap = createMap(elements, sections, "sectionId");

  const { id: oldId, ...rest } = unit; // remove oldId
  const u = { ...rest, ...changes, name: `${rest.name} (copy)` }; //build new unit
  const newUnit = await createUnit(u);

  await copyLessons(changes.courseId, newUnit.id, lessons, sectionsMap, elementsMap);
  return newUnit;
}

// COPY LESSON ===========================================
export async function copyLesson({ id, changes }: CopyItemProps) {
  const lesson = await getOne<LessonsResponse>("lessons", id); //full data
  if (!lesson) throw new Error("Lesson not found");

  //Get Sections
  const sOptions = { filter: `lessonId="${id}"` };
  const sections = await getFullList<SectionsResponse>("sections", sOptions);
  if (!sections) throw new Error("Sections not found");

  //Get Elements
  const options = {
    filter: sections.map((s) => `sectionId="${s.id}"`).join(" || "),
  };
  const elements = await getFullList<ElementsResponse>("elements", options);
  if (!elements) throw new Error("Elements not found");
  const elementsMap = createMap(elements, sections, "sectionId");

  const { id: oldId, ...rest } = lesson; // remove oldId
  const l = { ...rest, ...changes, name: `${rest.name} (copy)` }; //build new lesson,
  const newLesson = await createLesson(l);

  await copySections(changes.courseId, newLesson.id,  sections, elementsMap);
  return newLesson;
}

//calculate rank before
export async function copySection({ id, changes }: CopyItemProps) {
  const section = await getOne<SectionsResponse>("sections", id); //full data
  if (!section) throw new Error("Section not found");

  //Get Elements
  const options = { filter: `sectionId="${id}"` };
  const elements = await getFullList<ElementsResponse>("elements", options);
  if (!elements) throw new Error("Elements not found");

  const { id: oldId, ...rest } = section; // remove oldId
  const s = { ...rest, ...changes, name: `${rest.name} (copy)` }; //build new section,

  const newSection = await createSection(s);
  await copyElements(changes.courseId, newSection.id, elements);
  return newSection;
}

async function copyUnits(
  courseId: string,
  units: UnitsResponse[],
  lessonsMap: Map<string, LessonsResponse[]>,
  sectionsMap: Map<string, SectionsResponse[]>,
  elementsMap: Map<string, ElementsResponse[]>,
) {
  for (const unit of units) {
    const { id: oldId, ...rest } = unit; //pull out id
    const u = {...rest, courseId}; //new unit
    const newUnit = await createUnit(u);
    const unitLessons= lessonsMap.get(unit.id);
    if (!unitLessons) continue;
    //Copy each of the Lessons
    await copyLessons(courseId, newUnit.id, unitLessons, sectionsMap, elementsMap);
  }
}

async function copyLessons(
  courseId: string,
  unitId: string,
  lessons: LessonsResponse[],
  sectionsMap: Map<string, SectionsResponse[]>,
  elementsMap: Map<string, ElementsResponse[]>,
) {
  for (const lesson of lessons) {
    const { id: oldId, ...rest } = lesson; //pull out id
    const l = {...rest, unitId, courseId}; //new lesson
    const newLesson = await createLesson(l);
    const lessonSections= sectionsMap.get(lesson.id);
    if (!lessonSections) continue;
    //Copy each of the sections
    await copySections(courseId, newLesson.id, lessonSections, elementsMap);
  }
}

async function copySections(
  courseId: string,
  lessonId: string,
  sections: SectionsResponse[],
  elementsMap: Map<string, ElementsResponse[]>
) {
  for (const section of sections) {
    const { id: oldId, ...rest } = section; //pull out id
    const s = {...rest, lessonId, courseId}; //new section
    const newSection = await createSection(s);
    const sectionElements = elementsMap.get(section.id);
    if (!sectionElements) continue;
    //Copy each of the elements
    await copyElements(courseId, newSection.id, sectionElements);
  }
}

async function copyElements(
  courseId: string,
  sectionId: string,
  elements: ElementsResponse[],
) {
  for (const element of elements) {
    const { id: oldId, ...elementRecord } = element; //pull out id
    const e = { ...elementRecord, sectionId, courseId }; //new element
    await createElement(e);
  }
}


async function createRecord<T>(
  collectionName: string,
  data: T,
  identifierField: keyof T,
  entityName: string
) {
  const record = await pb.collection(collectionName).create(data);
  if (!record) {
    const identifier = data[identifierField];
    throw new Error(`${entityName} not created: ${identifier}`);
  }
  return record;
}

export async function createElement(data: ElementsRecord) {
  return createRecord("elements", data, "type", "Element");
}

async function createSection(data: SectionsRecord) {
  return createRecord("sections", data, "name", "Section");
}

async function createLesson(data: LessonsRecord) {
  return createRecord("lessons", data, "name", "Lesson");
}

async function createUnit(data: UnitsRecord) {
  return createRecord("units", data, "name", "Unit");
}