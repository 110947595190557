import {
  Atom,
  Bird,
  BookOpen,
  Bot,
  ChevronLeft,
  Code2,
  Eclipse,
  Frame,
  History,
  LifeBuoy,
  Map,
  PieChart,
  Rabbit,
  Send,
  Settings2,
  SquareTerminal,
  Star,
  Turtle,
} from "lucide-react";

import { NavSecondary } from "@/components/sidebar/nav-secondary";
import { UnitSwitcher } from "@/components/sidebar/unit-switcher";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarLabel,
} from "@/components/ui/sidebar";
import { UnitsResponse } from "@/lib/pb/types";
import { ComponentType } from "react";
import { LessonList } from "@/lms/lesson/LessonList";
import { Link } from "../nav/Link";
import TranslationDropdown from "../nav/TranslationDropdown";
import DarkModeSwitch from "../nav/DarkModeSwitch";
import AuthControls from "../auth/AuthControls";
import {
  useClass,
  useCourse,
  useEnrollment,
  useProgressTree,
  useUnit,
} from "@/lib/store";
import { NavigateButtons } from "@/lms/core/NavigateButtons";
import { WebContainerCard } from "@/lms/playground/WebContainerCard";
import { Progress } from "@/components/format/Progress";

const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },

  navSecondary: [
    {
      title: "Support",
      url: "#",
      icon: LifeBuoy,
    },
    {
      title: "Feedback",
      url: "#",
      icon: Send,
    },
  ],
  projects: [
    {
      name: "Design Engineering",
      url: "#",
      icon: Frame,
    },
    {
      name: "Sales & Marketing",
      url: "#",
      icon: PieChart,
    },
    {
      name: "Travel",
      url: "#",
      icon: Map,
    },
  ],
};

interface SidebarDetailsType {
  unit: ComponentType<{ item: any }>;
  lesson: ComponentType<{ item: any }>;
  section: ComponentType<{ item: any }>;
}

export function AppSidebar({
  units,
  details: Details,
}: {
  units: UnitsResponse[];
  details: SidebarDetailsType;
}) {
  const course = useCourse();
  const unit = useUnit();
  const cls = useClass();
  const progressTree = useProgressTree();
  const unitProgress = progressTree?.[unit.id];
  return (
    <Sidebar>
      <SidebarHeader>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex flex-col gap-0">
            <Link
              to={`/course/${course?.id}/`}
              className="flex bg-transparent tracking-tight"
            >
              <div className="flex items-center gap-1">
                <ChevronLeft className="h-6 w-6 -ms-4" />
                <BookOpen style={{ width: '1.5rem', height: '1.5rem' }}/>
                <div className="flex flex-col ps-2">
                  <div className="font-semibold">{course?.name}</div>
                  <div className="tracking-tight text-sm">
                    {cls ? cls.name : "No enrollment"}
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <div className="flex w-full">
              <UnitSwitcher items={units} />
              {unit && Details.unit && (
                <Details.unit item={unit?.original || unit} items={units} />
              )}
            </div>
            <Progress
              value={
                unitProgress && unitProgress.length > 0
                  ? (unitProgress.completed / unitProgress.length) * 100
                  : 0
              }
              className="px-2 mt-1 bg-green"
            />
          </div>
          {/* <SidebarItem>
            <SidebarSearch results={data.searchResults} />
          </SidebarItem> */}
        </div>
      </SidebarHeader>
      <SidebarContent>
        <SidebarItem className="p-2">
          {unit && (
            <LessonList
              lessons={unit?.children}
              detail={Details.lesson}
              subdetail={Details.section}
              progress={unitProgress ? unitProgress : null}
            />
          )}
        </SidebarItem>

        {/* <SidebarItem className="mt-auto">
          <SidebarLabel>Help</SidebarLabel>
          <NavSecondary items={data.navSecondary} />
          <WebContainerCard />
        </SidebarItem> */}
      </SidebarContent>
      <SidebarFooter>
        <div className="flex gap-2 w-full">
          <div className="flex me-auto w-full">
            <AuthControls />
          </div>
          <TranslationDropdown />
          <DarkModeSwitch />
        </div>
      </SidebarFooter>
    </Sidebar>
  );
}

// <SidebarItem>
//   <SidebarLabel>Projects</SidebarLabel>
//   <NavProjects projects={data.projects} />
// </SidebarItem>

// <SidebarItem>
//   <StorageCard />
// </SidebarItem>
