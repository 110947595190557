import { Button } from "@/components/ui/button";
import { useUser } from "@/lib/store";
import { Link } from "@tanstack/react-router";
import ProfileDropdown from "./ProfileDropdown";
import {useStaticTranslations} from "@/lib/lang";

export default function AuthControls() {
  const user = useUser();
  const st = useStaticTranslations();

  if (user) {
    return (
      <>
        <ProfileDropdown />
      </>
    );
  }
  return (
    <>
      <Link to="/login" className="[&.active]:font-bold">
        <Button size="sm" className="h-8 px-2">
          {st("Login")}
        </Button>
      </Link>
      <Link to="/signup" className="[&.active]:font-bold">
        <Button size="sm" variant="ghost" className="h-8 px-2">
          {st("Sign up")}
        </Button>
      </Link>
    </>
  );
}
