export function ElementTitle({
  icon: Icon,
  title,
  children,
}: {
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  title: string;
  children?: React.ReactNode;
}) {
  return (
    <div className="text-primary text-sm flex gap-2 items-center justify-between">
      <div className="flex gap-2 items-center">
        {Icon && <Icon className="h-4 w-4" />} <div>{title}</div>{" "}
      </div>
      {children}
    </div>
  );
}
