import { Header } from "@/components/format/Dialog";
import { ElementsResponse, SubmissionsResponse } from "@/lib/pb/types";
import { ArrowRight, ArrowLeft, Keyboard } from "lucide-react";
import { Playground } from "../playground/Playground";
import { useState } from "react";
import { cn, generateRandomString } from "@/lib/utils";
import { TooltipButton } from "@/components/format/Buttons";
import { TableComments } from "../comments/Comments";
import { useQuery, userDetailQuery } from "@/lib/pb/lms";
import { Avatar } from "@/components/format/Avatar";
export function SubmissionPlayground({
  submission,
  element,
}: {
  submission: SubmissionsResponse;
  element: ElementsResponse;
}) {
  const [current, setCurrent] = useState(submission);
  const [mountPoint, setMountPoint] = useState(generateRandomString()); //

  const { data: user, isPending } = useQuery(userDetailQuery(current.userId));

  return (
    <div className="flex flex-col w-full h-screen">
      <Header title={<Title />} description={<Description />} />
      <div className="flex gap-4 text-lg tracking tight py-2 items-center">
        <TooltipButton
          info="Previous"
          variant="ghost"
          disabled={!current.previous}
          onClick={() => {
            setCurrent(current.previous);
          }}
        >
          <ArrowLeft />
        </TooltipButton>
        <TooltipButton
          info="Next"
          variant="ghost"
          disabled={!current.next}
          onClick={() => {
            setCurrent(current.next);
          }}
        >
          <ArrowRight />
        </TooltipButton>

        {element.data?.enableComments && (
          <TableComments
            userId={current.userId}
            classId={current.classId}
            item={element}
            count={current.commentsLength}
          />
        )}
        <div className="flex gap-2 items-center">
          {user && <Avatar user={user} />}
          <div>{current.name}</div>{" "}
        </div>
      </div>
      <div className="grid w-full border-4 ">
        <Playground
          key={mountPoint} //remounts entire playground
          baseFiles={current?.data?.files}
          canEditFiles={false}
          showFileExplorer={element.data.showFileExplorer}
          mountPoint={mountPoint}
          showTerminal={true}
          showOpenInCodeSandbox={false} //form in form error
          environment={element.data.environment}
          height={"full"}
        />
      </div>
    </div>
  );
}

function Title() {
  return (
    <div className="flex gap-2">
      <Keyboard className="h-4 w-4" />
      Student Code Playground Submission
    </div>
  );
}

function Description() {
  return <div className="max-w-4xl"></div>;
}
