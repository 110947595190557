import { useMemo } from "react";
import { createFileRoute } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { PaginationTable, fuzzySort, fuzzyFilter } from "@/components/table";

import { usersListQuery } from "@/lib/pb/lms";

import { ColumnDef } from "@tanstack/react-table";
import { UsersResponse } from "@/lib/pb/types";
import { format } from "date-fns";
import { RoleNames } from "@/lib/roles";
import { DialogButton } from "@/components/format/Buttons";
import { Pencil, Trash2, BadgeCheck } from "lucide-react";
import { UserEditForm, UserDeleteForm } from "@/lms/user/UserAdminForms";
import { Avatar } from "@/components/format/Avatar";

export const USERS_COLUMNS: ColumnDef<UsersResponse>[] = [
  {
    accessorFn: (row) =>
      `${row.name} ${row.lastName ? row.lastName[0] + "." : ""}`,
    id: "className",
    header: () => <span>Name</span>,
    cell: (info) => {
      const name = info.getValue() as string;
      return (
        <DialogButton
          icon={Pencil}
          item={info.row.original}
          contents={UserEditForm}
          className="mx-auto"
          variant="ghost"
          label={
            <div className="flex gap-2 items-center">
              <Avatar user={info.row.original} />
              {name}
              {info.row.original.verified ? (
                <BadgeCheck className="text-success" size={16} />
              ) : (
                ""
              )}
            </div>
          }
        />
      );
    },
    meta: { filterVariant: "text", filterName: "Name" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  {
    accessorKey: "email",
    header: () => "Email",
    meta: { filterVariant: "text", filterName: "Email" },
    filterFn: fuzzyFilter, //or just define with the function
    sortingFn: fuzzySort, //sort by fuzzy rank (falls back to alphanumeric)
  },
  {
    accessorKey: "role",
    header: () => "Role",
    cell: (info) => {
      const value = info.getValue() as keyof typeof RoleNames;
      return value + " " + RoleNames[value];
    },
    meta: { filterVariant: "multiselect", filterName: "Role" },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "created",
    header: () => "Created",
    cell: (info) => {
      const value = info.getValue() as string | number | Date;
      return value ? format(value, "PPP") : "";
    },
    sortingFn: "datetime",
  },
  {
    id: "action",
    cell: ({ row }) => (
      <div className="flex">
        {/* <DialogButton
          icon={Pencil}
          item={row.original}
          contents={UserEditForm}
          className="mx-auto"
          variant="ghost"
        /> */}
        <DialogButton
          icon={Trash2}
          item={row.original}
          contents={UserDeleteForm}
          className="mx-auto"
          variant="ghost"
        />
      </div>
    ),
  },
];

export const Route = createFileRoute("/_auth/_site/users")({
  component: UsersTable,
});

export function UsersTable() {
  const { isPending, error, data } = useQuery(usersListQuery());
  const columns = useMemo(() => USERS_COLUMNS, []);

  if (error) return "An error has occurred: " + error.message;

  return (
    <div className="p-2 w-full m-auto max-w-7xl">
      <PaginationTable
        data={data}
        columns={columns}
        sortEnabled={true}
        isPending={isPending}
      />
    </div>
  );
}
