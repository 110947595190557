//import NavBrand from "./NavBrand";
import { NavLink, links } from "./Link";
import { SidebarSearch } from "../sidebar/nav-search";
import { useSearchResults } from "@/lib/store";

import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";

import { Title } from "./NavBox";
export default function NavBarLeft() {
  const searchResults = useSearchResults();
  return (
    <nav className="hidden flex-col gap-4 text-lg font-medium md:flex md:flex-row md:items-center md:text-sm ">
      <Title title="LMS2" url="/" />
      <NavigationMenu>
        <NavigationMenuList>
          {links.map((link) => (
            <NavLink to={link.to} key={link.to}>
              {link.label}
            </NavLink>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
      {searchResults && <SidebarSearch results={searchResults} />}
    </nav>
  );
}
