import { Avatar as ShadAvatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

export function Avatar({user}) {
  return (
    <ShadAvatar className="p-1">
      <AvatarImage src={user.avatar} />
      <AvatarFallback
        className="text-xl font-bold"
        style={{ background: user.color }}
      >
        {user.name[0]}
      </AvatarFallback>
    </ShadAvatar>
  )
}