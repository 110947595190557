import {
  NameField,
  OptionalTextField,
  MarkdownField,
  ActiveSwitchField,
  AccessField,
} from "@/lms/core/LmsFields";
import { SwitchField } from "@/components/form/CustomFields";
import { CalendarField } from "@/components/form/CalendarField";
import { zodValidator } from "@/components/form";
import { FormApi, useForm } from "@tanstack/react-form";
import { useEditClassMutation, useAddClassMutation } from "@/lib/pb/lms";
import {
  ClassesRecord,
  ClassesAdminViewResponse,
  CoursesResponse,
} from "@/lib/pb/types";
import { BaseForm } from "@/components/form/Form";
import { ContentsProps } from "@/components/format/Dialog";

const DEFAULTS = {
  name: "",
  instructor: "",
  info: "",
  details: "",
  startDate: "",
  endDate: "",
  requireTeamSelection: false,
  active: true,
  submitButton: "submit",
  access: 100,
};

export function ClassEditForm({
  item,
  onComplete,
}: ContentsProps<ClassesAdminViewResponse>) {
  if (!item) return;
  const { mutateAsync: edit, isPending } = useEditClassMutation(
    item.courseId,
    item.id,
  );

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item.name,
      instructor: item.instructor,
      info: item.info,
      details: item.details,
      startDate: item.startDate,
      endDate: item.endDate,
      requireTeamSelection: item.requireTeamSelection,
      active: item.active,
      access: 100,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if(isPending) return;
      const { submitButton, ...rest } = value;
      const data = rest as ClassesRecord;
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete(); //closes the dialog
    },
  });
  return (
    <BaseForm title="Edit Class" form={form} save={true}>
      <FormFields form={form} />
    </BaseForm>
  );
}

export function ClassAddForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  if (!item) return;
  const { mutateAsync: add, isPending } = useAddClassMutation(item.id);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { ...DEFAULTS },
    onSubmit: async ({ value }: { value: ClassesRecord }) => {
      if(isPending) return;
      value.courseId = item.id;
      await add({ data: value }).catch((e) => console.error(e));
      onComplete(); //closes the dialog
    },
  });
  return (
    <BaseForm title="Add Class" form={form}>
      <FormFields form={form} />
    </BaseForm>
  );
}

function FormFields({ form }: { form: FormApi<any, any> }) {
  return (
    <div className="grid gap-4 py-4 ">
      <div className="flex gap-4 w-full flex-wrap">
        <NameField form={form} className="grid gap-2 w-64" />

        <AccessField
          label="Access (default:100)"
          form={form}
          className="grid gap-2 "
        />

        <OptionalTextField
          form={form}
          className="grid gap-2 w-48"
          name="instructor"
          label="Instructor"
        />
        <OptionalTextField
          form={form}
          className="grid gap-2 w-96"
          name="info"
          label="Info"
        />
      </div>
      <div className="flex gap-4 w-full flex-wrap">
        <CalendarField
          form={form}
          className="grid gap-2"
          name="startDate"
          label="Start Date"
          validate={false}
        />
        <CalendarField
          form={form}
          className="grid gap-2"
          name="endDate"
          label="End Date"
          validate={false}
        />
      </div>
      <MarkdownField form={form} name="details" label="Details" />
      <div className="flex gap-4 w-full flex-wrap">
        <ActiveSwitchField form={form} />
        <SwitchField
          form={form}
          className="flex flex-row flex-row-reverse gap-0.5"
          name="requireTeamSelection"
          label="Require team selection to enroll"
        />
      </div>
    </div>
  );
}
