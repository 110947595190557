import {
  setSection,
  useSection,
  useCourse,
  useProgressTree,
  useLesson,
  setSectionProgress,
  useEnrollment,
} from "@/lib/store";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import { Section } from "@/lms/section/Section";
import {
  useAddSectionProgressMutation,
  useEditSectionProgressMutation,
} from "@/lib/pb/section-progress";

export const Route = createFileRoute(
  "/_auth/course/$courseId/$unitId/$lessonId/$sectionId",
)({
  component: SectionComponent,
});

function SectionComponent() {
  const { unitId, lessonId, sectionId } = Route.useParams();
  const course = useCourse();

  const section = useSection();
  const progressTree = useProgressTree();
  const enrollment = useEnrollment();
  const { mutateAsync: add } = useAddSectionProgressMutation(enrollment?.id);
  const { mutateAsync: edit } = useEditSectionProgressMutation(enrollment?.id);
  useEffect(() => {
    if (course?.sections) {
      //console.log("===setting section====");
      setSection(course?.sections[sectionId] ?? null);
    }
  }, [sectionId, course]);

  useEffect(() => {
    if (!section || !progressTree || !enrollment) return;
    if (!lessonId || !unitId) return;

    const sectionProgress =
      unitId && lessonId
        ? progressTree?.[unitId]?.children[lessonId]?.children[sectionId]
        : null;
    //console.log("!!!!!!!sectionProgress", sectionProgress, progressTree);

    if (sectionProgress) {
      const isComplete =
        sectionProgress.completedSet.size === sectionProgress.length;
      if (!sectionProgress.id) {
        const data = {
          sectionId,
          enrollmentId: enrollment.id,
          complete: isComplete,
        };
        add({ data }).catch((e) => console.error(e));
      } else if (!sectionProgress.complete && isComplete) {
        // when you delete an element you need a way to update to complete
        edit({ id: sectionProgress.id, data: { complete: isComplete } }).catch(
          (e) => console.error(e),
        );
      } else {
        setSectionProgress(sectionProgress);
      }
    }
  }, [progressTree, sectionId]);

  //if enrollment then we need to check for section progress?

  if (!section) return null;

  return <Section section={section} />;
}
