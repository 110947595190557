import {
  pb,
  getOne,
  getFullList,
  getFullMap,
  getTranslationMap,
  getTranslation,
} from "@/lib/pb";
import { useLanguage } from "@/lib/lang";
import { QueryKey, queryOptions, useQuery } from "@tanstack/react-query";
import {
  useBaseMutation, //for copying course
  useAddMutation,
  //useAddCacheMutation,
  useEditMutation,
  //useEditCacheMutation,
  useDeleteMutation,
  useCopyMutation,
  //useDeleteCacheMutation,
} from "@/lib/pb/mutations";

import {
  RecordIdString,
  CoursesRecord,
  CoursesResponse,
  ClassesRecord,
  ClassesResponse,
  UsersRecord,
  UsersResponse,
  EnrollmentsRecord,
  EnrollmentsResponse,
  TeamsRecord,
  TeamsResponse,
  TeamsViewResponse,
  ClassesAdminViewRecord,
  ClassesAdminViewResponse,
  UnitsResponse,
  UnitsRecord,
  LessonsResponse,
  LessonsRecord,
  SectionsRecord,
  SectionsResponse,
  ElementsRecord,
  ElementsResponse,
} from "./types";
import { RecordModel } from "pocketbase";
import { copyCourse, copySection, copyLesson, copyUnit } from "./copy";
import { useUser } from "@/lib/store";

export { useQuery } from "@tanstack/react-query";
export { useUser } from "@/lib/store";

export const DEFAULT_STALETIME = 1000 * 60 * 20; //20 minutes;

// const useTranslationListQuery = <T>(queryKey: QueryKey, filter?: string) => {
//   const language = useLanguage();
//   const fields = `id, translations.${language}`;
//   return useQuery({
//     queryKey: [...queryKey, { language }],
//     queryFn: () =>
//       getTranslationMap<T>(
//         queryKey[0] as string, //collection
//         filter ? { fields, filter } : { fields },
//         language,
//       ),
//     staleTime: DEFAULT_STALETIME,
//     meta: { errorMessage: "Failed to fetch translation map " + queryKey[0] },
//     enabled: language != "en",
//   });
// };

const useTranslationMapQuery = <T = RecordModel>(
  queryKey: QueryKey,
  filter?: string,
) => {
  const language = useLanguage();
  const fields = `id, translations.${language}`;
  return useQuery({
    queryKey: [...queryKey, { language }],
    queryFn: () =>
      getTranslationMap<T>(
        queryKey[0] as string, //collection
        filter ? { fields, filter } : { fields },
        language,
      ),
    staleTime: DEFAULT_STALETIME,
    //refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch translation map " + queryKey[0] },
    enabled: language != "en",
  });
};

const useTranslationQuery = (queryKey: QueryKey, id: string | undefined) => {
  const language = useLanguage();
  return useQuery({
    queryKey: [...queryKey, { language }],
    queryFn: () =>
      getTranslation(
        queryKey[0] as string, //collection
        id,
        { fields: `id, translations.${language}` },
        language,
      ),
    staleTime: Infinity,
    refetchOnWindowFocus: false, //will affect and wipe translation form
    refetchOnReconnect: false,
    refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch translation " + queryKey[0] },
    enabled: !!id && language != "en",
  });
};

//ELEMENT
export const useAddElementMutation = (
  courseId: RecordIdString,
  sectionId: RecordIdString,
) =>
  useAddMutation<ElementsRecord, ElementsResponse>("elements", [
    elementsTreeQuery(courseId).queryKey,
    elementsListQuery(sectionId).queryKey,
  ]);

export const useDeleteElementMutation = (
  courseId: RecordIdString,
  sectionId: RecordIdString,
) =>
  useDeleteMutation("elements", [
    elementsTreeQuery(courseId).queryKey,
    elementsListQuery(sectionId).queryKey,
  ]);

export const useEditElementMutation = (
  courseId: RecordIdString,
  sectionId: RecordIdString,
  elementId: RecordIdString,
  //  newCourseId?: RecordIdString,
  newSectionId?: RecordIdString, // for moving element to new section
) => {
  const keys = [
    elementsTreeQuery(courseId).queryKey,
    elementsListQuery(sectionId).queryKey,
    elementDetailQuery(elementId).queryKey,
  ];
  // if (newCourseId && newCourseId != courseId)
  //   keys.push(elementsTreeQuery(newCourseId).queryKey);
  if (newSectionId && newSectionId != sectionId)
    keys.push(elementsListQuery(newSectionId).queryKey);

  return useEditMutation<ElementsRecord, ElementsResponse>("elements", keys);
};

// SECTIONS
export const useAddSectionMutation = (
  courseId: RecordIdString,
  lessonId: RecordIdString,
) =>
  useAddMutation<SectionsRecord, SectionsResponse>("sections", [
    sectionsTreeQuery(courseId).queryKey,
    sectionsListQuery(lessonId).queryKey,
  ]);

export const useDeleteSectionMutation = (
  courseId: RecordIdString,
  lessonId: RecordIdString,
) =>
  useDeleteMutation("sections", [
    sectionsListQuery(lessonId).queryKey,
    sectionsTreeQuery(courseId).queryKey,
  ]);

export const useEditSectionMutation = (
  courseId: RecordIdString,
  lessonId: RecordIdString,
  sectionId: RecordIdString,
  //  newCourseId?: RecordIdString, DONT allow move to new course
  newLessonId?: RecordIdString,
) => {
  const keys = [
    sectionsTreeQuery(courseId).queryKey,
    sectionsListQuery(lessonId).queryKey,
    sectionDetailQuery(sectionId).queryKey,
  ];
  if (newLessonId && newLessonId != lessonId)
    //listqueries not used right now
    keys.push(sectionsListQuery(newLessonId).queryKey);

  return useEditMutation<SectionsRecord, SectionsResponse>("sections", keys);
};
export function useCopySectionMutation(
  courseId: RecordIdString,
  lessonId: RecordIdString,
) {
  const keys = [
    sectionsTreeQuery(courseId).queryKey,
    elementsTreeQuery(courseId).queryKey, //copied elements
    sectionsListQuery(lessonId).queryKey,
  ];
  return useBaseMutation({
    mutationFn: copySection,
    invalidates: keys,
    action: `Copy Section`,
  });
}

// LESSONS
export const useAddLessonMutation = (
  courseId: RecordIdString,
  unitId: RecordIdString,
) =>
  useAddMutation<LessonsRecord, LessonsResponse>("lessons", [
    lessonsListQuery(unitId).queryKey,
    lessonsTreeQuery(courseId).queryKey,
  ]);
export const useEditLessonMutation = (
  courseId: RecordIdString,
  unitId: RecordIdString,
  lessonId: RecordIdString,
  newUnitId?: RecordIdString,
) => {
  const keys = [
    lessonsListQuery(unitId).queryKey,
    lessonDetailQuery(lessonId).queryKey,
    lessonsTreeQuery(courseId).queryKey,
  ];
  if (newUnitId && newUnitId != unitId) {
    keys.push(lessonsListQuery(newUnitId).queryKey);
  }
  return useEditMutation<LessonsRecord, LessonsResponse>("lessons", keys);
};

export function useCopyLessonMutation(
  courseId: RecordIdString,
  unitId: RecordIdString,
) {
  const keys = [
    lessonsTreeQuery(courseId).queryKey,
    sectionsTreeQuery(courseId).queryKey, //copied sections
    elementsTreeQuery(courseId).queryKey, //copied elements
    lessonsListQuery(unitId).queryKey,
  ];
  return useBaseMutation({
    mutationFn: copyLesson,
    invalidates: keys,
    action: `Copy Lesson`,
  });
}
export const useDeleteLessonMutation = (
  courseId: RecordIdString,
  unitId: RecordIdString,
) =>
  useDeleteMutation("lessons", [
    lessonsListQuery(unitId).queryKey,
    lessonsTreeQuery(courseId).queryKey,
  ]);
// UNITS
export const useAddUnitMutation = (courseId: RecordIdString) =>
  useAddMutation<UnitsRecord, UnitsResponse>("units", [
    unitsListQuery(courseId).queryKey,
  ]);
export const useEditUnitMutation = (courseId: RecordIdString, unitId: string) =>
  useEditMutation<UnitsRecord, UnitsResponse>("units", [
    unitsListQuery(courseId).queryKey,
    unitDetailQuery(unitId).queryKey,
  ]);

export function useCopyUnitMutation(courseId: RecordIdString) {
  return useBaseMutation({
    mutationFn: copyUnit,
    invalidates: [
      unitsListQuery(courseId).queryKey,
      lessonsTreeQuery(courseId).queryKey, ////copied lessons
      sectionsTreeQuery(courseId).queryKey, //copied sections
      elementsTreeQuery(courseId).queryKey, //copied elements
    ],
    action: `Copy Unit`,
  });
}
export const useDeleteUnitMutation = (courseId: RecordIdString) =>
  useDeleteMutation("units", [unitsListQuery(courseId).queryKey]);
// COURSES
export const useAddCourseMutation = () =>
  useAddMutation<CoursesRecord, CoursesResponse>("courses", [
    coursesListQuery().queryKey,
  ]);
export const useEditCourseMutation = (courseId: string) =>
  useEditMutation<CoursesRecord, CoursesResponse>("courses", [
    coursesListQuery().queryKey,
    courseDetailQuery(courseId).queryKey,
  ]);
export const useDeleteCourseMutation = () =>
  useDeleteMutation("courses", [coursesListQuery().queryKey]);

export function useCopyCourseMutation() {
  return useBaseMutation({
    mutationFn: copyCourse,
    invalidates: [coursesListQuery().queryKey],
    action: `Copy Course`,
  });
}

// CLASSES
export const useAddClassMutation = (courseId: string) =>
  useAddMutation<ClassesRecord, ClassesResponse>("classes", [
    classesListQuery(courseId).queryKey, //updates list and admin-list
  ]);
export const useEditClassMutation = (courseId: string, classId: string) => {
  const user = useUser();
  return useEditMutation<ClassesRecord, ClassesResponse>("classes", [
    enrollmentsMapQuery(user?.id).queryKey,
    classesListQuery(courseId).queryKey, //updates list and admin-list
    classDetailQuery(classId).queryKey, //updates detail and admin-detail
  ])};
export const useDeleteClassMutation = (courseId: string) =>
  useDeleteMutation("classes", [
    classesListQuery(courseId).queryKey,
    //['classes', , {classId}] dont invalidate so it wont trigger refetch error
  ]);

// ENROLLMENTS
export const useAddEnrollmentMutation = (userId: string) =>
  useAddMutation<EnrollmentsRecord, EnrollmentsResponse>("enrollments", [
    enrollmentsListQuery(null).queryKey,
    enrollmentsMapQuery(userId).queryKey,
  ]);
export const useEditEnrollmentMutation = (userId: string) =>
  useEditMutation<EnrollmentsRecord, EnrollmentsResponse>("enrollments", [
    enrollmentsListQuery(null).queryKey,
    enrollmentsMapQuery(userId).queryKey,
  ]);
export const useDeleteEnrollmentMutation = (userId: string) =>
  useDeleteMutation("enrollments", [
    enrollmentsListQuery(null).queryKey,
    enrollmentsMapQuery(userId).queryKey,
  ]);

//USERS
export const useEditUserMutation = (userId: string) =>
  useEditMutation<UsersRecord, UsersResponse>("users", [
    enrollmentsMapQuery(userId).queryKey,
    enrollmentsListQuery(null).queryKey,
    userDetailQuery(userId).queryKey,
    usersListQuery().queryKey,
  ]);
export const useDeleteUserMutation = () =>
  useDeleteMutation("users", [
    enrollmentsListQuery(null).queryKey,
    usersListQuery().queryKey
  ]);

// TEAMS
export const useAddTeamMutation = (courseId: string, classId: string) =>
  useAddMutation<TeamsRecord, TeamsResponse>("teams", [
    teamsListQuery(classId).queryKey,
    classesAdminListQuery(courseId).queryKey, //updates TotalTeams
  ]);
export const useEditTeamMutation = (classId: string) =>
  useEditMutation<TeamsRecord, TeamsResponse>("teams", [
    teamsListQuery(classId).queryKey,
  ]);
export const useDeleteTeamMutation = (courseId: string, classId: string) =>
  useDeleteMutation("teams", [
    teamsListQuery(classId).queryKey,
    classesAdminListQuery(courseId).queryKey, //updates TotalTeams
  ]);
//CLASSES QUERY
const classesFields = 'id, name, courseId, startDate, endDate, info, active, requireTeamSelection, enrollmentCode' 
export const classesListQuery = (courseId: RecordIdString) =>
  queryOptions({
    queryKey: ["classes", "list", { courseId }],
    queryFn: () =>
      getFullList<ClassesResponse>("classes", {
        filter: `courseId="${courseId}"&&active=true`,
        fields: classesFields,
        sort: "-updated",
      }),
    staleTime: DEFAULT_STALETIME,
    refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch classes for " + courseId },
  });

export const classDetailQuery = (classId: RecordIdString | null) =>
  queryOptions({
    queryKey: ["classes", "detail", { classId }],
    queryFn: () => getOne<ClassesResponse>("classes", classId),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch class " + classId },
    enabled: !!classId,
  });

export const classesAdminListQuery = (courseId: RecordIdString) =>
  queryOptions({
    queryKey: ["classes", "list", { courseId }, "admin"],
    queryFn: () =>
      getFullList<ClassesAdminViewResponse>("classes_admin_view", {
        filter: `courseId="${courseId}"`,
        sort: "-updated",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch classes for " + courseId },
  });

export const classAdminDetailQuery = (classId: RecordIdString) =>
  queryOptions({
    queryKey: ["classes", "detail", { classId }, "admin"],
    queryFn: () =>
      getOne<ClassesAdminViewResponse>("classes_admin_view", classId),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch class " + classId },
  });

// COURSES QUERY

// export const courseAdminDetailQuery = (courseId: RecordIdString) =>
//   queryOptions({
//     queryKey: ["courses", "detail", { courseId }, "admin"],
//     queryFn: () => getOne<CoursesResponse>("courses", courseId),
//     staleTime: DEFAULT_STALETIME,
//     meta: { errorMessage: "Failed to fetch course" },
//   });
const coursesFields = //w/o translations
  "id, name, description, details, imageUrl, access, active, updated";

export const coursesListQuery = () =>
  queryOptions({
    queryKey: ["courses", "list"],
    queryFn: () =>
      getFullList<CoursesResponse>("courses", {
        sort: "-updated",
        fields: coursesFields,
      }),

    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch courses" },
  });

export const courseDetailQuery = (courseId: string, full: boolean = false) =>
  queryOptions({
    queryKey: ["courses", "detail", { courseId }],
    queryFn: () =>
      getOne<CoursesResponse>("courses", courseId, {
        fields: full ? coursesFields + ",translations" : coursesFields,
      }),
    staleTime: DEFAULT_STALETIME,
    // refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch course" },
    enabled: !!courseId,
  });

export const useCoursesTranslation = () => {
  return useTranslationMapQuery<CoursesResponse>(coursesListQuery().queryKey);
};

export const useCourseTranslation = (id: RecordIdString | null | undefined) => {
  return useTranslationQuery(courseDetailQuery(id).queryKey, id); //translate form
};

//USERS QUERY

export const userDetailQuery = (userId: string) =>
  queryOptions({
    queryKey: ["users_view", "detail", { userId }],
    queryFn: () => getOne<UsersResponse>("users_view", userId),
    staleTime: Infinity, //DEFAULT_STALETIME,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    meta: { errorMessage: "Failed to fetch users" },
  });

export const usersListQuery = () =>
  queryOptions({
    queryKey: ["users", "list"],
    queryFn: () => getFullList<UsersResponse>("users"),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch users" },
  });

//ENROLLMENTS QUERY - filter done on pb side

// const enrollmentFieldsNoProgress =
//   "id, userId, courseId, classId, teamId, updated";
// export const enrollmentsListQuery = () =>
//   queryOptions({
//     queryKey: ["enrollments", "list"],
//     queryFn: () =>
//       getFullList<EnrollmentsResponse>("enrollments", {
//         // fields: enrollmentFieldsNoProgress,
//         sort: "-updated",
//       }),
//     staleTime: DEFAULT_STALETIME,
//     meta: { errorMessage: "Failed to fetch enrollments" },
//   });

export const enrollmentsMapQuery = (userId: string) =>
  queryOptions({
    queryKey: ["enrollments", "object", userId],
    queryFn: () =>
      getFullMap<EnrollmentsResponse>(
        "enrollments",
        {
          filter: `userId="${userId}"`,
          expand: "classId",
          sort: "-updated",
        },
        "courseId",
      ),
    staleTime: Infinity, //DEFAULT_STALETIME,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: "always",
    meta: { errorMessage: "Failed to fetch enrollments object" },
  });

//ELements submission table
export const enrollmentsListQuery = (filter: string | null) =>
  queryOptions({
    queryKey: filter ? ["enrollments", "list", filter] : ["enrollments", "list"],
    queryFn: () =>
      getFullList<EnrollmentsResponse>("enrollments", {
        filter: filter ? filter : "",
        expand: "userId, classId, teamId",
        sort: "-updated",
        fields:
          "id, userId, courseId, classId, teamId, expand.userId.id, expand.userId.name, expand.userId.lastName, expand.userId.color, expand.userId.email, expand.userId.verified, expand.userId.role, created, expand.classId.name, expand.teamId.name",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch enrollments list" },
  });

export const enrollmentDetailQuery = (enrollmentId: RecordIdString) =>
  queryOptions({
    queryKey: ["enrollments", "detail", { enrollmentId }],
    queryFn: () => getOne<EnrollmentsResponse>("enrollments", enrollmentId, {}),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch enrollment detail" },
  });

//TEAMS QUERY
export const teamsListQuery = (classId: RecordIdString) =>
  queryOptions({
    queryKey: ["teams", "list", { classId }],
    queryFn: () =>
      getFullList<TeamsViewResponse>("teams_view", {
        filter: `classId="${classId}"`,
        sort: "name",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch teams " },
  });

//UNITS QUERY

const unitFields =
  "id, courseId, name, description, active, access, subtitle, rank";
export const unitsListQuery = (courseId: RecordIdString | undefined) =>
  queryOptions({
    queryKey: ["units", "list", { courseId }],
    queryFn: () =>
      getFullList<UnitsResponse>("units", {
        filter: `courseId="${courseId}"`,
        fields: unitFields,
        sort: "rank",
      }),
    staleTime: DEFAULT_STALETIME,
    //  refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch unit list" },
    enabled: !!courseId,
  });

export const unitDetailQuery = (
  unitId: RecordIdString,
  full: boolean = false,
) =>
  queryOptions({
    queryKey: ["units", "detail", { unitId }],
    queryFn: () =>
      getOne<UnitsResponse>("units", unitId, {
        fields: full ? unitFields + ",translations" : unitFields,
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch unit detail" },
  });

export const useUnitsTranslation = (courseId: string | undefined) => {
  return useTranslationMapQuery<UnitsResponse>(
    unitsListQuery(courseId).queryKey,
    `courseId="${courseId}"`,
  );
};

export const useUnitTranslation = (id: RecordIdString) => {
  return useTranslationQuery(unitDetailQuery(id).queryKey, id);
};

//LESSONS QUERY

const lessonFields = "id, courseId, unitId, name, active, access, rank";

export const lessonsTreeQuery = (courseId: RecordIdString | undefined) =>
  queryOptions({
    queryKey: ["lessons", "tree", { courseId }],
    queryFn: () =>
      getFullList<LessonsResponse>("lessons", {
        filter: `courseId="${courseId}"`,
        fields: lessonFields,
        sort: "rank", //-active
        //       sort: "name", //sort by rank eventually
      }),
    staleTime: DEFAULT_STALETIME,
    //  refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch lesson tree" },
    enabled: !!courseId,
  });

export const lessonsListQuery = (unitId: RecordIdString | undefined) =>
  queryOptions({
    queryKey: ["lessons", "list", { unitId }],
    queryFn: () =>
      getFullList<LessonsResponse>("lessons", {
        filter: `unitId="${unitId}"`,
        fields: lessonFields,
        sort: "rank",
        //       sort: "name", //sort by rank eventually
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch lesson list" },
    enabled: !!unitId,
  });

export const lessonDetailQuery = (
  lessonId: RecordIdString,
  full: boolean = false,
) =>
  queryOptions({
    queryKey: ["lessons", "detail", { lessonId }],
    queryFn: () =>
      getOne<LessonsResponse>("lessons", lessonId, {
        fields: full ? lessonFields + ",translations" : lessonFields,
        //expand: "unitId",
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch unit detail" },
  });

export const useLessonsTranslation = (courseId: string | undefined) => {
  return useTranslationMapQuery<LessonsResponse>(
    lessonsTreeQuery(courseId).queryKey,
    `courseId="${courseId}"`,
  );
};

export const useLessonTranslation = (id: RecordIdString) => {
  //translate form
  return useTranslationQuery(lessonDetailQuery(id).queryKey, id);
};

// SECTIONS QUERY

const sectionFields = "id, courseId, lessonId, name, active, access, rank";

export const sectionsTreeQuery = (courseId: RecordIdString | undefined) =>
  queryOptions({
    queryKey: ["sections", "tree", { courseId }],
    queryFn: () =>
      getFullList<SectionsResponse>("sections", {
        filter: `courseId="${courseId}"`,
        fields: sectionFields,
        sort: "rank",
        //sort: "name", //sort by rank eventually
      }),
    staleTime: DEFAULT_STALETIME,
    // refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch section tree" },
    enabled: !!courseId,
  });

export const sectionsListQuery = (lessonId: RecordIdString) =>
  queryOptions({
    queryKey: ["sections", "list", { lessonId }],
    queryFn: () =>
      getFullList<SectionsResponse>("sections", {
        filter: `lessonId="${lessonId}"`,
        fields: sectionFields,
        sort: "rank",
        // sort: "name", //sort by rank eventually
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch section list" },
  });

export const sectionDetailQuery = (
  sectionId: RecordIdString,
  full: boolean = false,
) =>
  queryOptions({
    queryKey: ["sections", "detail", { sectionId }],
    queryFn: () =>
      getOne<SectionsResponse>("sections", sectionId, {
        fields: full ? sectionFields + ",translations" : sectionFields,
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch section detail" },
  });

export const useSectionsTranslation = (courseId: string | undefined) => {
  return useTranslationMapQuery<SectionsResponse>(
    sectionsTreeQuery(courseId).queryKey,
    `courseId="${courseId}"`,
  );
};

export const useSectionTranslation = (id: RecordIdString) => {
  //translate form
  return useTranslationQuery(sectionDetailQuery(id).queryKey, id);
};

//ELEMENTS QUERY
const elementsFields = //no translations
  "id, courseId, sectionId, type, active, access, submissionRequired, isSubmitType, rank, data, updated";
const elementsFieldsMinimum = //no data and translations
  "id, courseId, sectionId, type, active, access, submissionRequired, isSubmitType, rank";
export const elementsTreeQuery = (courseId: RecordIdString | undefined) =>
  queryOptions({
    queryKey: ["elements", "tree", { courseId }],
    queryFn: () =>
      getFullList<ElementsResponse>("elements", {
        filter: `courseId="${courseId}"`,
        fields: elementsFieldsMinimum, //dont get data too much info
        sort: "sectionId, rank",
      }),
    staleTime: DEFAULT_STALETIME,
    //  refetchOnWindowFocus: false,
    meta: { errorMessage: "Failed to fetch element tree" },
    enabled: !!courseId,
  });

export const elementsListQuery = (sectionId: RecordIdString) =>
  queryOptions({
    queryKey: ["elements", "list", { sectionId }],
    queryFn: () =>
      getFullList<ElementsResponse>("elements", {
        filter: `sectionId="${sectionId}"`,
        fields: elementsFields,
        sort: "rank", //sort by rank eventually
      }),
    staleTime: Infinity,
    meta: { errorMessage: "Failed to fetch element list" },
    refetchOnWindowFocus: false, // prevents user input from clearing
    refetchOnReconnect: false,
    refetchOnMount: "always",
    // enabled: !!sectionId,
  });

export const elementDetailQuery = (
  elementId: RecordIdString,
  full: boolean = false,
) =>
  queryOptions({
    queryKey: ["elements", "detail", { elementId }],
    queryFn: () =>
      getOne<ElementsResponse>("elements", elementId, {
        fields: full ? elementsFields + ",translations" : elementsFields,
      }),
    staleTime: DEFAULT_STALETIME,
    meta: { errorMessage: "Failed to fetch element detail" },
  });

export const useElementsTranslation = (sectionId: string) => {
  return useTranslationMapQuery<ElementsResponse>(
    elementsListQuery(sectionId).queryKey,
    `sectionId="${sectionId}"`,
  );
};

export const useElementTranslation = (id: RecordIdString) => {
  //translate form
  return useTranslationQuery(elementDetailQuery(id).queryKey, id);
};
