import DarkModeSwitch from "./DarkModeSwitch";
import AuthControls from "@/components/auth/AuthControls";
import TranslationDropdown from "@/components/nav/TranslationDropdown";
//import {SearchToggle, LargeSearchToggle} from "@/components/nav/SearchToggle";



export default function NavBarRight() {
 
  return (
    <div className=" flex w-full items-center gap-4 md:ml-auto md:gap-2 lg:gap-4 ">
      <div className="ml-auto"></div>

      {/* <form className="ml-auto flex-1 sm:flex-initial">
        <div className="relative">
          <>
            <SearchToggle className="md:hidden" />
            <LargeSearchToggle className="w-full max-w-[240px] max-md:hidden" />
          </>
        </div>
      </form> */}

      <AuthControls />
      
      <TranslationDropdown/>
      <DarkModeSwitch />
    </div>
  );
}
