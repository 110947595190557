import { Plugin } from 'unified';
import { visit } from 'unist-util-visit';
import { Node } from 'unist';
import { Element } from 'hast';

const rehypeAddCrossorigin: Plugin = () => {
  return (tree: Node) => {
    visit(tree, 'element', (node: Node) => {
      if (node.type === 'element') {
        const elementNode = node as Element;
        if (elementNode.tagName === 'img') {
          elementNode.properties = elementNode.properties || {};
          elementNode.properties.crossorigin = 'anonymous';
        }
      }
    });
  };
};

export default rehypeAddCrossorigin;