import type { useForm } from "@tanstack/react-form";

export default function Submitter({
  form,
  children,
}: {
  form: ReturnType<typeof useForm>;
  children: ([canSubmit, isSubmitting, isDirty]: [boolean, boolean, boolean]) => React.ReactNode;
}) {
  return (
    <form.Subscribe
      selector={(state) => [state.canSubmit, state.isSubmitting, state.isDirty]}
      children={([canSubmit, isSubmitting, isDirty]) =>
        children([canSubmit, isSubmitting, isDirty])
      }
    />
  );
}

