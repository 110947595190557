import {
  createRootRouteWithContext,
  Outlet,
  useParams,
} from "@tanstack/react-router";
import { Toaster } from "@/components/ui/sonner";
import NavBar from "@/components/nav/NavBar";
import { type QueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import pb from "@/lib/pb";
import { useLogout } from "@/lib/pb/auth";

interface MyRouterContext {
  queryClient: QueryClient;
}

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: Root,
});

function Root() {
  //check for expired auth token at the root
  const logout = useLogout();

  useEffect(() => {
    if (!pb.authStore.isValid && pb.authStore.model) logout();
  }, [pb.authStore.isValid]);

  return (
    <div className="flex min-h-screen w-full flex-col ">
      {/* <NavBar /> */}
      <main className="flex flex-1 flex-col gap-4 p-0 3xl:gap-8 3xl:p-8">
        <Outlet />
      </main>
      <Toaster richColors expand={true} />
      {/* <TanStackRouterDevtools /> */}
    </div>
  );
}

//<header className="sticky  top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 xl:px-20 ">
