import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import pb from "@/lib/pb";
import { NavLink } from "@/components/nav/Link";
import NavBar from "@/components/nav/NavBar";
import {
  NavigationMenu,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import { Footer } from "@/components/nav/Footer";

export const Route = createFileRoute("/_auth/_site")({
  beforeLoad: ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (pb.authStore.model?.role < 900) {
      throw redirect({
        to: "/",
      });
    }
    return {
      user: pb.authStore.model,
    };
  },
  component: SiteManagment,
});

function SiteManagment() {
  return (
    <div className="flex w-full flex-col pb-16">
      <NavBar/>
      <div className="p-4 lg:mx-8 pb-8">
        <NavigationMenu className='mx-auto'>
          <NavigationMenuList className="max-w-max border shadow-lg rounded-xl p-2">
            <NavLink to={"/courses"} className="text-lg font-semibold">
              Manage Courses
            </NavLink>
            <NavLink to={"/users"} className="text-lg font-semibold">
              Manage Users
            </NavLink>
          </NavigationMenuList>
        </NavigationMenu>
      </div>
      <Outlet />
      <Footer/>
    </div>
  );
}
