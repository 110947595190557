import { Spinner } from "@/components/ui/spinner";
import {  CircleCheck, Box, CircleX} from "lucide-react";
import {  useAtom } from "jotai";
import { webContainerAtom } from "./WebContainer";

interface LoadingFramProps {
  mountStatus: string;
  installStatus: string;
  serverStatus: string;
}

export function LoadingFrame({
  mountStatus,
  installStatus,
  serverStatus,
}: LoadingFramProps) {
  const [webContainer] = useAtom(webContainerAtom);

  const LoadingIcon = <Spinner className={"h-4 w-4"} />;
  const SuccessIcon = <CircleCheck className={"h-4 w-4 text-success"} />;
  const ErrorIcon = <CircleX className={"h-4 w-4 text-destructive"} />;
  return (
    <div className="flex flex-col w-full h-full items-center justify-center gap-2">
      <Box className="h-16 w-16 animate-pulse text-muted-foreground" />
      <div className="flex flex-col w-44 h-32 gap-2 text-foreground">
        <div className="flex gap-4">
          {webContainer ? SuccessIcon : LoadingIcon}
          {webContainer ? `WebContainer started.` : "Booting WebContainer..."}
        </div>
        {mountStatus != "idle" && (
          <div className="flex gap-4">
            {mountStatus == "mounting" && <>{LoadingIcon} Creating file system...</>}
            {mountStatus == "success" && <>{SuccessIcon} File system created.</>}
            {mountStatus == "error" && <>{ErrorIcon} Error creating file system.</>}
          </div>
        )}
        {installStatus != "idle" && (
          <div className="flex gap-4">
            {installStatus == "installing" && <>{LoadingIcon} Installing packages...</>}
            {installStatus == "success" && <>{SuccessIcon} Packages installed.</>}
            {installStatus == "error" && <>{ErrorIcon} Error installing packages.</>}
          </div>
        )}
        {serverStatus != "idle" && (
          <div className="flex gap-4">
            {serverStatus == "starting" && <>{LoadingIcon} Starting server...</>}
            {serverStatus == "ready" && <>{SuccessIcon} Server is ready.</>}
            {serverStatus == "error" && <>{ErrorIcon} Error starting server.</>}
          </div>
        )}
      </div>
    </div>
  );
}