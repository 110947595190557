import { z } from "zod";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { FormApi } from "@tanstack/react-form";
import Field from "./Field";

export interface CalendarFieldProps {
  form: FormApi<any, any>;
  className?: string;
  name: string;
  label: JSX.Element | string;
  disabled?: boolean;
  validate?: boolean;
}

export const CalendarField = ({
  form,
  className,
  name,
  label,
  validate = false,
  ...props
}: CalendarFieldProps) => {
  return (
    <>
      <Field
        form={form}
        className={className}
        name={name}
        label={label}
        validator={
          validate
            ? z.date({ required_error: `${label} is required.` })
            : undefined
        }
        children={(field) => (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant={"outline"}
                className={cn(
                  "w-[240px] pl-3 text-left font-normal",
                  !field?.state.value && "text-muted-foreground",
                )}
                {...props}
              >
                {field?.state.value ? (
                  format(field?.state.value, "PPP")
                ) : (
                  <span>Pick a date</span>
                )}
                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <div>
                <Calendar
                  mode="single"
                  selected={new Date(field?.state.value)}
                  onSelect={(value) => {
                    field?.handleChange(value?.toISOString());
                  }}
                  className="rounded-md border"
                />
              </div>
              <div className="flex w-full">
                <Button
                  className="w-full h-7"
                  variant="outline"
                  onClick={() => field?.handleChange(null)}
                >
                  Clear
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        )}
      />
    </>
  );
};
