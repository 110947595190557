import svelteFiles from "./svelte";

export default {
	activeFile: "src/lib/Counter.svelte",
	visibleFiles: [
		"src/lib/Counter.svelte",
		"src/routes/+page.svelte",
		"src/routes/+page.server.js",
	],
	files: {
		...svelteFiles.files,
		"src/lib/Counter.svelte": `<script>
	let count = 0;

	$: if (count >= 10) {
		alert("count is dangerously high!");
		count = 9;
	}

	function handleClick() {
		count += 1;
	}
</script>

<button on:click={handleClick}>
	Clicked {count}
	{count === 1 ? 'time' : 'times'}
</button>`,
		"src/routes/+layout.svelte": `
<script>
  import './global.css';
</script>

<slot />`,
		"src/routes/+page.server.js": `
export const load = async () => {
	return {
		name: 'Basic Template'
	};
};`,
		"src/routes/+page.svelte": `
<script>
	import Counter from '$lib/Counter.svelte';

	export let data;
</script>

<h1>
	Hello {data.name}!
</h1>

<Counter />

<p>
	Learn more about templates at
	<a href="https://docs.sveltelab.dev/templates" target="_blank"> docs.sveltelab.dev/templates </a>
</p>`,
		"src/routes/global.css": `
:root {
	--sk-back-h: 206;
	--sk-back-3-hsl: ;
	--sk-back-1: hsl(0, 0%, 100%);
	--sk-back-2: hsl(0, 0%, 100%);
	--sk-back-3: hsl(206, 64%, 98%);
	--sk-back-4: hsl(206, 44%, 93%);
	--sk-back-5: hsl(206, 20%, 80%);
	--sk-text-1: hsl(0, 0%, 13%);
	--sk-text-2: hsl(0, 0%, 27%);
	--sk-text-3: hsl(240, 8%, 44%);
	--sk-theme-1: hsl(15, 100%, 55%);
	--sk-theme-2: hsl(240, 8%, 44%);
	--sk-theme-3: hsl(204, 100%, 63%);
	--sk-font: 'Overpass', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
		Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	--sk-font-mono: 'Fira Mono', monospace;
}

@media (prefers-color-scheme: dark) {
	:root {
		--sk-back-1: hsl(0, 0%, 10%);
		--sk-back-2: hsl(0, 0%, 18%);
		--sk-back-3: hsl(0, 0%, 14%);
		--sk-back-4: hsl(0, 0%, 22%);
		--sk-back-5: hsl(0, 0%, 40%);
		--sk-text-1: hsl(0, 0%, 90%);
		--sk-text-2: hsl(0, 0%, 80%);
		--sk-text-3: hsl(0, 0%, 65%);
	}

	body {
		color-scheme: dark;
	}
}

body {
	background-color: var(--sk-back-3);
	color: var(--sk-text-1);
	margin: 0.75em;
	font-family: var(--sk-font);
	accent-color: var(--sk-theme-1);
}
`,
	},
};
