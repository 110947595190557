import { ChevronsUpDown, Plus } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { UnitsResponse } from "@/lib/pb/types";
import { UnitItem } from "@/components/sidebar/unit-item";
import { useUnit, useUser } from "@/lib/store";

import { useState } from "react";
import { CreateUnitButton } from "@/lms/unit/UnitAdminMenu";
import { ROLES } from "@/lib/roles";

export function UnitSwitcher({ items }: { items: UnitsResponse[] }) {
  const activeUnit = useUnit();
  const [open, setOpen] = useState(false);
  const user = useUser();
  const isAdmin = user.role === ROLES.administrator;
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="w-full rounded-md ring-ring hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 data-[state=open]:bg-accent">
        <div className="flex items-center gap-1.5 overflow-hidden px-2 py-1.5 text-left text-sm transition-all">
          <div className="flex h-5 w-5 items-center justify-center rounded-sm bg-primary text-primary-foreground">
            {activeUnit?.index}
          </div>
          <div className="my-auto line-clamp-1 flex-1 ps-2 font-bold texl-lg tracking-tight">
            {activeUnit?.name}
          </div>
          <ChevronsUpDown className="ml-auto h-4 w-4 text-muted-foreground/50" />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className="w-full max-w-sm overflow-hidden p-2"
        align="start"
        side="right"
        sideOffset={4}
      >
        {items.map((unit) => (
          <div
            key={unit.id}
            className="items-start gap-2 px-1.5"
            onClick={() => setOpen(false)}
          >
            <UnitItem unit={unit} />
          </div>
        ))}

        {isAdmin && (
          <>
            <Separator />
            <div className="flex w-full justify-center mt-2">
              <CreateUnitButton />
            </div>
          </>
        )}

        {/* <DropdownMenuSeparator />
        <DropdownMenuItem className="gap-2 px-1.5">
          <div className="flex h-8 w-8 items-center justify-center rounded-md border bg-background">
            <Plus className="h-5 w-5" />
          </div>
          <div className="font-medium text-muted-foreground">Add workspace</div>
        </DropdownMenuItem> */}
      </PopoverContent>
    </Popover>
  );
}

//<activeUnit.logo className="h-3.5 w-3.5 shrink-0" />
//<unit.logo className="h-5 w-5 shrink-0" />

// <DropdownMenuShortcut className="self-center">
//   ⌘{index + 1}
// </DropdownMenuShortcut>
