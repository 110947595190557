import {
  AnnotationHandler,
  HighlightedCode,
  Inline,
  Pre,
  RawCode,
  highlight,
} from "codehike/code";
import { cn } from "@/lib/utils";

import { fold } from "./annotations/fold";
import { link } from "./annotations/link";
// import theme from "@/theme.mjs"
import { lineNumbers } from "./annotations/line-numbers";
import { CodeIcon } from "./annotations/icons";
import { collapse } from "./annotations/collapse";
import { callout } from "./annotations/callout";
import { mark } from "./annotations/mark";
import { pill } from "./annotations/pill";
import { ruler } from "./annotations/ruler";
import { wordWrap } from "./annotations/word-wrap";
import { tokenTransitions } from "./annotations/token-transitions";
import { focus } from "./annotations/focus";
import { diff } from "./annotations/diff";
import { tooltip } from "./annotations/tooltip";
import { className as clsname } from "./annotations/classname";
import { CopyTextButton } from "@/components/format/Buttons";

import { useEffect, useState } from "react";
import { useDarkMode } from "@/lib/store";

export function InlineCode({ codeblock }: { codeblock: RawCode }) {
  const [highlighted, setHighlighted] = useState<HighlightedCode | null>(null);
  const isDark = useDarkMode();
  useEffect(() => {
    async function highlightCode() {
      const highlightedCode = await highlight(
        codeblock,
        isDark ? "dark-plus" : "light-plus",
      );
      setHighlighted(highlightedCode);
    }
    highlightCode();
  }, [codeblock, isDark]);
  if (!highlighted) return null;
  return <Inline code={highlighted} style={highlighted.style} />;
}

export function FlexCode(props: any) {
  return <Code {...props} className={"flex"} />;
}

export function Code({
  codeblock,
  ...rest
}: {
  codeblock: RawCode;
  className?: string;
  style?: React.CSSProperties;
  extraHandlers?: AnnotationHandler[];
}) {
  const { flags } = extractFlags(codeblock);
  const [highlighted, setHighlighted] = useState<HighlightedCode | null>(null);
  const isDark = useDarkMode();

  useEffect(() => {
    async function highlightCode() {
      const highlightedCode = await highlight(
        codeblock,
        isDark ? "dark-plus" : "light-plus",
        {
          annotationPrefix: flags.includes("p") ? "!!" : undefined,
        },
      );
      setHighlighted(highlightedCode);
    }
    highlightCode();
  }, [codeblock, isDark]);

  if (!highlighted) {
    return <div>Processing...</div>;
  }
  return <HighCode highlighted={highlighted} isDark={isDark} {...rest} />;
}

export function HighCode({
  highlighted,
  className,
  style,
  isDark,
  extraHandlers = [],
}: {
  highlighted: HighlightedCode;
  className?: string;
  style?: React.CSSProperties;
  isDark: boolean;
  extraHandlers?: AnnotationHandler[];
}) {
  const { title, flags } = extractFlags(highlighted);
  const h = { ...highlighted, meta: title };

  const handlers = [
    ...extraHandlers,
    mark,
    tooltip,
    pill,
    fold,
    link,
    focus,
    ruler,
    flags.includes("a") && tokenTransitions,
    flags.includes("n") && lineNumbers,
    diff,
    ...collapse,
    flags.includes("w") && wordWrap,
    callout,
    clsname,
  ].filter(Boolean) as AnnotationHandler[];

  const pre = (
    <Pre
      code={h}
      className="mb-0 py-2 px-0 bg-editor-background rounded-none group flex-1 selection:bg-editor-selectionBackground"
      handlers={handlers}
      style={{
        marginBottom: "0px",
        paddingRight: title ? "" : "64px",
      }}
    />
  );

  if (title) {
    return (
      <div
        className={cn(
          "max-w-3xl mx-auto border border-editorGroup-border rounded overflow-hidden my-2",
        )}
        style={
          {
            ...style,
          } as any
        }
      >
        <div className="px-3 py-2 border-b border-editorGroup-border bg-editorGroupHeader-tabsBackground text-sm text-tab-activeForeground flex">
          <div className="text-tab-activeForeground text-sm flex items-center gap-3">
            <CodeIcon title={title} isDark={isDark} />
            <span>{title} </span>
          </div>
          {flags.includes("c") && (
            <CopyTextButton text={h.code} className="ml-auto" />
          )}
        </div>
        {pre}
      </div>
    );
  } else {
    return (
      <div className="max-w-3xl mx-auto flex">
        <div
          className={cn(
            "border border-editorGroup-border rounded overflow-hidden my-2 relative ",
            className,
          )}
          style={
            {
              ...style,
            } as any
          }
        >
          {flags.includes("c") && (
            <CopyTextButton
              text={h.code}
              className="absolute right-4 my-0 top-2"
            />
          )}
          {pre}
        </div>
      </div>
    );
  }
}

export function extractFlags(codeblock: RawCode) {
  const flags =
    codeblock.meta.split(" ").filter((flag) => flag.startsWith("-"))[0] ?? "";
  const title =
    codeblock.meta === flags
      ? ""
      : codeblock.meta.replace(" " + flags, "").trim();
  return { title, flags: flags.slice(1).split("") };
}
