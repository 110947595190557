export const staticPackageJson = `{
  "scripts": {
    "dev": "servor --reload",
    "dev-port": "servor . index.html $PORT --reload",
    "start": "servor --reload"
  },
  "dependencies": {
    "servor": "^4.0.2"
  }
}`;

export const staticPackageLockJson = `{
    "name": "stackblitz-starters-2twj2h",
    "lockfileVersion": 2,
    "requires": true,
    "packages": {
      "": {
        "dependencies": {
          "servor": "^4.0.2"
        }
      },
      "node_modules/servor": {
        "version": "4.0.2",
        "resolved": "https://registry.npmjs.org/servor/-/servor-4.0.2.tgz",
        "integrity": "sha512-MlmQ5Ntv4jDYUN060x/KEmN7emvIqKMZ9OkM+nY8Bf2+KkyLmGsTqWLyAN2cZr5oESAcH00UanUyyrlS1LRjFw==",
        "bin": {
          "servor": "cli.js"
        }
      }
    },
    "dependencies": {
      "servor": {
        "version": "4.0.2",
        "resolved": "https://registry.npmjs.org/servor/-/servor-4.0.2.tgz",
        "integrity": "sha512-MlmQ5Ntv4jDYUN060x/KEmN7emvIqKMZ9OkM+nY8Bf2+KkyLmGsTqWLyAN2cZr5oESAcH00UanUyyrlS1LRjFw=="
      }
    }
  }`;

export default {
  "package.json": {
    code: staticPackageJson,
  },
  "package-lock.json": {
    code: staticPackageLockJson,
  },
};


const staticFiles = {
  "styles.css": {
    code: `* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: system-ui, sans-serif;
  color: black;
  background-color: white;
}

nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  gap: 0.5rem;
  border-bottom: solid 1px #aaa;
  background-color: #eee;
}

nav a {
  display: inline-block;
  min-width: 9rem;
  padding: 0.5rem;
  border-radius: 0.2rem;
  border: solid 1px #aaa;
  text-align: center;
  text-decoration: none;
  color: #555;
}

nav a[aria-current='page'] {
  color: #000;
  background-color: #d4d4d4;
}

main {
  padding: 1rem;
}

h1 {
  font-weight: bold;
  font-size: 1.5rem;
}
`,
  },
  "script.js": {
    code: `console.log('Hello!');`,
  },
  "page2.html": {
    code: `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Other page</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <link rel="stylesheet" href="styles.css" />
    <script type="module" src="script.js"></script>
  </head>
  <body>
    <nav>
      <a href="/">Home</a>
      <a href="/page2.html" aria-current="page">Other page</a>
    </nav>
    <main>
      <h1>My other page</h1>
    </main>
  </body>
</html>
`,
  },
  "index.html": {
    code: `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Home</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width" />
    <link rel="stylesheet" href="styles.css" />
    <script type="module" src="script.js"></script>
  </head>
  <body>
    <nav>
      <a href="/" aria-current="page">Home</a>
      <a href="/page2.html">Other page</a>
    </nav>
    <main>
      <h1>Home page</h1>
    </main>
  </body>
</html>
`,
  },
};
