import Editor, { OnMount, Monaco } from "@monaco-editor/react";
import { editor, Range, IRange } from "monaco-editor";
import {
  useActiveCode,
  SandpackStack,
  FileTabs,
  useSandpack,
} from "@codesandbox/sandpack-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAtom } from "jotai";
import {
  MonacoEditorButtons,
  lineNumbersAtom,
  fontSizeAtom,
} from "./MonacoEditorButtons";
import { useDarkMode } from "@/lib/store";
import { SandpackFiles } from "@/lms/playground/Playground";
import { CloudFiles } from "@/lms/files/CloudFiles";
import {
  getLineDecorations,
  getRegexDecorations,
  getTextDecorations,
} from "./utils/decorations";

interface MonacoEditorProps {
  baseFiles: Record<string, any>;
  isDark?: boolean;
  showFileTabs?: boolean;
  readOnly?: boolean;
  updateParentForm?: (files: SandpackFiles) => void;
  closableTabs?: boolean;
  highlightText: string;
  highlightRegex: string;
  highlightLines: string;
}

export function MonacoEditor({
  baseFiles,
  showFileTabs = true,
  readOnly = false,
  updateParentForm,
  closableTabs = false,
  highlightText,
  highlightRegex,
  highlightLines,
}: MonacoEditorProps) {
  // // At the top of your component
  // const updateParentFormTimeout = useRef<ReturnType<typeof setTimeout> | null>(
  //   null,
  // );

  const isDark = useDarkMode();
  const [isMounted, setIsMounted] = useState(false);
  const [versionId, setVersionId] = useState(1);
  const { sandpack } = useSandpack();
  const { files, activeFile, visibleFiles } = sandpack;
  const { code, updateCode } = useActiveCode();

  const editorRef = useRef<editor.IStandaloneCodeEditor | null>(null);
  const decorationsRef = useRef<editor.IEditorDecorationsCollection | null>(
    null,
  );

  const [lineNumbers] = useAtom(lineNumbersAtom);
  const [fontSize] = useAtom(fontSizeAtom);
  // const [highlightText, setHighlightText] = useState("add");
  // const [highlightRegex, setHighlightRegex] = useState("image\\(.*\\)");
  // const [highlightLines, setHighlightLines] = useState("");

  //send the changes up to form
  useEffect(() => {
    if (updateParentForm) updateParentForm({ files, activeFile, visibleFiles });
  }, [files, visibleFiles, activeFile]);

  //force re-render when base file changes
  useEffect(() => {
    setVersionId(versionId + 1);
    if (updateParentForm) {
      console.log("updateParentForm on BaseFiles")
      updateParentForm(baseFiles);
    }
  }, [baseFiles]);

  // Function to handle editor mounting and access editor and monaco instances
  const handleEditorDidMount: OnMount = (editor, monaco) => {
    editorRef.current = editor;
    decorationsRef.current = editor.createDecorationsCollection();
    setIsMounted(true);
  };

  const highlight = () => {
    if (decorationsRef.current && editorRef.current) {
      const model = editorRef.current.getModel();

      if (model) {
        const allDecorations = [
          ...getTextDecorations(model, highlightText),
          ...getRegexDecorations(model, highlightRegex),
          ...getLineDecorations(model, highlightLines),
        ];

        decorationsRef.current.set(allDecorations);
      } else {
        console.error("Editor model is null or undefined");
      }
    }
  };

  useEffect(() => {
    if (isMounted) highlight();
  }, [isMounted]);

  function handleOnChange(value: any) {
    updateCode(value || "", true);
    decorationsRef?.current?.set([]); //clear decorations on edit
  }

  return (
    <SandpackStack key={versionId} className="h-100 m-0">
      <div className="flex items-center ">
        {showFileTabs && (
          <FileTabs className="w-full" closableTabs={closableTabs} />
        )}
        {!readOnly && <CloudFiles />}
      </div>
      {!readOnly && <MonacoEditorButtons ref={editorRef} />}
      <div className="flex-1 pt-2 bg-white dark:bg-[#1e1e1e]">
        <Editor
          width="100%"
          height="100%"
          language={getLanguageOfFile(activeFile)}
          theme={isDark ? "dark-plus" : "light-plus"}
          key={activeFile}
          defaultValue={code}
          onChange={handleOnChange}
          options={{
            minimap: { enabled: false },
            wordWrap: "on",
            smoothScrolling: true,
            hover: { enabled: false },
            tabSize: 2,
            autoIndent: "advanced",
            formatOnPaste: true,
            formatOnType: true,
            folding: false,
            glyphMargin: false,
            fontSize: fontSize,
            lineNumbers: lineNumbers,
            readOnly: readOnly,
          }}
          onMount={handleEditorDidMount}
        />
      </div>
    </SandpackStack>
  );
}

export const getLanguageOfFile = (filePath: string) => {
  const extensionDotIndex = filePath.lastIndexOf(".");
  const extension = filePath.slice(extensionDotIndex + 1);

  switch (extension) {
    case "js":
    case "jsx":
    case "ts":
    case "tsx":
      return "javascript";
    case "vue":
    case "html":
      return "html";
    case "css":
    case "scss":
    case "less":
      return "css";
    case "json":
      return "json";
    case "py":
      return "python";
    case "svelte":
      return "html";
    default:
      return "javascript";
  }
};

// if(updateParentForm) updateParentForm({
//   files: { ...files, [activeFile]: { code: value } },
//   visibleFiles, //need this for check dirty
// });

// if (updateParentForm) {
//   // Clear the previous timeout if it exists
//   if (updateParentFormTimeout.current) {
//     clearTimeout(updateParentFormTimeout.current);
//   }

//   // Set a new timeout
//   updateParentFormTimeout.current = setTimeout(() => {
//     console.log("📣 updateParentFormTimeout");

//     updateParentForm({
//       files: { ...files, [activeFile]: { code: value } },
//       visibleFiles, //need this for check dirty
//     });
//   }, 1000); // 2-second delay
// }
