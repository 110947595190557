
import { useClipboard, setClipboard } from "@/lib/store";
import { ClipboardX } from "lucide-react";
import { Button } from "@/components/ui/button";

export function Clipboard() {
  const clipboard = useClipboard();


  const { item, type, isCut, border } = clipboard ? clipboard : {}
  if (!item) return null;

  return (
    <div
      className={`flex border-2 ${border} rounded-md pt-1 ps-4 text-xs justify-center`}
    >
      <div className="my-auto">
        <div className="font-bold">
          {type} {isCut ? "Cut" : "Copied"}
        </div>
        <div className="">{item.name ? item.name : item.id}</div>
      </div>
      <Button
        variant="ghost"
        size="sm"
        className="ms-1 p-2 my-auto"
        onClick={() => setClipboard(null)}
      >
        <ClipboardX size="20" />
      </Button>
    </div>
  );
}