import {
  TriangleAlert,
  OctagonAlert,
  MessageSquareWarning,
  Info,
  Lightbulb,
} from "lucide-react";

export const AlertIcons = {
  note: Info,
  tip: Lightbulb,
  important: MessageSquareWarning,
  caution: OctagonAlert,
  warning: TriangleAlert,
};
export default function Alert({ type = "note", title, children }) {
  const Icon = AlertIcons[type.toLowerCase()] || Info;
  return (
    <div
      className={`markdown-alert markdown-alert-${type.toLowerCase()} border rounded bg-secondary/25`}
    >
      <div className="pt-2 px-2">
        <div className="markdown-alert-title flex gap-2 pb-4">
          <Icon size={16} />
          {title ? (
            <span>{title}</span>
          ) : (
            <span className="capitalize">{type}</span>
          )}
        </div>

        {children}
      </div>
    </div>
  );
}
