import { FormFieldsProps } from "@/components/form/Form";

import {
  CommonAdminFields,
  MarkdownField,
  PlacementSelectField,
  RequiredTextField,
  WidthSelectField,
  HeightSelectField,
  UrlField,
} from "@/lms/core/LmsFields";
import { YouTubeEmbed } from "./YouTube";
import { GenericInputField } from "@/components/form/CustomFields";

const Id = "youtube";
const Name = "YouTube";

const Defaults = {
  url: "",
  description: "",
  type: Id,
  width: "md",
  height: "md",
  placement: "right",
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  const url = form.useStore((state: any) => state.values.url);
  const width = form.useStore((state: any) => state.values.width);
  const height = form.useStore((state: any) => state.values.height);
  const startTime = form.useStore((state: any) => state.values.startTime);
  const endTime = form.useStore((state: any) => state.values.endTime);
  const switchClasses = "grid border-s-2 ps-2 gap-1 w-24";

  return (
    <div className="grid gap-4 py-4 w-full">
      <div className="flex gap-4 w-full h-full">
        <div className="flex flex-col gap-4 ps-2 pe-6 border-e-2">
          <RequiredTextField
            form={form}
            name="url"
            label={`YouTube URL or Code`}
            className="grid gap-2 w-96"
          />
          {!locked && (
            <div className="flex gap-4 ">
              <WidthSelectField
                form={form}
                label="Max Width"
                className={switchClasses}
              />

              <HeightSelectField
                form={form}
                label="Max Height"
                className={switchClasses}
              />
              <PlacementSelectField className={switchClasses} form={form} />
            </div>
          )}
          <div className="flex gap-4 ">
            <GenericInputField
              form={form}
              className={switchClasses + " w-20"}
              label="Start Time (s)"
              description="Optional"
              name="startTime"
              type="number"
            />

            <GenericInputField
              form={form}
              className={switchClasses + " w-20"}
              label="End Time (s)"
              description="Optional"
              name="endTime"
              type="number"
            />
          </div>
        </div>
        <div className="flex w-full justify-center mx-auto max-w-3xl">
          <YouTubeEmbed
            width={width}
            height={height}
            url={url}
            startTime={startTime}
            endTime={endTime}
          />
        </div>
      </div>

      <MarkdownField
        form={form}
        name="description"
        label="Markdown"
        size="sm"
        disabled={isOriginal}
        vertical={!!locked}
      />

      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export default { Id, Name, Defaults, FormFields };
