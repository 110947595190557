import { Link } from "@/components/nav/Link";
import { SectionsResponse } from "@/lib/pb/types";
import { cn } from "@/lib/utils";
import { useParams } from "@tanstack/react-router";
import { BasicMarkdown } from "@/components/format/BasicMarkdown";

import { ComponentType } from "react";
import {
  Check,
  Circle,
  CircleCheck,
  CircleCheckBig,
  Minus,
} from "lucide-react";

export function SectionList({
  sections,
  detail,
  selected,
  progress,
}: {
  sections: Array<SectionsResponse>;
  detail: ComponentType<{ item: any }>;
  selected: boolean;
  progress?: any;
}) {
  return (
    <div className={cn("flex flex-col ms-3 ps-0")}>
      {sections && sections.length > 0 ? (
        sections.map((section) => {
          const sp = progress?.children[section.id];
          return (
            <SectionNode
              key={section.id}
              section={section}
              detail={detail}
              progress={sp}
            />
          );
        })
      ) : (
        <div className="p-2 text-foreground/50">No sections</div>
      )}
    </div>
  );
}

function SectionNode({
  section,
  detail: Detail,
  progress,
}: {
  section: SectionsResponse;
  detail: ComponentType<{ item: any }>;
  progress: any;
}) {
  const { sectionId } = useParams({ strict: false });
  const { id, active, index, url, children: elements } = section;
  const complete = progress?.complete ?? false;
  const selected = sectionId == id;

  return (
    <div className={cn("flex gap-1", active ? "" : "opacity-50")}>
      <Link
        to={`${url}#section-top`}
        className={cn(
          "relative min-w-8 flex gap-2 overflow-hidden ps-2 text-sm font-medium ring-ring transition-all hover:bg-secondary hover:text-secondary-foreground focus-visible:ring-2",
          "rounded-s-none",
          selected ? "border-s-4 bg-primary/50 text-secondary-foreground" : "border-s-2",
          complete ? "border-success" : "border-warning",
        )}
      >
        <div className="text-xs "> {index}</div>
        <div className="ps-2 break-normal ">
          <BasicMarkdown
            className="line-clamp-2 tracking-tight"
            code={(selected ? "##### " : "###### ") + section?.name}
          />
        </div>
        {/* <div className="absolute right-0">
          {complete ? (
            <CircleCheckBig className="text-success" />
          ) : (
            <Circle className="text-warning" />
          )}
        </div> */}
        {/* <div className="ms-auto flex text-xs bg-secondary h-3 w-3 items-center justify-center text-secondary-foreground rounded-sm">
          {elements?.length}
        </div> */}
      </Link>

      {Detail && (
        <div className="my-auto w-6">
          <Detail item={section.original || section} />
        </div>
      )}
    </div>
  );
}
