import { Outlet, createFileRoute, useParams } from "@tanstack/react-router";
import { useUnit, setLesson, useLesson, setSection, useCourse} from "@/lib/store";
import { useEffect } from "react";
export const Route = createFileRoute(
  "/_auth/course/$courseId/$unitId/$lessonId",
)({
  component: LessonComponent,
});

function LessonComponent() {
  const { lessonId } = useParams({ strict: false });

  const course = useCourse();

  useEffect(() => {
    //console.log("useEffect $lessonId.tsx");
    let newLesson = null;
    if (course?.lessons) {
      newLesson = course.lessons[lessonId] ?? null;
    }
    //console.log("setLesson to ", newLesson)
    setLesson(newLesson);
  }, [lessonId, course]);

  return <Outlet />;
}
