import { Button, ButtonProps } from "@/components/ui/button";
import {
  TriggerProps,
  useDialog,
  DialogForm,
  ContentsProps,
} from "@/components/format/Dialog";
import { cn } from "@/lib/utils";
import { Copy, Check, Scissors, Ellipsis, EyeOff } from "lucide-react";
import React, { ReactNode, useState } from "react";

interface IconButtonProps extends ButtonProps {
  label?: React.ReactNode;
  className?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  triggerProps?: TriggerProps<HTMLButtonElement>;
}

export function IconButton({
  label,
  className,
  icon: Icon,
  triggerProps,
  ...props
}: IconButtonProps) {
  return (
    <Button
      size="sm"
      variant="outline"
      className={cn("h-10 gap-2", className)}
      {...triggerProps}
      {...props}
    >
      {Icon && <Icon className="h-4 w-4" />}

      {label && (
        <span
          className={`${Icon ? "sr-only" : ""}  sm:not-sr-only sm:whitespace-nowrap my-auto`}
        >
          {label}
        </span>
      )}
    </Button>
  );
}

import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

interface IconDivProps {
  label?: string;
  className?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  triggerProps?: TriggerProps<HTMLDivElement>;
  disabled?: boolean;
  onClick?: () => void;
}
export function IconMenuItem({
  label,
  className,
  icon: Icon,
  triggerProps,
  disabled,
  onClick,
  ...props
}: IconDivProps) {
  return (
    <DropdownMenuItem
      className={cn("h-10 my-auto gap-2", className)}
      onClick={onClick}
      disabled={disabled}
      {...triggerProps}
      {...props}
    >
      {Icon && <Icon className="h-4 w-4" />}

      {label && (
        <span
          className={`${Icon ? "sr-only" : ""}  sm:not-sr-only xl:whitespace-nowrap my-auto`}
        >
          {label}
        </span>
      )}
    </DropdownMenuItem>
  );
}

interface DialogButtonProps<TResponse> extends ButtonProps {
  label?: ReactNode;
  className?: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  item?: any;
  contents: React.ComponentType<ContentsProps<TResponse>>;
  fullScreen?: boolean;
  onComplete?: () => void;
  actionState?: [boolean, Function];
  preventDefault?: boolean;
}

export function FullDialogButton<TResponse>({
  ...props
}: DialogButtonProps<TResponse>) {
  return <DialogButton {...props} fullScreen={true} />;
}

export function DialogButton<TResponse>({
  label,
  className,
  icon,
  item,
  contents: Contents,
  fullScreen,
  onComplete,
  actionState,
  preventDefault = false,
  ...props
}: DialogButtonProps<TResponse>) {
  const { triggerProps, dialogProps } =
    useDialog<HTMLButtonElement>(preventDefault);

  return (
    <>
      <IconButton
        triggerProps={triggerProps}
        icon={icon}
        className={className}
        label={label}
        {...props}
      />
      <DialogForm
        dialogProps={dialogProps}
        contents={Contents}
        onComplete={onComplete}
        fullScreen={fullScreen}
        actionState={actionState}
        item={item}
      />
    </>
  );
}

//CopyText
export function CopyTextButton({
  text,
  className,
  label,
  ...props
}: {
  text: string;
  className?: string;
  label?: ReactNode
}) {
  const [copied, setCopied] = useState(false);

  return (
    <Button
      size="sm"
      variant="ghost"
      className={cn(
        `hover:bg-gray-400/20 -m-1 rounded hidden sm:flex gap-2`,
        className,
      )}
      aria-label="Copy to clipboard"
      onClick={() => {
        navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 1200);
         toast.success("Copied to clipboard");
      }}
      {...props}
    >
      {copied ? <Check size={16} /> : <Copy size={16} />} {label}
    </Button>
  );
}

import { setClipboard, useClipboard } from "@/lib/store";
import { toast } from "sonner";

export function CopyCutButtons({
  item,
  type,
}: {
  item: { id: string; courseId: string };
  type: string;
}) {
  function clip(isCut: boolean) {
    setClipboard({ item, type, isCut });
    toast.success(`${type} copied to clipboard`);
  }
  return (
    <>
      <IconMenuItem
        label={`Copy ${type}`}
        icon={Copy}
        onClick={() => clip(false)}
      />
      <IconMenuItem
        label={`Cut ${type}`}
        icon={Scissors}
        onClick={() => clip(true)}
      />
    </>
  );
}

import { DropdownMenuTrigger } from "@/components/ui/dropdown-menu";

export function MenuButton({
  id,
  size = 3,
  className,
  active = true,
  ...props
}: {
  id: string;
  size: number;
  className?: string;
  active?: boolean;
}) {
  const clipboard = useClipboard();

  const border =
    clipboard?.item?.id != id
      ? " border-2 border-transparent"
      : ` border-2 ${clipboard?.border}`;

  return (
    <DropdownMenuTrigger asChild>
      <Button
        variant="ghost"
        size="sm"
        className={cn(`p-0 h-${size + 2} w-${size + 2} `, className, border)}
        {...props}
      >
        {active ? (
          <Ellipsis className={`text-foreground/50 h-${size} w-${size}`} />
        ) : (
          <EyeOff className={`text-foreground/50 h-${size} w-${size}`} />
        )}
        <span className="sr-only">Menu</span>
      </Button>
    </DropdownMenuTrigger>
  );
}

export function PasteButton({ icon, label, isCut, onClick }) {
  const style = isCut
    ? "h-8 bg-destructive/75 border hover:border-destructive"
    : "h-8 bg-warning/75 border hover:border-warning";
  return (
    <IconMenuItem
      label={label}
      icon={icon}
      className={style}
      onClick={onClick}
    />
  );
}
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

export function TooltipButton({
  children,
  icon: Icon,
  onClick,
  info,
  ...props
}: IconButtonProps & { info: ReactNode }) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={(e) => {
              e.preventDefault();
              if (onClick) onClick(e);
            }}
            {...props}
          >
            {Icon ? <Icon className="h-4 w-4" /> : children}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

export function TooltipDialogButton({
  children,
  icon: Icon,
  onClick,
  info,
  iconSize,
  ...props
}: IconButtonProps & { info: ReactNode, iconSize?: string })
{
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div>
            <Dialog modal={false}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  className="h-8 p-2 rounded-md text-primary border border-primary hover:bg-primary hover:text-primary-foreground"
                  {...props}
                >
                  <div className="flex items-center">
                    {Icon && <Icon className={iconSize ? iconSize: "h-4 w-4"} />}
                  </div>
                </Button>
              </DialogTrigger>
              <DialogContent className="flex border-2 border-primary/75 max-w-full max-h-screen">
                {children}
              </DialogContent>
            </Dialog>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
// export function PasteButtons({ pasteTop, pasteBottom, isCut }) {
//   return (
//     <div className="flex gap-1">
//       <PasteButton
//         label={`Paste`}
//         icon={ArrowUpToLine}
//         onClick={pasteTop}
//         isCut={isCut}
//       />
//       <PasteButton
//         label={`Paste`}
//         icon={ArrowDownToLine}
//         onClick={pasteBottom}
//         isCut={isCut}
//       />
//     </div>
//   );
// }
