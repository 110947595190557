import { Link, useRouter } from "@tanstack/react-router";
import { Form, SubmitButton, zodValidator } from "@/components/form";
import { useRegisterMutation } from "@/lib/pb/auth";
import { useForm } from "@tanstack/react-form";
import { GoogleLogInButton } from "@/components/auth/GoogleLoginButton";
import {
  NameField,
  LastNameField,
  EmailField,
  NewPasswordField,
} from "@/components/form/CustomFields";

export default function SignupForm() {
  const register = useRegisterMutation();
  const router = useRouter();

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      email: "",
      password: "",
      name: "",
      lastName: "",
    },
    onSubmit: async ({ value }) => {
      if (register.isPending) return;
      await register.mutateAsync(value).catch((e) => console.log(e));
      router.invalidate();
    },
  });
  return (
    <Form form={form}>
      <div className="grid gap-4">
        <NameField form={form} className="grid gap-2" />
        <LastNameField form={form} className="grid gap-2" />
        <EmailField form={form} className="grid gap-2" />
        <NewPasswordField form={form} className="grid gap-2" />

        <SubmitButton
          className="w-full"
          label="Create an account"
          form={form}
          disabled={register.isPending}
        />

        <GoogleLogInButton />
      </div>
      <div className="mt-4 text-center text-sm">
        Already have an account?{" "}
        <Link to="/login" className="underline">
          Sign in
        </Link>
      </div>
    </Form>
  );
}
