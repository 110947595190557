import { base62 } from "mudder";

interface ItemType {
  id: string;
  rank: string;
}

const defaultRank = "A"; //base62.mudder(1)[0];

export function sortItems(items: ItemType[]) {
  return items ? [...items].sort((a, b) => (a.rank > b.rank ? 1 : -1)) : [];
}

export function getIndex(id: string, list: ItemType[]) {
  return id ? list.findIndex((i) => i.id === id) : -1;
}

export const Rank = {
  bottom: moveBottom,
  top: moveTop,
  up: moveUp,
  down: moveDown,
  above: moveAbove,
  below: moveBelow,
};

export function moveBottom(id: string, items: ItemType[]) {
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  if (id) {
    const index = getIndex(id, list);
    if (index == list.length - 1) return null; //unneeded
  }
  return base62.mudder(list[list.length - 1].rank)[0];
}

export function moveTop(id: string, items: ItemType[]) {
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  if (id) {
    const index = getIndex(id, list);
    if (index == 0) return null; //unneeded
  }
  return base62.mudder(undefined, list[0].rank)[0];
}

export function moveUp(id: string, items: ItemType[]) {
  if (!id) return null;
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  const index = getIndex(id, list);
  if (index == 0 || index == -1) return null; //unneeded
  const start = index == 1 ? undefined : list[index - 2].rank;
  const end = list[index - 1].rank;
  return base62.mudder(start, end)[0];
}

export function moveDown(id: string, items: ItemType[]) {
  if (!id) return null;
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  const index = getIndex(id, list);
  if (index == list.length - 1 || index == -1) return null; //unneeded

  const start = list[index + 1].rank;
  const end = index == list.length - 2 ? undefined : list[index + 2].rank;

  return base62.mudder(start, end)[0];
}

export function moveAbove(id: string, items: ItemType[]) {
  if (!id) return null;
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  const index = getIndex(id, list);
  if (index == -1) return null; //unneeded
  const start = index == 0 ? undefined : list[index - 1].rank;
  const end = list[index].rank;
  return base62.mudder(start, end)[0];
}

export function moveBelow(id: string, items: ItemType[]) {
  if (!id) return null;
  if (!items || items.length < 1) return defaultRank;
  const list = sortItems(items);
  const index = getIndex(id, list);
  if (index == -1) return null; //unneeded
  const start = list[index].rank;

  const end = index == list.length - 1 ? undefined : list[index + 1].rank;
  return base62.mudder(start, end)[0];
}
