import { FormFieldsProps } from "@/components/form/Form";
import { ImageViewer } from "@/lms/elements/Image";
import {
  CommonAdminFields,
  MarkdownField,
  PlacementSelectField,
  WidthSelectField,
  HeightSelectField,
  UrlField,
} from "@/lms/core/LmsFields";

const Id = "image";
const Name = "Image";

const Defaults = {
  url: "",
  description: "",
  type: Id,
  width: "md",
  height: "md",
  placement: "right",
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  const url = form.useStore((state: any) => state.values.url);
  const width = form.useStore((state: any) => state.values.width);
  const height = form.useStore((state: any) => state.values.height);
  const switchClasses = "grid border-s-2 ps-2 gap-1 w-24";

  return (
    <div className="grid gap-4 py-4 w-full">
      <div className="flex gap-4 w-full ">
        <div className="flex flex-col gap-4 border-e-2 ps-2 pe-6">
          <UrlField
            name="url"
            label="Image URL"
            form={form}
            className="flex flex-col gap-2 w-[400px] "
          />
          {!locked && (
            <div className="flex">
              <WidthSelectField
                form={form}
                label="Max Width"
                className={switchClasses}
              />

              <HeightSelectField
                form={form}
                label="Max Height"
                className={switchClasses}
              />
              <PlacementSelectField className={switchClasses} form={form} />
            </div>
          )}
        </div>

        <div className="flex max-w-3xl h-full justify-center mx-auto ">
          {url && (
            <ImageViewer
              width={width}
              height={height}
              url={url}
              className="border-4 border-info bg-white"
            />
          )}
        </div>
      </div>

      <MarkdownField
        form={form}
        name="description"
        label="Markdown"
        size="sm"
        disabled={isOriginal}
        vertical={!!locked}
      />

      {!locked && <CommonAdminFields form={form}></CommonAdminFields>}
    </div>
  );
}

export default { Id, Name, Defaults, FormFields };
