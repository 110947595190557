import { z } from "zod";
import {
  Selection,
  Selectable,
  SelectionProvider,
} from "codehike/utils/selection";
import { Block, CodeBlock, parseProps, parseRoot } from "codehike/blocks";
import { Code } from "./CHCode";

const Schema = Block.extend({
  steps: z.array(Block.extend({ code: CodeBlock })),
});

export default function Scrollycoding(props: unknown) {
  const { steps } = parseProps(props, Schema);
  return (
    <SelectionProvider className="flex gap-4 wide-content ">
      <div className="flex-1 mt-[0vh] mb-[30vh] ml-2 prose max-w-xl">
        {steps.map((step, i) => (
          <Selectable
            key={i}
            index={i}
            selectOn={["click", "scroll"]}
            className="border-l-4 data-[selected=true]:border-primary px-5 py-2 mb-24 rounded bg-card bg-secondary"
          >
            <div className="mt-2 border-b text-xl font-bold mb-4">
              {" "}
              {step.title}
            </div>
            <div>{step.children}</div>
          </Selectable>
        ))}
      </div>
      <div className="mt-4 w-1/2 max-w-xl bg-card">
        <div className="top-4 md:top-16 xl:top-64  sticky overflow-auto">
          <Selection
            from={steps.map((step) => (
              <Code codeblock={step.code} />
            ))}
          />
        </div>
      </div>
    </SelectionProvider>
  );
}
