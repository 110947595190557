import { visit } from 'unist-util-visit';
import { toString } from 'hast-util-to-string';
import { Root } from 'hast';
import { VFile } from 'vfile';

interface Heading {
  depth: number;
  text: string;
  id?: string;
}

function toMarkdownString(node) {
  if (node.type === 'text') {
    return node.value;
  }
  if (node.tagName === 'code') {
    return '`' + toMarkdownString(node.children[0]) + '`';
  }
  if (node.children) {
    return node.children.map(toMarkdownString).join('');
  }
  return '';
}


export default function rehypeCollectHeadings() {
  return (tree: Root, file: VFile) => {
    const headings: Heading[] = [];

    visit(tree, 'element', (node: any) => {
      if (/^h[1-6]$/.test(node.tagName)) {
        const text = toMarkdownString(node);
        const id = node.properties?.id;

        headings.push({
          depth: Number(node.tagName.charAt(1)),
          text,
          id,
        });
      }
    });

    file.data.headings = headings;
  };
}

