import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";

import { MoveButtons } from "@/lms/core/MoveButtons";
import {
  MenuButton,
  IconMenuItem,
  CopyCutButtons,
} from "@/components/format/Buttons";

import { DialogForm, useDialog } from "@/components/format/Dialog";

import { SectionsResponse } from "@/lib/pb/types";
import { useLanguage } from "@/lib/lang";
import {
  Pencil,
  Languages,
  Trash2,
  Eye,
  EyeOff,
  SquareArrowUp,
  SquareArrowDown,
  ClipboardPaste,
} from "lucide-react";
import {
  SectionDeleteForm,
  SectionEditForm,
  SectionTranslateForm,
} from "@/lms/section/SectionAdminForm";
import { useEditSectionMutation } from "@/lib/pb/lms";
import { useClipboard } from "@/lib/store";
import { Rank } from "@/lms/core/Rank";
import { ElementPasteButton } from "../element/ElementPasteButton";
import { SectionPasteButton } from "./SectionPasteButton";

export default function SectionAdminMenu({
  item,
  size = 3,
}: {
  item: SectionsResponse;
  size: number;
}) {
  const language = useLanguage();
  const editDialog = useDialog<HTMLDivElement>();
  const deleteDialog = useDialog<HTMLDivElement>();
  const translateDialog = useDialog<HTMLDivElement>();

  const type = "Section";
  const {
    id,
    lessonId,
    courseId,
    rank,
    active,
    children: elements,
    siblings: sections,
  } = item;

  const clipboard = useClipboard();

  const { mutateAsync: edit, isPending } = useEditSectionMutation(
    courseId,
    lessonId,
    id,
  );

  if (!item) return;

  return (
    <DropdownMenu>
      <MenuButton id={id} size={size} active={active} />

      <DropdownMenuContent
        side="right"
        align="start"
        className="border-2 border-primary/75"
      >
        <div className="flex gap-1">
          {elements && (
            <ElementPasteButton
              icon={SquareArrowUp}
              courseId={courseId}
              sectionId={id}
              rank={Rank.top(null, elements)}
            />
          )}
          {elements && (
            <ElementPasteButton
              icon={SquareArrowDown}
              courseId={courseId}
              sectionId={id}
              rank={Rank.bottom(null, elements)}
            />
          )}
        </div>
        <div className="flex gap-1">
          <SectionPasteButton
            icon={SquareArrowUp}
            courseId={courseId}
            lessonId={lessonId}
            rank={Rank.above(item.id, sections)}
          />
          <SectionPasteButton
            icon={SquareArrowDown}
            courseId={courseId}
            lessonId={lessonId}
            rank={Rank.below(item.id, sections)}
          />
        </div>
        <MoveButtons edit={edit} item={item} items={sections} />

        <IconMenuItem
          triggerProps={editDialog.triggerProps}
          label={`Edit ${type}`}
          icon={Pencil}
        />
        <IconMenuItem
          triggerProps={translateDialog.triggerProps}
          label={`Translate ${type}`}
          icon={Languages}
          disabled={!language || language == "en"}
        />
        <CopyCutButtons item={item} type={type} />
        
        <IconMenuItem
          triggerProps={deleteDialog.triggerProps}
          label={`Delete ${type}`}
          icon={Trash2}
        />
        <IconMenuItem
          label={item.active ? "Make Inactive" : "Make Active"}
          icon={active ? EyeOff : Eye}
          onClick={async () => {
            if (isPending) return;
            await edit({ id, data: { active: !active } });
          }}
        />
        <DropdownMenuItem
          onClick={() => navigator.clipboard.writeText(id)}
          className="text-muted-foreground font-xs"
        >
          id: {id} ({rank})
        </DropdownMenuItem>
      </DropdownMenuContent>

      <DialogForm
        item={item}
        dialogProps={editDialog.dialogProps}
        className="sm:max-w-2xl"
        contents={SectionEditForm}
      />
      <DialogForm
        item={item}
        dialogProps={translateDialog.dialogProps}
        className="sm:max-w-full"
        contents={SectionTranslateForm}
      />
      <DialogForm
        item={item}
        dialogProps={deleteDialog.dialogProps}
        className="sm:max-w-md"
        contents={SectionDeleteForm}
      />
    </DropdownMenu>
  );
}
