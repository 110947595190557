import { Header } from "@/components/format/Dialog";
import { Users } from "lucide-react";
import { CourseEnrollmentsTable } from "@/lms/course/CourseEnrollmentsTable";
import { CoursesResponse } from "@/lib/pb/types";
//import { ClassFilter } from "../classes/ClassFilter";

import { ScrollArea } from "@/components/ui/scroll-area";

export function CourseEnrollmentsDialog({ item }: { item: CoursesResponse }) {
  return (
    <div className="flex flex-col gap-2">
      <Header
        title={
          <div className="flex text-primary items-center gap-2">
            <Users className="h-6 w-6 me-2 text-4xl" /> Enrollments
            {/* <ClassFilter className="text-sm ms-10" /> */}
          </div>
        }
      />
      <ScrollArea className="h-[90dvh]">
        <div className="flex flex-col ">
         <CourseEnrollmentsTable course={item} />
        </div>
      </ScrollArea>
    </div>
  );
}
