const files = {
 "src/app.html": {
    code: `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />

    <meta name="viewport" content="width=device-width" />
    %sveltekit.head%
  </head>
  <body data-sveltekit-preload-data="hover">
    <div style="display: contents">%sveltekit.body%</div>
  </body>
</html>`
  },
  "package.json": {
    hidden: true,
    code: `
{
  "name": "sveltkittemplate",
  "version": "0.0.1",
  "private": true,
  "scripts": {
    "dev": "vite dev",
    "dev-port": "vite dev --port $PORT",
    "build": "vite build",
    "preview": "vite preview",
    "lint": "prettier --plugin-search-dir . --check .",
    "format": "prettier --plugin-search-dir . --write ."
  },
  "devDependencies": {
    "@sveltejs/adapter-auto": "^3.2.5",
    "@sveltejs/kit": "^2.6.3",
    "@sveltejs/vite-plugin-svelte": "^3.1.2",
    "prettier": "^3.3.3",
    "prettier-plugin-svelte": "^3.2.7",
    "svelte": "^4.2.19",
    "vite": "^5.4.8"
  },
  "type": "module",
  "dependencies": {
    "svelte-check": "^4.0.4"
  }
}`,
  },
  "svelte.config.js": {
    code: `
import adapter from '@sveltejs/adapter-auto';

/** @type {import('@sveltejs/kit').Config} */
const config = {
  kit: {
    // adapter-auto only supports some environments, see https://kit.svelte.dev/docs/adapter-auto for a list.
    // If your environment is not supported or you settled on a specific environment, switch out the adapter.
    // See https://kit.svelte.dev/docs/adapters for more information about adapters.
    adapter: adapter()
  }
};

export default config;
`,
  },
  "vite.config.js": {
    code: `
import { sveltekit } from '@sveltejs/kit/vite';
import { defineConfig } from 'vite';

export default defineConfig({
  plugins: [sveltekit()]
});`,
  },
};


export default {
  activeFile: "app.html",
  visibleFiles: ["app.html"],
  files,
}

 