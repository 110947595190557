//For View Components
import Markdown from "@/lms/elements/Markdown";
import FreeResponse from "@/lms/elements/FreeResponse";
import MultipleChoice from "@/lms/elements/MultipleChoice";
import CodePlayground from "@/lms/elements/CodePlayground";
import Image from "@/lms/elements/Image";
import YouTube from "@/lms/elements/YouTube";
import FileSubmission from "@/lms/elements/FileSubmission";
import LinkButton from "@/lms/elements/LinkButton";
import Feedback from "@/lms/elements/Feedback";
export default {
  markdown: Markdown,
  "free-response": FreeResponse,
  "multiple-choice": MultipleChoice,
  "code-playground": CodePlayground,
  image: Image,
  youtube: YouTube,
  "file-submission": FileSubmission,
  "link": LinkButton,
  feedback: Feedback,
};
