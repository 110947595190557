import {
  NameField,
  MarkdownField,
  CommonAdminFields,
  OptionalTextField,
} from "@/lms/core/LmsFields";
import { zodValidator } from "@/components/form";
import { useForm } from "@tanstack/react-form";
import {
  useEditUnitMutation,
  useAddUnitMutation,
  useUnitTranslation,
  useDeleteUnitMutation,
  unitDetailQuery,
  useQuery,
} from "@/lib/pb/lms";
import { CoursesResponse, UnitsRecord, UnitsResponse } from "@/lib/pb/types";

import {
  BaseForm,
  TranslateForm,
  ConfirmDeleteForm,
  FormFieldsProps,
} from "@/components/form/Form";
import { ContentsProps } from "@/components/format/Dialog";
import { useLanguage, getLanguageName } from "@/lib/lang";
import { setClipboard, useClipboard } from "@/lib/store";

const UNIT_DEFAULTS = {
  name: "",
  description: "",
  active: true,
  access: 200,
  courseId: "",
  subtitle: "",
  rank: "",
};

function FormFields({ isOriginal, isTranslate, form }: FormFieldsProps) {
  const locked = isOriginal || isTranslate;
  return (
    <div className="grid gap-4 px-1 py-4">
      <div className="flex flex-wrap gap-4 w-full">
        <NameField form={form} className="grid gap-2 w-80 " />
        <OptionalTextField
          form={form}
          label="Subtitle"
          name="subtitle"
          className="w-96"
        />
      </div>
      <MarkdownField
        form={form}
        name="description"
        label="Description"
        disabled={isOriginal}
        vertical={!!locked}
      />
      {!locked && <CommonAdminFields form={form} />}
    </div>
  );
}

export function UnitEditForm({
  item,
  onComplete,
}: ContentsProps<UnitsResponse>) {
  if (!item) return;
  //pull the latest the item here is translated
  const { mutateAsync: edit, isPending } = useEditUnitMutation(
    item.courseId,
    item.id,
  );

  //get full item with translation
  const { data: fullItem, tQuery } = useQuery(unitDetailQuery(item?.id, true));
  const languages = Object.keys(fullItem?.translations ?? {});

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: item?.name,
      description: item?.description,
      active: item?.active,
      access: item?.access,
      subtitle: item?.subtitle,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      const { submitButton, ...rest } = value;
      const data = rest as UnitsRecord;
      await edit({ id: item.id, data }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  });
  return (
    <BaseForm
      title="Edit Unit"
      form={form}
      save={true}
      extras={
        <TranslationReset
          languages={languages}
          reset={async () =>
            await edit({ id: item.id, data: { translations: null } })
          }
        />
      }
    >
      <FormFields form={form} />
    </BaseForm>
  );
}

import { Rank } from "@/lms/core/Rank";
import { getOne } from "@/lib/pb";
import { TranslationReset } from "../translate/TranslationReset";

export function UnitAddForm({
  item,
  onComplete,
}: ContentsProps<CoursesResponse>) {
  const { id: courseId, children: units } = item ?? {};
  const { mutateAsync: add, isPending } = useAddUnitMutation(courseId);

  const form = useForm({
    validatorAdapter: zodValidator(),
    defaultValues: { ...UNIT_DEFAULTS },

    onSubmit: async ({ value }: { value: UnitsRecord }) => {
      if (isPending) return;
      value.courseId = courseId;
      value.rank = Rank.bottom(null, units ?? []);
      await add({ data: value }).catch((e) => console.error(e));
      onComplete();
    },
  });

  if (!item) return <div>UnitAddForm: no item</div>;
  return (
    <BaseForm title="Add Unit" form={form}>
      <FormFields form={form} />
    </BaseForm>
  );
}
//Handle Fetch of Translation object
export function UnitTranslateForm({
  item,
  onComplete,
}: ContentsProps<UnitsResponse>) {
  const { data, isPending, error } = useUnitTranslation(item?.id);
  if (!item || isPending || error) return;

  return (
    <InnerTranslateForm
      item={item}
      onComplete={onComplete}
      translation={data ? data : item}
    />
  );
}

function InnerTranslateForm({
  item,
  onComplete,
  translation,
}: ContentsProps<UnitsResponse> & { translation: any }) {
  const { id, courseId } = item;
  const { mutateAsync: edit, isPending } = useEditUnitMutation(courseId, id);

  const formOptions = {
    validatorAdapter: zodValidator(),
    defaultValues: {
      name: translation.name,
      description: translation.description,
      subtitle: translation.subtitle,
      submitButton: "submit",
    },
    onSubmit: async ({ value }: { value: any }) => {
      if (isPending) return;
      //fetch entire translation JSON because you cant partial update
      const record = await getOne("units", item.id, {
        fields: "translations",
      });
      const translations = record?.translations ? record.translations : {};
      const { submitButton, ...rest } = value;
      translations[languageCode] = rest;
      await edit({ id, data: { translations } }).catch((e) => console.log(e));
      if (submitButton == "save") return;
      onComplete();
    },
  };
  const form = useForm(formOptions);
  const languageCode = useLanguage();
  const languageName = getLanguageName(languageCode);

  return (
    <TranslateForm
      form={form}
      fields={FormFields}
      item={item}
      language={`${languageName} (${languageCode})`}
      type="Unit"
    />
  );
}

export function UnitDeleteForm({
  item,
  onComplete,
}: ContentsProps<UnitsResponse>) {
  const clipboard = useClipboard();
  const { id, courseId, name, children: lessons } = item;
  const { mutateAsync: deleteItem, isPending } =
    useDeleteUnitMutation(courseId);

  async function remove() {
    if (isPending) return;
    await deleteItem({ id }).catch((e) => console.log(e));
    if (id == clipboard?.item?.id) {
      setClipboard(null);
    }
    onComplete();
  }
  if (!item) return;
  const isEmpty = !lessons || lessons.length < 1;

  const formProps = isEmpty
    ? { formOptions: { onSubmit: remove } }
    : {
        formOptions: {
          validatorAdapter: zodValidator(),
          defaultValues: { name: "" },
          onSubmit: async ({ value }: { value: UnitsRecord }) => {
            if (value.name !== name) return;
            await remove();
          },
        },
        match: name,
        fieldName: "name",
      };

  return (
    <ConfirmDeleteForm {...formProps} onComplete={onComplete} type="Unit" />
  );
}
