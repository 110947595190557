// Import necessary types
import { editor, Range, IRange } from "monaco-editor";
///import type * as monacoEditor from 'monaco-editor/esm/vs/editor/editor.api';


const matchHighlightClass =  "monaco-code-highlight ";
 
// Helper function to parse line numbers input
function parseLineNumbers(input: string): number[] {
  const lines = new Set<number>();

  input.split(',').forEach((part) => {
    const rangeMatch = part.trim().match(/^(\d+)-(\d+)$/);
    if (rangeMatch) {
      const start = parseInt(rangeMatch[1], 10);
      const end = parseInt(rangeMatch[2], 10);
      for (let i = start; i <= end; i++) {
        lines.add(i);
      }
    } else {
      const line = parseInt(part.trim(), 10);
      if (!isNaN(line)) {
        lines.add(line);
      }
    }
  });

  return Array.from(lines);
}

// Function to get decorations for simple text matching
export function getTextDecorations(
  model: editor.ITextModel,
  searchString: string
): editor.IModelDeltaDecoration[] {
  if (!searchString) return [];

  const matches = model.findMatches(
    searchString,
    true,  // search only editable range
    false, // isRegex
    false, // matchCase (set to true for case-sensitive)
    null,  // wordSeparators
    true   // captureMatches
  );

  const decorations = matches.map((match) => ({
    range: match.range,
    options: {
      inlineClassName: matchHighlightClass,
    },
  }));

  return decorations;
}

// Function to get decorations for regex matching
export function getRegexDecorations(
  model: editor.ITextModel,
  pattern: string
): editor.IModelDeltaDecoration[] {
  if (!pattern) return [];

  let regex: RegExp;
  try {
    regex = new RegExp(pattern, "g");
  } catch (e) {
    console.error('Invalid regex pattern:', e);
    return [];
  }

  const matches = model.findMatches(
    regex,     // Empty string because we provide the regex directly
    true,   // search only editable range
    true,   // isRegex
    false,  // matchCase (irrelevant for regex)
    null,   // wordSeparators
    true,   // captureMatches
  );

  const decorations = matches.map((match) => ({
    range: match.range,
    options: {
      inlineClassName: matchHighlightClass,
    },
  }));

  return decorations;
}

// Function to get decorations for line numbers
export function getLineDecorations(
  model: editor.ITextModel,
 // monaco: typeof monacoEditor,
  lineNumbersInput: string
): editor.IModelDeltaDecoration[] {
  if (!lineNumbersInput) return [];

  const lineNumbers = parseLineNumbers(lineNumbersInput);
  const maxLineNumber = model.getLineCount();

  const decorations = lineNumbers
    .filter((line) => line >= 1 && line <= maxLineNumber)
    .map((line) => ({
      range: new Range(line, 1, line, model.getLineMaxColumn(line)),
      options: {
        isWholeLine: true,
        className: matchHighlightClass,
      },
    }));
  return decorations;
}
