import { Link } from "@tanstack/react-router";
import { Search, type LucideIcon } from "lucide-react";
import { useIsMobile } from "@/components/sidebar/use-mobile";
import { matchSorter } from "match-sorter";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { useSearchResults } from "@/lib/store";
import { useEffect, useState } from "react";
import { BasicMarkdown } from "../format/BasicMarkdown";
import { useNavigate } from "@tanstack/react-router";

//const itemRank = rankItem(row.getValue(columnId), value);

export function SidebarSearch() {
  // const isMobile = useIsMobile();
  const allResults = useSearchResults();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  function search() {
    if (!allResults) return;
    const res = matchSorter(allResults, searchQuery, {
      keys: ["index", "name"],
    });
    if (res) setSearchResults(res);
  }

  useEffect(() => {
    // Set a timeout to delay calling the search function
    const handler = setTimeout(() => {
      if (searchQuery.length > 0) {
        search();
      }
    }, 200);

    // Clear the timeout if searchQuery changes before 200ms
    return () => clearTimeout(handler);
  }, [searchQuery]);

  // if (isMobile) {
  //   return (
  //     <Drawer>
  //       <DrawerTrigger className=" min-w-8 flex h-8 w-full flex-1 items-center gap-2 overflow-hidden rounded-md px-1.5 text-sm font-medium outline-none ring-ring transition-all hover:bg-accent hover:text-accent-foreground focus-visible:ring-2 data-[state=open]:bg-accent data-[state=open]:text-accent-foreground border-2">
  //         <Search className="h-4 w-4 shrink-0" />
  //         <div className="flex flex-1 overflow-hidden">
  //           <div className="line-clamp-1 pr-6">Search</div>
  //         </div>
  //       </DrawerTrigger>
  //       <DrawerContent>
  //         <form>
  //           <div className="border-b p-2.5">
  //             <Input
  //               type="search"
  //               placeholder="Search..."
  //               className="h-8 rounded-sm shadow-none focus-visible:ring-0"
  //             />
  //           </div>
  //         </form>
  //         <div className="grid gap-1 p-1.5 text-sm">
  //           {searchResults?.map((result, index) => (
  //             <div key={index}>{result}</div>
  //           ))}
  //           {results.map((result) => (
  //             <Link
  //               to={result.url}
  //               key={result.title}
  //               className="rounded-md p-2.5 outline-none ring-ring hover:bg-accent hover:text-accent-foreground focus-visible:ring-2"
  //             >
  //               <div className="font-medium">{result.title}</div>
  //               <div className="line-clamp-2 text-muted-foreground">
  //                 {result.teaser}
  //               </div>
  //             </Link>
  //           ))}
  //           <Separator className="my-1.5" />
  //           <Link
  //             to="#"
  //             className="rounded-md px-2.5 py-1 text-muted-foreground outline-none ring-ring hover:text-foreground focus-visible:ring-2"
  //           >
  //             See all results
  //           </Link>
  //         </div>
  //       </DrawerContent>
  //     </Drawer>
  //   );
  // }
  //overflow-hidden
  return (
    <Popover>
      <PopoverTrigger className="min-w-8 flex h-8 justify-center items-center gap-2  rounded-full p-1.5 text-sm font-medium outline-none ring-ring transition-all hover:bg-accent hover:text-accent-foreground focus-visible:ring-2 data-[state=open]:bg-accent data-[state=open]:text-accent-foreground bg-accent">
        <Search className="h-4 w-4 shrink-0" />
        <div className="flex overflow-hidden ">
          <div className="line-clamp-1 pr-6">Search</div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="bottom"
        align="start"
        sideOffset={4}
        className="w-96 p-0 border-primary"
      >
        <div className="border-b p-2.5">
          <Input
            type="search"
            placeholder="Search..."
            className="h-8 rounded-sm shadow-none focus-visible:ring-0"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

        <div className="grid gap-1 p-1.5 text-sm ">
          {searchResults?.map((result, key) => {
            //const [index, name] = result.split(", ");
            if (key > 8) return;
            return (
              <div
                key={key}
                className="rounded-md p-2.5 outline-none ring-ring hover:bg-accent hover:text-accent-foreground focus-visible:ring-2 flex gap-4 items-center cursor-pointer"
                onClick={() => navigate({ to: result.url })}
              >
                <div className="font-bold w-12 text-end">{result.index}</div>
                <div className="line-clamp-1 max-w-64">
                  <BasicMarkdown code={"##### " + result.name} />
                </div>
              </div>
            );
          })}

          <Separator className="my-1.5" />
          <div className="rounded-md px-2.5 py-1 text-muted-foreground outline-none">
            {searchResults?.length ? searchResults.length : 0} results
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
