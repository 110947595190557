import { Widths, Heights } from "@/lib/sizes";
import { ElementsResponse } from "@/lib/pb/types";
import CodeHike from "@/components/codehike/CodeHike";
import { cn, Direction } from "@/lib/utils";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import { useState } from "react";

export default function Image({ element }: { element: ElementsResponse }) {
  const data = element.original ? element.original.data : element.data;

  //can not be translated (use original)
  const width = data?.width || "md";
  const height = data?.height || "md";
  const placement = data?.placement || "right";

  return (
    <div className="w-full">
      <div
        className={cn(
          " flex max-w-3xl mx-auto justify-center gap-6",
          Direction[placement as keyof typeof Direction],
        )}
      >
        <CodeHike item={element} code={element.data.description} />
        <ImageViewer width={width} height={height} url={element.data.url}/>
      </div>
    </div>
  );
}

export function ImageViewer({
  width = "full",
  height = "full",
  url,
  className="bg-white",
  useThumbnail = false,
}: {
  width?: string;
  height?: string;
  url: string;
  className?: string;
  useThumbnail?: boolean;
}) {
  const [cover, setCover] = useState(false);
  const style = {
    maxHeight: Heights[height],
  };
  

  return (
    <Dialog modal={false}>
      <DialogTrigger asChild>
        <div className="flex">
          <img
            src={useThumbnail ? url + "?thumb=100x100" : url}
            style={style}
            className={cn(
              Widths[width],
              "object-contain cursor-zoom-in rounded-md  border-2 border-transparent hover:border-primary",
              className,
            )}
          />
        </div>
      </DialogTrigger>
      <DialogContent className="flex max-w-full max-h-screen h-full bg-background/75 items-center justify-center">
        <img src={url} className={cn("w-full h-full", cover ? "object-cover" : "object-contain bg-white")} onClick={()=>setCover(!cover)} />
      </DialogContent>
    </Dialog>
  );
}
