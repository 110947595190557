import { UnitsResponse } from "@/lib/pb/types";
import { Skeleton } from "@/components/ui/skeleton";
import { UnitItem } from "@/components/sidebar/unit-item";
import UnitAdminMenu, { CreateUnitButton } from "@/lms/unit/UnitAdminMenu";
import { Clipboard } from "@/lms/core/Clipboard";
import { BookOpen } from "lucide-react";
import { useProgressTree } from "@/lib/store";
export function UnitsList({
  units,
  isAdmin,
}: {
  units: UnitsResponse[];
  isAdmin: boolean;
}) {
  const progressTree = useProgressTree();

  return (
    <div className="grid mt-4 gap-2 w-full">
      <div className="flex gap-4 w-full mb-4">
        <div className="ms-4 text-xl font-semibold leading-none tracking-tight my-auto flex gap-4">
          <BookOpen />
          Units
        </div>
        {isAdmin && (
          <div className="flex gap-4">
            <CreateUnitButton />
            <Clipboard />
          </div>
        )}
      </div>
      <div className="w-full flex flex-col gap-4">
        {!units ? (
          <Skeleton className="h-10 w-full" />
        ) : (
          units?.map((unit) => {
            const unitProgress = progressTree?.[unit.id];
            return (
              <div key={unit.id} className="flex w-full items-center">
                <UnitItem unit={unit} progress={unitProgress}/>
               
                {isAdmin && (
                  <UnitAdminMenu item={unit.original || unit} items={units} />
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}
